import { Svg, Path } from 'react-native-svg';

export const Colones = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (<Svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M15.6816 2.13281L12.4241 22.0002" stroke="#2F322F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M12.2773 2L9.01977 21.8674" stroke="#2F322F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M18 7H10.2C9.08609 7 8.0178 7.52678 7.23015 8.46447C6.4425 9.40215 6 10.6739 6 12C6 13.3261 6.4425 14.5979 7.23015 15.5355C8.0178 16.4732 9.08609 17 10.2 17H16.2C17.3139 17 17.4 17 18 17" stroke="#2F322F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>)

}