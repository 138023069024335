import { Platform } from 'react-native';
import { Svg, Path, SvgXml } from 'react-native-svg';

export const LeftArrow = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    if (Platform.OS == 'web') {
        return (
            <Svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <Path d="M15 18L9 12L15 6" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </Svg>
        )
    }

    let image =
        `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 18L9 12L15 6" stroke="#DB7500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
    return <SvgXml fill={color} xml={image} height={height} width={width} />
}