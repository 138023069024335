export const getIdsFromConsultation = (nutritionalConsultations) => {
    return nutritionalConsultations.map(consultation => consultation.id)
}

export const AddConsultationToCounter = async (cart, setSelections) => {
    cart.nutritional_consultation.map(item => setSelections(value => parseInt(value) + 1))
}

export const getIdsFromPackages = (packages) => {
    return packages.map(p => p.armed_package)
}