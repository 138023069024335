import { Svg, G, Path, Rect, Circle } from 'react-native-svg';

export const PamilyPortion = (
    {
        color,
        height,
        width,
        active = false,
        solid = false
    }
) => {

    return (
        <Svg fill={'black'} height={height} viewBox="0 0 512 512" width={width} xmlns="http://www.w3.org/2000/svg">
            <G id="FilledOutline">
                <Path d="m319.14 126.23a56 56 0 0 1 -62.33 48.85c-30.67-3.72-44.61-30.6-40.92-61.27 0-.03.01-.07.01-.1 3.72-30.7 24.65-61.48 55.35-57.76 18.16 2.21 32.89 15.64 41.13 32.61 5.69 11.72 8.28 25.12 6.76 37.67z" fill="#85bb40" />
                <Path d="m319.14 126.23a56 56 0 0 1 -62.33 48.85c-30.67-3.72-44.61-30.6-40.92-61.27a72.45 72.45 0 0 1 16.11-1.81 71.586 71.586 0 0 1 39.46 11.79 96.555 96.555 0 0 1 40.92-35.23c5.69 11.72 8.28 25.12 6.76 37.67z" fill="#7ba03f" />
                <Path d="m432 176v72h-160v-72a80 80 0 0 1 64-78.4v-57.6h32v57.6a80.033 80.033 0 0 1 64 78.4z" fill="#0093b9" />
                <Path d="m304 184a71.92 71.92 0 0 1 -32 59.87v-67.87a79.594 79.594 0 0 1 11.86-41.93 71.7 71.7 0 0 1 20.14 49.93z" fill="#0784a9" />
                <Path d="m212.2 255.86-19.56 16.62-29.21 24.82-134.71-158.48a32 32 0 1 1 48.77-41.45l33.37 39.26z" fill="#eaae48" />
                <Path d="m212.2 255.86-19.56 16.62-8.64 2.12-48-35.54-25.14-102.43z" fill="#d7a147" />
                <Path d="m220.83 249.08-12.27 3.01-49.88 12.25-49.58-202.01a32 32 0 1 1 62.16-15.25z" fill="#eaae48" />
                <Path d="m220.83 249.08-12.27 3.01a72.02 72.02 0 0 1 -18.13-126.89z" fill="#d7a147" />
                <Circle cx="232" cy="184" fill="#fbbe1f" r="56" />
                <Path d="m464 240v24l-1.71 16-19.99 186.56a24.007 24.007 0 0 1 -23.87 21.44h-324.86a24.007 24.007 0 0 1 -23.87-21.44l-19.99-186.56-1.71-16v-24z" fill="#bb8f49" />
                <Path d="m464 240v24l-1.71 16h-412.58l-1.71-16v-24z" fill="#a17837" />
                <Rect fill="#bb8f49" height="48" rx="24" width="464" x="24" y="216" />
                <Path d="m416 216v48l-42.5 134.58a14.378 14.378 0 0 1 -21.16 8.02l-51.97-31.18a25.447 25.447 0 0 1 -12.37-21.84v-137.58z" fill="#e4e4e3" />
                <Path d="m336 48h32v24h-32z" fill="#0784a9" />
                <Path d="m328 24h48v32h-48z" fill="#5db9d2" />
                <Path d="m496 240a32.036 32.036 0 0 0 -32-32h-416a32 32 0 0 0 -7.279 63.155l21.027 196.254a31.942 31.942 0 0 0 31.818 28.591h324.868a31.942 31.942 0 0 0 31.818-28.591l21.027-196.254a32.051 32.051 0 0 0 24.721-31.155zm-130.129 156.174a6.416 6.416 0 0 1 -5.871 4.478v-176.652h16v140.1zm-21.871-3.903-16-9.6v-158.671h16zm48-168.271h16v38.767l-16 50.666zm-96 0h16v149.071l-7.518-4.51a17.562 17.562 0 0 1 -8.482-14.982zm-264 16a16.019 16.019 0 0 1 16-16h232v32h-232a16.019 16.019 0 0 1 -16-16zm402.344 225.7a15.973 15.973 0 0 1 -15.91 14.3h-324.868a15.973 15.973 0 0 1 -15.91-14.3l-20.756-193.7h223.1v81.579a33.641 33.641 0 0 0 16.25 28.7l51.972 31.183a22.416 22.416 0 0 0 32.907-12.472l40.734-128.99h33.237zm29.656-209.7h-40v-32h40a16 16 0 0 1 0 32z" />
                <Path d="m80 288h24v16h-24z" />
                <Path d="m128 288h32v16h-32z" />
                <Path d="m80 328h24v16h-24z" />
                <Path d="m128 328h32v16h-32z" />
                <Path d="m416 328h16v16h-16z" />
                <Path d="m80 368h24v16h-24z" />
                <Path d="m128 368h32v16h-32z" />
                <Path d="m408 368h24v16h-24z" />
                <Path d="m88 408h16v16h-16z" />
                <Path d="m128 408h32v16h-32z" />
                <Path d="m184 408h32v16h-32z" />
                <Path d="m296 408h32v16h-32z" />
                <Path d="m408 408h16v16h-16z" />
                <Path d="m128 448h32v16h-32z" />
                <Path d="m184 448h32v16h-32z" />
                <Path d="m240 448h32v16h-32z" />
                <Path d="m352 448h32v16h-32z" />
                <Path d="m296 448h32v16h-32z" />
                <Path d="m133.155 193.907 15.539-3.814-6.65-27.093 20.927-8.37-5.942-14.856-18.836 7.535-4.016-16.362 28.794-11.517-5.942-14.856-26.7 10.682-4.019-16.364 28.661-11.464-5.942-14.856-26.57 10.628-5.59-22.774a24 24 0 1 1 46.617-11.441l17.9 72.922 15.539-3.814-17.9-72.922a40 40 0 1 0 -77.7 19.068z" />
                <Path d="m370.664 123.181-5.328 15.088a40.063 40.063 0 0 1 26.664 37.731h16a56.089 56.089 0 0 0 -37.336-52.819z" />
                <Path d="m280 184h16a64 64 0 1 0 -127.352 9.134l15.838-2.268a48.758 48.758 0 0 1 -.486-6.866 48 48 0 0 1 96 0z" />
                <Path d="m252.616 66.924a81.845 81.845 0 0 1 2.962 13.995l15.9-1.838a96.955 96.955 0 0 0 -3.121-15.348c.649.036 1.3.087 1.935.164 11.725 1.42 22.569 8.725 30.536 20.569l13.272-8.932c-10.6-15.759-25.479-25.534-41.888-27.52a49.014 49.014 0 0 0 -9.607-.218 154.756 154.756 0 0 0 -16.205-28.596l-12.8 9.594a142.409 142.409 0 0 1 13.259 23.013 54.424 54.424 0 0 0 -15.879 11.059c-9.872 9.744-17.367 23.69-21.107 39.267l15.559 3.734c5.05-21.057 15.689-33.514 27.184-38.943z" />
                <Path d="m214.286 171.819a21.588 21.588 0 0 0 13.543 4.823 25.561 25.561 0 0 0 10.889-2.591c8.72-4.1 13.638-11.294 13.852-20.247l-16-.381c-.047 1.977-.638 4.254-4.669 6.151-3.893 1.832-5.95.968-7.449-.12a12.026 12.026 0 0 1 -3.237-3.8l.041.085-14.477 6.813a27.578 27.578 0 0 0 7.507 9.267z" />
                <Path d="m67.832 197.181 12.191-10.362-8.068-9.493 21.245-19.426-10.8-11.8-20.814 19.027-10.2-12 17.814-16.294-10.8-11.807-17.385 15.9-6.2-7.29a24 24 0 0 1 36.575-31.087l19.24 22.632 12.191-10.362-19.236-22.631a40 40 0 1 0 -60.957 51.812z" />
                <Path d="m328 64v27.317a87.975 87.975 0 0 0 -46.4 31.871l12.8 9.612a71.908 71.908 0 0 1 43.2-27.357 8 8 0 0 0 6.4-7.843v-33.6h16v33.6a8 8 0 0 0 6.408 7.839 72.188 72.188 0 0 1 57.592 70.561v16h16v-16a88.286 88.286 0 0 0 -64-84.682v-27.318a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8h-48a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8zm8-32h32v16h-32z" />
                <Path d="m184 368h32v16h-32z" />
                <Path d="m184 288h32v16h-32z" />
                <Path d="m184 328h32v16h-32z" />
                <Path d="m240 408h32v16h-32z" />
                <Path d="m240 368h24v16h-24z" />
                <Path d="m240 328h24v16h-24z" />
                <Path d="m240 288h24v16h-24z" />
            </G>
        </Svg>
    )
}
