import { useContext, useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  FlatList,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import moment from "moment";
import { LocaleConfig, Calendar } from "react-native-calendars";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  title,
  buttomOption,
  buttomOptionOnFocus,
  webContainer,
  sectionContainerWeb,
} from "../../../styles/pages/generalStyles";
//components
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
import { InputOverall } from "../../widgets/inputOverall";
import { PopUp } from "../../widgets/popUp";
//context
import { nutritionalConsulte } from "../../../context/nutritionalConsulte";
import { orderContext } from "../../../context/orderingContext";
//controllers
import { addNutritionalConsulte } from "../../controllers/nutritionalConsulte";
//utils
import { capitalize } from "../../utils/utils";
import {
  getDisabledDates,
  getFirstAvailableDate,
} from "../../utils/dateSelecter";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useCartStore from "../shoppingCart/useCartStore";
import useUserData from "../../../hooks/useUserData";
import useModal from "../../../hooks/useModal";

const times = [
  { time: "9:00 a. m.", value: "09:00AM" },
  { time: "11:00 a. m.", value: "11:00AM" },
  { time: "2:00 p. m. ", value: "02:00PM" },
  { time: "4:00 p. m.", value: "04:00PM" },
  { time: "6:00 p. m.", value: "06:00PM" },
];

const SHORT_MONTH = {
  Jan: "Ene",
  Feb: "Feb",
  Mar: "Mar",
  Apr: "Abr",
  May: "May",
  Jun: "Jun",
  Jul: "Jul",
  Aug: "Ago",
  Sep: "Sep",
  Oct: "Oct",
  Nov: "Nov",
  Dec: "Dic",
};

const { width } = Dimensions.get("window");
export const MakeAnAppointment = ({
  route,
  navigation,
  bottomTabNavigation,
}) => {
  const { access_token } = useUserData();
  const { setCurrentStep } = useContext(orderContext);
  const {
    type_consultation,
    schedule_date,
    setScheduleDate,
    healthConditions,
    setHealthConditions,
    age,
    setAge,
  } = useContext(nutritionalConsulte);

  const [ageError, setAgeError] = useState(null);
  const [healthConditionsError, setHealthConditionsError] = useState(null);
  const [loding, setLoading] = useState(false);
  const [time, setTime] = useState();
  const [openModal, setOpenModal] = useState(false);

  const disabledDates = getDisabledDates();
  const today = getFirstAvailableDate(disabledDates);

  const [pickDay, setPickDay] = useState(today.getDate());
  const [pickYear, setPickYear] = useState(moment(today).format("YYYY"));

  const [NewDate, setNewDate] = useState(moment(today).format("YYYY-MM-DD"));
  const [NewDate_, setNewDate_] = useState({
    [NewDate]: {
      marked: true,
      selected: true,
      dotColor: "#DB7500",
      textColor: "#DB7500",
    },
  });

  const { isComputer } = useIsWeb();
  const { addNutricionalConsultationToCart, createOrUpdateCart } =
    useCartStore();
  const { openMessageModal } = useModal();

  LocaleConfig.locales["es"] = {
    monthNames: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    monthNamesShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    dayNames: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    dayNamesShort: ["D", "L", "M", "M", "J", "V", "S"],
  };
  LocaleConfig.defaultLocale = "es";

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  useEffect(() => {
    if (route.name !== "selectTime") {
      setScheduleDate(NewDate);
      setNewDate_({
        [NewDate]: {
          marked: true,
          selected: true,
          dotColor: "#DB7500",
          textColor: "#DB7500",
        },
      });
    }
  }, [NewDate]);

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
    setTime(times[0].value);
    return () => {};
  }, []);

  const handleAddNutritionalConsulte = async () => {
    setLoading(true);
    try {
      const newConsult = await addNutritionalConsulte(
        {
          type_consultation,
          age: age,
          delivery_hour: time,
          schedule_date: schedule_date,
          text: healthConditions,
        },
        access_token
      );
      if (newConsult?.id) {
        addNutricionalConsultationToCart(newConsult);
        const cartResponse = await createOrUpdateCart(access_token);

        if (cartResponse) {
          setAge("");
          setScheduleDate("");
          setHealthConditions("");
          setCurrentStep(1);
          setOpenModal(true);
        } else {
          openMessageModal(cartResponse?.data?.message);
        }
      }
    } catch (error) {
      openMessageModal(
        error?.message ||
          "La fecha seleccionada no está disponible. Debe agendar con al menos una semana de anticipación."
      );
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    if (!age) setAgeError("La edad es requerida");

    if (!healthConditions)
      setHealthConditionsError("Debe indicar su condición de salud");

    if (age && healthConditions && healthConditions) {
      setAgeError(null);
      setHealthConditionsError(null);
      navigation.navigate("selectTime");
    }
  };

  return (
    <View style={styles.screen}>
      <ScrollView>
        <View
          style={[
            styles.container,
            webContainer("calc(100vh - 220px)"),
            isComputer && sectionContainerWeb(600, null, "100%"),
          ]}
        >
          <Text style={title("100%", "center")}>Agende su cita</Text>
          {route.name !== "selectTime" ? (
            <View style={isComputer && { alignItems: "center" }}>
              <Text style={[styles.generalText, { marginVertical: 25 }]}>
                Agende su consulta nutricional y nos pondremos en contacto con
                usted para confirmar y coordinar los detalles su cita.
              </Text>
              <InputOverall
                label="Edad*"
                value={age}
                keyboardType={"numeric"}
                placeholder="Edad"
                onChangeText={(value) => {
                  setAgeError(null);
                  setAge(value);
                }}
                type={"outlined"}
                marginT={10}
                noWeb={true}
                errors={ageError}
              />
              <InputOverall
                label="Condiciones especiales de salud*"
                value={healthConditions}
                placeholder="Condiciones especiales de salud"
                onChangeText={(value) => {
                  setHealthConditionsError(null);
                  setHealthConditions(value);
                }}
                multiline
                numberOfLines={4}
                type={"outlined"}
                marginT={10}
                noWeb={true}
                errors={healthConditionsError}
              />
            </View>
          ) : (
            <Text
              style={[
                styles.generalText,
                { marginVertical: 25, textAlign: "center" },
              ]}
            >
              Elija un horario.
            </Text>
          )}
          {route.name !== "selectTime" ? (
            <View style={{ paddingBottom: 30 }}>
              <Text
                style={[
                  styles.generalText,
                  { marginTop: 15, marginBottom: 15 },
                ]}
              >
                Seleccione la fecha
              </Text>
              <Calendar
                initialDate={today.toString()}
                minDate={today.toString()}
                onDayPress={(date_) => {
                  setPickDay(date_.day < 10 ? "0" + date_.day : date_.day);
                  setNewDate(
                    `${moment(new Date(NewDate)).format("YYYY")}-${
                      date_.month < 10 ? "0" + date_.month : date_.month
                    }-${date_.day < 10 ? "0" + date_.day : date_.day}`
                  );
                }}
                onDayLongPress={(date_) => {
                  setPickDay(date_.day < 10 ? "0" + date_.day : date_.day);
                  setNewDate(
                    `${moment(new Date(NewDate)).format("YYYY")}-${
                      date_.month < 10 ? "0" + date_.month : date_.month
                    }-${date_.day < 10 ? "0" + date_.day : date_.day}`
                  );
                }}
                monthFormat={"yyyy MM"}
                onMonthChange={(date_) => {
                  setPickDay(date_.day < 10 ? "0" + date_.day : date_.day);
                  setPickYear(date_.year);
                  setNewDate(
                    `${date_.year}-${
                      date_.month < 10 ? "0" + date_.month : date_.month
                    }-${date_.day < 10 ? "0" + date_.day : date_.day}`
                  );
                }}
                // firstDay={1}
                renderArrow={(direction) => {
                  return direction == "left" ? (
                    <BaranaIcons
                      draw={{ width: 22, height: 22 }}
                      name={"leftCalendarArrow"}
                    />
                  ) : (
                    <BaranaIcons
                      draw={{ width: 22, height: 22 }}
                      name={"rigthCalendarArrow"}
                    />
                  );
                }}
                markingType={"period"}
                markedDates={{ ...disabledDates, ...NewDate_ }}
                renderHeader={(date) => {
                  return (
                    <Text>
                      {`${
                        SHORT_MONTH[moment(new Date(date)).format("MMM")]
                      } ${pickDay}, ${pickYear}`}
                    </Text>
                  );
                }}
              />
            </View>
          ) : (
            <ScrollView
              showsHorizontalScrollIndicator={false}
              horizontal={true}
              style={{ width: "100%" }}
              contentContainerStyle={{ width: "100%" }}
            >
              <FlatList
                bounces={false}
                style={{ width: !isComputer ? width - 40 : "100%" }}
                data={times}
                renderItem={({ item, index }) => {
                  return (
                    <TouchableOpacity
                      key={`${index}-makeAnAppointment`}
                      style={[
                        buttomOption(55, 10, 16),
                        time == item.value && buttomOptionOnFocus(),
                      ]}
                      onPress={() => setTime(item.value)}
                    >
                      <Text style={styles.cardText}>
                        {capitalize(item.time)}
                      </Text>
                    </TouchableOpacity>
                  );
                }}
              />
            </ScrollView>
          )}
        </View>

        {openModal ? (
          <PopUp
            showModal={openModal}
            openModal={setOpenModal}
            title="¡Recibimos su solicitud!"
            text={"Pronto nos pondremos en contacto."}
            buttonText="Continuar"
            button={true}
            cancelButton={false}
            handleOnPress={() => {
              setOpenModal(false);
              setTimeout(() => {
                navigation.reset({
                  index: 0,
                  routes: [{ name: "ShoppingCart" }],
                });
              }, 50);
            }}
          />
        ) : null}
      </ScrollView>
      <LowerOptionsContainer>
        <GeneralButton
          text={route.name !== "selectTime" ? "Agendar" : "Agregar al carrito"}
          handleOnPress={() => {
            setCurrentStep((value) => parseInt(value) + 1);
            route.name !== "selectTime" && validateInputs();
            route.name == "selectTime" && handleAddNutritionalConsulte();
          }}
          disabled={loding}
          loading={loding}
          width={isComputer ? 350 : "100%"}
        />
      </LowerOptionsContainer>
    </View>
  );
};
