import { Svg, G, Defs, Path, ClipPath, Rect } from 'react-native-svg';


export const Subscriptions = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
      <Svg
        width={width}
        height={height}
        viewBox="0 0 45 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path d="M31.488 15.6719V20.9322H26.2871" fill="#DB7500" />
        <Path d="M11.5117 28.1139V22.8535H16.7126" fill="#DB7500" />
        <Path
          d="M14.1264 19.3695C14.5652 18.1099 15.3117 16.983 16.2965 16.0935C17.7826 14.7548 19.7188 14.0419 21.708 14.1011C23.6973 14.1602 25.5887 14.987 26.9945 16.4117L29.795 19.3695M13.0352 24.3885L15.9726 27.5595C16.9041 28.5013 18.0563 29.1892 19.3216 29.5591C20.5869 29.929 21.9242 29.9689 23.2089 29.675C24.4935 29.3811 25.6837 28.7631 26.6685 27.8784C27.6533 26.9938 28.4005 25.8713 28.8407 24.6158"
          stroke="#DB7500"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <Path
          d="M22.5 44.5C34.3741 44.5 44 34.8741 44 23C44 11.1259 34.3741 1.5 22.5 1.5C10.6259 1.5 1 11.1259 1 23C1 34.8741 10.6259 44.5 22.5 44.5Z"
          stroke="#DB7500"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    );
}