import {
  View,
  Text,
  FlatList,
  Dimensions,
  ScrollView,
  Image,
} from "react-native";
import { useTheme } from "react-native-paper";
//styles
import {
  styles,
  title,
  titleMargin,
  paragraph,
  sectionContainerWeb,
  flexContainer,
} from "../../../styles/pages/generalStyles";
import {
  sections,
  centerAlignedRow,
} from "../../../styles/pages/home/homePage";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

const technologys = [
  { text: "La comida se mantiene fresca más tiempo." },
  { text: "Captura el sabor de lo recién hecho." },
  { text: "Evita que se impregnen olores de la nevera." },
  { text: "Materiales reciclables." },
];

const { width } = Dimensions.get("window");
export const PackagingTechnology = ({ background }) => {
  const { isComputer } = useIsWeb();
  const { colors } = useTheme();
  return (
    <View style={{ backgroundColor: "#F7F7F7" }}>
      <Image
        style={{ width: "100%", height: isComputer ? 280 : 200 }}
        source={require("../../../assets/illustrations/Empaque_comida_preparada-Barana.jpg")}
      />
      <View style={sections(background == "dark", 280, 36, 20)}>
        <View
          style={
            isComputer && sectionContainerWeb(1000, null, "100%", "center")
          }
        >
          <Text
            style={[
              title("100%", isComputer ? "center" : "left", null, isComputer),
              {
                marginBottom: 30,
              },
            ]}
          >
            Tecnología de empaque única en el país
          </Text>
          <View
            style={[
              flexContainer(
                "row",
                "space-evenly",
                null,
                "wrap",
                null,
                null,
                20
              ),
              { width: "100%" },
            ]}
          >
            {technologys?.map((item, index) => {
              return (
                <View
                  key={`${index}-technology`}
                  style={[
                    centerAlignedRow(0.5),
                    { width: "100%", maxWidth: 500 },
                  ]}
                >
                  {/* <Text style={styles.vignette}></Text> */}
                  <View style={styles.darkCircle}>
                    <Text style={[styles.number]}>{index + 1}</Text>
                  </View>
                  <Text style={paragraph(null, isComputer ? 18 : 15)}>
                    {item.text}
                  </Text>
                </View>
              );
            })}
          </View>
          {/* <ScrollView
            showsHorizontalScrollIndicator={false}
            horizontal={true}
            style={isComputer ? { width: "100%" } : undefined}
            contentContainerStyle={isComputer ? { width: "100%" } : undefined}
          >
            <FlatList
              bounces={false}
              style={{ width: isComputer ? "100%" : width - 40 }}
              data={technologys}
              contentContainerStyle={
                isComputer
                  ? flexContainer(
                      "row",
                      "space-evenly",
                      null,
                      "wrap",
                      null,
                      20,
                      20
                    )
                  : undefined
              }
              renderItem={({ item, index }) => {
                return (
                  <View
                    key={`${index}-technology`}
                    style={[centerAlignedRow(0.5), { width: 400 }]}
                  >
                    <Text style={styles.vignette}></Text>
                    <View style={styles.darkCircle}>
                      <Text style={[styles.number]}>{index + 1}</Text>
                    </View>
                    <Text style={paragraph(null, isComputer ? 18 : 15)}>
                      {item.text}
                    </Text>
                  </View>
                );
              }}
            />
          </ScrollView> */}
        </View>
      </View>
      {isComputer ? <View style={[styles.line]}></View> : null}
    </View>
  );
};
