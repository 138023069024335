import { Svg, Path } from 'react-native-svg';


export const AdditiveFree = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 35 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M29.791 46.5C31.9787 46.5 33.75 44.2668 33.75 41.4928L23.6142 15.6109C23.6134 15.25 23.5815 14.8898 23.5188 14.5343V4.77039H23.8701C24.4024 4.77039 24.9129 4.55938 25.2893 4.18377C25.6657 3.80816 25.8772 3.29872 25.8772 2.76753C25.8763 2.30767 25.7168 1.86213 25.4256 1.50573C25.3204 1.49809 25.2147 1.49809 25.1095 1.50573H11.6268C11.0945 1.50573 10.584 1.71675 10.2076 2.09235C9.83119 2.46796 9.61973 2.9774 9.61973 3.50859C9.62064 3.96846 9.7801 4.414 10.0713 4.77039C10.1766 4.77823 10.2822 4.77823 10.3874 4.77039H10.7387V5.2711C11.0101 5.40538 11.3088 5.47563 11.6118 5.4764H11.968V16.5272L1.75193 42.2139C1.73136 42.9859 1.87551 43.7534 2.17483 44.4656C2.47415 45.1778 2.9218 45.8184 3.48807 46.3448C3.81243 46.4484 4.15103 46.5008 4.49161 46.5H29.791Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
            <Path d="M21.5414 30.8926C22.1317 30.8926 22.6102 30.4151 22.6102 29.826C22.6102 29.237 22.1317 28.7595 21.5414 28.7595C20.9512 28.7595 20.4727 29.237 20.4727 29.826C20.4727 30.4151 20.9512 30.8926 21.5414 30.8926Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
            <Path d="M20.0921 40.0959C20.0911 40.4661 19.9802 40.8277 19.7734 41.135C19.5666 41.4423 19.2732 41.6815 18.9302 41.8225C18.5872 41.9635 18.21 41.9998 17.8463 41.927C17.4826 41.8542 17.1487 41.6754 16.8868 41.4133C16.6248 41.1512 16.4466 40.8176 16.3746 40.4544C16.3026 40.0913 16.34 39.715 16.4822 39.3731C16.6244 39.0312 16.8649 38.7391 17.1734 38.5335C17.4819 38.328 17.8445 38.2183 18.2155 38.2183C18.4624 38.2183 18.7068 38.2669 18.9348 38.3613C19.1628 38.4557 19.3699 38.5941 19.5443 38.7685C19.7186 38.9429 19.8567 39.15 19.9507 39.3778C20.0447 39.6055 20.0928 39.8496 20.0921 40.0959Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
            <Path d="M13.9851 35.8247C15.6451 35.8247 16.9907 34.4819 16.9907 32.8255C16.9907 31.169 15.6451 29.8262 13.9851 29.8262C12.3252 29.8262 10.9795 31.169 10.9795 32.8255C10.9795 34.4819 12.3252 35.8247 13.9851 35.8247Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
        </Svg>

    )
}