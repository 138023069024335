import React, { useCallback } from "react";
import { debounce } from "lodash";

const useDebounce = (func) => {
  const debounceFunction = useCallback(debounce(func, 1500), []);

  return { debounceFunction };
};

export default useDebounce;
