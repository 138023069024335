import { View, Text, TouchableOpacity } from 'react-native'
//icons
import { BaranaIcons } from '../../assets/icons/BaranaIcons'
//styles
import * as widgetsStyles from '../../styles/pages/widgets/general'


export const SmallOrangeCards = ({ icon, text, selectedOption, onPress, list }) => {
    return (
        <TouchableOpacity
            style={[widgetsStyles.smallOrangeCards(selectedOption == text && '#FFF8EF')]}
            onPress={() => onPress(text)}
        // onPress={() => { list.length > 0 && onPress(text) }}
        >
            <View>
                <BaranaIcons draw={{ width: 28, height: 28 }} name={icon} />
            </View>
            <Text style={[widgetsStyles.styles.smallCardTypography]}>
                {text}
            </Text>
        </TouchableOpacity>
    )
}