import client from './httpClient'
import { addTrailingSlashOnIos } from "../utils/utils";
//--------------------------------------------------
//--------------SUBSCRIPTIONS REQUESTS-----------------------
//--------------------------------------------------

export const getSubscriptions = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(`/subscriptions/${id ? `${id}/` : ""}`)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data?.message);
    });
  return response;
};

export const createSubscriptions = async (token, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  try {
    const response = await client.post(`/subscriptions/`, data);
    return { subscription: response.data, created: true };
  } catch (error) {
    return { ...error.response.data, created: false };
  }
};

export const updateSubscriptions = async (token, id, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  try {
    const response = await client.patch(`/subscriptions/${id}/`, data);
    return { ...response.data, updated: true };
  } catch (error) {
    throw new Error(error.response.data?.message);
  }
};

export const paySubscriptions = async (token, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .post(`/subscriptions/subscription_pay/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data?.message);
    });
  return response;
};

export const automaticSuscripction = async (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(addTrailingSlashOnIos(`subscriptions/automatic_suscripction`))
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data?.message);
    });
  return response;
};

export const deleteSuscription = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .delete(`subscriptions/${id}/`)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data?.message);
    });
  return response;
};

export const get_last_package = async (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(addTrailingSlashOnIos(`subscriptions/get_last_package`))
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return response;
};