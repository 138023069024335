import { Svg, Path } from 'react-native-svg';


export const Prize = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M22.5004 2.09277L28.8209 15.1965L42.955 17.2897L32.7277 27.4838L35.1414 41.8644L22.5004 35.1032L9.85953 41.8644L12.2732 27.5048L2.0459 17.3107L16.18 15.2174L22.5004 2.09277Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    )
}