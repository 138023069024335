import { Svg, Path } from 'react-native-svg';

export const Equis = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M20 4L4 20M20 20L4 4L20 20Z" stroke="#FF941A" strokeWidth="2" strokeLinecap="round" />
        </Svg>
    )
}