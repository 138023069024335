import { View, Text, TouchableOpacity, Image } from "react-native";
//icons
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//styles
import {
  styles,
  buttomOption,
  buttomOptionOnFocus,
  plateImg,
  smallButton,
} from "../../../../styles/pages/generalStyles";
//utils
import { capitalize } from "../../../utils/utils";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";

export const PlateCard = ({
  item,
  navigation,
  openModalGarrinsons,
  handleOnPressMenu,
  editPlate,
}) => {
  const { isComputer } = useIsWeb();
  return (
    <View
      style={{
        width: isComputer ? 440 : "100%",
      }}
    >
      <TouchableOpacity
        onPress={() => {
          handleOnPressMenu(item.id);
        }}
        style={[
          buttomOption(190, 5),
          item.selected && buttomOptionOnFocus(),
          { height: 190 },
        ]}
      >
        <Image
          source={{ uri: item.image }}
          style={plateImg(isComputer ? "28%" : 105)}
        />
        <View
          style={[
            {
              width: "60%",
              padding: 15,
              height: 190,
              justifyContent: "space-between",
            },
          ]}
        >
          <Text style={[styles.cardText]}>{capitalize(item.recipe)} </Text>
          <Text
            ellipsizeMode="tail"
            numberOfLines={3}
            style={[styles.generalText]}
          >
            {item?.garrisons?.map((garrison) => `${garrison?.recipe}. `) || ""}
          </Text>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("detailsMenu", { id: item.id })
              }
            >
              <Text style={styles.orangeText}>Ver Detalles</Text>
            </TouchableOpacity>
            {item?.additional_amount > 0 ? (
              <Text style={styles.orangeText}>
                + ₡{item?.additional_amount}
              </Text>
            ) : null}
          </View>
        </View>
        {editPlate ? (
          <View
            style={{
              height: "100%",
              width: "10%",
            }}
          >
            <TouchableOpacity
              style={[smallButton()]}
              onPress={() => openModalGarrinsons(item)}
            >
              <View style={styles.checkIcon}>
                <BaranaIcons draw={{ width: 22, height: 22 }} name={"pencil"} />
              </View>
            </TouchableOpacity>
          </View>
        ) : null}
      </TouchableOpacity>
    </View>
  );
};
