//Get the package to edit
export const sortPackageToEdit = async (subPackage, armedPackageId, mealTimes, mainPackage) => {
    let orderDefinition = {};
    const { desayunos, almuerzos, cenas, generic } = subPackage;

    orderDefinition = {
        sub_package_id: subPackage.sub_package_id,
        barama_time_food: mealTimes,
        armed_package_id: armedPackageId,
        packageTypeId: mainPackage || null,
        breakfasts: {
            selected: desayunos?.plan?.id ? true : false,
            price: undefined,
            plate_default: desayunos?.plates || desayunos?.plate_default,
            plan: desayunos?.plan,
        },
        lunches: {
            selected: almuerzos?.plan?.id ? true : false,
            lunchAndDinner: almuerzos?.plan?.id && cenas?.plan?.id ? true : false,
            price: undefined,
            plate_default: almuerzos?.plates || almuerzos?.plate_default,
            plan: almuerzos?.plan,
        },
        dinners: {
            selected: cenas?.plan?.id ? true : false,
            lunchAndDinner: almuerzos?.plan?.id && cenas?.plan?.id ? true : false,
            price: undefined,
            plate_default: cenas?.plates || cenas?.plate_default,
            plan: cenas?.plan,
        },
        adult_or_family: {
            selected: generic?.plan?.id ? true : false,
            price: undefined,
            plate_default: generic?.plates || generic?.plate_default,
            plan: generic?.plan,
        },
    }

    return orderDefinition;
}

export const sortPackageToDelete = async (subPackage, armedPackageId, mealTimes, deleteSubPackage) => {
    let orderDefinition = {};
    const { desayunos, almuerzos, cenas, generic } = subPackage;

    orderDefinition = {
        sub_package_id: subPackage.sub_package_id,
        barama_time_food: mealTimes,
        armed_package_id: armedPackageId,
        breakfasts: deleteSubPackage?.includes('desayunos') ? {} : getSubPackage(desayunos),
        lunches: deleteSubPackage?.includes('almuerzos') ? {} : getSubPackage(almuerzos, almuerzos?.plan?.id && cenas?.plan?.id ? true : false),
        dinners: deleteSubPackage?.includes('cenas') ? {} : getSubPackage(cenas, almuerzos?.plan?.id && cenas?.plan?.id ? true : false),
        adult_or_family: deleteSubPackage?.includes('adulto') || deleteSubPackage?.includes('familiar') ? {} : getSubPackage(generic),
    }

    return orderDefinition;
}

const getSubPackage = (subPackage, setLunchAndDinner) => {
    if (setLunchAndDinner) {
        return {
            selected: subPackage?.plan?.id ? true : false,
            lunchAndDinner: setLunchAndDinner,
            price: undefined,
            plate_default: subPackage?.plates,
            plan: subPackage?.plan,
        }
    } else {
        return {
            selected: subPackage.plan?.id ? true : false,
            price: undefined,
            plate_default: subPackage?.plates,
            plan: subPackage?.plan,
        }
    }
}

export const AddPackageToCounter = async (cart, setSelections) => {
    cart.armed_packages.map(item => setSelections(value => parseInt(value) + 1))
}
