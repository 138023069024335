import { addTrailingSlashOnIos } from "../utils/utils";
import client from "./httpClient";

//--------------------------------------------------
//--------------SHOPPINGCART REQUESTS-----------------------
//--------------------------------------------------

export const getShoppingCart = async (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(addTrailingSlashOnIos(`/shopping_cart`))
    .then((value) => {
      const shopping_cart = value.data;
      return shopping_cart.shopping_cart ? shopping_cart : null;
    })
    .catch((error) => {
      throw new Error(error.response.detail);
    });
  return response;
};

export const createShoppingCart = async (data, token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .post(`/shopping_cart/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const deleteShoppingCart = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .delete(`/shopping_cart/${id}/`)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const updateShoppingCart = async (token, id, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .patch(`/shopping_cart/${id}/`, {
      address: data?.address,
      armed_packages: data?.armed_packages,
      combos: data?.combos,
      plates_supermaker: data?.plates_supermaker,
      dni: data?.dni,
      facture: data?.facture,
      method_payment: data?.method_payment,
      note: data?.note,
      nutritional_consultation: data?.nutritional_consultation,
      shopping_cart: data?.shopping_cart,
      type_method_pay: data?.type_method_pay,
    })
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const duplicateCartPackages = async (token, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .post(`/shopping_cart/subpackage_operation/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};
