import { useContext } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  Image,
  Linking,
  Platform,
} from "react-native";
// contexts
import { userFlow } from "../../context/userFlow";
import { shoppingCart } from "../../context/shoppingCart";
//icons
import { BaranaIcons } from "../../assets/icons/BaranaIcons";
//styles
import { titleStyle } from "../../styles/pages/navigators/headers";
import { styles } from "../../styles/pages/widgets/navBar";
import {
  BaranaIconStyle,
  flexContainer,
} from "../../styles/pages/generalStyles";
//hooks
import useUserData from "../../hooks/useUserData";
import useCartStore from "../screen/shoppingCart/useCartStore";
import useIsWeb from "../../hooks/useIsWeb";

export const NavBar = ({ navigation, route, marginL, headerTitle }) => {
  const { cart } = useCartStore();
  const { setShowMenu } = useContext(userFlow);
  const { isLoggedIn } = useUserData();
  const { isComputer } = useIsWeb();

  const selections =
    (cart?.armed_packages?.length || 0) +
    (cart?.combos?.length || 0) +
    (cart?.nutritional_consultation?.length || 0) +
    (cart?.plates_supermaker?.length || 0);

  const handleWhatsAppPress = async () => {
    await Linking.openURL("https://wa.link/1eyqs1");
  };

  const handleTelegramPress = async () => {
    await Linking.openURL("https://telegram.me/Baranacr");
  };

  return (
    <View style={[styles.navBarContainer, marginL && { marginLeft: marginL }]}>
      {!isLoggedIn || (route.name == "homePage" && isLoggedIn) ? (
        <>
          <View>
            <Image
              source={require("../../assets/illustrations/Barana.png")}
              style={BaranaIconStyle()}
            />
          </View>
          <View style={[styles.menu]}>
            {!isLoggedIn ? (
              <TouchableOpacity
                onPress={() => navigation.navigate("Signup")}
                style={styles.log}
              >
                <BaranaIcons
                  draw={{ width: 40, height: 24 }}
                  name={"profile"}
                />
                <Text style={styles.text}>Ingresar</Text>
              </TouchableOpacity>
            ) : (
              <View style={styles.whatsapp_container}>
                <TouchableOpacity onPress={() => handleWhatsAppPress()}>
                  <Image
                    source={require("../../assets/illustrations/whatsapp.png")}
                    style={styles.whatsapp}
                  />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleTelegramPress()}>
                  <Image
                    source={require("../../assets/illustrations/telegram.png")}
                    style={styles.whatsapp}
                  />
                </TouchableOpacity>
              </View>
            )}
            <TouchableOpacity
              onPress={() =>
                cart?.shopping_cart || isLoggedIn
                  ? navigation.navigate("ShoppingCart")
                  : navigation.navigate("Signup")
              }
              style={styles.icon}
            >
              {cart?.shopping_cart && selections > 0 ? (
                <View style={styles.notification}>
                  <Text>{selections}</Text>
                </View>
              ) : null}
              <BaranaIcons
                draw={{ width: 40, height: 24 }}
                name={"shoppingCart"}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setShowMenu(true)}
              tyle={styles.icon}
            >
              <BaranaIcons draw={{ width: 24, height: 24 }} name={"menu"} />
            </TouchableOpacity>
          </View>
        </>
      ) : (
        <View
          style={[
            {
              width: "100%",
            },
            flexContainer("row", "space-between", "center"),
          ]}
        >
          <View
            style={[
              { flex: 1, paddingTop: Platform.OS === "ios" ? 15 : undefined },
              flexContainer(null, "center", "center"),
            ]}
          >
            <Text style={titleStyle(isComputer ? 25 : 20)}>{headerTitle}</Text>
          </View>
          <View style={{ alignSelf: "flex-end", justifyContent: "flex-end" }}>
            <TouchableOpacity
              onPress={() => navigation.navigate("ShoppingCart")}
              style={styles.icon}
            >
              {cart?.shopping_cart && selections > 0 ? (
                <View
                  style={
                    route.name == "homePage"
                      ? styles.notification
                      : styles.notification2
                  }
                >
                  <Text>{selections}</Text>
                </View>
              ) : null}
              <BaranaIcons
                draw={{ width: 40, height: 24 }}
                name={"shoppingCart"}
              />
            </TouchableOpacity>
          </View>
        </View>
      )}
    </View>
  );
};
