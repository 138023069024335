import { useEffect, useState } from "react";
import { NutritionalPlanList } from "../../plans/nutritionalPlanList";
//controllers
import { getPlansUser } from "../../../controllers/plans";
//hooks
import useUserData from "../../../../hooks/useUserData";
import useNutritionalPrescription from "../../../../hooks/useNutritionalPrescription";
import useOrderStore from "../../../../hooks/useOrderStore";
import {
  getTimeFoods,
  updateCustomPackage,
} from "../../../controllers/package";
import useModal from "../../../../hooks/useModal";

export const NutritionalPlans = ({
  navigation,
  bottomTabNavigation,
  route,
}) => {
  const { access_token } = useUserData();
  const {
    setNutritionalPlan,
    setIsCustom,
    setNutritionalPrescriptionInitialValue,
  } = useNutritionalPrescription();
  const { subpackage, setSubpackage, setPlan, subscription } = useOrderStore();
  const { openMessageModal } = useModal();
  const to = route.params?.to;

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  const handleGetPlans = async () => {
    try {
      const plans = await getPlansUser(access_token, "personalizado");
      return plans;
    } catch (error) {
      openMessageModal(error.message);
    }
  };

  const setPlanforThePackage = (plan) => {
    setPlan(plan);
    if (to.includes("desayunos")) {
      setSubpackage({
        ...subpackage,
        desayunos: { ...subpackage?.desayunos, plan },
      });
    } else if (to.includes("almuerzos")) {
      setSubpackage({
        ...subpackage,
        almuerzos: { ...subpackage?.almuerzos, plan },
      });
    } else if (to.includes("cenas")) {
      setSubpackage({
        ...subpackage,
        cenas: { ...subpackage?.cenas, plan },
      });
    } else if (to.includes("familiar")) {
      setSubpackage({
        ...subpackage,
        generic: { ...subpackage?.generic, plan },
      });
    }
  };

  const handleGetTimeFoods = async () => {
    try {
      const timeFoodResponse = await getTimeFoods(access_token);
      return timeFoodResponse;
    } catch (error) {
      openMessageModal(error.message);
    }
  };

  const handleUpdatePackage = async (setLoading) => {
    if (subpackage?.armed_package) {
      setLoading(true);
      try {
        const mealsTime = await handleGetTimeFoods();
        const timeFoodArray =
          subpackage?.food_time || subpackage?.times_food || [];
        const timesFoodId = timeFoodArray?.map((timeFood) => {
          const timeFoodAux = mealsTime.find(
            (mealTime) => mealTime.name.toLowerCase() === timeFood.toLowerCase()
          );
          if (timeFoodAux) return timeFoodAux.id;
        });
        const packageAux = {
          armed_package: subpackage?.armed_package,
          sub_package: {
            sub_package_id: subpackage?.sub_package_id,
            time_food: timesFoodId,
            almuerzos: {
              plates:
                subpackage?.almuerzos?.plates?.map((plate) => plate.id) || [],
              plan: subpackage?.almuerzos?.plan?.id,
            },
            cenas: {
              plates: subpackage?.cenas?.plates?.map((plate) => plate.id) || [],
              plan: subpackage?.cenas?.plan?.id,
            },
            desayunos: {
              plates:
                subpackage?.desayunos?.plates?.map((plate) => plate.id) || [],
              plan: subpackage?.desayunos?.plan?.id,
            },
            generic: {
              plates:
                subpackage?.familiar?.plates?.map((plate) => plate.id) ||
                subpackage?.ninguno?.plates?.map((plate) => plate.id) ||
                [],
              plan:
                subpackage?.familiar?.plan?.id || subpackage?.ninguno?.plan?.id,
            },
          },
        };
        const packageUpdated = await updateCustomPackage(
          access_token,
          packageAux
        );

        if (packageUpdated?.status === 200) {
          navigation.navigate("suscriptionDetails", {
            subscription_id: subscription.subscription_id,
          });
        }
      } catch (error) {
        openMessageModal(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <NutritionalPlanList
      handleGetNutritionalPlans={handleGetPlans}
      handleAddNutritionalPlan={() => {
        setNutritionalPrescriptionInitialValue();
        setIsCustom(true);
        navigation.navigate("nutritionalPrescription");
      }}
      handleConfirmButton={handleUpdatePackage}
      handleCancelButton={() => {
        navigation.goBack();
      }}
      handleEditNutritionalPlan={(plan) => {
        setNutritionalPlan(plan);
        setIsCustom(true);
        navigation.navigate("nutritionalPrescription", { id: plan.id });
      }}
      handleSelectPlan={setPlanforThePackage}
    />
  );
};
