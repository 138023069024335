import { StyleSheet } from 'react-native'

export const loginStyles = StyleSheet.create({
    //fidls of verification with OPT
    inputsContainer: {
        marginBottom: '6%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    codeFieldRoot: {
        marginTop: 20
    },
    cell: {
        borderWidth: 1,
        borderColor: '#e0e0e0',
        textAlign: 'center',
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 17,
        lineHeight: 22,
        color: '#2F322F',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 18,
        width: 52,
        height: 52,
        backgroundColor: '#FFFFFF',
        borderRadius: 5,
    },
    focusCell: {
        borderColor: '#c7c7c7',
    },
})