import { useEffect, useState } from "react";
import { Platform, Dimensions } from "react-native";

const useIsWeb = () => {
  const [isWeb, setIsWeb] = useState(false);
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get("window").width
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(Dimensions.get("window").width);
    };
    if (Platform.OS === "web") {
      Dimensions.addEventListener("change", handleResize);

      setIsWeb(windowWidth > 499);
    }

    return () => {
      if (Platform.OS === "web") {
        Dimensions.removeEventListener("change", handleResize);
      }
    };
  }, [windowWidth]);

  return { isComputer: isWeb, windowWidth };
};

export default useIsWeb;
