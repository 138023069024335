import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  FlatList,
  Dimensions,
  TouchableOpacity,
} from "react-native";
//controllers
import { getCouponList, addCoupon } from "../../../controllers/coupons";
//Styles
import {
  styles,
  buttomOption,
  webContainer,
  paragraph,
  sectionContainerWeb,
} from "../../../../styles/pages/generalStyles";
import { signupStyles } from "../../../../styles/pages/signup/signup";
import { centerAlignedRow } from "../../../../styles/pages/home/homePage";
//components
import { LoadingPage } from "../../../widgets/loadingPage";
import { CouponFormModal } from "../../shoppingCart/coupon/CouponFormModal";
//icons
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//hooks
import useUserData from "../../../../hooks/useUserData";
import useIsWeb from "../../../../hooks/useIsWeb";

const { width } = Dimensions.get("window");
export const CouponList = ({ navigation }) => {
  const { access_token } = useUserData();
  const [error, setError] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);
  const { isComputer } = useIsWeb();

  useEffect(() => {
    handleGetCouponsList();
  }, []);

  const handleGetCouponsList = async () => {
    setLoading(true);
    try {
      const couponsList = await getCouponList(access_token);
      setValues([...couponsList]);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddCoupon = async (coupon) => {
    try {
      const couponResponse = await addCoupon(access_token, coupon);
      setValues([...couponResponse]);
      setOpenFormModal(false);
    } catch (error) {
      setCouponError(error.message);
    }
  };

  return (
    <View style={styles.screen}>
      <ScrollView horizontal={false}>
        <View
          style={[
            styles.container,
            webContainer(null),
            isComputer && sectionContainerWeb(800, null, "100%", "center"),
          ]}
        >
          <TouchableOpacity
            onPress={() => setOpenFormModal(true)}
            style={[
              buttomOption(
                isComputer ? 30 : 52,
                null,
                isComputer ? 10 : 16,
                null,
                isComputer ? "50%" : "100%"
              ),
              { marginTop: 20 },
            ]}
          >
            <View style={styles.rowContainer}>
              <View style={{ flex: 1 }}>
                <Text style={signupStyles.optionButtonText}>Añadir cupón</Text>
              </View>
              <View>
                <BaranaIcons draw={{ width: 24, height: 24 }} name={"plus"} />
              </View>
            </View>
          </TouchableOpacity>
          {loading ? (
            <View style={{ marginTop: "50%" }}>
              <LoadingPage />
            </View>
          ) : (
            <View style={[styles.inputsContainer]}>
              {values.length === 0 ? (
                <Text
                  style={[
                    paragraph("100%", 17, 10),
                    { marginBottom: 10, marginTop: 25 },
                  ]}
                >
                  No tienes cupones disponibles
                </Text>
              ) : (
                <ScrollView
                  showsHorizontalScrollIndicator={false}
                  horizontal={true}
                  style={{ width: "100%" }}
                  contentContainerStyle={{ width: "100%" }}
                >
                  <FlatList
                    bounces={false}
                    style={{ width: isComputer ? "100%" : width - 40 }}
                    data={values}
                    renderItem={({ item, index }) => {
                      return (
                        <View
                          key={`${index}-location`}
                          style={buttomOption(85, 10, 16)}
                        >
                          <View style={{ width: "100%" }}>
                            <View
                              style={centerAlignedRow(
                                4,
                                "space-between",
                                "row",
                                "center"
                              )}
                            >
                              <Text style={styles.timeText}>Cupón: </Text>
                              <Text style={styles.helpText}>{item.coupon}</Text>
                            </View>
                            <View
                              style={centerAlignedRow(
                                4,
                                "space-between",
                                "row",
                                "center"
                              )}
                            >
                              <Text style={styles.timeText}>
                                Descuento de:{" "}
                              </Text>
                              <Text style={styles.helpText}>
                                {" "}
                                {item.ponderation} ₡
                              </Text>
                            </View>
                          </View>
                        </View>
                      );
                    }}
                  />
                </ScrollView>
              )}
            </View>
          )}
        </View>
      </ScrollView>
      {openFormModal ? (
        <CouponFormModal
          title="Añade un nuevo cupón"
          openModal={setOpenFormModal}
          handleAddCoupon={handleAddCoupon}
          error={couponError}
          setError={setCouponError}
        />
      ) : null}
      {/* <LowerOptionsContainer>
                <GeneralButton
                    text='Confirmar'
                    handleOnPress={() => navigation.goBack()}
                    width='100%'
                // loading={loding}
                />
            </LowerOptionsContainer> */}
    </View>
  );
};
