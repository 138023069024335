import { useState, useContext } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import {
  CodeField,
  Cursor,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
//styles
import {
  styles,
  title,
  titleMargin,
  paragraph,
  actionText,
  webContainer,
} from "../../../styles/pages/generalStyles";
import { OptCellShading } from "../../../styles/pages/widgets/shadows";
import { loginStyles } from "../../../styles/pages/login/globalLoginStyles";
//components
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
// contexts
import { forgotContext } from "../../../context/forgotContext";
//controllers
import {
  verifyEmailCode,
  RecoveryPassword,
} from "../../controllers/authentication";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useModal from "../../../hooks/useModal";

const CELL_COUNT = 4;
export const Verification = ({ route, navigation }) => {
  const { email, setCode } = useContext(forgotContext);
  const [loding, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const { isComputer } = useIsWeb();
  const { openMessageModal } = useModal();
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  const handleVerificationCode = async () => {
    setLoading(true);
    try {
      const res = await verifyEmailCode(value);
      if (res) {
        setCode(value);
        navigation.navigate("changePassword");
      }
    } catch (error) {
      openMessageModal(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRequest = async () => {
    setLoading(true);
    try {
      const res = await RecoveryPassword(email);
      if (res) {
        openMessageModal(
          "El código de verificación ha sido enviado a su correo electrónico"
        );
      }
    } catch (error) {
      openMessageModal(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={styles.screen}>
      <ScrollView>
        <View
          style={[
            styles.container,
            webContainer(!isComputer && "65vh", null, isComputer),
            isComputer && { alignItems: "center", marginTop: "5%" },
          ]}
        >
          <Text
            style={[
              isComputer ? title("70%", "center") : title("80%", "left"),
              titleMargin(isComputer),
            ]}
          >
            Verificación
          </Text>
          <Text style={paragraph("100%", null, null, isComputer && "center")}>
            Ingrese el código de verificación de 4 dígitos que enviamos a su
            correo electrónico.
          </Text>
          <View style={loginStyles.inputsContainer}>
            <CodeField
              ref={props.ref}
              {...props}
              value={value}
              onChangeText={setValue}
              cellCount={CELL_COUNT}
              rootStyle={loginStyles.codeFieldRoot}
              keyboardType="number-pad"
              textContentType="oneTimeCode"
              renderCell={({ index, symbol, isFocused }) => (
                <OptCellShading>
                  <Text
                    key={index}
                    style={[
                      loginStyles.cell,
                      isFocused && loginStyles.focusCell,
                    ]}
                    onLayout={getCellOnLayoutHandler(index)}
                  >
                    {symbol || (isFocused ? <Cursor /> : null)}
                  </Text>
                </OptCellShading>
              )}
            />
          </View>
          <TouchableOpacity
            style={{ flexDirection: "row" }}
            onPress={() => handleRequest()}
          >
            <Text style={styles.helpText}>¿No recibió el correo? </Text>
            <Text style={actionText("#DB7500")}> Reenviar</Text>
          </TouchableOpacity>

          {isComputer ? (
            <View style={{ marginTop: 45 }}>
              <GeneralButton
                text="Continuar"
                handleOnPress={handleVerificationCode}
                disabled={loding || !value || value.length < 4}
                loading={loding}
                width={350}
              />
            </View>
          ) : null}
        </View>
      </ScrollView>
      {!isComputer ? (
        <LowerOptionsContainer>
          <GeneralButton
            text="Continuar"
            handleOnPress={handleVerificationCode}
            disabled={loding || !value || value.length < 4}
            loading={loding}
            width="100%"
          />
        </LowerOptionsContainer>
      ) : null}
    </View>
  );
};
