import React from "react";
import { Text, TouchableOpacity } from "react-native";
//icons
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  spaceBetween,
  buttomOption,
} from "../../../../styles/pages/generalStyles";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";

export const ProfileOption = ({ onPress, iconName, title }) => {
  const { isComputer } = useIsWeb();

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        buttomOption(isComputer ? 40 : 99, 11, 16),
        isComputer && { justifyContent: "inherit" },
      ]}
    >
      <BaranaIcons
        draw={{
          width: isComputer ? 28 : 67,
          height: isComputer ? 28 : 67,
        }}
        name={iconName}
      />
      <Text
        style={[
          styles.cardText,
          { width: "75%" },
          isComputer && { fontSize: 16, marginLeft: 20 },
        ]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  );
};
