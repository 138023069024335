import { useContext, useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  Platform,
  TouchableOpacity,
} from "react-native";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";
//controllers
import { getUserData, updateUserData } from "../../controllers/user";
import { updatePassword } from "../../controllers/authentication";
// contexts
import { mainContext } from "../../../context/mainContext";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//Styles
import { styles, webContainer } from "../../../styles/pages/generalStyles";
import { centerAlignedRow } from "../../../styles/pages/home/homePage";
import { profileStyles } from "../../../styles/pages/profile";
import * as inputStyles from "../../../styles/pages/widgets/inputs";
//components
import { GeneralButton } from "../../widgets/generalButton";
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { InputOverall } from "../../widgets/inputOverall";
import { SelectorModal } from "../../widgets/selectorModal";
import { LoadingPage } from "../../widgets/loadingPage";
import { PopUp } from "../../widgets/popUp";
//utils
import {
  Month,
  yearSelector,
  monthFormat,
  monthsArray,
  daySelector,
} from "../../utils/dateSelecter";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useUserData from "../../../hooks/useUserData";
import useModal from "../../../hooks/useModal";

const userDataValidatioSchema = yup.object({
  first_name: yup.string().required("El nombre es requerido."),
  full_name: yup.string().required("El apellido es requerido."),
  last_name: yup.string(),
  password: yup
    .string()
    .min(
      8,
      ({ min }) => `La contraseña debe contener al menos ${min} caracteres.`
    )
    .matches(/[A-Z]/, "La contraseña debe contener al menos una mayúscula.")
    .matches(/[a-z]/, "La contraseña debe contener al menos una minúscula.")
    .matches(
      /\W/g,
      "La contraseña debe contener al menos un caracter especial."
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "No coinciden las contraseñas."),
  email: yup
    .string()
    .email("Por favor introduzca un correo electrónico válido.")
    .required("El correo electrónico es requerido."),
  username: yup.string(),
  phone_number: yup
    .string()
    .length(
      8,
      ({ length }) =>
        `El número telefónico debe contener máximo ${length} digitos.`
    )
    .nullable(true),
  date_of_birth: yup.date().nullable(true),
  facture: yup.boolean(),
  dni: yup
    .string()
    .length(9, ({ length }) => `La cédula debe contener ${length} caracteres`)
    .nullable(true),
});

export const UserData = ({ navigation, bottomTabNavigation }) => {
  const { access_token, user_id, email } = useUserData();
  const { userProfile, setUserProfile } = useContext(mainContext);
  const [loding, setLoading] = useState(false);
  const [lodingButton, setLodingButton] = useState(false);
  const [days, setDays] = useState([]);
  const [years, setYears] = useState([]);
  const [setDay, setOpenDay] = useState(false);
  const [setMonth, setOpenMonth] = useState(false);
  const [setYear, setOpenYear] = useState(false);
  const [setElectronicInvoice, setOpenElectronicInvoice] = useState(false);
  const { isComputer } = useIsWeb();
  const { openMessageModal } = useModal();
  const [values, setValues] = useState({
    first_name: "",
    full_name: "",
    last_name: "",
    confirm_password: "",
    password: "",
    id: "",
    email: "",
    username: "",
    phone_number: null,
    date_of_birth: null,
    facture: false,
    dni: null,
  });

  const [pickDay, setPickDay] = useState();
  const [pickMonth, setPickMonth] = useState();
  const [pickYear, setPickYear] = useState();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
    handleGetUser();
    setYears(yearSelector(true));
    setDays(daySelector());
    return () => {};
  }, []);

  const formik = useFormik({
    initialValues: userProfile || values,
    validationSchema: userDataValidatioSchema,
    validateOnChange: false,
    onSubmit: (formValue) => {
      handleUpdateUser();
    },
  });

  useEffect(() => {}, [values]);

  useEffect(() => {
    const date = new Date(`${monthFormat[pickMonth]} ${pickDay} ${pickYear}`);
    setValues({ ...values, date_of_birth: moment(date).format("YYYY-MM-DD") });
    return () => {};
  }, [pickDay, pickMonth, pickYear]);

  const handleUpdatePassword = async () => {
    setLodingButton(true);

    await updatePassword({
      email: email,
      code: "cHJvZmlsZS1wYXNzd29yZA==",
      password: formik.values.password,
      comfirm_password: formik.values.confirm_password,
    })
      .then(async (res) => {
        handleGetUser();
        setLodingButton(false);
      })
      .catch((err) => {
        setLodingButton(false);
        openMessageModal(
          err.message ||
            "Algo inesperado ha ocurrido, intente de nuevo más tarde."
        );
      });
  };

  const handleUpdateUser = async () => {
    setLodingButton(true);

    await updateUserData(
      user_id,
      {
        first_name: formik.values.first_name,
        full_name: formik.values.full_name,
        last_name: formik.values.last_name,
        id: formik.values.id,
        email: formik.values.email,
        username: formik.values.username,
        phone_number: formik.values.phone_number || null,
        date_of_birth: values?.date_of_birth || null,
        facture: formik.values.facture,
        dni: formik.values.dni || null,
      },
      access_token
    )
      .then(async (res) => {
        setUserProfile({ ...formik.values });

        if (res?.updated) {
          if (formik.values.confirm_password && formik.values.password) {
            handleUpdatePassword().then(() => {
              openMessageModal("Contraseña actualizada.");
              navigation.goBack();
            });
          } else {
            openMessageModal("¡El perfil se ha actualizado!");
            navigation.goBack();
          }
        }
        setLodingButton(false);
      })
      .catch((err) => {
        setLodingButton(false);
        openMessageModal(
          err?.message ||
            "Algo inesperado ha ocurrido, intente de nuevo más tarde."
        );
      });
  };

  const handleGetUser = async () => {
    setLoading(true);
    await getUserData(user_id, access_token)
      .then(async (res) => {
        setValues({ ...res });
        setUserProfile({ ...res });

        formik.setFieldValue("first_name", res.first_name);
        formik.setFieldValue("full_name", res.full_name);
        formik.setFieldValue("last_name", res.last_name);
        formik.setFieldValue("id", res.id);
        formik.setFieldValue("email", res.email);
        formik.setFieldValue("username", res.username);
        formik.setFieldValue("phone_number", res.phone_number);
        formik.setFieldValue("date_of_birth", res.date_of_birth);
        formik.setFieldValue("facture", res.facture);
        formik.setFieldValue("dni", res.dni);

        if (res.date_of_birth) {
          let monthOfDate = moment(res.date_of_birth).format("MM");
          setPickDay(moment(res.date_of_birth).format("DD"));
          setPickMonth(monthsArray[monthOfDate]);
          setPickYear(moment(res.date_of_birth).format("YYYY"));
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <View style={styles.screen}>
      {loding ? (
        <LoadingPage />
      ) : (
        <ScrollView>
          <View
            style={[
              styles.container,
              webContainer("calc(100vh - 220px)", isComputer ? 50 : 20),
              isComputer && styles.sectionContainerWeb,
            ]}
          >
            {/* <Text style={[title('100%', 'center'), titleMargin(isComputer)]}>
                        Datos de usuario
                    </Text> */}
            <View style={[styles.inputsContainer]}>
              <InputOverall
                label="Nombre"
                value={formik.values.first_name}
                onChangeText={(text) =>
                  formik.setFieldValue("first_name", text)
                }
                errors={formik.errors.first_name}
                type={"outlined"}
                noWeb={true}
              />
              <InputOverall
                label="Dos apellidos"
                value={formik.values.last_name}
                onChangeText={(text) => formik.setFieldValue("last_name", text)}
                errors={formik.errors.last_name}
                type={"outlined"}
                noWeb={true}
              />
              <InputOverall
                label="Email"
                value={formik.values.email}
                onChangeText={(text) => formik.setFieldValue("email", text)}
                errors={formik.errors.email}
                type={"outlined"}
                noWeb={true}
              />
              <InputOverall
                label="Telefono"
                value={formik.values.phone_number}
                onChangeText={(text) =>
                  formik.setFieldValue("phone_number", text)
                }
                errors={formik.errors.phone_number}
                type={"outlined"}
                noWeb={true}
              />
              <View style={{ width: "100%" }}>
                <InputOverall
                  label="¿Desea factura electrónica?"
                  value={formik.values.facture ? "si" : "no"}
                  type={"outlined"}
                  editable={false}
                  onFocus={() =>
                    setOpenElectronicInvoice(!setElectronicInvoice)
                  }
                  errors={formik.errors.facture}
                  noWeb={true}
                  icon={
                    <TouchableOpacity
                      onPress={() =>
                        setOpenElectronicInvoice(!setElectronicInvoice)
                      }
                      style={inputStyles.dropDownListIcon()}
                    >
                      <BaranaIcons
                        draw={{ width: 24, height: 24 }}
                        name={setElectronicInvoice ? "rollOut" : "rollDown"}
                      />
                    </TouchableOpacity>
                  }
                />
                {setElectronicInvoice ? (
                  <SelectorModal
                    height={120}
                    width={isComputer && "45%"}
                    openModal={setOpenElectronicInvoice}
                    valueList={["si", "no"]}
                    // setValue={value => setValues({ ...values, facture: value == 'si' ? true : false })}
                    setValue={(text) =>
                      formik.setFieldValue(
                        "facture",
                        text == "si" ? true : false
                      )
                    }
                  />
                ) : null}
              </View>
              <InputOverall
                label="Cédula"
                value={formik.values.dni}
                onChangeText={(text) => formik.setFieldValue("dni", text)}
                errors={formik.errors.dni}
                type={"outlined"}
                noWeb={true}
              />
              <InputOverall
                label="Cambiar Contraseña"
                value={formik.values.password}
                onChangeText={(text) => formik.setFieldValue("password", text)}
                errors={formik.errors.password}
                type={"outlined"}
                noWeb={true}
              />
              <InputOverall
                label="Confirmar Contraseña"
                value={formik.values.confirm_password}
                onChangeText={(text) =>
                  formik.setFieldValue("confirm_password", text)
                }
                errors={formik.errors.confirm_password}
                type={"outlined"}
                noWeb={true}
              />
              <Text
                style={[
                  profileStyles.text,
                  {
                    marginBottom: 5,
                    marginTop: 10,
                    textAlign: "left",
                    width: "100%",
                  },
                ]}
              >
                Fecha de nacimiento
              </Text>
              <View
                style={[
                  centerAlignedRow(null, "space-between"),
                  { width: "100%", marginBottom: 10 },
                ]}
              >
                <InputOverall
                  label="Dia"
                  value={pickDay}
                  type={"outlined"}
                  editable={false}
                  onFocus={() => setOpenDay(!setDay)}
                  noWeb={true}
                  icon={
                    <TouchableOpacity
                      onPress={() => setOpenDay(!setDay)}
                      style={inputStyles.dropDownListIcon()}
                    >
                      <BaranaIcons
                        draw={{ width: 24, height: 24 }}
                        name={setDay ? "rollOut" : "rollDown"}
                      />
                    </TouchableOpacity>
                  }
                  width_={"32.5%"}
                />
                {setDay ? (
                  <SelectorModal
                    openModal={setOpenDay}
                    valueList={days}
                    setValue={(value) => setPickDay(value)}
                    width={isComputer && "45%"}
                  />
                ) : null}
                <InputOverall
                  label="Mes"
                  value={pickMonth}
                  type={"outlined"}
                  editable={false}
                  onFocus={() => setOpenMonth(!setMonth)}
                  noWeb={true}
                  icon={
                    <TouchableOpacity
                      onPress={() => setOpenMonth(!setMonth)}
                      style={inputStyles.dropDownListIcon()}
                    >
                      <BaranaIcons
                        draw={{ width: 24, height: 24 }}
                        name={setMonth ? "rollOut" : "rollDown"}
                      />
                    </TouchableOpacity>
                  }
                  width_={"32.5%"}
                />
                {setMonth ? (
                  <SelectorModal
                    openModal={setOpenMonth}
                    valueList={Month}
                    setValue={(value) => setPickMonth(value)}
                    width={isComputer && "45%"}
                  />
                ) : null}
                <InputOverall
                  label="Año"
                  value={pickYear}
                  type={"outlined"}
                  editable={false}
                  onFocus={() => setOpenYear(!setYear)}
                  noWeb={true}
                  icon={
                    <TouchableOpacity
                      onPress={() => setOpenYear(!setYear)}
                      style={inputStyles.dropDownListIcon()}
                    >
                      <BaranaIcons
                        draw={{ width: 24, height: 24 }}
                        name={setYear ? "rollOut" : "rollDown"}
                      />
                    </TouchableOpacity>
                  }
                  width_={"32.5%"}
                />
                {setYear ? (
                  <SelectorModal
                    openModal={setOpenYear}
                    valueList={years}
                    setValue={(value) => setPickYear(value)}
                    width={isComputer && "45%"}
                  />
                ) : null}
                {formik.errors.date_of_birth ? (
                  <Text style={styles.error}>
                    {formik.errors.date_of_birth}
                  </Text>
                ) : null}
              </View>
            </View>
          </View>
        </ScrollView>
      )}
      <LowerOptionsContainer>
        <GeneralButton
          text="Confirmar"
          handleOnPress={formik.handleSubmit}
          loading={lodingButton}
          disabled={lodingButton}
          width={isComputer ? 350 : "100%"}
        />
      </LowerOptionsContainer>
    </View>
  );
};
