import { Svg, Path } from 'react-native-svg';


export const ThumbsUpOutline = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M6.41732 20.1668H3.66732C3.18109 20.1668 2.71477 19.9737 2.37096 19.6299C2.02714 19.286 1.83398 18.8197 1.83398 18.3335V11.9168C1.83398 11.4306 2.02714 10.9643 2.37096 10.6205C2.71477 10.2767 3.18109 10.0835 3.66732 10.0835H6.41732M12.834 8.25016V4.5835C12.834 3.85415 12.5443 3.15468 12.0285 2.63895C11.5128 2.12323 10.8133 1.8335 10.084 1.8335L6.41732 10.0835V20.1668H16.7573C17.1995 20.1718 17.6285 20.0169 17.9654 19.7305C18.3023 19.4441 18.5244 19.0457 18.5907 18.6085L19.8557 10.3585C19.8955 10.0957 19.8778 9.82745 19.8037 9.57223C19.7296 9.317 19.6009 9.08093 19.4265 8.88038C19.2521 8.67984 19.0362 8.5196 18.7938 8.41078C18.5513 8.30196 18.2881 8.24715 18.0223 8.25016H12.834Z" stroke="#2F322F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    )
}