import { useEffect } from 'react'
import { Text, TouchableOpacity, ActivityIndicator } from 'react-native';
//color
import { useTheme } from 'react-native-paper'
//styles
import {
    overallButton,
    disabledOverallButton,
    textOverallButton
} from '../../styles/pages/widgets/general'


export const GeneralButton = (props) => {
    const { colors } = useTheme();
    const { text, handleOnPress, disabled, loading, width, customColor, marginR } = props;

    useEffect(() => {
    }, [loading])

    return (
        <TouchableOpacity
            style={[overallButton(customColor ? customColor : colors.secondary, width, marginR), disabled && disabledOverallButton(colors, width)]}
            onPress={() => handleOnPress()}
            disabled={disabled || loading}
        >
            {loading ?
                <ActivityIndicator size='small' color={'black'} /> :
                <Text style={textOverallButton()}>{text}</Text>}
        </TouchableOpacity>
    )
}