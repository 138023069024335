import { useState, useContext } from "react";
import { View, Text, ScrollView } from "react-native";
import { Formik } from "formik";
import * as yup from "yup";
//Styles
import {
  styles,
  title,
  titleMargin,
  paragraph,
  webContainer,
} from "../../../styles/pages/generalStyles";
//components
import { InputOverall } from "../../widgets/inputOverall";
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
//controllers
import { RecoveryPassword } from "../../controllers/authentication";
// contexts
import { forgotContext } from "../../../context/forgotContext";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useModal from "../../../hooks/useModal";

const loginValidationSchema = yup.object({
  email: yup
    .string()
    .email("Por favor introduzca un correo electrónico válido.")
    .required("El correo electrónico es requerido."),
});

export const ForgotPassword = ({ route, navigation }) => {
  const { setEmail } = useContext(forgotContext);
  const [typeInput, setTypeInput] = useState("outlined");
  const [loding, setLoading] = useState(false);
  const { isComputer } = useIsWeb();
  const { openMessageModal } = useModal();

  const handleRequest = async (values) => {
    setLoading(true);
    try {
      const res = await RecoveryPassword(values.email);
      if (res) {
        setEmail(values.email);
        navigation.navigate("checkYourEmailMessage");
      }
    } catch (error) {
      openMessageModal(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={loginValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleRequest}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
      }) => (
        <View style={styles.screen}>
          <ScrollView>
            <View
              style={[
                styles.container,
                webContainer(!isComputer && "65vh", null, isComputer),
                isComputer && { alignItems: "center", marginTop: "5%" },
              ]}
            >
              <Text
                style={[
                  title("100%", isComputer ? "center" : "left"),
                  titleMargin(isComputer),
                ]}
              >
                ¿Olvidó su contraseña?
              </Text>
              <Text
                style={[paragraph("94%", null, null, isComputer && "center")]}
              >
                Ingrese el correo electrónico asociado a su cuenta, y siga los
                pasos para cambiar la contraseña.
              </Text>
              <View style={styles.inputsContainer}>
                <InputOverall
                  label="Correo Electrónico"
                  value={values.email}
                  placeholder="Correo Electrónico"
                  onChangeText={handleChange("email")}
                  autoCapitalize={"none"}
                  type={typeInput}
                  errors={errors.email && touched.email && errors.email}
                  onBlur={() => handleBlur("email")}
                />
              </View>

              {isComputer ? (
                <View style={{ marginTop: 45 }}>
                  <GeneralButton
                    text="Continuar"
                    handleOnPress={handleSubmit}
                    disabled={loding || values.email === "" ? true : false}
                    loading={loding}
                    width={350}
                  />
                </View>
              ) : null}
            </View>
          </ScrollView>

          {!isComputer ? (
            <LowerOptionsContainer>
              <GeneralButton
                text="Continuar"
                handleOnPress={handleSubmit}
                disabled={loding || values.email === "" ? true : false}
                loading={loding}
                width="100%"
              />
            </LowerOptionsContainer>
          ) : null}
        </View>
      )}
    </Formik>
  );
};
