import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useNutritionalPrescription = create(
  devtools((set, get) => ({
    nutritionalPlan: {
      name: "",
      equivalence_system: "PINEC",
      type_plan: "",
      note: "",
      photo: null,
    },
    planPortions: {
      desayunos: {
        time_food: "desayunos",
        selected: false,
        carnes: 1,
        grasas: 0,
        leguminosas: 0,
        harinas: 1,
        vegetales: 0,
      },
      almuerzos: {
        time_food: "almuerzos",
        selected: false,
        carnes: 1,
        grasas: 1,
        leguminosas: 1,
        harinas: 1,
        vegetales: 1,
      },
      cenas: {
        time_food: "cenas",
        selected: false,
        carnes: 1,
        grasas: 1,
        harinas: 1,
        leguminosas: 1,
        vegetales: 1,
      },
    },
    isCustom: false,
    nutritionalPrescriptions: [],
    setNutritionalPlan: (newPlan) =>
      set((state) => ({
        nutritionalPlan: { ...state.nutritionalPlan, ...newPlan },
      })),
    setPlanPortions: (foodTime, newPortions) =>
      set((state) => ({
        planPortions: { ...state.planPortions, [foodTime]: newPortions },
      })),
    setIsCustom: (custom) =>
      set((state) => ({
        isCustom: custom,
      })),
    setNutritionalPrescriptions: (prescriptions) => {
      set({ nutritionalPrescriptions: prescriptions });
    },
    addNutritionalPrescription: (prescription) => {
      set((state) => ({
        nutritionalPrescriptions: [
          ...state.nutritionalPrescriptions,
          prescription,
        ],
      }));
    },
    removeNutritionalPrescription: (prescriptionId) => {
      const prescriptions = get().nutritionalPrescriptions.filter(
        (prescription) => prescription.id !== prescriptionId
      );
      set({ nutritionalPrescriptions: prescriptions });
    },
    updateNutritionalPrescriptions: (prescriptionUpdated) => {
      const prescriptionsAux = get().nutritionalPrescriptions;
      const prescriptionIndex = prescriptionsAux.findIndex(
        (prescription) => prescription.id === prescriptionUpdated.id
      );

      if (prescriptionIndex !== -1) {
        prescriptionsAux[prescriptionIndex] = prescriptionUpdated;
      }

      set({ nutritionalPrescriptions: prescriptionsAux });
    },
    setNutritionalPrescriptionInitialValue: () => {
      set({
        nutritionalPlan: {
          name: "",
          equivalence_system: "PINEC",
          type_plan: "",
          note: "",
          photo: null,
        },
        planPortions: {
          desayunos: {
            time_food: "desayunos",
            selected: false,
            carnes: 1,
            grasas: 0,
            leguminosas: 0,
            harinas: 1,
            vegetales: 0,
          },
          almuerzos: {
            time_food: "almuerzos",
            selected: false,
            carnes: 1,
            grasas: 1,
            leguminosas: 1,
            harinas: 1,
            vegetales: 1,
          },
          cenas: {
            time_food: "cenas",
            selected: false,
            carnes: 1,
            grasas: 1,
            harinas: 1,
            leguminosas: 1,
            vegetales: 1,
          },
        },
      });
    },
  }))
);

export default useNutritionalPrescription;
