import { DefaultTheme } from 'react-native-paper'

export const lightTheme = {
  ...DefaultTheme,
  dark: false,
  roundness: 4,
  colors: {
    ...DefaultTheme.colors,
    // default colors
    background: '#FFFBF4',
    surface: '#FFFFFF',
    onSurface: '#000000',
    error: '#DC6E6E',
    primary: '#d1d1d1',
    primary050: '#FFFBF4',
    primary100: '#E0E0E0',
    secondary: '#FF941A',
    secondary050: '#ec8209',

    // custom colors
    // onError: '#FFFFFF',
    // correct: '#5CB468',
    // onCorrect: '#FFFFFF',
    // onBackground: '#000000',
    // primary050: '#def2f1',
    // primary100: '#c0dad6',
    // primary200: '#9fbfbb',
    // primary300: '#7da5a0',
    // primary400: '#63908c',
    // primary500: '#497d78',
    // primary600: '#3f6e69',
    // primary700: '#315956',
    // primary800: '#234644',
    // primaryVariant: '#234644',
    // onPrimary: '#FFFFFF',
    // secondary: '#FFFBF4',
    // secondary050: '#fffbf4',
    // secondary100: '#faf6ef',
    // secondary200: '#f5f1ea',
    // secondary300: '#ebe7e1',
    // secondary400: '#c9c5bf',
    // secondary500: '#aba7a1',
    // secondary600: '#817e77',
    // secondary700: '#6c6963',
    // secondary800: '#4d4a44',
    // secondaryVariant: '#F5F1EA',
    // onSecondary: '#000000',
    // onBorder:'#13312F'
  },
}
