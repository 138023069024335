
import { View, ActivityIndicator } from 'react-native'
//styles
import * as generalStyles from '../../styles/pages/generalStyles'


export const LoadingPage = () => {
    return (
        <View style={[generalStyles.styles.screen, { justifyContent: 'center', alignItems: 'center' }]}>
            <ActivityIndicator size='small' color={'black'} />
        </View>
    )
}