import { StyleSheet } from 'react-native'
// import { StyleSheet as mediaQueryStyles } from 'react-native-media-query';

export const homeStyles = StyleSheet.create({
  helperText: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 17,
    lineHeight: 22,
    textAlign: "left",
    letterSpacing: 0.01,
    color: "#4F534F",
  },
  orangeBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingVertical: 20,
    paddingHorizontal: 16,
    width: "100%",
    height: 170,
    backgroundColor: "#FFF8EF",
    borderColor: "#FFE6C8",
    borderWidth: 1.5,
    borderRadius: 10,
  },
  strongText: {
    fontWeight: "700",
    textAlign: "center",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontSize: 17,
    lineHeight: 20,
    letterSpacing: 0.01,
    color: "#000000",
    // textTransform: 'capitalize'
  },
  strongTextLeft: {
    fontWeight: "700",
    textAlign: "center",
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontSize: 17,
    lineHeight: 20,
    letterSpacing: 0.01,
    textAlign: "left",
    color: "#000000",
  },
  loader: {
    width: "100%",
    height: 280,
    justifyContent: "center",
    alignItems: "center",
  },
  containerFromWeb: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleFromWeb: {
    fontSize: 48,
    lineHeight: 58,
    letterSpacing: 1,
  },
});

export const sections = (dark, height, paddingV, paddingH, width) => ({
    minHeight: height ? height : 377,
    backgroundColor: dark ? '#F7F7F7' : 'white',
    paddingHorizontal: paddingH || 0,
    paddingVertical: paddingV || 0,
    justifyContent: 'center',
    width: width || null
})

export const centerAlignedRow = (marginV, justify, col, align, wrap) => ({
  flexDirection: col || "row",
  marginVertical: marginV || 0,
  alignItems: align || "center",
  justifyContent: justify || "flex-start",
  flexWrap: wrap || null,
});

export const container = (minHeight, backgroundColor) => ({
    backgroundColor: backgroundColor || '#FFF8EF',
    width: '100%',
    minHeight: minHeight || 350,
    paddingHorizontal: 20,
    paddingVertical: 30
})
