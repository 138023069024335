import { useState } from 'react'
import { View, Text, TouchableOpacity } from 'react-native';
//color
import { useTheme } from 'react-native-paper'
//icons
import { BaranaIcons } from '../../assets/icons/BaranaIcons';
//styles
import * as widgetsStyles from '../../styles/pages/widgets/general';
import { homeStyles } from '../../styles/pages/home/homePage'
//utils
import { capitalize } from '../utils/utils'


export const DropdownOption = ({ title, text, uniqueKey }) => {
    const { colors } = useTheme();
    const [actionEnable, setActionEnable] = useState(false);
    return (
      <TouchableOpacity
        key={uniqueKey}
        onPress={() => setActionEnable(!actionEnable)}
        style={{ width: "100%" }}
      >
        <View style={widgetsStyles.dropdownOption()}>
          <Text style={[homeStyles.strongText, { textTransform: "none" }]}>
            {capitalize(title)}
          </Text>
          <TouchableOpacity onPress={() => setActionEnable(!actionEnable)}>
            <BaranaIcons
              draw={{ width: 24, height: 24 }}
              name={actionEnable ? "rollOut" : "rollDown"}
            />
          </TouchableOpacity>
        </View>
        {actionEnable && (
          <Text style={[homeStyles.helperText, { paddingBottom: 33 }]}>
            {text ? text : " "}
          </Text>
        )}
      </TouchableOpacity>
    );
}