import { useState } from 'react'
import { View, Text } from 'react-native'
import { useNavigation } from '@react-navigation/core'
//icons
import { BaranaIcons } from '../../assets/icons/BaranaIcons'
//styles
import { styles, actionText, orangeBox, orangeCardBody } from '../../styles/pages/generalStyles'
import { centerAlignedRow } from '../../styles/pages/home/homePage'
import { styles as navStyles } from '../../styles/pages/widgets/navBar'
//components
import { GeneralButton } from './generalButton'
import { OutlinedButton } from './outlinedButton'
//utils
import { capitalize } from '../utils/utils'


export const OrangeCard = ({
    icon,
    title,
    title2,
    action_rollDown,
    action_rollOut,
    text,
    priceOrDescription,
    typeButton,
    textButton,
    onPress,
    widthButton,
    height,
    width,
    footer,
    rightSection
}) => {
    const Navigation = useNavigation();
    const [actionEnable, setActionEnable] = useState(false);
    return (
      <View
        style={orangeBox(
          (actionEnable && 250) || (!action_rollDown && height),
          width
        )}
      >
        <View>
          <BaranaIcons draw={{ width: 65, height: 65 }} name={icon} />
        </View>
        <View style={orangeCardBody((icon || rightSection) && "75%")}>
          <View>
            <Text style={styles.cardText}>{capitalize(title)}</Text>
            {title2 && (
              <Text style={[styles.cardText, { marginVertical: 12 }]}>
                {capitalize(title2)}
              </Text>
            )}
            {actionEnable || (!action_rollDown && text) ? (
              <>
                <Text style={[styles.generalText, { marginTop: 6 }]}>
                  {text}
                </Text>
                <Text
                  style={[
                    styles.generalText,
                    { marginTop: 6, marginBottom: !action_rollDown ? 10 : 0 },
                  ]}
                >
                  {priceOrDescription}
                </Text>
              </>
            ) : null}
            {action_rollDown && (
              <View style={centerAlignedRow(6)}>
                <Text
                  onPress={() => setActionEnable(!actionEnable)}
                  style={[actionText("#DB7500")]}
                >
                  {!actionEnable ? action_rollDown : action_rollOut}
                </Text>
                <BaranaIcons
                  draw={{ width: 20, height: 20 }}
                  name={!actionEnable ? "rollDown" : "rollOut"}
                />
              </View>
            )}
          </View>
          {typeButton == "general" ? (
            <GeneralButton
              text={textButton}
              handleOnPress={() => {
                onPress ? onPress() : Navigation.navigate("Signup");
              }}
              width={widthButton}
            />
          ) : (
            <OutlinedButton
              text={textButton}
              handleOnPress={onPress}
              width={"100%"}
            />
          )}
          {footer && <Text style={[navStyles.text]}>{footer}</Text>}
        </View>
        {rightSection && (
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {/* <Text style={[styles.helpText, { marginBottom: 50 }]}>₡27,600</Text> */}
            <Text style={[navStyles.text]}>Suscribirse</Text>
          </View>
        )}
      </View>
    );
}