import client from './httpClient';
import { addTrailingSlashOnIos } from "../utils/utils";

export const ordes = async (token) => {
  try {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await client.get(addTrailingSlashOnIos(`/user-orders`));
    return response;
  } catch (error) {
    return error.response;
  }
};

export const reorder = async (token, id) => {
  try {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await client.get(
      addTrailingSlashOnIos(`/user-orders/${id}/reorder_order`)
    );

    return response;
  } catch (error) {
    return error.response;
  }
};
