import { Svg, Path } from 'react-native-svg';


export const Loupe = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 34 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M16.7467 0C16.7467 7.76801 0.841797 19.6897 0.841797 29.5245C0.841797 39.3592 6.57372 44.4909 16.7467 44.4909C26.9197 44.4909 32.6516 39.3693 32.6516 29.5245C32.6516 19.6796 16.7467 7.76801 16.7467 0Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
        </Svg>

    )
}
