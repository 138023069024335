import { useEffect } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/core";
//icons
import { BaranaIcons } from "../../assets/icons/BaranaIcons";
//styles
import { flexContainer, styles } from "../../styles/pages/generalStyles";
import { centerAlignedRow } from "../../styles/pages/home/homePage";
import * as cartStyles from "../../styles/pages/widgets/cart";
//components
import { AddRemoveToCart } from "./cart/addRemoveToCart";
import useIsWeb from "../../hooks/useIsWeb";

export const SupermarketItemCard = ({
  item,
  updatingCart,
  addToCard,
  deleteToCard,
  uniqueKey,
  variation,
}) => {
  const Navigation = useNavigation();
  const { windowWidth } = useIsWeb();

  useEffect(() => {}, [item.quantity]);

  const goToDetails = () => {
    Navigation.navigate("detailsPlate", {
      plate: { ...item, variation: variation },
    });
  };

  return (
    <View
      key={uniqueKey}
      style={[
        centerAlignedRow(null, "space-between"),
        { height: 80, width: windowWidth < 725 ? windowWidth - 40 : "100%" },{ marginTop: 10, marginBottom: 10}
      ]}
    >
      <View style={flexContainer("row", null, "center", null, null, 8)}>
        <TouchableOpacity onPress={() => goToDetails()}>
          <Image
            source={{ uri: `${item.image}` }}
            style={cartStyles.styles.img}
          />
        </TouchableOpacity>
        <View
          style={[
            centerAlignedRow(null, "space-between", "column", "flex-start"),
            { width: windowWidth < 725 ? "50%" : 150 },
          ]}
        >
          <Text
            onPress={() => goToDetails()}
            style={[styles.generalText, { textAlign: "left" }]}
            ellipsizeMode={"tail"}
            numberOfLines={2}
          >
            {item.recipe}
          </Text>
          <Text onPress={() => goToDetails()} style={styles.generalText}>
            ₡{item.price.toFixed(2)}
          </Text>
        </View>
      </View>

      <View
        style={[
          centerAlignedRow(null, "space-between", "column", "flex-end"),
          { height: "80%" },
        ]}
      >
        {item.quantity && item.quantity > 0 ? (
          <AddRemoveToCart
            updatingCart={updatingCart}
            addToCard={() => addToCard()}
            deleteToCard={() => deleteToCard()}
            quantity={item.quantity}
          />
        ) : (
          <TouchableOpacity
            disabled={updatingCart}
            onPress={() => addToCard(item.id)}
          >
            <BaranaIcons draw={{ width: 20, height: 20 }} name={"plusCircle"} />
          </TouchableOpacity>
        )}
        <TouchableOpacity disabled={updatingCart} onPress={() => goToDetails()}>
          <Text style={[styles.orangeText, { fontSize: 14 }]}>Detalles</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};
