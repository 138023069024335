import { useState, useContext } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Platform,
  SafeAreaView,
  Image,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Formik } from "formik";
import * as yup from "yup";
//icons
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
//color
import { useTheme } from "react-native-paper";
//components
import { InputOverall } from "../../widgets/inputOverall";
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
//controllers
import { loginController } from "../../controllers/authentication";
//Styles
import {
  styles,
  title,
  actionText,
  webContainer,
  BaranaIconStyle,
} from "../../../styles/pages/generalStyles";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useUserData from "../../../hooks/useUserData";

const loginValidationSchema = yup.object({
  email: yup
    .string()
    .trim("Por favor elimine los espacios extras")
    .email("Por favor introduzca un correo electrónico válido.")
    .required("El correo electrónico es requerido."),
  password: yup.string().required("La contraseña es requerida."),
});

export const Login = ({ route, navigation }) => {
  const { setEmail, setAccessToken, setUserId, setIsLoggedIn, setProvider } =
    useUserData();
  const [eye, setEye] = useState("eye-off-outline");
  const [errorLogin, setErrorLogin] = useState(false);
  const { colors } = useTheme();
  const passVisible = eye === "eye-off-outline" ? true : false;
  const [typeInput, setTypeInput] = useState("outlined");
  const [loding, setLoading] = useState(false);
  const fromWeb = Platform.OS == "web";
  const { isComputer } = useIsWeb();

  const handleLogin = async (values) => {
    setLoading(true);
    const auxValues = {
      ...values,
      email: values.email.trim(),
    };

    const expoToken = await AsyncStorage.getItem("expoToken");

    await loginController({ ...auxValues, fcm_token: expoToken })
      .then(async (res) => {
        setLoading(false);
        if (res.email) {
          await setEmail(res.email);
          await setProvider("email");
          await setAccessToken(res.access_token);
          await setUserId(res.id);
          setIsLoggedIn(true);
          fromWeb && navigation.navigate("homePage");
        } else if (res.status == 400 || res.status == 401) {
          setErrorLogin(res.data.message);
        } else {
          setErrorLogin(
            "Algo inesperado ha sucedido por favor vuelva a intentarlo."
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        setErrorLogin(
          "Algo inesperado ha sucedido por favor vuelva a intentarlo."
        );
      });
  };

  const pressEye = () => {
    if (eye === "eye-outline") {
      setEye("eye-off-outline");
    } else {
      setEye("eye-outline");
    }
  };

  return (
    <SafeAreaView style={styles.screen}>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={loginValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleLogin}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <>
            <ScrollView>
              <View
                style={[
                  styles.container,
                  webContainer(!isComputer && "65vh", null, isComputer),
                ]}
              >
                <View
                  style={[
                    styles.justifyContent,
                    { marginTop: "10%", marginBottom: 5 },
                  ]}
                >
                  <Image
                    source={require("../../../assets/illustrations/Barana.png")}
                    style={BaranaIconStyle(
                      !isComputer ? 180 : 250,
                      !isComputer ? 70 : 90
                    )}
                  />
                </View>
                <Text style={[title("100%", "center")]}>
                  Ingrese a su cuenta
                </Text>
                <View style={[styles.inputsContainer]}>
                  <InputOverall
                    label="Correo Electrónico"
                    value={values.email}
                    placeholder="Correo Electrónico"
                    onChangeText={handleChange("email")}
                    autoCapitalize={"none"}
                    type={typeInput}
                    errors={errors.email && touched.email && errors.email}
                    onBlur={() => handleBlur("email")}
                  />
                  <InputOverall
                    label="Contraseña"
                    value={values.password}
                    placeholder="Contraseña"
                    secureTextEntry={passVisible}
                    onChangeText={handleChange("password")}
                    autoCapitalize={"none"}
                    type={typeInput}
                    errors={
                      touched.password && errors.password && errors.password
                    }
                    onBlur={() => handleBlur("password")}
                    icon={
                      <MaterialCommunityIcons
                        name={eye}
                        color={colors.secondary}
                        size={25}
                        style={styles.inputIcon}
                        onPress={pressEye}
                      />
                    }
                  />
                </View>

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "5%",
                  }}
                >
                  {errorLogin && <Text style={styles.error}>{errorLogin}</Text>}
                  <TouchableOpacity
                    onPress={() => navigation.navigate("forgotPassword")}
                    style={{ width: isComputer ? 220 : "100%" }}
                  >
                    <Text
                      style={[
                        actionText("#DB7500"),
                        {
                          marginHorizontal: 15,
                          marginBottom: 45,
                          marginTop: 12,
                        },
                      ]}
                    >
                      ¿Olvidó su contraseña?
                    </Text>
                  </TouchableOpacity>
                  {/* <TouchableOpacity
                    style={[styles.actionContainer]}
                    onPress={() => navigation.goBack()}>
                    <Text style={styles.helpText}>¿No tiene cuenta? </Text>
                    <Text style={[actionText('#DB7500')]}> Crear una cuenta</Text>
                  </TouchableOpacity> */}

                  {isComputer && (
                    <GeneralButton
                      text="Ingresar"
                      handleOnPress={handleSubmit}
                      disabled={
                        loding || values.password === "" || values.email === ""
                          ? true
                          : false
                      }
                      loading={loding}
                      width={330}
                    />
                  )}
                </View>

                {/* {!auth_google_facebook ?
                  <>
                    <TouchableOpacity onPress={() => navigation.navigate('forgotPassword')}>
                      <Text style={[actionText('#2F322F'), { marginHorizontal: '3%' }]}>
                        ¿Olvidó su contraseña?
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.actionContainer}
                      onPress={() => navigation.navigate('Signup')}>
                      <Text style={styles.helpText}>¿No tiene cuenta? </Text>
                      <Text style={[actionText('#DB7500')]}> Crear una cuenta</Text>
                    </TouchableOpacity>
                  </>
                  :
                  <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => navigation.navigate('Signup')}>
                      <Text style={[styles.generalText, { marginHorizontal: '3%', textAlign: 'center', marginBottom: 25 }]}>
                        Si no posee usuario <Text style={styles.timeText}>registrese aquí:</Text>
                      </Text>
                    </TouchableOpacity>
                    <Text style={[styles.timeText, { textAlign: 'center', fontStyle: 'italic' }]}>Ingresar utilizando: </Text>
                    <TouchableOpacity style={[styles.auth_g]}>
                      <View style={styles.socialMediaIcon_google}>
                        <Image style={{ width: 31, height: 31 }} source={require('../../../assets/illustrations/google.png')} />
                      </View>
                      <Text style={[styles.number, { marginRight: 18 }]}>
                        Iniciar sesión con Google
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.auth_f]}>
                      <View style={styles.socialMediaIcon_facebook}>
                        <BaranaIcons draw={{ width: 36, height: 36 }} name={'blue_faceBook'} />
                      </View>
                      <Text style={[styles.number]}>Iniciar sesión con Facebook</Text>
                    </TouchableOpacity>
                  </View>
                } */}
              </View>
            </ScrollView>
            {!isComputer && (
              <LowerOptionsContainer>
                <GeneralButton
                  text="Ingresar"
                  handleOnPress={handleSubmit}
                  disabled={
                    values.password === "" || values.email === "" || loding
                  }
                  loading={loding}
                  width="100%"
                />
              </LowerOptionsContainer>
            )}
          </>
        )}
      </Formik>
    </SafeAreaView>
  );
};
