import { useState } from 'react'
import { View, Text, TouchableOpacity, FlatList, Dimensions, ScrollView } from 'react-native';
//color
import { useTheme } from 'react-native-paper'
//icons
import { BaranaIcons } from '../../assets/icons/BaranaIcons';
//styles
import * as widgetsStyles from '../../styles/pages/widgets/general';
import { homeStyles } from '../../styles/pages/home/homePage'
//utils
import { capitalize } from '../utils/utils'
//hooks
import useIsWeb from '../../hooks/useIsWeb';

const { width } = Dimensions.get("window");
export const DropdownOptionRenderArray = ({
  title,
  text,
  uniqueKey,
  array,
  description,
}) => {
  const [actionEnable, setActionEnable] = useState(false);
  const { isComputer } = useIsWeb();
  return (
    <TouchableOpacity
      key={uniqueKey}
      onPress={() => setActionEnable(!actionEnable)}
      style={{ width: "100%" }}
    >
      <View style={widgetsStyles.dropdownOption()}>
        <Text style={homeStyles.strongText}>{capitalize(title)}</Text>
        <TouchableOpacity onPress={() => setActionEnable(!actionEnable)}>
          <BaranaIcons
            draw={{ width: 24, height: 24 }}
            name={actionEnable ? "rollOut" : "rollDown"}
          />
        </TouchableOpacity>
      </View>
      {actionEnable && (
        <>
          {description ? (
            <Text
              style={[
                homeStyles.helperText,
                { marginBottom: 12, width: "70%" },
              ]}
            >
              {description}
            </Text>
          ) : null}
          <ScrollView
            showsHorizontalScrollIndicator={false}
            horizontal={true}
            style={{}}
          >
            <FlatList
              bounces={false}
              style={{ width: isComputer ? "100%" : width - 40 }}
              data={array}
              numColumns={3}
              renderItem={({ item, index }) => {
                return (
                  <View
                    key={`${index}-alergenos`}
                    style={{
                      marginRight: 15,
                      alignItems: "center",
                      marginBottom: 15,
                    }}
                  >
                    {/* <BaranaIcons draw={{ width: 68, height: 55 }} name={item.name} /> */}
                    <Text style={[homeStyles.helperText]}>{item.name}</Text>
                  </View>
                );
              }}
            />
          </ScrollView>
        </>
      )}
    </TouchableOpacity>
  );
};