import { StyleSheet } from 'react-native'

export const profileStyles = StyleSheet.create({
    text: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 17,
        lineHeight: 22,
        letterSpacing: 0.01,
        color: '#979797',
    },
})

