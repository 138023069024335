import { Svg, Image, G, Defs, Path, ClipPath, Rect, Pattern, Use } from 'react-native-svg';


export const LeanMeats = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 48 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M46.5331 8.49781C46.5238 8.42562 46.5016 8.35545 46.4673 8.29034C46.0726 5.71766 43.8097 3.76325 41.3319 2.80887C36.9069 1.09928 31.6047 1.3607 26.9692 1.93748C22.3337 2.51426 17.5666 3.40225 13.2161 5.0247C6.72987 7.44385 0.498005 13.3942 1.96717 20.1579C1.9626 20.1951 1.9626 20.2327 1.96717 20.2699C2.2303 24.9547 3.11618 30.154 8.22097 32.2204C13.795 34.4736 19.2506 33.5109 24.6098 31.1955C27.1797 30.0876 29.8724 28.6187 31.8722 26.7099C34.2361 24.4692 35.7491 21.548 38.2138 19.3612C39.9987 17.7761 41.8143 17.2283 43.915 16.2117C44.5285 15.969 45.0813 15.6071 45.5376 15.1494C47.1953 13.3569 46.7393 10.6804 46.5331 8.49781Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
            <Path d="M14.4091 19.4691C16.3826 19.6974 18.7551 18.7928 19.6717 17.0541C19.893 16.6497 19.9914 16.195 19.9557 15.7408C19.9201 15.2866 19.752 14.8506 19.47 14.4815C18.9349 13.83 18.1192 13.747 17.3035 13.6267C17.2802 13.6246 17.2567 13.6246 17.2333 13.6267C17.207 13.6267 17.1895 13.6267 17.1588 13.6059C15.2598 13.4482 12.1373 14.3113 11.4576 16.2118C10.7734 18.0459 12.6899 19.2741 14.4091 19.4691Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
            <Path d="M28.2064 8.65969C28.3495 8.65969 28.4867 8.60591 28.5878 8.5102C28.689 8.41448 28.7458 8.28466 28.7458 8.14929C28.7458 8.01393 28.689 7.88412 28.5878 7.78841C28.4867 7.69269 28.3495 7.63892 28.2064 7.63892C28.0634 7.63892 27.9262 7.69269 27.825 7.78841C27.7238 7.88412 27.667 8.01393 27.667 8.14929C27.667 8.28466 27.7238 8.41448 27.825 8.5102C27.9262 8.60591 28.0634 8.65969 28.2064 8.65969Z" fill="#DB7500" />
            <Path d="M30.0875 7.95012C31.9821 7.45634 33.9643 7.04138 35.7317 8.10365C36.3238 8.45636 36.8632 7.57252 36.2799 7.21981C34.2626 6.01231 31.9733 6.38991 29.7981 6.96669C29.7298 6.98467 29.666 7.0152 29.6102 7.05652C29.5544 7.09785 29.5078 7.14916 29.473 7.20753C29.4027 7.32543 29.3847 7.46493 29.4231 7.59534C29.4615 7.72575 29.553 7.83639 29.6776 7.90293C29.8023 7.96946 29.9497 7.98644 30.0875 7.95012Z" fill="#DB7500" />
        </Svg>

    )
}