import { Svg, G, Defs, Path, ClipPath, Rect } from 'react-native-svg';

export const Vegetables = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <G clipPath="url(#clip0_94_4299)">
                <Path fillRule="evenodd" clipRule="evenodd" d="M0.833984 40.5944C5.60393 40.3563 60.803 40.224 71.6608 39.9974C70.9459 48.9408 67.9632 54.6615 63.5517 61.3408C59.3797 67.6603 52.225 70.7614 52.225 70.7614C52.225 70.7614 52.3323 71.8157 52.1066 75.8878C46.2236 76.341 20.1505 76.0067 20.1505 76.0067C20.1505 76.0067 20.2578 73.4458 20.2703 69.9258C13.2566 69.6996 6.35029 60.8853 3.86196 55.2298C2.27722 51.837 0.953753 45.006 0.833984 40.5944Z" fill="#C5E6EF" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M0.834079 41.3774C24.3952 40.299 48.0789 41.2549 71.6609 40.7804C71.4001 40.5196 71.1393 40.2588 70.878 39.9975C69.776 51.8671 63.1952 65.0435 51.8302 70.0854C51.6003 70.1871 51.4223 70.5154 51.4422 70.7615C51.5786 72.4544 51.4098 74.1982 51.3238 75.8879C51.5851 75.6267 51.8459 75.3658 52.1067 75.105C41.5203 75.8514 30.7559 75.3589 20.1506 75.2239C20.4114 75.4847 20.6722 75.746 20.9335 76.0068C21.0135 73.9809 21.0394 71.9531 21.0528 69.9259C21.0565 69.4736 20.6926 69.1772 20.2704 69.143C13.9379 68.6338 9.14298 62.7721 6.05396 57.7441C2.97234 52.7286 1.86344 46.3938 1.61697 40.5945C1.57396 39.5887 0.00864557 39.585 0.0516513 40.5945C0.328646 47.1249 1.7298 53.6965 5.17489 59.3034C8.44286 64.6227 13.7118 70.1811 20.2704 70.7087C20.0091 70.4479 19.7483 70.1867 19.4875 69.9259C19.4736 71.9531 19.4482 73.9809 19.3677 76.0068C19.3506 76.4373 19.7367 76.7841 20.1506 76.7897C30.7559 76.9242 41.5203 77.4167 52.1067 76.6704C52.5497 76.6394 52.8665 76.3374 52.8896 75.8879C52.9691 74.3217 53.0505 72.7541 53.0274 71.186C53.0158 70.4049 53.0824 71.2317 52.6671 71.4158C53.0181 71.2609 53.3589 71.0833 53.7002 70.908C56.8826 69.2743 59.7344 67.0186 62.1645 64.4063C68.1867 57.9309 71.6387 48.6667 72.4438 39.9975C72.4826 39.5781 72.0586 39.2068 71.6609 39.2151C48.0789 39.6891 24.3952 38.7333 0.834079 39.8116C-0.170776 39.8579 -0.175401 41.4237 0.834079 41.3774Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M46.2701 22.0127C46.2701 22.0127 47.8141 15.2007 42.567 16.631C45.0701 13.77 39.8298 8.8525 38.0213 12.2453C38.5133 8.04649 29.6893 7.21134 30.1041 12.6985C25.754 11.0273 20.284 16.348 23.8608 21.3561" fill="#63AF45" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M47.0252 22.2208C47.796 18.6083 46.6548 14.9898 42.3588 15.8763C42.6534 16.2597 42.9479 16.643 43.243 17.0264C45.7923 13.2955 40.2293 7.7052 37.3452 11.8499C37.8316 11.9817 38.3176 12.1131 38.8041 12.2453C38.9424 6.5385 29.2929 6.77295 29.3211 12.6985C29.6513 12.4465 29.9815 12.1949 30.3116 11.9434C24.2723 10.2051 20.0901 16.6703 23.1847 21.7514C23.7091 22.612 25.0635 21.8259 24.5368 20.9612C22.1438 17.0319 25.2083 12.1034 29.8959 13.4532C30.3842 13.5938 30.8892 13.203 30.8864 12.6985C30.8684 8.75306 37.3276 8.5889 37.2383 12.2453C37.218 13.0749 38.2765 13.2446 38.6973 12.6402C40.3958 10.1986 43.2518 14.2444 41.8908 16.2361C41.4728 16.8474 42.1572 17.5133 42.775 17.3861C45.8127 16.7591 45.9745 19.652 45.5153 21.8046C45.3054 22.7891 46.8143 23.2081 47.0252 22.2208Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M23.0127 21.6391C27.9893 21.8652 48.2571 22.4243 49.8414 21.5202C50.972 20.8404 55.3739 17.0342 56.2802 15.677C60.8041 9.11651 62.9054 4.3179 70.597 4.09131C73.3124 4.09131 76.0504 4.35304 77.8608 6.61524C79.4441 8.65038 79.1722 13.1734 79.1722 13.1734C76.0721 15.4388 74.1373 12.384 71.4233 11.0273C68.4828 9.66911 65.6014 12.6744 63.7914 14.4844C59.4927 19.0088 54.9729 24.495 50.6747 28.7933C48.64 31.0555 47.618 33.104 45.7863 33.9207C42.4092 35.424 30.5239 34.7549 30.5239 34.7549C30.5239 34.7549 26.0148 34.8173 24.2044 29.3889C23.5265 27.1267 23.2097 25.6289 23.0127 21.6391Z" fill="#C5E6EF" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M23.0124 22.422C27.904 22.6366 32.803 22.7355 37.6987 22.7864C41.5303 22.8261 46.1713 23.4056 49.9123 22.3346C51.6233 21.8444 53.3713 19.7126 54.6212 18.5658C56.7414 16.6236 58.1569 14.2745 59.7588 11.905C61.8772 8.77297 64.1805 6.17875 67.9493 5.18684C69.9974 4.64765 72.3197 4.80858 74.3595 5.25944C77.5669 5.96835 78.2471 8.95378 78.3946 11.7163C78.6018 15.5873 72.5116 10.5547 70.7516 9.99008C68.4436 9.24973 65.99 11.3533 64.4441 12.7457C61.3204 15.56 58.6476 18.9649 55.8527 22.096C52.9824 25.3108 50.0288 28.397 47.2848 31.7186C44.1431 35.5221 35.7107 34.1936 31.2931 34.0095C24.9084 33.7431 24.0862 26.7646 23.7953 21.6391C23.738 20.6352 22.1727 20.6301 22.2295 21.6391C22.5264 26.8775 23.2224 33.7177 29.214 35.3589C32.9791 36.3901 37.8055 35.782 41.6066 35.5008C43.9058 35.3311 45.9442 34.9885 47.7537 33.4962C49.4027 32.1362 50.5297 30.0544 52.0358 28.5274C54.0242 26.5126 55.9021 24.3878 57.7865 22.2772C60.833 18.8636 63.7671 14.8483 67.578 12.2435C71.3199 9.68627 74.421 15.6655 78.4829 14.4553C80.5444 13.8407 80.0136 11.9836 79.8522 10.2745C79.6011 7.61968 78.3895 5.00465 75.6908 4.06731C69.1942 1.81205 63.3805 4.4391 59.4453 9.64557C56.1435 14.0118 52.9579 20.7929 46.8145 21.124C40.6887 21.4541 34.5038 21.2086 28.3761 21.0412C26.5879 20.9922 24.7993 20.9348 23.0124 20.8562C22.0025 20.8118 22.0067 22.3776 23.0124 22.422Z" fill="#1A1919" />
            </G>
            <Defs>
                <ClipPath id="clip0_94_4299">
                    <Rect width="80" height="80" fill="white" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}