import { Platform, Dimensions } from 'react-native'
import styled from 'styled-components/native'

const { width } = Dimensions.get("window");
export const OptCellShading = styled.View`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: ${Platform.OS == 'web' ? '52px' : '71px'};
    height: ${Platform.OS == 'web' ? '52.1px' : '63px'};
    box-shadow: 1px 1px 50px rgba(26, 48, 54, 0.24);
    border-radius: ${Platform.OS == 'web' ? '4px' : '10px'};
    overflow: hidden;
    elevation: 4;
    margin: ${Platform.OS == 'web' ? '15px' : '0 1.51%'};
`;

export const InputShadow = styled.View`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px ${props => props.platform == 'ios' ? '4px' : ''};
    width: 100%;
    min-height: ${width >= 1024 ? '' : '59px'};
    box-shadow: 1px 1px 50px rgba(26, 48, 54, 0.24);
    border-radius: 10px;
    overflow: hidden;
    elevation: 5;
    margin:  ${width >= 1024 ? '20px 0' : '5px 0'};
`;