export const edit_dish_details = (
  garrinsons,
  selectedGarrison,
  plateId,
  optionsList,
  setOptionsList
) => {
  if (garrinsons["Acompañamiento 1"]?.length > 0) {
    selectedGarrison.map((plateGarrison) => {
      plateGarrison.garrison.map((garrID) => {
        garrinsons["Acompañamiento 1"]?.map((g) => {
          if (garrID == g.id && plateGarrison.plate == plateId) {
            setOptionsList([
              ...optionsList.map((option, i) => {
                if (option.id == plateId) {
                  return {
                    ...option,
                    additional_amount:
                      option.additional_amount + g.additional_amount,
                    fats: option.fats + g.fats,
                    proteins: option.proteins + g.proteins,
                    saturated_fats: option.saturated_fats + g.saturated_fats,
                    sugars: option.sugars + g.sugars,
                    cho: option.cho + g.cho,
                    calories: option.calories + g.calories,
                    alergenos:
                      option?.alergenos?.length > 0
                        ? option?.alergenos.concat(g?.alergenos)
                        : g?.alergenos?.length > 0
                        ? g?.alergenos
                        : [],
                    tags:
                      option?.tags?.length > 0
                        ? option?.tags.concat(g?.tags)
                        : g?.tags?.length > 0
                        ? g?.tags
                        : [],
                  };
                } else {
                  return option;
                }
              }),
            ]);
          }
        });
      });
    });
  }
  if (garrinsons["Acompañamiento 2"]?.length > 0) {
    selectedGarrison.map((plateGarrison) => {
      plateGarrison.garrison.map((garrID) => {
        garrinsons["Acompañamiento 2"]?.map((g) => {
          if (garrID == g.id && plateGarrison.plate == plateId) {
            setOptionsList([
              ...optionsList.map((option, i) => {
                if (option.id == plateId) {
                  return {
                    ...option,
                    additional_amount:
                      option.additional_amount + g.additional_amount,
                    fats: option.fats + g.fats,
                    proteins: option.proteins + g.proteins,
                    saturated_fats: option.saturated_fats + g.saturated_fats,
                    sugars: option.sugars + g.sugars,
                    cho: option.cho + g.cho,
                    calories: option.calories + g.calories,
                    alergenos:
                      option?.alergenos?.length > 0
                        ? option?.alergenos.concat(g?.alergenos)
                        : g?.alergenos?.length > 0
                        ? g?.alergenos
                        : [],
                    tags:
                      option?.tags?.length > 0
                        ? option?.tags.concat(g?.tags)
                        : g?.tags?.length > 0
                        ? g?.tags
                        : [],
                  };
                } else {
                  return option;
                }
              }),
            ]);
          }
        });
      });
    });
  }
};

export const edit_dish_details_single = (
  garrinsons,
  selectedGarrison,
  plateId,
  plate,
  setPlate
) => {
  if (garrinsons["Acompañamiento 1"]?.length > 0) {
    selectedGarrison.map((plateGarrison) => {
      plateGarrison.garrison.map((garrID) => {
        garrinsons["Acompañamiento 1"]?.map((g) => {
          if (garrID == g.id && plateGarrison.plate == plateId) {
            setPlate({
              ...plate,
              additional_amount: plate.additional_amount + g.additional_amount,
              fats: plate.fats + g.fats,
              proteins: plate.proteins + g.proteins,
              saturated_fats: plate.saturated_fats + g.saturated_fats,
              sugars: plate.sugars + g.sugars,
              cho: plate.cho + g.cho,
              calories: plate.calories + g.calories,
              // alergenos: plate?.alergenos?.length > 0 ? plate?.alergenos.concat(g?.alergenos) : g?.alergenos?.length > 0 ? g?.alergenos : [],
              // tags: plate?.tags?.length > 0 ? plate?.tags.concat(g?.tags) : g?.tags?.length > 0 ? g?.tags : []
            });
          }
        });
      });
    });
  }
  if (garrinsons["Acompañamiento 2"]?.length > 0) {
    selectedGarrison.map((plateGarrison) => {
      plateGarrison.garrison.map((garrID) => {
        garrinsons["Acompañamiento 2"]?.map((g) => {
          if (garrID == g.id && plateGarrison.plate == plateId) {
            setPlate({
              ...plate,
              additional_amount: plate.additional_amount + g.additional_amount,
              fats: plate.fats + g.fats,
              proteins: plate.proteins + g.proteins,
              saturated_fats: plate.saturated_fats + g.saturated_fats,
              sugars: plate.sugars + g.sugars,
              cho: plate.cho + g.cho,
              calories: plate.calories + g.calories,
              // alergenos: plate?.alergenos?.length > 0 ? plate?.alergenos.concat(g?.alergenos) : g?.alergenos?.length > 0 ? g?.alergenos : [],
              // tags: plate?.tags?.length > 0 ? plate?.tags.concat(g?.tags) : g?.tags?.length > 0 ? g?.tags : []
            });
          }
        });
      });
    });
  }
};

export const get_extra_amount_receivable = (
  garrinsons,
  selectedGarrison,
  plateId,
  optionsList,
  setOptionsList
) => {
  const garrisons1And2 = [
    ...garrinsons["Acompañamiento 1"],
    ...garrinsons["Acompañamiento 2"],
  ];

  if (garrisons1And2?.length > 0) {
    const newGarrisons = selectedGarrison
      .map((garrisonId) =>
        garrisons1And2.find((garrinson) => garrinson.id === garrisonId)
      )
      .filter((garrison) => garrison !== undefined);

    setOptionsList([
      ...optionsList.map((option, i) => {
        if (option.id == plateId) {
          return {
            ...option,
            garrisons: newGarrisons,
            additional_amount:
              option.additional_amount +
              newGarrisons.reduce(
                (acc, current) => acc + current?.additional_amount || 0,
                0
              ),
          };
        } else {
          return option;
        }
      }),
    ]);

    // selectedGarrison.map((plateGarrison) => {
    //   plateGarrison.garrison.map((garrID) => {
    //     garrisons1And2?.map((g) => {
    //       if (garrID == g.id && plateGarrison.plate == plateId) {
    //         setOptionsList([
    //           ...optionsList.map((option, i) => {
    //             if (option.id == plateId) {
    //               return {
    //                 ...option,
    //                 garrisons: [g],
    //                 additional_amount:
    //                   option.additional_amount + g.additional_amount,
    //               };
    //             } else {
    //               return option;
    //             }
    //           }),
    //         ]);
    //       }
    //     });
    //   });
    // });
  }
  //   if (garrinsons["Acompañamiento 2"]?.length > 0) {
  //     selectedGarrison.map((plateGarrison) => {
  //       plateGarrison.garrison.map((garrID) => {
  //         garrinsons["Acompañamiento 2"]?.map((g) => {
  //           if (garrID == g.id && plateGarrison.plate == plateId) {
  //             setOptionsList([
  //               ...optionsList.map((option, i) => {
  //                 if (option.id == plateId) {
  //                   return {
  //                     ...option,
  //                     garrisons: [...option.garrisons, g],
  //                     additional_amount:
  //                       option.additional_amount + g.additional_amount,
  //                   };
  //                 } else {
  //                   return option;
  //                 }
  //               }),
  //             ]);

  //             // optionsList.map((option, i) => {
  //             //     if (option.id == plateId) {
  //             //         option?.alergenos?.push(g?.alergenos)
  //             //         option?.tags?.push(g?.tags)

  //             //         optionsList[i] = {
  //             //             ...option,
  //             //             additional_amount: option.additional_amount + g.additional_amount,
  //             //             fats: option.fats + g.fats,
  //             //             proteins: option.proteins + g.proteins,
  //             //             saturated_fats: option.saturated_fats + g.saturated_fats,
  //             //             sugars: option.sugars + g.sugars,
  //             //             cho: option.cho + g.cho,
  //             //             calories: option.calories + g.calories,
  //             //             alergenos: option?.alergenos,
  //             //             tags: option?.tags
  //             //         }

  //             //     }

  //             // })
  //             // setOptionsList([...optionsList])
  //           }
  //         });
  //       });
  //     });
  //   }
};
