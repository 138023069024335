import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
    garrinsonPrice: {
        fontWeight: 'bold',
    }
})

export const garrinsonPrice = () => ({
    fontFamily: 'Nunito Sans',
    fontWeight: '800',
    fontSize: 19,
    lineHeight: 22,
    textAlign: 'right',
    color: '#DB7500',
    marginRight: 20,
})

export const AddCartButton = () => ({
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FCCC95',
    borderRadius: 50,
})
