import { StyleSheet, Platform, Dimensions } from 'react-native'

const { width } = Dimensions.get("window");
const isComputer = width >= 1024;
export const headerComponentStyle = StyleSheet.create({
  titleContainer: {
    textAlign: "center",
    width: "100%",
    alignItems: "center",
    height: isComputer ? 60 : 32,
  },
  containerHeight: {
    height: Platform.OS === 'ios' ? 100 : 62,
  },
  headerGeneral: {
    elevation: 0,
    shadowOpacity: 0,
    border: "none",
  },
});

export const headerTitleContainer = (height) => ({
  textAlign: "center",
  width: "100%",
  alignItems: "center",
  height,
  alignSelf: "center",
});

export const styles = StyleSheet.create({
  headerWithBorder: {
    height: Platform.OS === 'ios' ? 100 : 62,
    borderBottomColor: "#DB7500",
    borderBottomWidth: 0.7,
  },
  borderBottom: {
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 0.8,
    // width: '100%',
  },
  steps: {
    width: "18%",
    height: 4,
    backgroundColor: "#E0E0E0",
    borderRadius: 5,
  },
  stepDone: {
    backgroundColor: "#2F322F",
  },
});

export const titleStyle = (fontSize) => ({
  fontFamily:
    Platform.OS == "web" ? "Playfair Display" : "Playfair Display Bold",
  fontSize: fontSize || 25,
  fontWeight: "600",
  fontStyle: "normal",
  lineHeight: 38,
  letterSpacing: 0.01,
  // marginTop: 8,
  // marginLeft: '2%',
});

export const steps = (width) => ({
  width: `${width}%` || '18%',
  height: 4,
  backgroundColor: '#E0E0E0',
  borderRadius: 5,
})