import { registerRootComponent } from 'expo';
import { createRoot } from "react-dom/client";
import { createElement } from "react";
import { Platform } from "react-native";

import App from "./App";
import { head } from "lodash";

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

if ("web" === Platform.OS) {
  const scriptPixel = document.createElement("script");

  scriptPixel.innerHTML = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '646758619409348'); 
  fbq('track', 'PageView');`;

  const head = document.querySelector("body");

  head.appendChild(scriptPixel);

  const rootTag = createRoot(
    document.getElementById("root") ?? document.getElementById("main")
  );
  rootTag.render(createElement(App));
} else {
  registerRootComponent(App);
}
