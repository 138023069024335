import { createContext } from 'react'

export const orderContext = createContext({
    createNewSubscription: false,
    setCreateNewSubscription: () => { },
    createSubscriptionFromHome: false,
    setCreateSubscriptionFromHome: () => { },
    editSubscription: false,
    setEditSubscription: () => { },
    //user selections
    orderDefinition: {
        reorder: false,
        address: undefined,
        packageTypeId: '',
        packageType: null,
        pre_armed_package_id: null,
        sub_package_id: null,
        barama_time_food: [],
        armed_package_id: null,
        garrisons: [],
        //for predefined packages
        breakfasts: {
            selected: false,
            time_food: undefined,//id
            id: null,
            price: undefined,
            plates: [],
            plate_default: [],
            plan: null,
            freeSelection: false,
        },
        lunches: {
            selected: false,
            time_food: undefined,//id
            id: null,
            lunchAndDinner: false,
            price: undefined,
            plates: [],
            plate_default: [],
            plan: null,
            freeSelection: false,
        },
        dinners: {
            selected: false,
            time_food: undefined,//id
            id: null,
            lunchAndDinner: false,
            price: undefined,
            plates: [],
            plate_default: [],
            plan: null,
            freeSelection: false,
        },
        adult_or_family: {
            selected: false,
            time_food: undefined,//id
            price: undefined,
            plates: [],
            plate_default: [],
            plan: null,
            freeSelection: false,
        },
        nutritional_consultation: [],
        combos: [],
        plates_supermaker: [],
        orderMade: false
    },
    setOrderDefinition: () => { },
    selectedGarrison: [],
    setSelectedGarrisons: () => [],

    //order steps
    currentStep: 1,
    setCurrentStep: () => { },
    totalSteps: 1,
    setTotalSteps: () => { },

    //list of packages and plans to place an order
    baranaPlanList: [],
    setBaranaPlanList: () => [],
    baranaPackageList: [],
    setBaranaPackageList: () => [],
    mealTimesList: [],
    setMealTimesList: () => [],
    packageArmedList: [],
    setPackageArmedList: () => [],
})