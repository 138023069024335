import { View, Text, Modal, TouchableOpacity, Image } from "react-native";
//styles
import { spaceBetween, textAlignment } from "../../styles/pages/generalStyles";
import { modalStyles, options } from "../../styles/pages/widgets/modalMenu";
import { homeStyles, centerAlignedRow } from "../../styles/pages/home/homePage";
//components
import { GeneralButton } from "./generalButton";
//utils
import { capitalize } from "../utils/utils";

export const PopUp = ({
  openModal,
  showModal,
  title,
  text,
  handleOnPress,
  buttonText,
  img,
  button,
  cancelButton,
  handleCancelOnPress,
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={true}
      onRequestClose={() => openModal(false)}
    >
      <TouchableOpacity
        style={modalStyles.container_options}
        onPress={() => {
          handleCancelOnPress
            ? handleCancelOnPress()
            : handleOnPress
            ? handleOnPress()
            : null;
          openModal(false);
        }}
      >
        <View style={options()}>
          <View
            style={[spaceBetween(), { flexDirection: "row", marginBottom: 15 }]}
          >
            {title && (
              <Text style={homeStyles.strongText}>{capitalize(title)}</Text>
            )}
          </View>
          <View>
            <Text
              style={[homeStyles.helperText, textAlignment("left", title && 6)]}
            >
              {text ||
                "El código de verificación a sido enviado a su correo electrónico"}
            </Text>
          </View>
          {img && (
            <Image
              source={img}
              style={{
                width: 280,
                marginBottom: 12,
                marginTop: 8,
                height: 180,
                alignSelf: "center",
              }}
            />
          )}
          {button && (
            <View
              style={[centerAlignedRow(null, "flex-end"), { marginTop: 12 }]}
            >
              {cancelButton && (
                <GeneralButton
                  customColor={"#E0E0E0"}
                  text={"Cancelar"}
                  handleOnPress={() => {
                    handleCancelOnPress && handleCancelOnPress();
                  }}
                  width={"45%"}
                  marginR={5}
                />
              )}
              <GeneralButton
                text={buttonText || "Confirmar"}
                handleOnPress={() => {
                  handleOnPress && handleOnPress();
                  openModal(false);
                }}
                width={"45%"}
              />
            </View>
          )}
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
