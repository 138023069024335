import { useContext } from "react";
import { View, Text, Platform, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/core";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//styles
import * as generalStyles from "../../../styles/pages/generalStyles";
import {
  sections,
  centerAlignedRow,
} from "../../../styles/pages/home/homePage";
//components
import { GeneralButton } from "../../widgets/generalButton";
//context
import { mainContext } from "../../../context/mainContext";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

export const Intro = ({ background, setOpenPopup }) => {
  const Navigation = useNavigation();
  const { cuttingDay } = useContext(mainContext);

  const { isComputer } = useIsWeb();
  const fromWeb = Platform.OS == "web";
  return (
    <View style={[sections(background == "dark", 370, 36, 20)]}>
      <View
        style={[
          sections(background == "dark", 290),
          generalStyles.sectionContainerWeb(1000, null, "100%"),
          isComputer &&
            generalStyles.flexContainer(
              "row",
              "center",
              "center",
              "wrap",
              "center",
              80,
              50
            ),
        ]}
      >
        {isComputer ? (
          <View style={{ width: 220, height: 220 }}>
            <BaranaIcons
              draw={{ width: 220, height: 220 }}
              name={"vegetables"}
            />
          </View>
        ) : null}

        <View style={{ width: "100%", maxWidth: 700 }}>
          <View style={[centerAlignedRow(null, "space-between")]}>
            <Text
              style={[
                generalStyles.title("100%", "left", "0%", isComputer),
                isComputer ? generalStyles.styles.titleFromWeb : undefined,
              ]}
            >
              {!isComputer
                ? "¿Desea comer rico y saludable?"
                : "¿Listo para comer rico y saludable?"}
            </Text>
            {!isComputer ? (
              <BaranaIcons
                draw={{ width: 65, height: 65 }}
                name={"vegetables"}
              />
            ) : null}
          </View>

          <Text style={generalStyles.paragraph("100%", isComputer && 18, "5%")}>
            {!fromWeb
              ? `Encargue semanalmente su comida sin cocinar, gastar tiempo, o sentirse mal por lo que come. Disfrute de la comida sin preocupaciones.`
              : `Baraná se encarga de ayudarle sin el estrés de pensar que vas a cocinar en la semana.`}
          </Text>

          <View
            style={[
              generalStyles.flexContainer(
                "row",
                null,
                "center",
                "wrap",
                null,
                10,
                10
              ),
              { marginVertical: isComputer ? 30 : 15.5 },
            ]}
          >
            <GeneralButton
              text="Ordenar"
              handleOnPress={() => Navigation.navigate("Signup")}
              width={isComputer ? 200 : 110}
            />
            <Text
              style={[generalStyles.paragraph("fit-content", isComputer && 18)]}
            >
              {cuttingDay?.day_delivery_general || ""}
            </Text>
            <TouchableOpacity
              style={generalStyles.smallButton()}
              onPress={() => setOpenPopup(true)}
            >
              <BaranaIcons draw={{ width: 22, height: 22 }} name={"helpIcon"} />
            </TouchableOpacity>
          </View>

          {!isComputer && (
            <>
              <View style={[generalStyles.styles.line]}></View>
              <View
                style={[
                  centerAlignedRow(null, "space-between"),
                  { marginTop: 16 },
                ]}
              >
                <Text style={generalStyles.styles.generalText}>
                  Corte de pedidos
                </Text>
                <View style={centerAlignedRow()}>
                  <BaranaIcons
                    draw={{ width: 20, height: 20 }}
                    name={"clock"}
                  />
                  <Text style={generalStyles.styles.timeText}>
                    {" "}
                    {cuttingDay?.dia_corte || ""}
                  </Text>
                </View>
              </View>
            </>
          )}
        </View>
      </View>
    </View>
  );
};
