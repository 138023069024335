import { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  SafeAreaView,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTheme } from "react-native-paper";
//components
import { ProfileOption } from "./components/ProfileOption";
//Styles
import {
  styles,
  buttomOption,
  webContainer,
} from "../../../styles/pages/generalStyles";
import { bottomBar } from "../../../styles/pages/buttomNavigator/bottomNavigatorsStyle";
// contexts
import { mainContext } from "../../../context/mainContext";
import { shoppingCart } from "../../../context/shoppingCart";
import { orderContext } from "../../../context/orderingContext";
//Utils
import { shoppingCartInitialValues } from "../../utils/shoppingCart/initialValues";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useCartStore from "../shoppingCart/useCartStore";
import useUserData from "../../../hooks/useUserData";
import useOrderStore from "../../../hooks/useOrderStore";
import client from "../../controllers/httpClient";
import { signOut } from "firebase/auth";
import { auth } from "../../../firebaseConfig";

export const Profile = ({ navigation, bottomTabNavigation }) => {
  const { colors } = useTheme();
  const [loading, setLoading] = useState(false);
  const {
    setUserData,
    setUserProfile,
    setUserAaddress,
    setIBANAccount,
    setOrders,
    setPlansAccordingBarana,
  } = useContext(mainContext);
  const { combos, setCombos, plates, setPlates } = useContext(shoppingCart);

  const { cart, setCart, clearCart } = useCartStore();
  const { setCreateNewSubscription } = useContext(orderContext);
  const { setUserSubscriptions } = useOrderStore();
  const { removeUserData, provider } = useUserData();
  const { isComputer } = useIsWeb();

  const cleanContext = () => {
    setOrders([]);
    setUserProfile({});
    setIBANAccount([]);
    setUserAaddress([]);
    setUserSubscriptions([]);
    setPlansAccordingBarana([]);
  };

  const initializeSupermarket = async () => {
    if (cart.combos.length > 0) {
      setCombos(combos.map((item) => ({ ...item, quantity: 0 })));
    }
    if (cart.plates_supermaker.length > 0) {
      setPlates(plates.map((item) => ({ ...item, quantity: 0 })));
    }
  };

  useEffect(() => {
    bottomTabNavigation.setOptions({
      tabBarStyle: bottomBar(isComputer ? "#DB7500" : colors.primary050),
    });
  });

  return (
    <SafeAreaView style={[styles.screen]}>
      <ScrollView>
        <View
          style={[
            styles.container,
            webContainer("50vh", 30),
            isComputer && {
              paddingBottom: 30,
              width: "40%",
              alignSelf: "center",
            },
          ]}
        >
          <ProfileOption
            onPress={() => navigation.navigate("userData")}
            iconName={"myDetails"}
            title={"Mis datos"}
          />
          <ProfileOption
            onPress={() => navigation.navigate("locations")}
            iconName={"addresses"}
            title={"Mis direcciones"}
          />
          <ProfileOption
            onPress={() => navigation.navigate("newSubscription")}
            iconName={"subscriptions"}
            title={"Suscripciones"}
          />
          <ProfileOption
            onPress={() => navigation.navigate("IBAN_Accounts")}
            iconName={"automaticPayment"}
            title={"Pago automático"}
          />
          <ProfileOption
            onPress={() => navigation.navigate("nutritionalPlan")}
            iconName={"schedule"}
            title={"Plan nutricional"}
          />
          <ProfileOption
            onPress={() => navigation.navigate("termsAndConditions")}
            iconName={"information"}
            title={"Términos y condiciones"}
          />

          <TouchableOpacity
            onPress={async () => {
              setLoading(true);
              if (provider === "gmail") {
                await signOut(auth);
              }
              await initializeSupermarket();
              setUserData({
                email: undefined,
                access_token: undefined,
                user_id: undefined,
              });
              setCart(shoppingCartInitialValues);
              cleanContext();
              await AsyncStorage.removeItem("expoToken");
              await AsyncStorage.removeItem("shoppingCartId");
              await AsyncStorage.removeItem("cartArmedPackages");
              await AsyncStorage.removeItem("nutritionalConsultations");
              await AsyncStorage.removeItem("packagesBarana");
              await clearCart();
              await removeUserData();
              setCreateNewSubscription(false);

              setLoading(false);
              client.defaults.headers.common["Authorization"] = undefined;

              navigation.reset({
                index: 0,
                routes: [{ name: "Home" }],
              });
            }}
            style={[
              buttomOption(isComputer ? 40 : 60, 25, 16, "#FB3A2E"),
              { justifyContent: "center" },
            ]}
          >
            {loading ? (
              <ActivityIndicator size="small" color={"white"} />
            ) : (
              <Text style={[styles.number, isComputer && { fontSize: 16 }]}>
                Cerrar Sesión
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};
