import { StyleSheet, Platform } from 'react-native'

export const styles = StyleSheet.create({
    LowerOptionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 12,
        paddingHorizontal: '3.3%',
        justifyContent: 'space-around',
        width: '100%',
        height: 78,
        backgroundColor: '#FFFFFF',
        borderTopWidth: 1,
        borderTopColor: 'rgba(26, 48, 54, 0.13)',
    },
    smallCardTypography: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 13,
        lineHeight: 20,
        textAlign: 'center',
        letterSpacing: 0.01,
        color: '#2F322F',
    },
    dishCard: {
        marginTop: 12,
        paddingRight: 1,
    },
    dish_plus: {
        position: 'absolute',
        right: '0%',
        top: '0%',
        zIndex: 1
    },
    img: {
        width: '93%',
        height: 100,
        borderRadius: 10,
    }
})

export const dropdownOption = () => ({
  height: 75,
  borderTopColor: "#E0E0E0",
  borderTopWidth: 0.9,
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

//Overall Button
export const overallButton = (colors, width, marginR) => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 14,
    paddingHorizontal: 16,
    width: width || '100%',
    height: Platform.OS !== 'web' ? 50 : 45,
    backgroundColor: colors,
    borderRadius: 45,
    marginRight: marginR || 0
})

export const disabledOverallButton = () => ({
    backgroundColor: '#E0E0E0',
})

export const textOverallButton = (colors) => ({
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: Platform.OS === 'ios' ? 16 : 19,
    // lineHeight: 18,
    lineHeight: 19,
    color: '#2F322F',
    textAlign: 'center',
})

//outlined button
export const outlinedButton = (colors, width) => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // alignItems: 'flex-start',
    paddingTop: 14,
    paddingBottom: 13,
    paddingHorizontal: 16,
    width: width,
    height: 50,
    backgroundColor: '#FFF8EF',
    borderRadius: 45,
    borderWidth: 1.5,
    borderColor: '#DB7500',
    marginTop: 15
})

export const dishCardContainer = (width) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: 0,
    marginRight: 4,
    width: width || 140,
    height: 175,
    marginBottom: 10
})

export const smallOrangeCards = (color) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    width: '22%',
    height: 62,
    backgroundColor: color || '#FFE6C8',
    borderRadius: 10,
})
