import { useEffect, useContext } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
//color
import { useTheme } from 'react-native-paper'
//styles
import {
  styles,
  titleStyle,
  headerComponentStyle,
  headerTitleContainer,
} from "../../styles/pages/navigators/headers";
import { bottomBar } from "../../styles/pages/buttomNavigator/bottomNavigatorsStyle";
//screens
import { ShoppingCart } from "../screen/shoppingCart/shoppingCart";
import { FoodMenu } from "../screen/orderDefinition/foodMenu";
import { DetailsMenu } from "../screen/orderDefinition/menu/details";
import { Plan } from "../screen/orderDefinition/planList/planList";
import { NumberOfMeals } from "../screen/orderDefinition/numberOfMeals";

import { AccordingToBarana } from "../screen/orderDefinition/planList/accordingToBarana";
import { NutritionalPrescription } from "../screen/orderDefinition/planList/nutritionalPrescription";
import { EditPlan } from "../screen/orderDefinition/planList/editPlan";
import { NutritionalPlan } from "../screen/profile/nutritionalPlan";
//navigation
import { PaymentsNavigator } from "./paymentsNavigator";
import { OrderDefinitionNavigator } from "./orderDefinitionNavigator";
//context
import { shoppingCart } from "../../context/shoppingCart";
//components
import { GoBack } from "../widgets/goBack";
//hooks
import useIsWeb from "../../hooks/useIsWeb";
import useCartStore from "../screen/shoppingCart/useCartStore";
import useUserData from "../../hooks/useUserData";

export const ShoppingCartNavigator = ({ navigation, route }) => {
  const { colors } = useTheme();
  const Stack = createStackNavigator();
  const { selections } = useContext(shoppingCart);
  const bottomTabNavigation = navigation;
  const { isLoggedIn, access_token } = useUserData();
  const { isComputer } = useIsWeb();
  const { loadCartFromBackendOrLocalStorage } = useCartStore();

  useEffect(() => {
    !isLoggedIn
      ? navigation.setOptions({ tabBarStyle: { display: "none" } })
      : navigation.setOptions({
          tabBarStyle: bottomBar(isComputer ? "#DB7500" : colors.primary050),
        });
  });

  useEffect(() => {
    const handleGetShoppingCart = async () =>
      await loadCartFromBackendOrLocalStorage(access_token);

    handleGetShoppingCart();
  }, []);
  return (
    <Stack.Navigator initialRouteName={"shoppingCart"}>
      <Stack.Screen
        name={"shoppingCart"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} text={"Volver"} icon={"leftArrow"} />
          ),
          headerStyle: styles.headerWithBorder,
          // headerShown: false,
        })}
      >
        {({ navigation }) => (
          <ShoppingCart
            navigation={navigation}
            showDetails={route?.params?.showDetails}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"packages"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => null,
          headerTransparent: true,
        })}
      >
        {({ navigation, route }) => (
          <OrderDefinitionNavigator
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"editPackageMenu"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <FoodMenu
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"detailsMenu"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"leftArrow"} text={"Volver"} />
          ),
          headerStyle: styles.headerWithBorder,
        })}
      >
        {({ navigation, route }) => (
          <DetailsMenu
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
            fromShoppingCart={true}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"editPackagePlan"}
        options={({ navigation }) => ({
          headerTitle: "Tipo de Plan",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Plan
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"nutritionalPlan"}
        options={({ navigation }) => ({
          headerTitle: "Plan Nutricional",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <NutritionalPlan
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"accordingToBarana"}
        options={({ navigation }) => ({
          headerTitle: "Mis Planes Según Barana",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <AccordingToBarana
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"nutritionalPrescription"}
        options={({ navigation, route }) => ({
          headerTitle: "Prescripción Nutricional",
          headerLeft: () => (
            <GoBack
              navigator={navigation}
              icon={"arrowBack"}
              width={48}
              alternative={null}
            />
          ),
          headerTitleStyle: [titleStyle(), { marginLeft: "5%" }],
          headerTitleContainerStyle: headerComponentStyle.titleContainer,
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <NutritionalPrescription
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"editPlan"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"leftArrow"} text={"Volver"} />
          ),
          headerStyle: styles.headerWithBorder,
        })}
      >
        {({ navigation, route }) => (
          <EditPlan
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"payments"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => null,
          headerTransparent: true,
        })}
      >
        {({ navigation, route }) => (
          <PaymentsNavigator
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
};