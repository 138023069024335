import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useModal = create(
  devtools((set, get) => ({
    isOpen: false,
    message: "",
    openMessageModal: (message) => {
      set({
        isOpen: true,
        message,
      });
    },
    closeModal: () => {
      set({
        isOpen: false,
        message: "",
      });
    },
  }))
);

export default useModal;
