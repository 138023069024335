import { Svg, Path } from 'react-native-svg';



export const Fresh = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 36 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M34.75 39.6045V35.3701C34.7487 33.1245 33.879 30.9712 32.3319 29.3832C30.7848 27.7953 28.6868 26.9027 26.4988 26.9014H10.0012C7.81322 26.9027 5.71523 27.7953 4.1681 29.3832C2.62097 30.9712 1.75125 33.1245 1.75 35.3701V39.6045" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M18.2427 18.4375C19.875 18.4394 21.4712 17.9444 22.8294 17.015C24.1875 16.0856 25.2466 14.7636 25.8725 13.2163C26.4985 11.6691 26.6633 9.96597 26.346 8.32255C26.0286 6.67913 25.2435 5.16923 24.09 3.98388C22.9364 2.79854 21.4662 1.991 19.8654 1.66344C18.2645 1.33588 16.605 1.50302 15.0968 2.14372C13.5885 2.78441 12.2993 3.86987 11.3922 5.26276C10.4851 6.65565 10.001 8.29338 10.001 9.96876C10.001 12.2131 10.869 14.3657 12.4143 15.9537C13.9597 17.5416 16.056 18.4349 18.2427 18.4375V18.4375Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M20.2967 35.3458C19.9629 35.0006 19.5657 34.7266 19.1281 34.5397C18.6917 34.3538 18.2239 34.2581 17.7514 34.2581C17.2789 34.2581 16.811 34.3538 16.3746 34.5397C15.9374 34.7252 15.5403 34.9975 15.206 35.3409L14.5152 36.0499L13.8198 35.3409C13.4855 34.9982 13.0887 34.7263 12.6521 34.541C12.2155 34.3556 11.7476 34.2603 11.2751 34.2605C10.8026 34.2608 10.3347 34.3565 9.89829 34.5423C9.46184 34.7281 9.06532 35.0003 8.73137 35.3434C8.39742 35.6864 8.13257 36.0937 7.95195 36.5418C7.77134 36.9899 7.67849 37.4702 7.67871 37.9552C7.67893 38.4401 7.77221 38.9203 7.95324 39.3682C8.13426 39.8162 8.39947 40.2232 8.73373 40.5659L9.42449 41.2798L14.5152 46.4999L19.6013 41.2798L20.2967 40.5659C20.631 40.2215 20.8976 39.8143 21.0821 39.3665C21.4418 38.4606 21.4418 37.4462 21.0821 36.5404C20.8989 36.0945 20.6339 35.6889 20.3015 35.3458H20.2967Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    )
}