import { View, ScrollView, FlatList, Dimensions } from 'react-native'
//components
import { ScheduledAppointmentItem } from './items'
//hooks
import useIsWeb from '../../../../hooks/useIsWeb';

const { width } = Dimensions.get("window");
export const ScheduledAppointmentList = ({ nutritionalConsultes }) => {
    const { isComputer } = useIsWeb();

    return (
      <View>
        <ScrollView
          showsHorizontalScrollIndicator={false}
          horizontal={true}
          style={{ width: "100%" }}
          contentContainerStyle={{ width: "100%" }}
        >
          <FlatList
            bounces={false}
            style={{ width: !isComputer ? width - 44 : "100%" }}
            data={nutritionalConsultes}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => {
              return <ScheduledAppointmentItem item={item} index={index} />;
            }}
          />
        </ScrollView>
      </View>
    );
}