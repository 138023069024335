export const Days = [
  "Sábado: 12:00 p.m. - 4:30 p.m",
  "Lunes: 7:30 a.m. - 3:00 p.m",
  "Coordinar día/Hora",
];

export const filterZones = (province) => {
    let newArray = Zone.filter(item => item.from.includes(province))
    newArray = newArray.map(item => item.name)
    return newArray;
}

export const filterLocality = (zone) => {
    let newArray = Locality.filter(item => item.from.includes(zone))
    newArray = newArray.map(item => item.name)
    return newArray;
}

export const sortUserAddresses = (addresses) => {
    let newArray = [];

    newArray = addresses.map(item => {
        return `${item?.province ? item?.province : ''}, ${item?.zone && item?.zone !== 'No aplica' ? item?.zone : ''}, ${item?.location && item?.location !== 'No aplica' ? item?.location : ''}${item?.text && item?.text !== 'No aplica' && ', '}${item?.text && item?.text !== 'No aplica' ? item?.text : ''}.`
    })
    return newArray;
}

export const getSelectedPickerAddress = (addresses, address) => {
    let addressSelected = {};

    addressSelected = addresses.find(item => {
        const compare = `${item?.province ? item?.province : ''}, ${item?.zone && item?.zone !== 'No aplica' ? item?.zone : ''}, ${item?.location && item?.location !== 'No aplica' ? item?.location : ''}${item?.text && item?.text !== 'No aplica' && ', '}${item?.text && item?.text !== 'No aplica' ? item?.text : ''}.`
        if (compare === address) {
            return item
        }
    })
    return addressSelected;
}

export const getOrderAddress = (address) => {
    let orderAddress = '';
    orderAddress = `${address?.province ? address.province : ''}, ${address?.zone && address?.zone !== 'No aplica' ? address.zone : ''}, ${address?.location && address?.location !== 'No aplica' ? address.location : ''}${address?.text && address?.text !== 'No aplica' && ', '}${address?.text && address?.text !== 'No aplica' ? address.text : ''}.`

    return orderAddress;
}

export const Provinces = [
    'San José',
    'Alajuela',
    'Heredia',
    'Cartago',
]

export const Zone = [
    {
        name: 'San José',
        nature: 'zone',
        from: 'San José',
    },
    {
        name: 'Escazú',
        nature: 'zone',
        from: 'San José',
    },
    {
        name: 'Desamparados',
        nature: 'zone',
        from: 'San José',
    },
    {
        name: 'Goicoechea',
        nature: 'zone',
        from: 'San José',
    },
    {
        name: 'Santa Ana',
        nature: 'zone',
        from: 'San José',
    },
    {
        name: 'Tibás',
        nature: 'zone',
        from: 'San José',
    },
    {
        name: 'Moravia',
        nature: 'zone',
        from: 'San José',
    },
    {
        name: 'Montes de Oca',
        nature: 'zone',
        from: 'San José',
    },
    {
        name: 'Curridabat',
        nature: 'zone',
        from: 'San José',
    },
    {
        name: 'Consultar por otro lugar',
        nature: 'zone',
        from: 'San José',
    },

    {
        name: 'Guácima',
        nature: 'zone',
        from: 'Alajuela',
    },
    {
        name: 'Concasa',
        nature: 'zone',
        from: 'Alajuela',
    },
    {
        name: 'San Rafael',
        nature: 'zone',
        from: 'Alajuela',
    },
    {
        name: 'Consultar por otro lugar',
        nature: 'zone',
        from: 'Alajuela',
    },


    {
        name: 'Heredia',
        nature: 'zone',
        from: 'Heredia',
    },
    {
        name: 'Santo Domingo',
        nature: 'zone',
        from: 'Heredia',
    },
    {
        name: 'San Rafael',
        nature: 'zone',
        from: 'Heredia',
    },
    {
        name: 'Belén',
        nature: 'zone',
        from: 'Heredia',
    },
    {
        name: 'San Pablo',
        nature: 'zone',
        from: 'Heredia',
    },
    {
        name: 'Consultar por otro lugar',
        nature: 'zone',
        from: 'Heredia',
    },

    {
        name: 'Tres Ríos',
        nature: 'zone',
        from: 'Cartago',
    },
    {
        name: 'Cartago Centro',
        nature: 'zone',
        from: 'Cartago',
    },
    {
        name: 'El Guarco',
        nature: 'zone',
        from: 'Cartago',
    },
    {
        name: 'Consultar por otro lugar',
        nature: 'zone',
        from: 'Cartago',
    },

]

export const Locality = [
    {
        name: 'Carmen',
        nature: 'locality',
        from: 'San José',
        id: 1,
    },
    {
        name: 'Catedral',
        nature: 'locality',
        from: 'San José',
        id: 2,
    },
    {
        name: 'Hospital',
        nature: 'locality',
        from: 'San José',
        id: 3,
    },
    {
        name: 'Merced',
        nature: 'locality',
        from: 'San José',
        id: 4,
    },
    {
        name: 'Pavas: La Asunción',
        nature: 'locality',
        from: 'San José',
        id: 5,
    },
    {
        name: 'Pavas: Pavas-Embajada USA',
        nature: 'locality',
        from: 'San José',
        id: 6,
    },
    {
        name: 'Pavas: Rohrmoser',
        nature: 'locality',
        from: 'San José',
        id: 7,
    },
    {
        name: 'Sabana Norte',
        nature: 'locality',
        from: 'San José',
        id: 8,
    },
    {
        name: 'Sabana Sur',
        nature: 'locality',
        from: 'San José',
        id: 9,
    },
    {
        name: 'San Francisco de Dos Ríos',
        nature: 'locality',
        from: 'San José',
        id: 10,
    },
    {
        name: 'San Sebastián',
        nature: 'locality',
        from: 'San José',
        id: 11,
    },
    {
        name: 'Uruca: Barceló-La peregrina',
        nature: 'locality',
        from: 'San José',
        id: 12,
    },
    {
        name: 'Uruca: Hospital México',
        nature: 'locality',
        from: 'San José',
        id: 13,
    },
    {
        name: 'Zapote',
        nature: 'locality',
        from: 'San José',
        id: 14,
    },

    {
        name: 'Anonos',
        nature: 'locality',
        from: 'Escazú',
        id: 15,
    },
    {
        name: 'Escazú centro',
        nature: 'locality',
        from: 'Escazú',
        id: 16,
    },
    {
        name: 'Guachilelín',
        nature: 'locality',
        from: 'Escazú',
        id: 17,
    },
    {
        name: 'Los Laureles',
        nature: 'locality',
        from: 'Escazú',
        id: 18,
    },
    {
        name: 'Maynard',
        nature: 'locality',
        from: 'Escazú',
        id: 19,
    },
    {
        name: 'Palma de Mayorca',
        nature: 'locality',
        from: 'Escazú',
        id: 20,
    },
    {
        name: 'Rivergrand',
        nature: 'locality',
        from: 'Escazú',
        id: 21,
    },
    {
        name: 'San Rafael',
        nature: 'locality',
        from: 'Escazú',
        id: 22,
    },
    {
        name: 'Santa Marta',
        nature: 'locality',
        from: 'Escazú',
        id: 23,
    },
    {
        name: 'Tena',
        nature: 'locality',
        from: 'Escazú',
        id: 24,
    },
    {
        name: 'Trejos Montealegre',
        nature: 'locality',
        from: 'Escazú',
        id: 25,
    },
    {
        name: 'Vista Alegre',
        nature: 'locality',
        from: 'Escazú',
        id: 26,
    },


    {
        name: 'Centro',
        nature: 'locality',
        from: 'Desamparados',
        id: 27,
    },
    {
        name: 'Gravilias',
        nature: 'locality',
        from: 'Desamparados',
        id: 28,
    },
    {
        name: 'San Antonio',
        nature: 'locality',
        from: 'Desamparados',
        id: 29,
    },

    {
        name: 'Calle Blancos',
        nature: 'locality',
        from: 'Goicoechea',
        id: 30,
    },
    {
        name: 'Guadalupe',
        nature: 'locality',
        from: 'Goicoechea',
        id: 31,
    },
    {
        name: 'San Francisco',
        nature: 'locality',
        from: 'Goicoechea',
        id: 32,
    },

    {
        name: 'Piedades',
        nature: 'locality',
        from: 'Santa Ana',
        id: 33,
    },
    {
        name: 'Pozos Sur',
        nature: 'locality',
        from: 'Santa Ana',
        id: 34,
    },
    {
        name: 'Río Oro',
        nature: 'locality',
        from: 'Santa Ana',
        id: 35,
    },

    {
        name: 'San Juan',
        nature: 'locality',
        from: 'Tibás',
        id: 36,
    },
    {
        name: 'Llorente',
        nature: 'locality',
        from: 'Tibás',
        id: 37,
    },
    {
        name: 'Colima',
        nature: 'locality',
        from: 'Tibás',
        id: 38,
    },
    {
        name: 'Cinco Esquinas',
        nature: 'locality',
        from: 'Tibás',
        id: 39,
    },
    {
        name: 'La Trinidad',
        nature: 'locality',
        from: 'Tibás',
        id: 40,
    },

    {
        name: 'Los Colegios',
        nature: 'locality',
        from: 'Moravia',
        id: 41,
    },
    {
        name: 'San Martín',
        nature: 'locality',
        from: 'Moravia',
        id: 42,
    },
    {
        name: 'San Vicente',
        nature: 'locality',
        from: 'Moravia',
        id: 43,
    },
    {
        name: 'Jardines',
        nature: 'locality',
        from: 'Moravia',
        id: 44,
    },

    {
        name: 'Dent',
        nature: 'locality',
        from: 'Montes de Oca',
        id: 45,
    },
    {
        name: 'Don Boscoo',
        nature: 'locality',
        from: 'Montes de Oca',
        id: 46,
    },
    {
        name: 'La Granja',
        nature: 'locality',
        from: 'Montes de Oca',
        id: 47,
    },
    {
        name: 'Los Yoses',
        nature: 'locality',
        from: 'Montes de Oca',
        id: 48,
    },
    {
        name: 'Monterrey',
        nature: 'locality',
        from: 'Montes de Oca',
        id: 49,
    },
    {
        name: 'Rossevelt',
        nature: 'locality',
        from: 'Montes de Oca',
        id: 50,
    },
    {
        name: 'Saprissa',
        nature: 'locality',
        from: 'Montes de Oca',
        id: 51,
    },
    {
        name: 'Vargas Araya',
        nature: 'locality',
        from: 'Montes de Oca',
        id: 52,
    },

    {
        name: 'Curridabat',
        nature: 'locality',
        from: 'Curridabat',
        id: 53,
    },
    {
        name: 'Granadilla',
        nature: 'locality',
        from: 'Curridabat',
        id: 54,
    },
    {
        name: 'Guayabos',
        nature: 'locality',
        from: 'Curridabat',
        id: 55,
    },
    {
        name: 'Hacienda vieja',
        nature: 'locality',
        from: 'Curridabat',
        id: 56,
    },
    {
        name: 'La Lía',
        nature: 'locality',
        from: 'Curridabat',
        id: 57,
    },
    {
        name: 'Montufar',
        nature: 'locality',
        from: 'Curridabat',
        id: 58,
    },
    {
        name: 'Pinares',
        nature: 'locality',
        from: 'Curridabat',
        id: 59,
    },

    {
        name: 'Consultar por otro lugar',
        nature: 'locality',
        from: 'Consultar por otro lugar',
        id: 60,
    },

    {
        name: 'No aplica',
        nature: 'locality',
        from: 'Guácima',
        id: 61,
    },
    {
        name: 'No aplica',
        nature: 'locality',
        from: 'Concasa',
        id: 62,
    },
    {
        name: 'No aplica',
        nature: 'locality',
        from: 'San Rafael',
        id: 63,
    },


    {
        name: 'Consultar por otro lugar',
        nature: 'locality',
        from: 'Consultar por otro lugar',
        id: 64,
    },

    {
        name: 'Aurora',
        nature: 'locality',
        from: 'Heredia',
        id: 65,
    },
    {
        name: 'Barreal',
        nature: 'locality',
        from: 'Heredia',
        id: 66,
    },
    {
        name: 'Lagunilla',
        nature: 'locality',
        from: 'Heredia',
        id: 67,
    },
    {
        name: 'San Francisco',
        nature: 'locality',
        from: 'Heredia',
        id: 68,
    },

    {
        name: 'Santo Domingo',
        nature: 'locality',
        from: 'Santo Domingo',
        id: 69,
    },

    {
        name: 'San Josecito',
        nature: 'locality',
        from: 'San Rafael',
        id: 70,
    },
    {
        name: 'San Rafael',
        nature: 'locality',
        from: 'San Rafael',
        id: 71,
    },
    {
        name: 'Santiago',
        nature: 'locality',
        from: 'San Rafael',
        id: 72,
    },

    {
        name: 'Cariari',
        nature: 'locality',
        from: 'Belén',
        id: 73,
    },
    {
        name: 'La Asunción',
        nature: 'locality',
        from: 'Belén',
        id: 74,
    },
    {
        name: 'La Ribera',
        nature: 'locality',
        from: 'Belén',
        id: 75,
    },
    {
        name: 'Los Arcos',
        nature: 'locality',
        from: 'Belén',
        id: 76,
    },
    {
        name: 'San Antonio',
        nature: 'locality',
        from: 'Belén',
        id: 77,
    },

    {
        name: 'San Pablo',
        nature: 'locality',
        from: 'San Pablo',
        id: 78,
    },

    {
        name: 'Consultar por otro lugar',
        nature: 'locality',
        from: 'Consultar por otro lugar',
        id: 79,
    },

    {
        name: 'No aplica',
        nature: 'locality',
        from: 'Tres Ríos',
        id: 80,
    },

    {
        name: 'Barrio Santa Cecilia',
        nature: 'locality',
        from: 'Cartago Centro',
        id: 81,
    },
    {
        name: 'Barrio Santa Cecilia',
        nature: 'locality',
        from: 'La Lima',
        id: 82,
    },
    {
        name: 'Barrio Santa Cecilia',
        nature: 'locality',
        from: 'Loyola',
        id: 83,
    },
    {
        name: 'Barrio Santa Cecilia',
        nature: 'locality',
        from: 'Taras',
        id: 84,
    },

    {
        name: 'Barrio el Tejar',
        nature: 'locality',
        from: 'El Guarco',
        id: 85,
    },
    {
        name: 'Barrio Nuevo del Guarco',
        nature: 'locality',
        from: 'El Guarco',
        id: 86,
    },

    {
        name: 'Consultar por otro lugar',
        nature: 'locality',
        from: 'Consultar por otro lugar',
        id: 87,
    },
]