import { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
} from "react-native";
import { useLinkTo } from "@react-navigation/native";
import { useNavigation } from "@react-navigation/core";
//icons
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  paragraph,
  spaceBetween,
  smallButton,
  flexContainer,
} from "../../../../styles/pages/generalStyles";
import {
  homeStyles,
  centerAlignedRow,
} from "../../../../styles/pages/home/homePage";
// contexts
import { orderContext } from "../../../../context/orderingContext";
//utils
import { sortPackageToEdit } from "../../../utils/shoppingCart/package";
import { capitalize } from "../../../utils/utils";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";
import useOrderStore from "../../../../hooks/useOrderStore";

const PlanRow = (
  subPackage,
  handleDuplicateCartPackages,
  armedPackageId,
  mealTimes,
  setSubpackage
) => {
  const linkTo = useLinkTo();
  return (
    <>
      {subPackage["almuerzos"]?.plan?.name && (
        <View style={[centerAlignedRow(5, "space-between")]}>
          <View style={[centerAlignedRow()]}>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="doctorTool" />
            <Text style={[paragraph("80%", 17, 0, "auto"), { marginLeft: 10 }]}>
              Tipo de Plan de Almuerzos:{" "}
              {capitalize(subPackage["almuerzos"].plan.name)}
            </Text>
          </View>
          {handleDuplicateCartPackages && (
            <TouchableOpacity
              style={smallButton()}
              onPress={async () => {
                setSubpackage({
                  ...subPackage,
                  armed_package: armedPackageId
                })
                setTimeout(
                  () =>
                    linkTo(
                      `/editPackagePlan/almuerzos/${subPackage["almuerzos"]?.plan?.id}`
                    ),
                  50
                );
              }}
            >
              <BaranaIcons draw={{ width: 24, height: 24 }} name="pencil" />
            </TouchableOpacity>
          )}
        </View>
      )}
      {subPackage["desayunos"]?.plan?.name && (
        <View style={[centerAlignedRow(5, "space-between")]}>
          <View style={[centerAlignedRow()]}>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="doctorTool" />
            <Text style={[paragraph("80%", 17, 0, "auto"), { marginLeft: 10 }]}>
              Tipo de Plan de Desayunos:{" "}
              {capitalize(subPackage["desayunos"].plan.name) || ""}
            </Text>
          </View>
          {handleDuplicateCartPackages && (
            <TouchableOpacity
              style={smallButton()}
              onPress={async () => {
                setSubpackage({
                  ...subPackage,
                  armed_package: armedPackageId
                })
                setTimeout(
                  () =>
                    linkTo(
                      `/editPackagePlan/desayunos/${subPackage["desayunos"]?.plan?.id}`
                    ),
                  50
                );
              }}
            >
              <BaranaIcons draw={{ width: 24, height: 24 }} name="pencil" />
            </TouchableOpacity>
          )}
        </View>
      )}
      {subPackage["cenas"]?.plan?.name && (
        <View style={[centerAlignedRow(5, "space-between")]}>
          <View style={[centerAlignedRow()]}>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="doctorTool" />
            <Text style={[paragraph("80%", 17, 0, "auto"), { marginLeft: 10 }]}>
              Tipo de Plan de Cenas:{" "}
              {capitalize(subPackage["cenas"].plan.name) || ""}
            </Text>
          </View>
          {handleDuplicateCartPackages && (
            <TouchableOpacity
              style={smallButton()}
              onPress={async () => {
                setSubpackage({
                  ...subPackage,
                  armed_package: armedPackageId
                })
                setTimeout(
                  () =>
                    linkTo(
                      `/editPackagePlan/cenas/${subPackage["cenas"]?.plan?.id}`
                    ),
                  50
                );
              }}
            >
              <BaranaIcons draw={{ width: 24, height: 24 }} name="pencil" />
            </TouchableOpacity>
          )}
        </View>
      )}
      {subPackage["generic"]?.plan?.name && (
        <View style={[centerAlignedRow(5, "space-between")]}>
          <View style={[centerAlignedRow()]}>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="doctorTool" />
            <Text style={[paragraph("80%", 17, 0, "auto"), { marginLeft: 10 }]}>
              Tipo de Plan de{" "}
              {subPackage["generic"]?.time_food?.name.includes("ninguno")
                ? "Adulto mayor"
                : "Familiar"}
              : {capitalize(subPackage["generic"].plan.name) || ""}
            </Text>
          </View>
          {handleDuplicateCartPackages && (
            <TouchableOpacity
              style={smallButton()}
              onPress={async () => {
                setSubpackage({
                  ...subPackage,
                  armed_package: armedPackageId
                })
                setTimeout(
                  () =>
                    linkTo(
                      `/editPackagePlan/${subPackage?.title.toLowerCase()}/${
                        subPackage["generic"]?.plan?.id
                      }`
                    ),
                  50
                );
              }}
            >
              <BaranaIcons draw={{ width: 24, height: 24 }} name="pencil" />
            </TouchableOpacity>
          )}
        </View>
      )}
    </>
  );
};

export const SubPackageListItems = ({
  subPackage,
  handleDuplicateCartPackages,
  armedPackageId,
}) => {
  const {
    setCurrentStep,
    setTotalSteps,
    orderDefinition,
    setOrderDefinition,
    mealTimesList,
  } = useContext(orderContext);
  const [mealTimes, setMealTimes] = useState([]);
  const [change, setChange] = useState(false);
  const [cant, setCant] = useState(subPackage?.copy?.cant || "1");
  const navigation = useNavigation();
  const { isComputer } = useIsWeb();
  const { setTimesFood, setSubpackage } = useOrderStore();

  useEffect(() => {
    setChange(!change);
  }, [subPackage, armedPackageId, cant]);

  useEffect(() => {
    // mealTimesList.map((item) => {
    //   if (subPackage?.title?.includes(item.name)) {
    //     mealTimes.push(item.id);
    //     setMealTimes(mealTimes);
    //   }
    // });

    setTimesFood({
      desayunos: subPackage.times_food.some(
        (time) => time.toLowerCase() === "desayunos"
      ),
      almuerzos: subPackage.times_food.some(
        (time) => time.toLowerCase() === "almuerzos"
      ),
      cenas: subPackage.times_food.some(
        (time) => time.toLowerCase() === "cenas"
      ),
      familiar: subPackage.times_food.some(
        (time) => time.toLowerCase() === "familiar"
      ),
      ninguno: subPackage.times_food.some(
        (time) => time.toLowerCase() === "ninguno"
      ),
    });
  }, []);

  return (
    <View>
      <View style={[styles.line, { marginTop: 15 }]}></View>
      <View style={[centerAlignedRow(25), spaceBetween(25)]}>
        <Text style={homeStyles.strongText}>
          {capitalize(subPackage?.title) || "Platos del paquete"}
        </Text>
        <TouchableOpacity
          onPress={async () => {
            if (handleDuplicateCartPackages) {
              setCant(0);
              handleDuplicateCartPackages(
                false,
                armedPackageId,
                subPackage.sub_package_id
              );
              setTimeout(() => {
                // navigation.navigate("Home", {
                //   forseNavTo: "order_definition",
                // });
                navigation.navigate("packages", { screen: "numberOfMeals" });
              }, 50);
            }
          }}
          style={smallButton()}
        >
          <BaranaIcons
            draw={{ width: 24, height: 24 }}
            name={handleDuplicateCartPackages && "pencil"}
          />
        </TouchableOpacity>
      </View>
      {PlanRow(
        subPackage,
        handleDuplicateCartPackages,
        armedPackageId,
        mealTimes,
        setSubpackage
      )}
      <View style={[centerAlignedRow(5, "space-between")]}>
        <View style={[centerAlignedRow()]}>
          <BaranaIcons
            draw={{ width: 24, height: 24, color: "black" }}
            name="littleHeart"
          />
          <Text style={[styles.helpText, { marginLeft: 10 }]}>Menu</Text>
        </View>
      </View>
      <View>
        {subPackage?.desayunos?.plates?.length > 0 ? (
          <View style={flexContainer("column", null, "flex-start")}>
            <Text style={[homeStyles.strongText, { marginTop: 10 }]}>
              Desayunos
            </Text>
            <View>
              {subPackage.desayunos.plates.map((item) => {
                return (
                  <Text key={item.id} style={[paragraph("100%", 17, 10)]}>
                    {item?.recipe || ""}
                  </Text>
                );
              })}
            </View>
          </View>
        ) : null}
        {subPackage?.almuerzos?.plates?.length > 0 ? (
          <View style={flexContainer("column", null, "flex-start")}>
            <Text style={[homeStyles.strongText, { marginTop: 10 }]}>
              Almuerzos
            </Text>
            <View>
              {subPackage.almuerzos.plates.map((item) => {
                return (
                  <Text key={item.id} style={[paragraph("100%", 17, 10)]}>
                    {item?.recipe || ""}
                  </Text>
                );
              })}
            </View>
          </View>
        ) : null}
        {subPackage?.cenas?.plates?.length > 0 ? (
          <View style={flexContainer("column", null, "flex-start")}>
            <Text style={[homeStyles.strongText, { marginTop: 10 }]}>
              Cenas
            </Text>
            <View>
              {subPackage.cenas.plates.map((item) => {
                return (
                  <Text key={item.id} style={[paragraph("100%", 17, 10)]}>
                    {item?.recipe || ""}
                  </Text>
                );
              })}
            </View>
          </View>
        ) : null}
        {subPackage?.generic?.plates?.length > 0 ? (
          <View style={flexContainer("column", null, "flex-start")}>
            {subPackage.generic.plates.map((item) => {
              return (
                <Text key={item.id} style={[paragraph("100%", 17, 10)]}>
                  {item?.recipe || ""}
                </Text>
              );
            })}
          </View>
        ) : null}
      </View>
      <View style={{ alignItems: "flex-end", marginTop: 10 }}>
        <View style={[centerAlignedRow()]}>
          <View style={[centerAlignedRow()]}>
            <TouchableOpacity
              onPress={() => {
                if (handleDuplicateCartPackages) {
                  const newCant = cant - 1;
                  setCant(newCant);
                  handleDuplicateCartPackages(
                    false,
                    armedPackageId,
                    subPackage.sub_package_id,
                    newCant
                  );
                }
              }}
            >
              <BaranaIcons
                draw={{ width: 24, height: 24 }}
                name={handleDuplicateCartPackages && "outlinedMinus"}
              />
            </TouchableOpacity>
            <Text style={[homeStyles.strongText, { marginHorizontal: 5 }]}>
              {subPackage?.copy?.cant > 0 ? cant : "1"}{" "}
              {!handleDuplicateCartPackages && "    x"}
            </Text>
            <TouchableOpacity
              onPress={() => {
                if (handleDuplicateCartPackages) {
                  const newCant = cant + 1;
                  setCant(newCant);
                  handleDuplicateCartPackages(
                    true,
                    armedPackageId,
                    subPackage.sub_package_id,
                    newCant
                  );
                }
              }}
            >
              <BaranaIcons
                draw={{ width: 24, height: 24 }}
                name={handleDuplicateCartPackages && "outlinedPlus"}
              />
            </TouchableOpacity>
          </View>
          <Text style={[homeStyles.strongText, { marginLeft: 20 }]}>
            ₡ {subPackage?.price * cant}
          </Text>
        </View>
      </View>
    </View>
  );
};
