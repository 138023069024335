import { createContext } from 'react'

export const nutritionalConsulte = createContext({
    type_consultation: "",
    setTypeConsultation: () => { },
    age: "",
    setAge: () => { },
    schedule_date: "",
    setScheduleDate: () => { },
    delivery_hour: "",
    setDeliveryHour: () => { },
    healthConditions: "",
    setHealthConditions: () => { },

    contact_name: "",
    setContactName: () => { },
    contact_email: "",
    setContactEmail: () => { },
    contact_phone: "",
    setContactPhone: () => { },
})