import { Svg, Path } from 'react-native-svg';

export const Plus = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M12 12H4M12 20V12V20ZM12 12V4V12ZM12 12H20H12Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" />
        </Svg>
    )
}