import { useContext } from 'react'
import { View, Text, ScrollView, Platform } from "react-native";
//Styles
import {
  styles,
  title,
  titleMargin,
  paragraph,
  webContainer,
} from "../../../styles/pages/generalStyles";
//components
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
// contexts
import { forgotContext } from "../../../context/forgotContext";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

export const CheckYourEmailMessage = ({ route, navigation }) => {
  const { email } = useContext(forgotContext);
  const { isComputer } = useIsWeb();

  return (
    <View style={[styles.screen]}>
      <ScrollView style={[styles.scrollScreen]}>
        <View
          style={[
            webContainer(!isComputer && "65vh", null, isComputer),
            isComputer && { alignItems: "center" },
          ]}
        >
          <View style={[isComputer && { alignItems: "center" }]}>
            <Text
              style={[
                title("90%", isComputer ? "center" : "left"),
                titleMargin(isComputer),
              ]}
            >
              Revise su correo electrónico
            </Text>
            <Text style={[paragraph("94%")]}>
              Hemos enviado un correo de recuperación a {email} si el correo es
              correcto recibirá un enlace para recuperar su contraseña.
            </Text>
          </View>
          {isComputer ? (
            <View style={{ marginTop: 48 }}>
              <GeneralButton
                text="Continuar"
                handleOnPress={() => navigation.navigate("verification")}
                width={330}
              />
            </View>
          ) : null}
        </View>
      </ScrollView>
      {!isComputer ? (
        <LowerOptionsContainer>
          <GeneralButton
            text="Continuar"
            handleOnPress={() => navigation.navigate("verification")}
            width="100%"
          />
        </LowerOptionsContainer>
      ) : null}
    </View>
  );
};