import { Svg, Path } from 'react-native-svg';


export const WinWithBarana = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M44.083 7.66699V19.167H32.583" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M1.91699 38.333V26.833H13.417" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M6.72783 17.2503C7.6999 14.5034 9.352 12.0474 11.53 10.1116C13.7079 8.17576 16.3408 6.82323 19.1829 6.18018C22.0249 5.53712 24.9836 5.6245 27.7828 6.43416C30.5819 7.24383 33.1304 8.74938 35.1903 10.8103L44.0837 19.167M1.91699 26.8337L10.8103 35.1903C12.8703 37.2513 15.4187 38.7569 18.2179 39.5665C21.017 40.3762 23.9757 40.4636 26.8178 39.8205C29.6599 39.1775 32.2927 37.8249 34.4707 35.8891C36.6487 33.9533 38.3008 31.4973 39.2728 28.7503" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>

    )
}