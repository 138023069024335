import { View, Text, ScrollView, FlatList, Dimensions } from "react-native";
//styles
import {
  styles,
  title,
  titleMargin,
  sectionContainerWeb,
} from "../../../styles/pages/generalStyles";
import { sections } from "../../../styles/pages/home/homePage";
//components
import { DropdownOption } from "../../widgets/dropdownOption";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

const questions = [
  {
    title: "¿Cuánto dura la comida?",
    text: "El sistema de empaque está diseñado para que la comida se consuma durante la semana. Dependiendo de las características del alimento este puede durar hasta 2 semanas. Sin embargo, cada producto tiene su fecha de consumo sugerida, pues algunos tienen menor duración.",
  },
  {
    title: "Métodos de pago",
    text: "Pago en línea, Sinpe Móvil o Transferencia. Efectivo solo en casos especiales.",
  },
  {
    title: "¿Empaques reciclables?",
    text: "Utilizamos empaques plásticos que son retornables y reciclables. Esto porque los empaques biodegradables que existen para almacenar alimentos por varios días generan microplásticos. Lo que, en lugar de contribuir a disminuir el uso plástico, promueve la proliferación de microplásticos en el ambiente.",
  },
  {
    title: "¿Dónde realizan entregas?",
    text: "Realizamos entregas en diferentes partes de San José y Heredia, así como Alajuela y Cartago centro. Puede llamarnos o escribirnos al WhatsApp para consultar por su zona al 8888-2406.",
  },
  {
    title: "¿El menú cambia cada semana?",
    text: "Sí, contamos con gran variedad de alimentos. Al seleccionar uno de nuestros paquetes o al ingresar a nuestro supermercado podrá seleccionar opciones diferentes todas las semanas.",
  },
];

const { width } = Dimensions.get("window");
export const FrequentlyQuestions = ({ background }) => {
  const { isComputer } = useIsWeb();

  return (
    <View style={sections(background == "dark", 510, 36, 20)}>
      <View
        style={
          isComputer
            ? sectionContainerWeb(800, null, "100%", "center")
            : undefined
        }
      >
        <Text
          style={[
            title("100%", isComputer ? "center" : "left"),
            titleMargin(isComputer),
          ]}
        >
          Preguntas frecuentes
        </Text>
        <ScrollView
          showsHorizontalScrollIndicator={false}
          horizontal={true}
          style={{ width: "100%" }}
          contentContainerStyle={{ width: "100%" }}
        >
          <FlatList
            bounces={false}
            style={{ width: !isComputer ? width - 40 : "100%" }}
            data={questions}
            renderItem={({ item, index }) => {
              return (
                <DropdownOption
                  {...item}
                  uniqueKey={`frequentlyQuestions-${index}`}
                />
              );
            }}
          />
        </ScrollView>
        <View style={[styles.line]}></View>
      </View>
    </View>
  );
};
