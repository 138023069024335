import { Svg, G, Defs, Path, ClipPath, Rect } from 'react-native-svg';

export const Steak = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <G clipPath="url(#clip0_94_4323)">
                <Path fillRule="evenodd" clipRule="evenodd" d="M11.3351 57.7733C6.84972 74.6461 25.9876 68.2102 35.4191 70.4282C41.9032 71.5374 45.5512 78.5523 58.3407 78.5523C70.4661 78.5523 69.4694 65.741 68.4728 59.961" fill="#E07A69" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M10.4453 57.5421C8.77803 64.3785 10.7517 69.5134 18.5972 70.5216C26.3184 71.5134 33.7529 69.4862 40.725 73.6488C47.5008 77.6939 57.7349 82.5519 65.3574 77.4324C70.961 73.6693 70.2872 65.2955 69.363 59.7298C69.1798 58.6262 67.4014 59.097 67.5829 60.1919C68.5512 66.0221 69.0234 74.7596 61.8517 77.2155C53.4784 80.0837 45.9562 74.1791 39.1559 70.7642C34.3749 68.3632 28.4322 69.0601 23.2266 69.0401C20.2655 69.0288 17.1159 68.9267 14.4444 67.6001C10.7342 65.7575 11.4561 61.157 12.2254 58.0042C12.4909 56.9144 10.7119 56.4519 10.4453 57.5421Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M12.3984 46.1893C8.51165 54.336 11.2197 59.4965 15.9357 61.7139C20.6512 63.9319 29.4934 61.7139 34.2089 63.3775C40.6936 65.5949 44.8197 68.9216 51.8932 69.4765C67.8094 70.0308 71.5446 60.3708 65.6498 47.6185C59.7551 34.8662 44.8878 34.9636 33.0301 35.1006C25.0129 35.1924 15.9864 39.3375 12.3984 46.1893Z" fill="#EFADB2" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M11.5082 45.9586C9.11752 51.3478 8.46654 57.595 13.8657 61.5299C19.8101 65.8617 28.5503 62.2617 35.2307 64.6714C39.0793 66.0602 42.6057 68.0894 46.5165 69.3519C50.1291 70.5181 53.9259 70.5237 57.6649 70.0381C62.9436 69.3519 67.266 66.3612 68.756 61.4914C70.3835 56.1709 68.0385 49.8878 65.4134 45.2232C62.1825 39.4812 55.8793 36.4525 49.3772 35.1442C36.3479 32.5217 18.6586 33.3566 11.6015 45.7514C11.0394 46.7381 12.633 47.6145 13.1951 46.6273C19.6951 35.2109 34.8938 34.9545 46.8878 36.4935C53.047 37.2832 58.8153 39.6001 62.641 44.3555C66.0229 48.5586 68.0391 55.0801 67.1422 60.3248C66.3135 65.173 61.9153 67.5376 57.1742 68.3637C49.5931 69.6858 43.5947 66.1114 36.9345 63.4771C31.296 61.2478 25.7894 62.2109 19.8417 61.7832C10.8333 61.135 10.5574 52.575 13.2883 46.4201C13.7463 45.3873 11.9597 44.9412 11.5082 45.9586Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M30.7685 43.3997C26.6424 41.1817 19.6708 46.4428 21.9651 49.9622C28.2769 59.6479 41.7686 48.4679 30.7685 43.3997Z" fill="#E06578" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M31.2346 42.6504C27.5125 40.8509 22.6575 43.2288 21.0273 46.6288C19.3099 50.2104 23.4491 53.8186 26.9193 54.5155C30.1851 55.1719 33.7666 53.5068 35.2643 50.7237C37.1185 47.2796 34.4154 44.1816 31.2346 42.6504C30.1775 42.1411 29.2436 43.6391 30.3034 44.1493C32.5862 45.2488 34.9072 47.235 33.6706 49.8473C32.722 51.8514 30.3356 52.9493 28.0899 52.9098C25.5171 52.8652 21.7922 50.2406 22.5986 47.5914C23.4055 44.9406 27.6477 42.8657 30.3034 44.1493C31.36 44.6601 32.294 43.1621 31.2346 42.6504Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M31.1886 0.0688274C26.0364 2.06934 26.677 7.72729 27.8138 11.9883C28.7673 15.5632 36.4744 25.834 30.0262 26.7842C28.8529 26.9575 29.3529 28.6304 30.5164 28.4586C36.2716 27.6104 34.4653 22.1237 32.791 18.5268C30.9187 14.5047 24.9253 4.36524 31.6793 1.74319C32.7637 1.32216 32.2888 -0.358352 31.1886 0.0688274Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M39.6602 0.0688274C34.508 2.06883 35.148 7.72678 36.2848 11.9883C37.2378 15.5627 44.9449 25.834 38.4972 26.7842C37.324 26.9575 37.8239 28.6304 38.9874 28.4586C44.7426 27.6104 42.9358 22.1232 41.2614 18.5268C39.3897 14.5047 33.3957 4.36524 40.1503 1.74319C41.2347 1.32216 40.7604 -0.358352 39.6602 0.0688274Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M48.6294 0.0688274C43.4772 2.06883 44.1173 7.72678 45.254 11.9883C46.2071 15.5627 53.9146 25.834 47.4659 26.7842C46.2932 26.9575 46.7932 28.6304 47.9572 28.4586C53.7118 27.6104 51.905 22.1232 50.2307 18.5268C48.359 14.5047 42.365 4.36524 49.1196 1.74319C50.204 1.32216 49.7296 -0.358352 48.6294 0.0688274Z" fill="#1A1919" />
            </G>
            <Defs>
                <ClipPath id="clip0_94_4323">
                    <Rect width="80" height="80" fill="white" />
                </ClipPath>
            </Defs>
        </Svg>

    )
}