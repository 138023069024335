import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
    addRemoveToCart: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: 113,
        height: 36,
        backgroundColor: '#FFF8EF',
        borderWidth: 1,
        borderColor: 'rgba(26, 48, 54, 0.13)',
    },
    img: {
        width: 80,
        height: 66,
        borderRadius: 10,
        marginRight: 5,
    }
})

export const addRemoveToCart = (background) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 75,
    height: 36,
    backgroundColor: background || '#FFF8EF',
    paddingVertical: 4,
    paddingHorizontal: 4,
    borderRadius: 10,
})
