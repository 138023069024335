import { Svg, Path } from 'react-native-svg';

export const LeftCalendarArrow = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M15 18L9 12L15 6" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    )
}