import React from "react";
import { View, Text, ScrollView, Image, ImageBackground } from "react-native";
//Styles
import {
  styles,
  paragraph,
  sectionContainerWeb,
} from "../../../../styles/pages/generalStyles";

export const Opinions = ({ navigation, route }) => {
  return (
    <View style={[styles.screen, { alignItems: "center" }]}>
      <ScrollView
        style={[styles.scrollScreen, { paddingHorizontal: 0, width: "100%" }]}
      >
        <ImageBackground
          source={require("../../../../assets/illustrations/opiniones-banner.png")}
          style={{
            width: "100%",
            height: 300,
          }}
          resizeMode="cover"
        />
        <View style={[sectionContainerWeb(), styles.homeContainer]}></View>
      </ScrollView>
    </View>
  );
};
