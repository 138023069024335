import { useContext, useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  Image,
  FlatList,
} from "react-native";
//Styles
import {
  styles,
  title,
  textTransform,
  webContainer,
  paragraph,
  sectionContainerWeb,
} from "../../../../styles/pages/generalStyles";
import { homeStyles } from "../../../../styles/pages/home/homePage";
import { flatList } from "../../../../styles/pages/signup/signup";
//componentes
import { DropdownOption } from "../../../widgets/dropdownOption";
import { DropdownOptionRenderArray } from "../../../widgets/dropdownOptionRenderArray";
import { LoadingPage } from "../../../widgets/loadingPage";
// contexts
import { orderContext } from "../../../../context/orderingContext";
//controllers
import { getPlate } from "../../../controllers/menus";
//hooks
import { useGarrinsons } from "../../../hooks/useGarrisons";
//utils
import { edit_dish_details_single } from "../../../utils/garrisons";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";

const plateImage = require("../../../../assets/illustrations/toast.jpg");

const Nutrition = [
  {
    percentage: "1 g",
    name: "Grasas",
  },
  {
    percentage: "1 g",
    name: "Proteínas",
  },
  {
    percentage: "1 g",
    name: "G. Saturadas",
  },
  {
    percentage: "1 g",
    name: "Azúcar",
  },
  {
    percentage: "1 g",
    name: "Carbos",
  },
  {
    percentage: "1 kcal",
    name: "Calorías",
  },
];

const { width } = Dimensions.get("window");
export const DetailsMenu = ({
  navigation,
  route,
  bottomTabNavigation,
  fromShoppingCart,
}) => {
  const { selectedGarrison } = useContext(orderContext);
  const { garrinsons } = useGarrinsons();
  const [plate, setPlate] = useState({});
  const [nutritionTable, setNutritionTable] = useState(Nutrition);
  const [loding, setLoading] = useState(true);
  const { isComputer } = useIsWeb();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  useEffect(() => {
    handleGetPlateDetails();
    return () => {
      setNutritionTable([]);
    };
  }, []);

  useEffect(() => {
    Nutrition.map((item, i) => {
      if (item.name == "Grasas") {
        Nutrition[i].percentage = `${plate.fats} g`;
        // nutritionTable.push(item)
      } else if (item.name == "Proteínas") {
        Nutrition[i].percentage = `${plate.proteins} g`;
        // nutritionTable.push(item)
      } else if (item.name == "G. Saturadas") {
        Nutrition[i].percentage = `${plate.saturated_fats} g`;
        // nutritionTable.push(item)
      } else if (item.name == "Azúcar") {
        Nutrition[i].percentage = `${plate.sugars} g`;
        // nutritionTable.push(item)
      } else if (item.name == "Carbos") {
        Nutrition[i].percentage = `${plate.cho} g`;
        // nutritionTable.push(item)
      } else if (item.name == "Calorías") {
        Nutrition[i].percentage = `${plate.calories} kcal`;
        // nutritionTable.push(item)
      }
    });
    setNutritionTable([...nutritionTable]);
  }, [plate]);

  useEffect(() => {
    if (
      garrinsons["Acompañamiento 1"]?.length > 0 ||
      garrinsons["Acompañamiento 2"]?.length > 0
    ) {
      edit_dish_details_single(
        garrinsons,
        selectedGarrison,
        route?.params?.id,
        plate,
        setPlate
      );
    }
  }, [garrinsons]);

  const handleGetPlateDetails = async () => {
    setLoading(true);
    await getPlate(route?.params?.id)
      .then((res) => {
        setPlate({ ...res });
        setLoading(false);
      })
      .catch((err) => {
        // console.log('err ', err)
        setLoading(false);
      });
  };

  if (loding) return <LoadingPage />;

  return (
    <ScrollView style={[styles.screen]} horizontal={false}>
      <View
        style={
          isComputer ? [
            styles.container,
             webContainer("80vh", 30),
            sectionContainerWeb(1200, null, "100%"),
          ] : {}
        }
      >
        <View style={isComputer && [styles.rowContainer, { marginBottom: 20 }]}>
          <View
            style={
              isComputer && {
                width: "40%",
                height: "100%",
                borderRadius: 16,
                overflow: "hidden",
              }
            }
          >
            <Image
              style={{
                width: "100%",
                height: isComputer ? "100%" : 196,
              }}
              source={plate.image ? { uri: plate.image } : plateImage}
            />
          </View>
          <View
            style={isComputer ? { width: "58%" } : { paddingHorizontal: 20 }}
          >
            <Text
              style={[
                title("100%", "left"),
                textTransform("capitalize"),
                { paddingVertical: 25 },
              ]}
            >
              {plate.recipe || "Title"}
            </Text>
            <Text
              style={[paragraph("100%", 15, 5, "right"), { marginBottom: 5 }]}
            >
              * Información incluye los acompañamientos
            </Text>
            <ScrollView
              showsHorizontalScrollIndicator={false}
              horizontal={true}
              contentContainerStyle={isComputer && { width: "100%" }}
            >
              <FlatList
                bounces={false}
                style={{
                  width: isComputer ? "100%" : width - 40,
                  marginTop: 10,
                }}
                data={plate.tags}
                numColumns={2}
                renderItem={({ item, index }) => {
                  return (
                    <View key={`${index}-details1`} style={flatList()}>
                      <Text style={{ textTransform: "capitalize" }}>
                        {item.name}
                      </Text>
                    </View>
                  );
                }}
              />
            </ScrollView>
            <View style={[styles.line, { marginVertical: 20 }]}></View>
            <ScrollView
              showsHorizontalScrollIndicator={false}
              horizontal={true}
              contentContainerStyle={isComputer && { width: "100%" }}
            >
              {
                <FlatList
                  bounces={false}
                  style={{ width: isComputer ? "100%" : width - 40 }}
                  data={nutritionTable}
                  numColumns={3}
                  renderItem={({ item, index }) => {
                    return (
                      <View
                        key={`${index}-details2`}
                        style={{
                          flexDirection: "column",
                          width: "33%",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingBottom: 20,
                        }}
                      >
                        <Text style={[homeStyles.strongText]}>
                          {item.percentage}
                        </Text>
                        <Text>{item.name}</Text>
                      </View>
                    );
                  }}
                />
              }
            </ScrollView>
          </View>
        </View>
        <View style={!isComputer && styles.container}>
          {plate?.alergenos?.length > 0 ? (
            <DropdownOptionRenderArray
              array={plate.alergenos}
              title={"Alergenos"}
              uniqueKey={`tags-details3`}
            />
          ) : null}
          {plate?.content ? (
            <DropdownOption
              text={plate.content}
              title={"Contenido"}
              uniqueKey={`content-details3`}
            />
          ) : null}
          {plate?.curiosities ? (
            <DropdownOption
              text={plate.curiosities}
              title={"Curiosidades"}
              uniqueKey={`curiosities-details3`}
            />
          ) : null}
          <View style={[styles.line, { marginVertical: 8 }]}></View>
        </View>
      </View>
    </ScrollView>
  );
};
