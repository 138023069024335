export const getItemType = (item) => {
  return item?.plates || item?.combo_id ? "combos" : "plates_supermaker";
};

export const getItemQuantity = (itemId, type, getProduct = () => {}) => {
  const item = getProduct(type, itemId);
  return item ? item.quantity : 0;
};

export const mergeArraysWithUniqueElements = (arr1 = [], arr2 = []) => {
  const mergedArray = [...arr1, ...arr2];
  return mergedArray.filter((item, index, self) => {
    const isDuplicate =
      self.findIndex((obj) => JSON.stringify(obj) === JSON.stringify(item)) !==
      index;
    return !isDuplicate;
  });
};
