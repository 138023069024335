import { Svg, Path } from 'react-native-svg';

export const Schedule = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M26.0022 17.1348H27.7951C28.2696 17.133 28.7262 17.3105 29.0682 17.6296C29.2334 17.7853 29.3649 17.9715 29.455 18.1772C29.545 18.3828 29.5918 18.6039 29.5927 18.8273V30.6704C29.5916 30.8938 29.5446 31.1147 29.4546 31.3204C29.3645 31.526 29.2332 31.7122 29.0682 31.8681C28.7251 32.1855 28.2692 32.3627 27.7951 32.3629H17.0427C16.5686 32.3627 16.1127 32.1855 15.7696 31.8681C15.6046 31.7122 15.4733 31.526 15.3832 31.3204C15.2932 31.1147 15.2462 30.8938 15.2451 30.6704V18.8273C15.2459 18.6039 15.2928 18.3828 15.3828 18.1772C15.4729 17.9715 15.6044 17.7853 15.7696 17.6296C16.1116 17.3105 16.5682 17.133 17.0427 17.1348H18.8499" fill="#DB7500" />
            <Path d="M24.9598 13.6562H19.6155C19.1426 13.6562 18.7217 13.9194 18.7217 14.2447V15.4168C18.7217 15.7422 19.1237 16.0053 19.6155 16.0053H24.9598C25.4327 16.0053 25.8489 15.7422 25.8489 15.4168V14.2447C25.8489 13.9194 25.4517 13.6562 24.9598 13.6562Z" fill="#DB7500" />
            <Path d="M22.5 44.5C34.3741 44.5 44 34.8741 44 23C44 11.1259 34.3741 1.5 22.5 1.5C10.6259 1.5 1 11.1259 1 23C1 34.8741 10.6259 44.5 22.5 44.5Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    )
}