import { Svg, Path } from 'react-native-svg';

export const MyDetails = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 126 124" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M62.7906 122.636C96.7224 122.636 124.23 95.4548 124.23 61.9256C124.23 28.3963 96.7224 1.21552 62.7906 1.21552C28.8588 1.21552 1.35156 28.3963 1.35156 61.9256C1.35156 95.4548 28.8588 122.636 62.7906 122.636Z" stroke="#DB7500" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M88.6989 89.1059V82.635C88.6954 79.2037 87.3301 75.914 84.9028 73.4878C82.4755 71.0615 79.1844 69.6968 75.7516 69.6933H49.8434C48.1357 69.6897 46.4441 70.0235 44.8658 70.6755C43.2876 71.3275 41.8538 72.2848 40.6469 73.4925C39.4399 74.7002 38.4837 76.1343 37.833 77.7126C37.1824 79.2908 36.8502 80.9821 36.8555 82.689V89.1059" fill="#DB7500" />
            <Path d="M62.7906 60.6017C65.3546 60.6044 67.8618 59.8468 69.9949 58.4249C72.1281 57.003 73.7914 54.9806 74.7745 52.6136C75.7575 50.2465 76.0161 47.6412 75.5176 45.1273C75.019 42.6133 73.7857 40.3036 71.9736 38.4905C70.1615 36.6773 67.8522 35.4421 65.3376 34.9411C62.8231 34.4401 60.2164 34.6959 57.8473 35.676C55.4783 36.6562 53.4533 38.3167 52.0285 40.4475C50.6037 42.5783 49.8433 45.0835 49.8433 47.6464C49.8433 51.08 51.2069 54.3732 53.6346 56.8024C56.0623 59.2316 59.3555 60.5981 62.7906 60.6017Z" fill="#DB7500" />
        </Svg>
    )
}