import { createContext } from 'react'


export const shoppingCart = createContext({
    //supermarket
    combos: [],
    setCombos: () => [],
    plates: [],
    setPlates: () => [],
    cart: {
        shopping_cart: null,
        combos: [],
        plates_supermaker: [],
        facture: false,
        dni: undefined,
        armed_packages: [],
        nutritional_consultation: [],
        type_method_pay: undefined,
        method_payment: undefined,
        note: undefined,
        address: undefined,
        total: 0,
        total_price: 0,

        orderMade: false,
        showDetails: false
    },
    setCart: () => { },
    editAddress: false,
    setEditAddress: () => { },
    selections: 0,
    setSelections: () => { },
    total: 0,
    setTotal: () => { },
})
