import { Svg, Path } from 'react-native-svg';


export const Users = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 48 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M34 43.375V39.2917C34 37.1257 33.1571 35.0485 31.6569 33.517C30.1566 31.9854 28.1217 31.125 26 31.125H10C7.87827 31.125 5.84344 31.9854 4.34315 33.517C2.84285 35.0485 2 37.1257 2 39.2917V43.375" stroke="#DB7500" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M18 22.9583C22.4183 22.9583 26 19.302 26 14.7917C26 10.2813 22.4183 6.625 18 6.625C13.5817 6.625 10 10.2813 10 14.7917C10 19.302 13.5817 22.9583 18 22.9583Z" stroke="#DB7500" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M46 43.3752V39.2919C45.9987 37.4824 45.4087 35.7246 44.3227 34.2945C43.2368 32.8644 41.7163 31.843 40 31.3906" stroke="#DB7500" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M32 6.89062C33.7208 7.34041 35.2461 8.36206 36.3353 9.79451C37.4245 11.227 38.0157 12.9887 38.0157 14.8021C38.0157 16.6154 37.4245 18.3772 36.3353 19.8097C35.2461 21.2421 33.7208 22.2638 32 22.7135" stroke="#DB7500" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    )
}