import { useEffect, useContext } from "react";
import { createStackNavigator } from "@react-navigation/stack";
//styles
import {
  styles,
  titleStyle,
  headerComponentStyle,
  headerTitleContainer,
} from "../../styles/pages/navigators/headers";
//screens
import { OrderingOptions } from "../screen/orderDefinition/orderingOptions";
import { MealTimes } from "../screen/orderDefinition/mealTimes";
import { NumberOfMeals } from "../screen/orderDefinition/numberOfMeals";
import { Plan } from "../screen/orderDefinition/planList/planList";
import { EditPlan } from "../screen/orderDefinition/planList/editPlan";
import { DetailsMenu } from "../screen/orderDefinition/menu/details";
import { FoodMenu } from "../screen/orderDefinition/foodMenu";
import { NutritionalPrescription } from "../screen/plans/nutritionalPrescription";
import { NutritionalPlans } from "../screen/orderDefinition/planList/nutritionalPlans";
import { AccordingBaranaPlans } from "../screen/orderDefinition/planList/accordingBaranaPlans";
//components
import { StepProgress } from "../widgets/stepProgress";
import { GoBack } from "../widgets/goBack";
//context
import { orderContext } from "../../context/orderingContext";
import { mainContext } from "../../context/mainContext";
//hooks
import useIsWeb from "../../hooks/useIsWeb";
import useUserData from "../../hooks/useUserData";

export const OrderDefinitionNavigator = ({ route, bottomTabNavigation }) => {
  const Stack = createStackNavigator();
  const { isNewUser } = useUserData();
  const { currentStep, totalSteps } = useContext(orderContext);
  const { isComputer } = useIsWeb();

  return (
    <Stack.Navigator initialRouteName={"orderType"}>
      <Stack.Screen
        name={"orderType"}
        options={({ navigation }) => ({
          headerTitle: "Opciones de Compra",
          headerLeft: () => (
            <GoBack
              navigator={navigation}
              icon={"arrowBack"}
              width={48}
              alternative={isNewUser ? "homePage" : null}
            />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <OrderingOptions
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"mealTimes"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitle: () => (
            <StepProgress step={currentStep} totalSteps={totalSteps} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
          headerTitleContainerStyle: isComputer && headerTitleContainer(60),
        })}
      >
        {({ navigation }) => (
          <MealTimes
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"numberOfMeals"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitle: () => <StepProgress step={2} totalSteps={totalSteps} />,
          headerStyle: headerComponentStyle.containerHeight,
          headerTitleContainerStyle: isComputer && headerTitleContainer(60),
        })}
      >
        {({ navigation, route }) => (
          <NumberOfMeals
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"foodMenu"}
        options={({ navigation, route }) => ({
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitle: () => (
            <StepProgress step={currentStep} totalSteps={totalSteps} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
          headerTitleContainerStyle: isComputer && headerTitleContainer(60),
        })}
      >
        {({ navigation, route }) => (
          <FoodMenu
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"plans"}
        options={({ navigation }) => ({
          headerTitle: "Tipo de Plan",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Plan
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"accordingToBarana"}
        options={({ navigation }) => ({
          headerTitle: "Mis Planes Según Barana",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <AccordingBaranaPlans
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"nutritionalPrescription"}
        options={({ navigation, route }) => ({
          headerTitle: "Prescripción Nutricional",
          headerLeft: () => (
            <GoBack
              navigator={navigation}
              icon={"arrowBack"}
              width={48}
              alternative={null}
            />
          ),
          headerTitleStyle: [titleStyle(), { marginLeft: "5%" }],
          headerTitleContainerStyle: headerComponentStyle.titleContainer,
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <NutritionalPrescription
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"nutritionalPlan"}
        options={({ navigation }) => ({
          headerTitle: "Plan Nutricional",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <NutritionalPlans
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"editPlan"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"leftArrow"} text={"Volver"} />
          ),
          headerStyle: styles.headerWithBorder,
        })}
      >
        {({ navigation, route }) => (
          <EditPlan
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"detailsMenu"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"leftArrow"} text={"Volver"} />
          ),
          headerStyle: styles.headerWithBorder,
        })}
      >
        {({ navigation, route }) => (
          <DetailsMenu
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
};
