import client from './httpClient'
import { addTrailingSlashOnIos } from "../utils/utils";
//--------------------------------------------------
//--------------PAYMENT METHODS REQUESTS-----------------------
//--------------------------------------------------

export const getPaymentMethods = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(addTrailingSlashOnIos(`/payment-method/${id}`))
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const updatePaymentMethods = async (token, id, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .patch(`/payment-method/${id}/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const createPaymentMethods = async (token, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .post(`/payment-method/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const deletePaymentMethods = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .delete(`/payment-method/${id}/`)
    .then((value) => {
      return value;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const payNotIban = async (token, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .post(`/procces-pay/pay_not_iban/`, data)
    .then((value) => {
      return true;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const payIban = async (token, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .post(`/procces-pay/ridiv_pay/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

