import { useEffect, useState, useContext } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/core";
//icons
import { BaranaIcons } from "../../../../../assets/icons/BaranaIcons";
//styles
import {
  styles,
  actionText,
  orangeBox,
  orangeCardBody,
} from "../../../../../styles/pages/generalStyles";
import { centerAlignedRow } from "../../../../../styles/pages/home/homePage";
import { styles as navStyles } from "../../../../../styles/pages/widgets/navBar";
//components
import { GeneralButton } from "../../../../widgets/generalButton";
import { OutlinedButton } from "../../../../widgets/outlinedButton";
//utils
import { getPlanName } from "../../../../utils/plans";
import { capitalize } from "../../../../utils/utils";
//context
import { subscription } from "../../../../../context/subscription";
import { orderContext } from "../../../../../context/orderingContext";

const packageType = {
  desayunos: "Desayunos",
  almuerzos: "Almuerzos",
  cenas: "Cenas",
  "adulto mayor": "Adulto mayor",
  familiar: "Familiar",
};

const planType = {
  estandar: "Estándar",
  estándar: "Estándar",
  "bajo en harina": "Bajo en harinas",
  "bajo en harinas": "Bajo en harinas",
};

export const CardComponent = ({
  icon,
  action_rollDown,
  action_rollOut,
  text,
  priceOrDescription,
  typeButton,
  textButton,
  onPress,
  widthButton,
  height,
  footer,
  actionFooter,
  rightSection,
  item,
  packagesOnLeft,
}) => {
  const Navigation = useNavigation();
  const [actionEnable, setActionEnable] = useState(false);
  const [paqueteId, setPaqueteId] = useState();
  const [detailedInformation, setDetailedInformation] = useState("");
  const {
    baranaPlanList,
    setTotalSteps,
    setCurrentStep,
    setCreateSubscriptionFromHome,
    setCreateNewSubscription,
  } = useContext(orderContext);
  const { createSubscription, setCreateSubscription } =
    useContext(subscription);

  useEffect(() => {
    setDetailedInformation("");

    item?.shopping_cart?.armed_packages &&
    item?.shopping_cart?.armed_packages.length > 0
      ? setPaqueteId(item?.shopping_cart?.armed_packages[0][0]?.armed_package)
      : null;
    sortPackage();
    return () => {
      setDetailedInformation("");
    };
  }, [item]);

  const sortPackage = () => {
    let getPackage = "";
    const packages = item?.shopping_cart?.armed_packages;
    packages?.map((armedPackage, i) => {
      armedPackage.sub_packages?.map((paq) => {
        if (getPackage === "") {
          getPackage = `${capitalize(paq.title)}`;
          setDetailedInformation(getPackage);
        } else {
          getPackage = `${getPackage}\n${capitalize(paq.title)}`;
        }
        //   const PLAN = await getPlanName(baranaPlanList, paq?.plan);
        //   if (!getPackage || getPackage == "") {
        //     getPackage = `${paq.title}\nTipo de plan: ${
        //       planType[PLAN] || PLAN
        //     }`;
        //     setDetailedInformation(getPackage);
        //   } else {
        //     getPackage = `${getPackage}\n${
        //       packageType[paq.time_food.name] || paq.time_food.name
        //     } - ${paq.cant_plates} días\nTipo de plan: ${
        //       planType[PLAN] || PLAN
        //     }`;
        //   }
      });
    });
    setDetailedInformation(getPackage);
  };

  return (
    <View
      style={orangeBox((actionEnable && 250) || (!action_rollDown && height))}
    >
      <View>
        <BaranaIcons draw={{ width: 65, height: 65 }} name={icon} />
      </View>
      <View style={orangeCardBody((icon || rightSection) && "75%")}>
        <View>
          {/* {item?.shopping_cart?.armed_packages?.map((aP, i) => {
                        return (
                            <View key={`orders${i}${i}`}>
                                {aP?.map(paq => {
                                    return (
                                        <View key={`order${i}`} style={{}}>
                                            <Text
                                                ellipsizeMode='tail'
                                                numberOfLines={2}
                                                style={[{ textAlign: packagesOnLeft ? 'left' : 'center' }]}
                                            >
                                                <Text style={[styles.timeText, { marginBottom: 2, textAlign: packagesOnLeft ? 'left' : 'center' }]}>
                                                    {`${paq.time_food.name} - ${paq.cant_plates} días\n`}
                                                </Text>

                                                <Text
                                                    style={[styles.helpText, { textAlign: packagesOnLeft ? 'left' : 'center' }]}>
                                                    Tipo de plan: <Text style={{}}>
                                                        {getPlanName(baranaPlanList, paq?.plan)}
                                                    </Text>
                                                </Text>
                                            </Text>
                                        </View>
                                    )
                                })}
                            </View>
                        )
                    })
                    } */}

          <Text
            ellipsizeMode="tail"
            numberOfLines={3}
            style={[
              { textAlign: packagesOnLeft ? "left" : "center", fontSize: 16 },
            ]}
          >
            {detailedInformation}
          </Text>
          {actionEnable || (!action_rollDown && text) ? (
            <>
              <Text style={[styles.generalText, { marginTop: 6 }]}>{text}</Text>
              <Text
                style={[
                  styles.generalText,
                  { marginTop: 6, marginBottom: !action_rollDown ? 10 : 0 },
                ]}
              >
                {priceOrDescription}
              </Text>
            </>
          ) : null}
          {action_rollDown ? (
            <View style={centerAlignedRow(6)}>
              <Text
                onPress={() => setActionEnable(!actionEnable)}
                style={[actionText("#DB7500")]}
              >
                {!actionEnable ? action_rollDown : action_rollOut}
              </Text>
              <BaranaIcons
                draw={{ width: 20, height: 20 }}
                name={!actionEnable ? "rollDown" : "rollOut"}
              />
            </View>
          ) : null}
        </View>
        {typeButton == "general" ? (
          <GeneralButton
            text={textButton}
            handleOnPress={() => {
              onPress ? onPress(item?.id) : Navigation.navigate("Signup");
            }}
            width={widthButton}
          />
        ) : (
          <OutlinedButton
            text={textButton}
            handleOnPress={() => onPress(item?.id)}
            width={"100%"}
          />
        )}
        {footer ? (
          <TouchableOpacity onPress={() => actionFooter()}>
            <Text style={[navStyles.text]}>{footer}</Text>
          </TouchableOpacity>
        ) : null}
      </View>
      {rightSection ? (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Text style={[styles.helpText, { marginBottom: 50 }]}>
            ₡ {rightSection}
          </Text>
          <TouchableOpacity
            onPress={() => {
              setCurrentStep(1);
              setTotalSteps(2);
              setCreateNewSubscription(true);
              setCreateSubscriptionFromHome(true);
              setCreateSubscription({
                ...createSubscription,
                armed_packages: paqueteId,
              });
              Navigation.navigate("subscription");
            }}
          >
            <Text style={[navStyles.text]}>Suscribirse</Text>
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  );
};
