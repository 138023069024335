import AsyncStorage from "@react-native-async-storage/async-storage";
import { create } from "zustand";
import {
  getShoppingCart,
  createShoppingCart,
  deleteShoppingCart,
  updateShoppingCart,
  duplicateCartPackages,
} from "../../controllers/shoppingCart";
import { devtools } from "zustand/middleware";
import { mergeArraysWithUniqueElements } from "../../utils/shoppingCartUtils";

const useCartStore = create(
  devtools((set, get) => ({
    cart: {
      combos: [],
      plates_supermaker: [],
      armed_packages: [],
      nutritional_consultation: [],
      total_price: 0,
      facture: false,
    },
    setCart: (cart) => {
      set((state) => ({
        cart: { ...cart },
      }));
    },
    addToCart: (type, product) => {
      const idKey = `${type === "combos" ? "combo" : "plate"}_id`;
      const cartItemIndex = get().cart[type]?.findIndex(
        (item) => item[idKey] === product.id || item[idKey] === product[idKey]
      );
      if (cartItemIndex !== -1) {
        // if the item already exists in the cart, increase its quantity by 1
        const updatedCartItem = {
          ...get().cart[type][cartItemIndex],
          quantity: get().cart[type][cartItemIndex].quantity + 1,
        };
        set((state) => ({
          cart: {
            ...state.cart,
            [type]: [
              ...state.cart[type].slice(0, cartItemIndex),
              updatedCartItem,
              ...state.cart[type].slice(cartItemIndex + 1),
            ],
          },
        }));
      } else {
        // if the item does not exist in the cart, add it with quantity 1
        const newProduct = {
          [idKey]: product.id,
          quantity: 1,
          recipe: product.recipe,
          price: product.price,
        };
        set((state) => ({
          cart: {
            ...state.cart,
            [type]: [...state.cart[type], newProduct],
          },
        }));
      }
      get().calculateTotalPrice();
      get().saveToLocalStorage();
    },
    removeFromCart: (type, productId) => {
      const idKey = `${type === "combos" ? "combo" : "plate"}_id`;
      const cartItemIndex = get().cart[type]?.findIndex(
        (item) => item[idKey] === productId
      );
      if (cartItemIndex !== -1) {
        const updatedCartItem = {
          ...get().cart[type][cartItemIndex],
          quantity: get().cart[type][cartItemIndex].quantity - 1,
        };
        set((state) => ({
          cart: {
            ...state.cart,
            [type]: [
              ...state.cart[type].slice(0, cartItemIndex),
              updatedCartItem,
              ...state.cart[type].slice(cartItemIndex + 1),
            ],
          },
        }));
        // }
        get().calculateTotalPrice();
        get().saveToLocalStorage();
      }
    },
    addNutricionalConsultationToCart: (consult) => {
      set((state) => ({
        cart: {
          ...state.cart,
          nutritional_consultation: [
            ...(Array.isArray(state.cart["nutritional_consultation"])
              ? state.cart["nutritional_consultation"]
              : []),
            consult,
          ],
        },
      }));
      get().calculateTotalPrice();
      get().saveToLocalStorage();
    },
    removeNutricionalConsultationToCart: (consultId) => {
      const cartConsultIndex = get().cart[
        "nutritional_consultation"
      ]?.findIndex((consult) => consult.id === consultId);
      if (cartConsultIndex !== -1) {
        set((state) => ({
          cart: {
            ...state.cart,
            nutritional_consultation: [
              ...state.cart["nutritional_consultation"].slice(
                0,
                cartConsultIndex
              ),
              ...state.cart["nutritional_consultation"].slice(
                cartConsultIndex + 1
              ),
            ],
          },
        }));
        get().calculateTotalPrice();
        get().saveToLocalStorage();
      }
    },
    addArmedPackageToCart: (packages) => {
      set((state) => ({
        cart: {
          ...state.cart,
          armed_packages: [...state.cart.armed_packages, ...packages],
        },
      }));
      get().saveToLocalStorage();
    },
    addRemovePackageToCart: (sum, armedPackageId, subpackageId) => {
      const { armed_packages } = get().cart;
      const armedPackageIndex = armed_packages.findIndex(
        (ap) => ap.armed_package === armedPackageId
      );

      if (armedPackageIndex != -1) {
        const subpackageIndex = armed_packages[
          armedPackageIndex
        ]?.sub_packages.findIndex((sp) => sp.sub_package_id === subpackageId);

        if (subpackageIndex != -1) {
          // Subpaquete ya existe en el carrito, aumentar la cantidad en 1

          const subpackage =
            armed_packages[armedPackageIndex]?.sub_packages[subpackageIndex];

          const updatedSubpackage = {
            ...subpackage,
            copy: {
              ...subpackage.copy,
              cant: sum ? subpackage.copy.cant + 1 : subpackage.copy.cant - 1,
            },
          };

          const updatedPackage = {
            ...armed_packages[armedPackageIndex],
            sub_packages: [
              ...armed_packages[armedPackageIndex].sub_packages.slice(
                0,
                subpackageIndex
              ),
              updatedSubpackage,
              ...armed_packages[armedPackageIndex].sub_packages.slice(
                subpackageIndex + 1
              ),
            ],
          };

          set((state) => ({
            cart: {
              ...state.cart,
              armed_packages: [
                ...state.cart.armed_packages.slice(0, armedPackageIndex),
                updatedPackage,
                ...state.cart.armed_packages.slice(armedPackageIndex + 1),
              ],
            },
          }));
        }
      }

      get().calculateTotalPrice();
      get().saveToLocalStorage();
    },
    clearCart: async () => {
      await AsyncStorage.removeItem("shopping_cart");
      set({
        cart: {
          combos: [],
          plates_supermaker: [],
          armed_packages: [],
          nutritional_consultation: [],
          total_price: 0,
          facture: false,
        },
      });
    },
    fetchCartFromApi: async (token) => {
      try {
        const data = await getShoppingCart(token);
        set({ cart: data });
      } catch (error) {
        console.error(error);
      }
    },
    loadCartFromBackendOrLocalStorage: async (token) => {
      try {
        const stringData = await AsyncStorage.getItem("shopping_cart");
        const storedCart = JSON.parse(stringData);

        if (token) {
          const cartFromApi = await getShoppingCart(token);
          if (cartFromApi) {
            if (storedCart) {
              const mergedCart = {
                ...storedCart,
                ...cartFromApi,
                combos: mergeArraysWithUniqueElements(
                  storedCart.combos,
                  cartFromApi.combos
                ),
                plates_supermaker: mergeArraysWithUniqueElements(
                  storedCart.plates_supermaker,
                  cartFromApi.plates_supermaker
                ),
              };
              set({ cart: mergedCart });
              await AsyncStorage.setItem(
                "shopping_cart",
                JSON.stringify(mergedCart)
              );
            } else {
              set({ cart: cartFromApi });
              await AsyncStorage.setItem(
                "shopping_cart",
                JSON.stringify(cartFromApi)
              );
            }
          } else if (storedCart) {
            set({ cart: storedCart });
          }
        } else if (storedCart) {
          set({ cart: storedCart });
        }
      } catch (error) {
        console.error(error);
      }
    },
    saveToLocalStorage: async () => {
      try {
        await AsyncStorage.setItem("shopping_cart", JSON.stringify(get().cart));
      } catch (error) {
        console.error(error);
      }
    },
    loadFromLocalStorage: async () => {
      const stringData = await AsyncStorage.getItem("shopping_cart");
      const storedCart = JSON.parse(stringData);
      if (storedCart) {
        set({ cart: storedCart });
      }
    },
    deleteFromLocalStorage: async () => {
      try {
        await AsyncStorage.removeItem("shopping_cart");
      } catch (error) {
        console.error(error);
      }
    },
    getCart: async (token) => {
      try {
        const cart = await getShoppingCart(token);
        if (cart.shopping_cart) {
          set({ cart });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    createCart: async (data, token) => {
      try {
        const cart = await createShoppingCart(data, token);
        if (cart.shopping_cart) {
          set({ cart });
          get().saveToLocalStorage("shopping_cart", cart);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    deleteCart: async (token, id) => {
      try {
        const response = await deleteShoppingCart(token, id);
        set({
          cart: {
            combos: [],
            plates_supermaker: [],
            armed_packages: [],
            nutritional_consultation: [],
            total_price: 0,
            facture: false,
          },
        });
        get().deleteFromLocalStorage("shopping_cart");
        return response.success;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    updateCart: async (token, id, data) => {
      try {
        const cart = await updateShoppingCart(token, id, data);
        if (cart.shopping_cart) {
          set({ cart });
          get().saveToLocalStorage("shopping_cart", cart);
          return true;
        } else if (cart) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    createOrUpdateCart: async (token) => {
      try {
        if (token) {
          const dataCart = get().cart;
          const nutritionalConsultation = dataCart.nutritional_consultation;
          const armedPackages = dataCart.armed_packages;
          const data = {
            ...dataCart,
            nutritional_consultation: nutritionalConsultation?.map(
              (consult) => consult.id
            ),
            armed_packages: armedPackages?.map((ap) => ap.armed_package),
          };

          if (dataCart?.shopping_cart) {
            return await get().updateCart(
              token,
              get().cart.shopping_cart,
              data
            );
          } else {
            return await get().createCart(data, token);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    duplicatePackageToCart: async (
      token,
      armedPackageId,
      subpackageId,
      cantPackage
    ) => {
      try {
        const cart = get().cart;
        const updatedCart = await duplicateCartPackages(token, {
          shopping_cart: cart.shopping_cart,
          armed_package: armedPackageId,
          sub_package_id: subpackageId,
          sum_package: cantPackage,
        });
        if (updatedCart) {
          set({ cart: updatedCart });
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    getProductById: (type, productId) => {
      const idKey = `${type === "combos" ? "combo" : "plate"}_id`;
      return get().cart[type]?.find((item) => item[idKey] === productId);
    },
    calculateTotalPrice: () => {
      const {
        combos,
        plates_supermaker,
        armed_packages,
        nutritional_consultation,
      } = get().cart;
      const combosPrice = combos?.reduce(
        (acc, item) => acc + (item?.unit_price || item.price) * item.quantity,
        0
      );
      const platesPrice = plates_supermaker?.reduce(
        (acc, item) => acc + (item?.unit_price || item.price) * item.quantity,
        0
      );
      const nutritionalConsultationPrice = nutritional_consultation?.reduce(
        (acc, item) => acc + item?.price,
        0
      );
      const packagesPrice = armed_packages?.reduce(
        (acc, ap) =>
          acc +
          ap?.sub_packages?.reduce(
            (acc, sp) => acc + sp?.price * sp?.copy?.cant,
            0
          ),
        0
      );
      const total_price =
        combosPrice +
        platesPrice +
        packagesPrice +
        nutritionalConsultationPrice;
      set((state) => ({ cart: { ...state.cart, total_price } }));
    },
  }))
);

export default useCartStore;
