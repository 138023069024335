import { Svg, Path } from 'react-native-svg';

export const ArrowsInCircle = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/Svg">
        <Path d="M17 1L21 5L17 9" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M3 11V9C3 7.93913 3.42143 6.92172 4.17157 6.17157C4.92172 5.42143 5.93913 5 7 5H21" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M7 23L3 19L7 15" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M21 13V15C21 16.0609 20.5786 17.0783 19.8284 17.8284C19.0783 18.5786 18.0609 19 17 19H3" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>

}