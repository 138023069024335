import { View, Text, Modal, TouchableOpacity } from 'react-native'
//icons
import { BaranaIcons } from '../../../../assets/icons/BaranaIcons'
//styles
import { spaceBetween, textAlignment, styles } from '../../../../styles/pages/generalStyles'
import { modalStyles, helpModal } from '../../../../styles/pages/widgets/modalMenu'
import { homeStyles, centerAlignedRow } from '../../../../styles/pages/home/homePage'
//utils
import { capitalize } from '../../../utils/utils'

export const HelpModal = ({ openModal, showModal, title, handleOnPress }) => {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={true}
        onRequestClose={() => openModal(false)}
        style={{ width: "87%", height: "277" }}
      >
        <TouchableOpacity
          style={modalStyles.container_options}
          onPress={() => openModal(false)}
        >
          <View style={helpModal()}>
            <View
              style={[
                spaceBetween(),
                { flexDirection: "row", marginBottom: 15 },
              ]}
            >
              {title && (
                <Text style={homeStyles.strongText}>{capitalize(title)}</Text>
              )}
            </View>
            <View style={centerAlignedRow(0, 0, "row", "flex-start")}>
              <BaranaIcons draw={{ width: 22, height: 22 }} name={"location"} />
              <Text
                style={[
                  homeStyles.helperText,
                  textAlignment("left", title && 6, "80%"),
                  { marginLeft: 15 },
                ]}
              >
                {`San José, Cantón Central.\nAvenida 24, calles 9 y 175.\nCircunvalación por el Parque de la Paz.\nDe la esquina sur-oeste de Plaza González Víquez, 100m sur y 70m oeste.`}
              </Text>
            </View>
            <View style={[styles.line, { marginVertical: 12 }]}></View>
            <View style={centerAlignedRow(0, 0, "row", "flex-start")}>
              <BaranaIcons draw={{ width: 22, height: 22 }} name={"calendar"} />
              <Text
                style={[
                  homeStyles.helperText,
                  textAlignment("left", title && 6, "80%"),
                  { marginLeft: 15 },
                ]}
              >
                {`Horario de recolección \nSábado: 12:00 p.m. a 4:30 p.m. \nLunes: 7:30 a.m. a 3:00 p.m.`}
              </Text>
            </View>
            <View style={[styles.line, { marginVertical: 12 }]}></View>
            <View style={centerAlignedRow(0, 0, "row", "flex-start")}>
              <BaranaIcons draw={{ width: 22, height: 22 }} name={"note"} />
              <Text
                style={[
                  homeStyles.helperText,
                  textAlignment("left", title && 6, "80%"),
                  { marginLeft: 15 },
                ]}
              >
                {`Para coordinar otro día u hora de recolección contáctenos al 8888-2406.`}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </Modal>
    );
}
