import { Provider as PaperProvider } from 'react-native-paper'
import { NavigationContainer } from '@react-navigation/native'
import { ModalMessage } from "../components/widgets/ModalMessage";
// contexts
import { mainContext } from "../context/mainContext";
import { orderContext } from "../context/orderingContext";
import { shoppingCart } from "../context/shoppingCart";
import { nutritionalConsulte } from "../context/nutritionalConsulte";
// styles
import { theme } from "../styles/theme";
//components
import { ToolBar } from "../components/navigation/bottomNavigator";
//paths
import { paths } from "./paths";
import { navigationRef } from "./navigationUtils";

const linking = {
  prefixes: ["barana://app"],
  config: {
    initialRouteName: "Home",
    screens: paths,
  },
};

export default function Setup({
  context,
  orderingContext,
  consulteContext,
  shoppingCartContext,
}) {
  return (
    <NavigationContainer ref={navigationRef} linking={linking}>
      <PaperProvider theme={theme(context.darkTheme)}>
        <mainContext.Provider value={context}>
          <nutritionalConsulte.Provider value={consulteContext}>
            <orderContext.Provider value={orderingContext}>
              <shoppingCart.Provider value={shoppingCartContext}>
                <ToolBar />
                <ModalMessage />
              </shoppingCart.Provider>
            </orderContext.Provider>
          </nutritionalConsulte.Provider>
        </mainContext.Provider>
      </PaperProvider>
    </NavigationContainer>
  );
}

