import { useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const useRefreshStorage = () => {
  const keys = [
    "cuttingDay",
    "plansBarana",
    "plates_supermaker",
    "packagesBarana",
    "combos",
  ];

  useEffect(() => {
    const checkTimeRefresh = async () => {
      try {
        const timeRefresh = await AsyncStorage.getItem("timerefresh");

        if (timeRefresh === null) {
          await AsyncStorage.multiRemove(keys);
          await AsyncStorage.setItem("timerefresh", JSON.stringify(Date.now()));
        } else {
          const currentTime = Date.now();
          const threeHoursInMilliseconds = 3 * 60 * 60 * 1000;

          if (currentTime - Number(timeRefresh) >= threeHoursInMilliseconds) {
            await AsyncStorage.multiRemove(keys);
            await AsyncStorage.setItem(
              "timerefresh",
              JSON.stringify(currentTime)
            );
          }
        }
      } catch (error) {
        console.error("Failed to refresh storage: ", error);
      }
    };

    checkTimeRefresh();
  }, []);
};

export default useRefreshStorage;
