import { createContext } from 'react'

export const subscription = createContext({
    createSubscription: {
        day_delivery: undefined,
        packageType: undefined,
        delivery_day: undefined,
        hours_range: undefined,
        note: undefined,
        address: undefined,
        detailedDirections: undefined,
        payment_method: undefined,
        price: undefined,
        suscription_id: undefined,
        subscription_id: undefined,
        armed_packages_id: undefined,
        armed_packages: {
            sub_packages: {

            }
        }
    },
    setCreateSubscription: () => { },
})