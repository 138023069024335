export const paths = {
  Home: {
    initialRouteName: "homePage",
    screens: {
      Signup: {
        initialRouteName: "registrationOptions",
        screens: {
          registrationOptions: "registrationOptions",
          signup: "signup",
          auth: {
            path: "auth_",
          },
        },
      },
      auth: {
        initialRouteName: "login",
        screens: {
          login: "login",
          forgotPassword: "forgotPassword",
          checkYourEmailMessage: "checkYourEmailMessage",
          verification: "verification",
          changePassword: "changePassword",
        },
      },
      subscription: {
        initialRouteName: "introduction",
        screens: {
          introduction: "introduction",
          suscriptionDetails: {
            path: "suscription_details/:subscription_id",
            parse: {
              subscription_id: (id) => id,
            },
          },
          createSubscriptionAddress: {
            path: "create_subscriptionAddress/:location/:edit",
            parse: {
              location: (address) => address,
              edit: (boolean) => boolean,
            },
          },
          editSubscriptionAddress: "edit_subscriptionAddress",
          mapScreen: {
            path: "MapScreen/"
          },
          editMenu: {
            path: "package_menu/:to/:timeFood",
            parse: {
              to: (editable_mealtime) => editable_mealtime,
              timeFood: (editable_mealtime) => editable_mealtime,
            },
          },
          editPackagePlan: {
            path: "editplan/:to/:planId",
            parse: {
              to: (editable_mealtime) => editable_mealtime,
              planId: (plan) => plan,
            },
          },
          IBAN: "IBAN_",
          createOnlinePayment: {
            path: "create_online_payment",
          },
          detailsMenu: {
            path: "dish_details/:id",
            parse: {
              id: (id) => id,
            },
          },
          editPlan: {
            path: "switch_plan/:item/:editable/:planId/:create",
            parse: {
              item: (plan) => plan,
              editable: (bolean) => bolean,
              planId: (id) => id,
              create: (bolean) => bolean,
            },
          },
          accordingToBarana: {
            path: "plan_according_to_barana/:to",
            parse: {
              to: (menu) => menu,
            },
          },
          nutritionalPrescription: {
            path: "nutritionalPrescriptionOrdening/:to/:planType",
            parse: {
              to: (menuType) => menuType,
              planType: (string) => string,
            },
          },
          subscriptionSummary: {
            path: "SubscriptionSummary/:id",
          },
          orderDefinition: {
            screens: {
              orderType: {
                path: "package_type",
              },
              mealTimes: {
                path: "select_mealtime",
              },
              numberOfMeals_breakfasts: {
                path: "number_of_breakfasts/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              numberOfMeals_lunches: {
                path: "number_of_lunches/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              numberOfMeals_dinners: {
                path: "number_of_dinners/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              plans: {
                path: "plan_list/:to/:menuType",
                parse: {
                  to: (menuType) => menuType,
                  planId: (id) => id,
                },
              },
              accordingToBarana: {
                path: "according_to_barana/:to",
                parse: {
                  to: (menu) => menu,
                },
              },
              editPlan: {
                path: "change_plans/:item/:editable/:planId/:create",
                parse: {
                  item: (plan) => plan,
                  editable: (bolean) => bolean,
                  planId: (id) => id,
                  create: (bolean) => bolean,
                },
              },
              detailsMenu: {
                path: "plate_details/:id",
                parse: {
                  id: (id) => id,
                },
              },
              nutritionalPrescription_ordening: {
                path: "nutritional_prescription_ordening/:to/:planType",
                parse: {
                  to: (menuType) => menuType,
                  planType: (string) => string,
                },
              },
              foodMenu_breakfasts: {
                path: "breakfasts_menu/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              foodMenu_lunches: {
                path: "lunches_menu/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              foodMenu_dinners: {
                path: "dinners_menu/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              adult_or_family: {
                path: "adult_family/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
            },
          },
        },
      },
      Supermarket: {
        initialRouteName: "supermarket",
        screens: {
          supermarket: "supermarket",
          detailsPlate: "detailsPlate",
        },
      },
      ScheduleAppointment: {
        initialRouteName: "nutritionalCare",
        screens: {
          nutritionalCare: "nutritionalCare",
          makeAnAppointment: "makeAnAppointment",
          selectTime: "selectTime",
          groupSession: "groupSession",
          evaluation: "evaluation",
        },
      },
      order_definition: {
        screens: {
          orderType: "orderType",
          mealTimes: {
            path: "mealTimes",
          },
          numberOfMeals_breakfasts: {
            path: "numberOfMeals_breakfasts/:to",
            parse: {
              to: (menuType) => menuType,
            },
          },
          numberOfMeals_lunches: {
            path: "numberOfMeals_lunches/:to",
            parse: {
              to: (menuType) => menuType,
            },
          },
          numberOfMeals_dinners: {
            path: "numberOfMeals_dinners/:to",
            parse: {
              to: (menuType) => menuType,
            },
          },
          plans: {
            path: "plans/:to/:planId",
            parse: {
              to: (menuType) => menuType,
              planId: (id) => id,
            },
          },
          accordingToBarana: {
            path: "accordingToBarana/:to",
            parse: {
              to: (menu) => menu,
            },
          },
          editPlan: {
            path: "editPlan/:to/:planId",
            parse: {
              to: (editable_mealtime) => editable_mealtime,
              planId: (plan) => plan,
            },
          },
          detailsMenu: {
            path: "detailsMenu/:id",
            parse: {
              id: (id) => id,
            },
          },
          nutritionalPrescription_ordening: {
            path: "nutritionalPrescription_ordening/:to/:planType",
            parse: {
              to: (menuType) => menuType,
              planType: (string) => string,
            },
          },
          foodMenu_breakfasts: {
            path: "foodMenu_breakfasts/:to",
            parse: {
              to: (menuType) => menuType,
            },
          },
          foodMenu_lunches: {
            path: "foodMenu_lunches/:to",
            parse: {
              to: (menuType) => menuType,
            },
          },
          foodMenu_dinners: {
            path: "foodMenu_dinners/:to",
            parse: {
              to: (menuType) => menuType,
            },
          },
          adult_or_family: {
            path: "adult_or_family/:to",
            parse: {
              to: (menuType) => menuType,
            },
          },
        },
      },
      couponList: "couponList",
      order_details: {
        path: "order_details/:order",
        parse: {
          order: (obj) => obj,
        },
      },
    },
  },
  ShoppingCart: {
    initialRouteName: "shoppingCart",
    screens: {
      shoppingCart: "shoppingCart",
      editPackageMenu: {
        path: "editPackageMenu/:to",
        parse: {
          to: (editable_mealtime) => editable_mealtime,
        },
      },
      editPackagePlan: {
        path: "editPackagePlan/:to/:planId",
        parse: {
          to: (editable_mealtime) => editable_mealtime,
          planId: (plan) => plan,
        },
      },
      detailsMenu: {
        path: "details_menu/:id",
        parse: {
          id: (id) => id,
        },
      },
      accordingToBarana: {
        path: "planAccordingBarana/:to",
        parse: {
          to: (menu) => menu,
        },
      },
      nutritionalPrescription_ordening: {
        path: "NutritionalPrescriptionOrdening/:to/:planType",
        parse: {
          to: (menuType) => menuType,
          planType: (string) => string,
        },
      },
      editPlan: {
        path: "edit_plan/:item/:editable/:planId/:create",
        parse: {
          item: (plan) => plan,
          editable: (bolean) => bolean,
          planId: (id) => id,
          create: (bolean) => bolean,
        },
      },
      payments: {
        screens: {
          deliveryAddress: {
            path: "deliveryAddress/:location",
            parse: {
              location: (address) => address,
            },
          },
          deliveryAddress: {
            path: "deliveryAddress",
          },
          mapScreen: {
            path: "map_screen/"
          },
          deliveryAddressList: {
            path: "deliveryAddressList/:address",
            parse: {
              address: (paymentAddress) => paymentAddress,
            },
          },
          methodOfPayment: {
            path: "methodOfPayment",
          },
          methodOfPaymentEdit: {
            path: "methodOfPayment/:to",
          },
          IBANAccounts: "IBANAccounts",
          paymentScreen: "paymentScreen",
          onlinePayment: {
            path: "onlinePayment",
          },
          SinpeMovil: "SinpeMovil",
          thanksForYourPurchase: "thanksForYourPurchase",
          subscriptionSummary: {
            path: "subscriptionSummary/:id",
          },
        },
      },
    },
  },
  Orders: {
    initialRouteName: "orders",
    screens: {
      orders: "orders",
      orderDetails: {
        path: "orderDetails/:order",
        parse: {
          order: (obj) => obj,
        },
      },
    },
  },
  Profile: {
    initialRouteName: "profile",
    screens: {
      profile: "profile",
      userData: "userData",
      locations: "locations",
      delivery_address: {
        path: "delivery_address",
      },
      mapScreen: {
        path: "map/"
      },
      newSubscription: {
        initialRouteName: "subscriptions",
        screens: {
          subscriptions: "subscriptions",
          suscriptionDetails: {
            path: "suscriptionDetails/:subscription_id",
            parse: {
              subscription_id: (id) => id,
            },
          },
          editSubscriptionAddress: {
            path: "editSubscriptionAddress",
            parse: {
              address: (suscriptionAddress) => suscriptionAddress,
            },
          },
          mapScreen: {
            path: "mapScreen/"
          },
          createSubscriptionAddress: {
            path: "createSubscriptionAddress/:location/:edit",
            parse: {
              location: (address) => address,
              edit: (boolean) => boolean,
            },
          },
          editMenu: {
            path: "menu/:to/:timeFood",
            parse: {
              to: (editable_mealtime) => editable_mealtime,
              timeFood: (editable_mealtime) => editable_mealtime,
            },
          },
          editPackagePlan: {
            path: "editSuscriptionPlan/:to/:planId",
            parse: {
              to: (editable_mealtime) => editable_mealtime,
              planId: (plan) => plan,
            },
          },
          detailsMenu: {
            path: "details_menu_/:id",
            parse: {
              id: (id) => id,
            },
          },
          IBAN: {
            path: "IBAN",
          },
          accordingToBarana: {
            path: "according_barana/:to",
            parse: {
              to: (menu) => menu,
            },
          },
          nutritionalPlan: {
            path: "nutritional_plan/:to",
            parse: {
              to: (menu) => menu,
            },
          },
          nutritionalPrescription: {
            path: "nutritionalPrescription_ordening_/:to/:planType",
            parse: {
              to: (menuType) => menuType,
              planType: (string) => string,
            },
          },
          editPlan: {
            path: "edit_plan_/:item/:editable/:planId/:create",
            parse: {
              item: (plan) => plan,
              item: (plan) => plan,
              editable: (bolean) => bolean,
              planId: (id) => id,
              create: (bolean) => bolean,
            },
          },
          createOnlinePayment: {
            path: "createOnlinePayment",
          },
          subscriptionSummary: {
            path: "subscription_summary/:id",
          },
          orderDefinition: {
            screens: {
              orderType: {
                path: "packageType",
              },
              mealTimes: {
                path: "selectMealtime",
              },
              numberOfMeals_breakfasts: {
                path: "numberOfBreakfasts/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              numberOfMeals_lunches: {
                path: "numberOfLunches/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              numberOfMeals_dinners: {
                path: "numberOfDinners/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              plans: {
                path: "planList/:to/:menuType",
                parse: {
                  to: (menuType) => menuType,
                  planId: (id) => id,
                },
              },
              editPlan: {
                path: "switchPlans/:item/:editable/:planId/:create",
                parse: {
                  item: (plan) => plan,
                  editable: (bolean) => bolean,
                  planId: (id) => id,
                  create: (bolean) => bolean,
                },
              },
              detailsMenu: {
                path: "plateDetails/:id",
                parse: {
                  id: (id) => id,
                },
              },
              nutritionalPrescription_ordening: {
                path: "nutritionalPrescriptionOrdening_/:to/:planType",
                parse: {
                  to: (menuType) => menuType,
                  planType: (string) => string,
                },
              },
              foodMenu_breakfasts: {
                path: "breakfastMenu/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              foodMenu_lunches: {
                path: "lunchesMenu/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              foodMenu_dinners: {
                path: "dinnersMenu/:to",
                parse: {
                  to: (menuType) => menuType,
                },
              },
              adult_or_family: {
                path: "adultOrFamily/:to",
                parse: {
                  to: (menuType) => menuType,
                  from: (comesFrom) => comesFrom,
                },
              },
            },
          },
        },
      },
      IBAN_Accounts: "IBAN_Accounts",
      online_payment: {
        path: "online_payment",
      },
      nutritionalPlan: "nutritionalPlan",
      planType: {
        path: "planType/:to/:menuType",
        parse: {
          to: (menuType) => menuType,
          planId: (id) => id,
        },
      },
      accordingToBarana: {
        path: "accordingBarana/:to",
        parse: {
          to: (menu) => menu,
        },
      },
      nutritionalPrescription: "nutritionalPrescription",
      planDetails: {
        path: "planDetails/:to/:id/:planId",
        parse: {
          to: (timeFood) => timeFood,
          id: (id) => id,
          planId: (id) => id,
        },
      },
      termsAndConditions: "termsAndConditions",
    },
  },
};
