import { SvgXml, Svg, Image, G, Defs, Path, ClipPath, Rect, Pattern, Use } from 'react-native-svg';

export const Google = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M6.98918 13.8777L6.36263 16.2167L4.07258 16.2652C3.3882 14.9958 3 13.5435 3 12.0001C3 10.5077 3.36295 9.10031 4.00631 7.86108H4.0068L6.04559 8.23486L6.9387 10.2614C6.75177 10.8064 6.64989 11.3914 6.64989 12.0001C6.64996 12.6608 6.76963 13.2937 6.98918 13.8777Z" fill="#DB7500" />
            <Path d="M20.8429 10.3186C20.9462 10.863 21.0001 11.4253 21.0001 11.9999C21.0001 12.6443 20.9324 13.2728 20.8033 13.8791C20.3652 15.9421 19.2204 17.7436 17.6346 19.0184L17.6341 19.0179L15.0661 18.8869L14.7027 16.6181C15.755 16.001 16.5773 15.0352 17.0105 13.8791H12.198V10.3186H17.0807H20.8429Z" fill="#DB7500" />
            <Path d="M17.6338 19.0181L17.6343 19.0185C16.092 20.2583 14.1327 21 11.9999 21C8.57251 21 5.59263 19.0843 4.07251 16.2651L6.98911 13.8777C7.74915 15.9061 9.70591 17.3501 11.9999 17.3501C12.986 17.3501 13.9097 17.0835 14.7024 16.6182L17.6338 19.0181Z" fill="#DB7500" />
            <Path d="M17.7447 5.07197L14.8291 7.45894C14.0087 6.94615 13.039 6.64992 12 6.64992C9.65409 6.64992 7.66073 8.16013 6.93877 10.2613L4.00684 7.86098H4.00635C5.50421 4.97307 8.52168 3 12 3C14.1838 3 16.186 3.77787 17.7447 5.07197Z" fill="#DB7500" />
        </Svg>
    )
}