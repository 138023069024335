import client from "./httpClient";
import { addTrailingSlashOnIos } from "../utils/utils";
//--------------------------------------------------
//--------------PLANS REQUESTS-----------------------
//--------------------------------------------------

//plans
export const getPlansBarana = async (token) => {
  try {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await client.get(addTrailingSlashOnIos(`/plans-barana`));
    return response.data.sort((a, b) => a.id - b.id);
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getPlans = async (id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(addTrailingSlashOnIos(`/plans/${id}`))
    .then((value) => {
      // console.log('success! ')
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const getPlansUser = async (token, type) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const query = type
    ? `${addTrailingSlashOnIos("/plans-user")}?type_plan=${type}`
    : addTrailingSlashOnIos("/plans-user");
  const response = await client
    .get(query)
    .then((value) => {
      // console.log('success! ')
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data?.message);
    });
  return response;
};

export const getUserPlan = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(addTrailingSlashOnIos(`/plans-user/${id}`))
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const deletePlanUser = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .delete(`/plans-user/${id}/`)
    .then((value) => {
      return { ...value.data, deleted: true };
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const getPlanPortion = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(
      `${addTrailingSlashOnIos("/plan-portion/get_plan_portion")}?plan_id=${id}`
    )
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const createPlanPortion = async (token, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .post(`/plan-portion/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const updatePlanPortion = async (token, data, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .patch(`/plan-portion/${id}/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const deletePlanPortion = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .delete(`/plan-portion/${id}/`)
    .then((value) => {
      return { ...value.data, deleted: true };
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const createPlanCustom = async (token, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const response = await client
    .post(`/plans-user/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const editPlanCustom = async (token, data, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .patch(`/plans-user/${id}/`, data)
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};
