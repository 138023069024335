import { useContext, useEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
//contexts
import { orderContext } from '../../context/orderingContext'
//screens
import { Options } from '../screen/nutritionalConsulte/options'
import { MakeAnAppointment } from '../screen/nutritionalConsulte/makeAnAppointment'
import { GroupSession } from '../screen/nutritionalConsulte/groupSession'
import { Evaluation } from '../screen/nutritionalConsulte/evaluation'
//styles
import {
  titleStyle,
  headerComponentStyle,
  headerTitleContainer,
} from "../../styles/pages/navigators/headers";
//components
import { GoBack } from "../widgets/goBack";
import { StepProgress } from "../widgets/stepProgress";
//hooks
import useIsWeb from "../../hooks/useIsWeb";

export const ScheduleAppointmentNavigator = ({ bottomTabNavigation }) => {
  const { currentStep, totalSteps } = useContext(orderContext);
  const Stack = createStackNavigator();
  const { isComputer } = useIsWeb();

  return (
    <Stack.Navigator initialRouteName={"nutritionalCare"}>
      <Stack.Screen
        name={"nutritionalCare"}
        options={({ navigation }) => ({
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitle: () => (
            <StepProgress step={currentStep} totalSteps={totalSteps} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Options
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"makeAnAppointment"}
        options={({ navigation }) => ({
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitle: () => (
            <StepProgress step={currentStep} totalSteps={totalSteps} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <MakeAnAppointment
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"selectTime"}
        options={({ navigation }) => ({
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitle: () => (
            <StepProgress step={currentStep} totalSteps={totalSteps} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <MakeAnAppointment
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"groupSession"}
        options={({ navigation }) => ({
          headerTitle: "Sesión Grupal",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <GroupSession
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"evaluation"}
        options={({ navigation }) => ({
          headerTitle: "Evaluación",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Evaluation
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
};