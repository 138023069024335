import { ScrollView, FlatList, Dimensions, View } from "react-native";
//components
import { SupermarketItem } from "./supermarketItem";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";
import { flexContainer } from "../../../../styles/pages/generalStyles";

const { width } = Dimensions.get("window");
export const SupermarketListItem = ({ list, comboList, editable, margin }) => {
  const { isComputer } = useIsWeb();

  return (
    <View
      style={[
        flexContainer("column", null, null, null, null, 20, 20),
        { width: !isComputer ? width - 44 : "100%", paddingVertical: 20 },
      ]}
    >
      {list?.map((item, index) => {
        return item.quantity > 0 || item.count > 0 ? (
          <SupermarketItem
            key={`${index}-supermarketItem`}
            uniqueKey={`${index}-supermarketItem`}
            item={item}
            comboList={comboList}
            editable={editable}
          />
        ) : null;
      })}
    </View>
  );
};
