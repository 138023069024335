import { Svg, G, Defs, Path, ClipPath, Rect } from 'react-native-svg';

export const Egg = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <G clipPath="url(#clip0_94_701)">
                <Path fillRule="evenodd" clipRule="evenodd" d="M41.0107 8.05086C48.8983 4.97025 54.0816 -4.71211 66.8629 4.89856C74.7895 10.8576 76.1733 17.885 75.2654 26.7551C74.3565 36.0691 80.9236 44.9436 77.7444 54.7016C74.5652 64.4591 57.9945 75.3962 47.5464 74.0658C35.7378 72.2914 28.6846 60.0169 19.6011 55.5816C10.5181 51.1463 1.35198 44.2277 0.896794 32.6963C0.443629 22.9388 5.40464 10.2514 20.0522 6.73081C24.2143 5.73064 32.672 10.691 41.0107 8.05086Z" fill="#FFFFFE" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M41.4424 8.77321C44.4302 7.51892 46.9836 5.57197 49.7188 3.88803C54.133 1.17081 58.3771 1.0472 63.0272 3.40599C67.8074 5.83054 71.7325 10.0147 73.5502 14.9577C75.251 19.5838 74.3492 24.3192 74.3264 29.0753C74.2788 39.0652 81.05 48.3085 75.2221 57.9676C70.7568 65.3693 61.6723 70.9516 53.2616 72.9025C43.5401 75.1575 35.8631 67.7054 29.3644 61.8091C26.7047 59.396 23.975 57.0654 20.8302 55.2702C16.6712 52.8966 12.6535 50.8853 9.0677 47.652C2.0363 41.3117 0.362887 32.1624 2.96694 23.4322C5.24339 15.7987 13.3462 7.00227 22.1998 7.47442C25.5325 7.65191 28.8692 8.93192 32.1725 9.41841C35.2505 9.87177 38.254 9.67105 41.2378 8.85776C42.3011 8.56804 41.8495 6.95332 40.7826 7.24354C35.4004 8.70993 30.3497 7.43883 25.0667 6.26709C21.5629 5.4899 18.7204 5.91557 15.413 7.36763C8.72592 10.3034 4.04947 15.4832 1.5801 22.1878C-0.822936 28.7114 -0.507493 36.1235 2.68036 42.309C6.44238 49.6068 13.889 53.2471 20.7523 57.1647C28.0226 61.3147 32.9619 68.4524 40.3659 72.5174C46.972 76.1448 53.7148 75.1683 60.3629 72.2539C67.2702 69.2251 73.9624 63.9212 77.5517 57.3333C81.8905 49.37 77.7644 40.4213 76.3411 32.3087C75.292 26.3259 77.0576 20.4737 75.2034 14.5127C73.5943 9.3398 69.4637 5.50522 64.9857 2.59369C60.9938 -0.000926022 56.3634 -0.81125 51.8733 0.908278C47.7953 2.47009 44.6044 5.63723 40.5781 7.32808C39.5644 7.75376 40.4388 9.19494 41.4424 8.77321Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M47.3199 23.454C37.405 27.6351 33.6941 40.9973 47.3199 48.9804C57.7681 55.6331 66.7108 43.5509 65.3488 34.2369C64.8946 30.2455 55.8845 20.5933 47.3199 23.454Z" fill="#FAEC32" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M46.8882 22.7316C40.3449 25.6916 36.4051 32.1772 38.0072 39.1996C39.676 46.5153 49.0001 53.4305 56.6335 51.1918C63.5408 49.1663 67.8993 39.5482 65.9373 33.0414C63.8087 25.9823 54.2173 20.6487 47.0927 22.6471C46.0315 22.9442 46.4831 24.5599 47.5479 24.2613C54.1403 22.4117 60.7672 27.1115 63.8725 32.5722C67.1717 38.3725 61.8309 47.3691 56.1773 49.5776C49.0775 52.3517 40.9018 44.199 39.4846 37.9879C38.1246 32.0239 42.4973 26.5548 47.7525 24.1768C48.7545 23.7234 47.8836 22.2807 46.8882 22.7316Z" fill="#1A1919" />
            </G>
            <Defs>
                <ClipPath id="clip0_94_701">
                    <Rect width="80" height="80" fill="white" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}