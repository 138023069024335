import React, { useState } from "react";
import { View, Text, Modal } from "react-native";
import { useFormik } from "formik";
//styles
import {
  spaceBetween,
  styles,
  flexContainer,
} from "../../../../styles/pages/generalStyles";
import {
  modalStyles,
  options,
} from "../../../../styles/pages/widgets/modalMenu";
import { homeStyles } from "../../../../styles/pages/home/homePage";
//components
import { GeneralButton } from "../../../widgets/generalButton";
import { InputOverall } from "../../../widgets/inputOverall";

export const CouponFormModal = ({
  openModal,
  handleAddCoupon = () => {},
  title,
  error,
  setError,
}) => {
  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: { coupon: "" },
    // validationSchema: null,
    validateOnChange: false,
    onSubmit: (values) => {
      handleAddCoupon(values.coupon);
    },
  });

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={true}
      onRequestClose={() => {
        openModal(false);
        setError(null);
      }}
    >
      <View style={modalStyles.container_options}>
        <View style={options()}>
          <View
            style={[spaceBetween(), { flexDirection: "row", marginBottom: 15 }]}
          >
            <Text style={homeStyles.strongText}>{title}</Text>
          </View>
          <View>
            <InputOverall
              label="Cupón"
              value={values.coupon}
              onChangeText={(value) => setFieldValue("coupon", value)}
              errors={errors.coupon}
              type={"outlined"}
              noWeb={true}
            />
            {error ? (
              <View>
                <Text style={[styles.error, { marginVertical: 5 }]}>
                  {error}
                </Text>
              </View>
            ) : null}
          </View>
          <View
            style={[
              flexContainer("row", "space-between", "center"),
              { marginTop: 12 },
            ]}
          >
            <GeneralButton
              customColor={"#E0E0E0"}
              text={"Cancelar"}
              handleOnPress={() => {
                openModal(false);
                setError(null);
              }}
              width={"45%"}
              marginR={5}
            />
            <GeneralButton
              text={"Agregar"}
              handleOnPress={() => {
                handleSubmit();
              }}
              width={"45%"}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};
