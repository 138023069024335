import { useState, useEffect, useContext } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Formik } from "formik";
import * as yup from "yup";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//color
import { RadioButton } from "react-native-paper";
//components
import { SelectorModal } from "../../widgets/selectorModal";
import { InputOverall } from "../../widgets/inputOverall";
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
import { PopUp } from "../../widgets/popUp";
// contexts
import { orderContext } from "../../../context/orderingContext";
//controllers
import {
  getPaymentMethods,
  updatePaymentMethods,
  payIban,
} from "../../controllers/paymentMethods";
//Styles
import {
  styles,
  title,
  paragraph,
  titleMargin,
  actionText,
  webContainer,
  sectionContainerWeb,
} from "../../../styles/pages/generalStyles";
import { centerAlignedRow } from "../../../styles/pages/home/homePage";
import { dropDownListIcon } from "../../../styles/pages/widgets/inputs";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useCartStore from "../shoppingCart/useCartStore";
import useUserData from "../../../hooks/useUserData";
import useOrderStore from "../../../hooks/useOrderStore";
import useModal from "../../../hooks/useModal";

const validationSchema = yup.object({
  card_number: yup
    .string()
    .required("El número de cuenta es requerido.")
    .length(
      22,
      ({ length }) => `El número de cuenta  debe contener ${length} caracteres.`
    ),
  name_owner: yup.string().required("El nombre es requerido."),
  last_name: yup.string().required("El apellido es requerido."),
  dni: yup
    .string()
    .required("La cédula es requerida.")
    .min(9, ({ min }) => `La cédula debe contener ${min} caracteres.`),
  coin_type: yup.string().required("Seleccione el tipo de moneda."),
  phone_number: yup
    .string()
    .required("El número telefónico es requerido.")
    .length(
      8,
      ({ length }) => `El número telefónico debe contener ${length} dígitos.`
    ),
});

export const OnlinePayment = ({ navigation, route, bottomTabNavigation }) => {
  const { access_token } = useUserData();
  const { createSubscriptionFromHome } = useContext(orderContext);
  const [termOfConditions, setTermOfConditions] = useState(true);
  const [savePaymentMethod, setSavePaymentMethod] = useState(true);
  const [setCoinType, setOpenCoinType] = useState(false);
  const [loding, setLoading] = useState(false);
  const [seeReference, setSeeReference] = useState(false);
  const [initialValues, setInitialValues] = useState(route?.params?.method);
  const { isComputer } = useIsWeb();
  const { cart, clearCart } = useCartStore();
  const { subscription, setInitialValues: setOrderInitialValues } =
    useOrderStore();
  const { openMessageModal } = useModal();

  useEffect(() => {
    if (route?.name == "online_payment") {
      bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
    }

    if (route?.params?.edit) handleGetPaymentMethod();
  }, []);

  const handleAddPaymentMethods = async (values) => {
    if (route.name === "onlinePayment") {
      handlePayIban(values);
    } else if (
      route.name === "createOnlinePayment" ||
      route.name === "create_online_payment"
    ) {
      handlePaymentSuscription(values);
    }
  };

  const handleUpdatePaymentMethod = async (values) => {
    setLoading(true);
    await updatePaymentMethods(access_token, route.params?.method?.id, {
      ...values,
      term_of_conditions: termOfConditions,
    })
      .then(async (res) => {
        setLoading(false);
        navigation.navigate("IBAN_Accounts");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleGetPaymentMethod = async () => {
    setLoading(true);
    await getPaymentMethods(access_token, route.params.id || null)
      .then(async (res) => {
        if (res?.message?.length > 0) {
          // handlePayIban(res.message[0].id)
        } else {
          setInitialValues({ ...res.message[0] });
          setTermOfConditions(res.message[0].term_of_conditions);
          setSavePaymentMethod(res.message[0].is_save);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handlePayIban = async (values) => {
    setLoading(true);
    await payIban(access_token, {
      shopping_cart: cart.shopping_cart,
      iban_account: values.card_number,
      dni: values.dni,
      phone_number: values.phone_number,
      name: values.name_owner,
      last_name: values.last_name,
      type_coint: values.coin_type,
      terms: termOfConditions,
      is_save: savePaymentMethod,
      address: cart.address.id,
    })
      .then(async (res) => {
        setLoading(false);
        await AsyncStorage.setItem("shoppingCart_copy", JSON.stringify(cart));
        await AsyncStorage.removeItem("nutritionalConsultations");
        await clearCart();

        navigation.reset({
          index: 0,
          routes: [{ name: "thanksForYourPurchase" }],
        });
      })
      .catch((err) => {
        setLoading(false);
        openMessageModal(err?.message);
      });
  };

  const handlePaymentSuscription = async (values) => {
    setLoading(true);
    await payIban(access_token, {
      subscription: subscription.subscription_id,
      iban_account: values.card_number,
      dni: values.dni,
      phone_number: values.phone_number,
      name: values.name_owner,
      last_name: values.last_name,
      type_coint: values.coin_type,
      terms: termOfConditions,
      is_save: savePaymentMethod,
      address: subscription.address.id,
    })
      .then(async (res) => {
        setInitialValues();
        createSubscriptionFromHome
          ? navigation.navigate("subscriptionSummary", {
              id: subscription.subscription_id,
            })
          : navigation.navigate("subscription_summary", {
              id: subscription.subscription_id,
            });
      })
      .catch((err) => {
        openMessageModal(err?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Formik
      initialValues={
        initialValues || {
          card_number: "",
          name_owner: "",
          last_name: "",
          dni: "",
          coin_type: "colones",
          phone_number: "",
        }
      }
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleAddPaymentMethods(values);
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
      }) => (
        <View style={styles.screen}>
          <ScrollView
            contentContainerStyle={isComputer && { alignItems: "center" }}
          >
            <View
              style={[
                styles.container,
                webContainer("calc(100vh - 160px)"),
                isComputer && sectionContainerWeb(600, null, "100%"),
              ]}
            >
              <View
                style={[
                  centerAlignedRow(null, "center", 0),
                  titleMargin(isComputer),
                ]}
              >
                <Text
                  style={[
                    title(!isComputer ? "60%" : 200, !isComputer && "left"),
                  ]}
                >
                  Pago en Línea
                </Text>
                <TouchableOpacity onPress={() => setSeeReference(true)}>
                  <BaranaIcons
                    draw={{ width: 24, height: 24 }}
                    name={"helpIcon"}
                  />
                </TouchableOpacity>
              </View>
              <Text style={paragraph("100%", 17, 10)}>
                ¡Pague de manera más segura con su cuenta IBAN y olvídese de
                ingresar su número de tarjeta! Encuentre su IBAN en la parte
                frontal de su tarjeta.{" "}
                <Text
                  onPress={() => setSeeReference(true)}
                  style={styles.orangeText}
                >
                  {" "}
                  ¿Dónde está?
                </Text>
              </Text>
              <View style={[styles.inputsContainer]}>
                <View
                  style={[
                    centerAlignedRow(null, "space-between"),
                    { width: "100%" },
                  ]}
                >
                  <InputOverall
                    label="Número de cuenta IBAN"
                    value={values.card_number}
                    placeholder="Número de cuenta IBAN"
                    onChangeText={handleChange("card_number")}
                    type={"outlined"}
                    errors={
                      touched.card_number &&
                      errors.card_number &&
                      errors.card_number
                    }
                    onBlur={() => handleBlur("card_number")}
                    width_={isComputer ? "95%" : "82%"}
                    noWeb={true}
                  />
                  <TouchableOpacity
                    onPress={() => setSeeReference(true)}
                    style={{ marginTop: 15 }}
                  >
                    <BaranaIcons
                      draw={{ width: 70, height: 24 }}
                      name={"helpIcon"}
                    />
                  </TouchableOpacity>
                </View>
                <InputOverall
                  label="Número de cédula"
                  value={values.dni}
                  placeholder="Número de cédula"
                  onChangeText={handleChange("dni")}
                  keyboardType={"numeric"}
                  type={"outlined"}
                  errors={touched.dni && errors.dni && errors.dni}
                  onBlur={() => handleBlur("dni")}
                  noWeb={true}
                />
                <InputOverall
                  label="Nombre"
                  value={values.name_owner}
                  placeholder="Nombre"
                  onChangeText={handleChange("name_owner")}
                  type={"outlined"}
                  errors={
                    touched.name_owner && errors.name_owner && errors.name_owner
                  }
                  onBlur={() => handleBlur("name_owner")}
                  noWeb={true}
                />
                <InputOverall
                  label="Dos apellidos"
                  value={values.last_name}
                  placeholder="Dos apellidos"
                  onChangeText={handleChange("last_name")}
                  type={"outlined"}
                  errors={
                    touched.last_name && errors.last_name && errors.last_name
                  }
                  onBlur={() => handleBlur("last_name")}
                  noWeb={true}
                />
                <InputOverall
                  label="Número telefónico"
                  value={values.phone_number}
                  placeholder="Número telefónico"
                  onChangeText={handleChange("phone_number")}
                  type={"outlined"}
                  keyboardType={"numeric"}
                  errors={
                    touched.phone_number &&
                    errors.phone_number &&
                    errors.phone_number
                  }
                  onBlur={() => handleBlur("phone_number")}
                  noWeb={true}
                />
                <View
                  style={{
                    marginBottom: setCoinType ? 154 : 0,
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <InputOverall
                    label="¿En qué moneda está su cuenta?"
                    value={values.coin_type}
                    placeholder="¿En qué moneda está su cuenta?"
                    editable={false}
                    type={"outlined"}
                    errors={
                      touched.coin_type && errors.coin_type && errors.coin_type
                    }
                    onBlur={() => handleBlur("coin_type")}
                    icon={
                      <TouchableOpacity
                        onPress={() => setOpenCoinType(!setCoinType)}
                        style={dropDownListIcon(errors.coin_type)}
                      >
                        <BaranaIcons
                          draw={{ width: 24, height: 24 }}
                          name={setCoinType ? "rollOut" : "rollDown"}
                        />
                      </TouchableOpacity>
                    }
                    noWeb={true}
                  />
                  {setCoinType ? (
                    <SelectorModal
                      height={120}
                      openModal={setOpenCoinType}
                      valueList={["Colones", "Dólares"]}
                      setValue={handleChange("coin_type")}
                      width={isComputer && "45%"}
                    />
                  ) : null}
                </View>
                <View
                  style={[centerAlignedRow(), { marginTop: 22, width: "100%" }]}
                >
                  <RadioButton
                    color={"#DB7500"}
                    value={termOfConditions}
                    status={termOfConditions ? "checked" : "unchecked"}
                    onPress={() => setTermOfConditions(!termOfConditions)}
                  />
                  <Text style={actionText("#DB7500")}>
                    Acepto términos y condiciones.
                  </Text>
                </View>
                <View
                  style={[centerAlignedRow(), { marginTop: 22, width: "100%" }]}
                >
                  <RadioButton
                    color={"#DB7500"}
                    value={savePaymentMethod}
                    status={savePaymentMethod ? "checked" : "unchecked"}
                    onPress={() => setSavePaymentMethod(!savePaymentMethod)}
                  />
                  <Text style={actionText("#DB7500")}>
                    Guardar el método de pago.
                  </Text>
                </View>
              </View>
              <View
                style={[
                  centerAlignedRow(null, "center", "column"),
                  { paddingBottom: 30 },
                ]}
              >
                <Text style={{ marginVertical: 10 }}>Powered by</Text>
                <BaranaIcons
                  draw={{ width: 110, height: 42 }}
                  name={"Ridivi"}
                />
              </View>

              {seeReference && (
                <PopUp
                  showModal={seeReference}
                  openModal={setSeeReference}
                  text="Su número de cuenta IBAN se encuentra en la parte frontal de su tarjeta, debajo de su nombre."
                  buttonText={"ok"}
                  img={require("../../../assets/illustrations/card_IBAN.png")}
                  button={true}
                />
              )}
            </View>
          </ScrollView>
          <LowerOptionsContainer>
            <GeneralButton
              text="Continuar"
              handleOnPress={() => handleSubmit(values)}
              disabled={loding}
              width={isComputer ? 350 : "100%"}
              loading={loding}
            />
          </LowerOptionsContainer>
        </View>
      )}
    </Formik>
  );
};
