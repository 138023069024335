import * as Icons from './BaranaIconsImports'

export const BaranaIcons = (nameIcon) => {
  switch (nameIcon.name) {
    case "Barana":
      return <Icons.Barana {...nameIcon.draw} />;
    case "home":
      return <Icons.Home {...nameIcon.draw} />;
    case "shoppingCart":
      return <Icons.ShoppingCart {...nameIcon.draw} />;
    case "orders":
      return <Icons.Orders {...nameIcon.draw} />;
    case "profile":
      return <Icons.Profile {...nameIcon.draw} />;
    case "menu":
      return <Icons.Menu {...nameIcon.draw} />;
    case "leftArrow":
      return <Icons.LeftArrow {...nameIcon.draw} />;
    case "arrowBack":
      return <Icons.ArrowBack {...nameIcon.draw} />;
    case "phone":
      return <Icons.Phone {...nameIcon.draw} />;
    case "google":
      return <Icons.Google {...nameIcon.draw} />;
    case "facebook":
      return <Icons.FaceBook {...nameIcon.draw} />;
    case "email":
      return <Icons.Email {...nameIcon.draw} />;
    case "helpIcon":
      return <Icons.HelpIcon {...nameIcon.draw} />;
    case "plusCircle":
      return <Icons.PlusCircle {...nameIcon.draw} />;
    case "happyEmoji":
      return <Icons.HappyEmoji {...nameIcon.draw} />;
    case "clock":
      return <Icons.Clock {...nameIcon.draw} />;
    case "blackClock":
      return <Icons.BlackClock {...nameIcon.draw} />;
    case "cube":
      return <Icons.Cube {...nameIcon.draw} />;
    case "location":
      return <Icons.Location {...nameIcon.draw} />;
    case "pencil":
      return <Icons.Pencil {...nameIcon.draw} />;
    case "exclamation":
      return <Icons.Exclamation {...nameIcon.draw} />;
    case "dolar":
      return <Icons.Dolar {...nameIcon.draw} />;
    case "calendar":
      return <Icons.Calendar {...nameIcon.draw} />;
    case "outlinedMinus":
      return <Icons.OutlinedMinus {...nameIcon.draw} />;
    case "outlinedPlus":
      return <Icons.OutlinedPlus {...nameIcon.draw} />;
    case "plus":
      return <Icons.Plus {...nameIcon.draw} />;
    case "check":
      return <Icons.Check {...nameIcon.draw} />;
    case "arrowsInCircle":
      return <Icons.ArrowsInCircle {...nameIcon.draw} />;
    case "creditCard":
      return <Icons.CreditCard {...nameIcon.draw} />;
    case "blue_faceBook":
      return <Icons.Blue_faceBook {...nameIcon.draw} />;
    case "equis":
      return <Icons.Equis {...nameIcon.draw} />;
    case "GooglePay":
      return <Icons.GooglePay {...nameIcon.draw} />;
    case "AppStore":
      return <Icons.AppStore {...nameIcon.draw} />;
    case "littleHeart":
      return <Icons.LittleHeart {...nameIcon.draw} />;
    case "scale":
      return <Icons.Scale {...nameIcon.draw} />;
    case "fresh":
      return <Icons.Fresh {...nameIcon.draw} />;
    case "loupe":
      return <Icons.Loupe {...nameIcon.draw} />;
    case "leanMeats":
      return <Icons.LeanMeats {...nameIcon.draw} />;
    case "canningReduction":
      return <Icons.CanningReduction {...nameIcon.draw} />;
    case "oliveOil":
      return <Icons.OliveOil {...nameIcon.draw} />;
    case "additive-free":
      return <Icons.AdditiveFree {...nameIcon.draw} />;
    case "fishPreparations":
      return <Icons.FishPreparations {...nameIcon.draw} />;
    case "delete":
      return <Icons.Delete {...nameIcon.draw} />;
    case "camera":
      return <Icons.Camera {...nameIcon.draw} />;
    case "note":
      return <Icons.Note {...nameIcon.draw} />;
    case "onlinePayment":
      return <Icons.OnlinePayment {...nameIcon.draw} />;
    case "colorLocation":
      return <Icons.ColorLocation {...nameIcon.draw} />;
    case "minus":
      return <Icons.Minus {...nameIcon.draw} />;
    case "lunches":
      return <Icons.Lunches {...nameIcon.draw} />;
    case "olderAdults":
      return <Icons.OlderAdults {...nameIcon.draw} />;
    case "pamilyPortion":
      return <Icons.PamilyPortion {...nameIcon.draw} />;
    case "winWithBarana":
      return <Icons.WinWithBarana {...nameIcon.draw} />;
    case "users":
      return <Icons.Users {...nameIcon.draw} />;
    case "prize":
      return <Icons.Prize {...nameIcon.draw} />;
    case "myDetails":
      return <Icons.MyDetails {...nameIcon.draw} />;
    case "addresses":
      return <Icons.Addresses {...nameIcon.draw} />;
    case "subscriptions":
      return <Icons.Subscriptions {...nameIcon.draw} />;
    case "automaticPayment":
      return <Icons.AutomaticPayment {...nameIcon.draw} />;
    case "schedule":
      return <Icons.Schedule {...nameIcon.draw} />;
    case "information":
      return <Icons.Information {...nameIcon.draw} />;
    case "thumbsUpOutline":
      return <Icons.ThumbsUpOutline {...nameIcon.draw} />;
    case "colones":
      return <Icons.Colones {...nameIcon.draw} />;
    case "doctor":
      return <Icons.Doctor {...nameIcon.draw} />;
    case "paperAirplane":
      return <Icons.PaperAirplane {...nameIcon.draw} />;
    case "Ridivi":
      return <Icons.Ridivi {...nameIcon.draw} />;
    case "supermarket":
      return <Icons.Supermarket {...nameIcon.draw} />;
    case "Maiz":
      return <Icons.Corn {...nameIcon.draw} />;
    case "Nueces":
      return <Icons.Nuts {...nameIcon.draw} />;
    case "Azucar":
      return <Icons.Sugar {...nameIcon.draw} />;
    case "Trigo":
      return <Icons.Wheat {...nameIcon.draw} />;
    case "doctorTool":
      return <Icons.DoctorTool {...nameIcon.draw} />;
    case "egg":
      return <Icons.Egg {...nameIcon.draw} />;
    case "soup":
      return <Icons.Soup {...nameIcon.draw} />;
    case "spaghetti":
      return <Icons.Spaghetti {...nameIcon.draw} />;
    case "fish":
      return <Icons.Fish {...nameIcon.draw} />;
    case "vegetables":
      return <Icons.Vegetables {...nameIcon.draw} />;
    case "steak":
      return <Icons.Steak {...nameIcon.draw} />;
    case "rollOut":
      return <Icons.RollOut {...nameIcon.draw} />;
    case "rollDown":
      return <Icons.RollDown {...nameIcon.draw} />;
    case "rigthCalendarArrow":
      return <Icons.RigthCalendarArrow {...nameIcon.draw} />;
    case "leftCalendarArrow":
      return <Icons.LeftCalendarArrow {...nameIcon.draw} />;
    case "subscription":
      return <Icons.Subscription {...nameIcon.draw} />;
    default:
      return null;
  }
};