import { Svg, Path } from 'react-native-svg';


export const CanningReduction = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 35 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M17.7478 14.4C26.5844 14.4 33.75 11.5137 33.75 7.95C33.75 4.3863 26.5844 1.5 17.7478 1.5C8.91112 1.5 1.75 4.3863 1.75 7.95C1.75 11.5137 8.92894 14.4 17.7478 14.4Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M33.75 22.9902C33.75 26.5588 26.6201 29.4402 17.7478 29.4402C8.87547 29.4402 1.75 26.5588 1.75 22.9902" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M1.75 7.94995V38.0499C1.75 41.6185 8.85764 44.4999 17.7478 44.4999C26.6379 44.4999 33.75 41.6431 33.75 38.0499V7.94995" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>

    )
}