import { Text, TouchableOpacity, ActivityIndicator } from 'react-native';
//color
import { useTheme } from 'react-native-paper'
//styles
import {
    outlinedButton,
    textOverallButton
} from '../../styles/pages/widgets/general'


export const OutlinedButton = (props) => {
    const { colors } = useTheme();
    const { text, handleOnPress, disabled, loding, width } = props;
    return (
        <TouchableOpacity
            style={[outlinedButton(colors, width)]}
            onPress={() => handleOnPress()}
            disabled={disabled}
        >
            {loding ?
                <ActivityIndicator size='small' color={'black'} /> :
                <Text style={textOverallButton()}>{text}</Text>}
        </TouchableOpacity>
    )
}