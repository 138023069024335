import { useContext, useState, useEffect } from "react";
import { View, Image, Text } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
// contexts
import { mainContext } from "../../../../context/mainContext";
import { shoppingCart } from "../../../../context/shoppingCart";
import { orderContext } from "../../../../context/orderingContext";
//components
import { AboutSubscriptions } from "./aboutSubscriptions";
import { AboutOrders } from "./aboutOrders";
import { AboutCurrentOrders } from "./aboutCurrentOrders";
import { fetchHomeFeed } from "../../../controllers/homeFeed";
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//styles
import {
  sectionContainerWeb,
  flexContainer,
  paragraph,
} from "../../../../styles/pages/generalStyles";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";

export const FirstSectionActions = ({ background, navigation }) => {
  const { orders } = useContext(mainContext);
  const { cart } = useContext(shoppingCart);
  const { baranaPlanList } = useContext(orderContext);
  const [aboutOrders, setAboutOrders] = useState(null);
  const [preassembledPackageOrders, setPreassembledPackageOrders] =
    useState(false);
  const { isComputer } = useIsWeb();

  useEffect(() => {
    const fetch = async () => {
      const token = await AsyncStorage.getItem("access_token");

      const response = await fetchHomeFeed(token);
      setAboutOrders(response);
    };
    fetch();
  }, []);

  useEffect(() => {
    setPreassembledPackageOrders(orders.some((order) => order.reorder));
  }, [orders]);

  // useEffect(() => {
  // }, [baranaPlanList])

  return (
    <View style={{ paddingVertical: 25, paddingHorizontal: 20 }}>
      <View style={[sectionContainerWeb(1000, null, "100%"), { gap: 10 }]}>
        {isComputer ? (
          <Image
            source={require("../../../../assets/illustrations/banner.png")}
            style={{ width: "100%", height: 338 }}
          />
        ) : null}
        <View
          style={
            isComputer && [
              flexContainer(
                "row",
                "space-evenly",
                "center",
                "wrap",
                "center",
                20,
                20
              ),
              { width: "100%" },
            ]
          }
        >
          <View style={isComputer && sectionContainerWeb(600, null, "100%")}>
            {/* <AboutSubscriptions navigation={navigation} /> */}

            {aboutOrders?.ha_pedido &&
            !aboutOrders?.tiene_suscripcio &&
            preassembledPackageOrders > 0 &&
            baranaPlanList.length > 0 ? (
              <AboutOrders
                navigation={navigation}
                cuttingDay={aboutOrders?.dia_corte}
              />
            ) : // <AboutCurrentOrders navigation={navigation} hasSubscriptions={aboutOrders?.tiene_suscripcio} />
            orders.length > 0 &&
              preassembledPackageOrders &&
              baranaPlanList.length > 0 ? (
              <AboutCurrentOrders
                navigation={navigation}
                hasSubscriptions={aboutOrders?.tiene_suscripcio}
              />
            ) : !aboutOrders?.tiene_suscripcio ? (
              <AboutSubscriptions navigation={navigation} />
            ) : null}
          </View>
          {isComputer ? (
            <View
              style={[
                flexContainer(
                  "column",
                  "center",
                  "center",
                  null,
                  null,
                  null,
                  10
                ),
                sectionContainerWeb(380, null, "100%", "center"),
              ]}
            >
              <Text
                style={[
                  paragraph("100%", 24),
                  { textAlign: "center", lineHeight: 30, fontWeight: 800 },
                ]}
              >
                El app de comida saludable ¡Descárguela ya!
              </Text>
              <BaranaIcons
                draw={{ width: 174, height: 55 }}
                name={"AppStore"}
              />
              <BaranaIcons
                draw={{ width: 174, height: 63 }}
                name={"GooglePay"}
              />
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};
