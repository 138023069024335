import { StyleSheet, Platform } from 'react-native'

export const styles = StyleSheet.create({
    navBarContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: 210,
    },
    log: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginRight: '5%',
    },
    whatsapp_container: {
        marginRight: 15,
        width: 82,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    whatsapp: {
        marginLeft: 15,
        width: 28,
        height: 28,
    },
    text: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 17,
        lineHeight: 22,
        color: '#DB7500',
        textAlign: 'center'
    },
    icon: {
        marginHorizontal: '3.2%'
    },
    notification: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: '#FF941A',
        width: 12,
        height: 19,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        right: 0,
        top: -5,
        zIndex: 1
    },
    notification2: {
        display: 'flex',
        position: 'absolute',
        backgroundColor: '#FF941A',
        width: 12,
        height: 19,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        right: Platform.OS === 'ios' ? 15 : 0,
        top: -5,
        zIndex: 1
    }
})
