import { StyleSheet, Platform } from 'react-native'

export const signupStyles = StyleSheet.create({
    socialMediaIcon: {
        position: 'absolute',
        right: 15,
        top: 13,
    },
    lisOptions: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 22,
    },
    buttomOption: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 16,
        paddingVertical: 14,
        width: Platform.OS !== 'web' ? '100%' : 356,
        minHeight: 55,
        backgroundColor: '#FFF8EF',
        borderWidth: 1.5,
        borderColor: '#FFE6C8',
        borderRadius: 10,
        marginVertical: 8,
        justifyContent: 'space-between',
    },
    optionButtonText: {
        fontFamily: 'Nunito Sans',
        fontWeight: '800',
        fontSize: 19,
        lineHeight: 22,
        letterSpacing: 0.01,
        color: '#2F322F',
        width: '100%',
        textAlign: 'center',
    },
})

export const flatList = () => ({
    paddingHorizontal: 22,
    justifyContent: 'center',
    marginRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 35,
    backgroundColor: '#FFF8EF',
    borderWidth: 1.5,
    borderColor: '#FFE6C8',
    borderRadius: 10,
    marginVertical: 5,
    justifyContent: 'space-between',
    paddingVertical: 2,
})

export const buttomOption = (isComputer) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 14,
    width: !isComputer ? '100%' : 356,
    minHeight: 55,
    backgroundColor: '#FFF8EF',
    borderWidth: 1.5,
    borderColor: '#FFE6C8',
    borderRadius: 10,
    marginVertical: 8,
    justifyContent: 'space-between',
})

