import { Svg, G, Defs, Path, ClipPath, Rect } from 'react-native-svg';

export const LittleHeart = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <G clipPath="url(#clip0_1288_24863)">
                <Path d="M19.2286 4.85055C18.7604 4.38214 18.2045 4.01056 17.5927 3.75705C16.9808 3.50353 16.3251 3.37305 15.6628 3.37305C15.0005 3.37305 14.3447 3.50353 13.7329 3.75705C13.121 4.01056 12.5651 4.38214 12.0969 4.85055L11.1253 5.82222L10.1536 4.85055C9.20789 3.90484 7.92522 3.37354 6.58777 3.37354C5.25032 3.37354 3.96766 3.90484 3.02194 4.85055C2.07622 5.79627 1.54492 7.07894 1.54492 8.41639C1.54492 9.75383 2.07622 11.0365 3.02194 11.9822L3.9936 12.9539L11.1253 20.0856L18.2569 12.9539L19.2286 11.9822C19.697 11.514 20.0686 10.9581 20.3221 10.3463C20.5756 9.73446 20.7061 9.07867 20.7061 8.41639C20.7061 7.75411 20.5756 7.09832 20.3221 6.48648C20.0686 5.87464 19.697 5.31875 19.2286 4.85055V4.85055Z" stroke={color ? color : "#DB7500"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </G>
            <Defs>
                <ClipPath id="clip0_1288_24863">
                    <Rect width="22" height="22" fill="white" transform="translate(0.125 0.625)" />
                </ClipPath>
            </Defs>
        </Svg>

    )
}