import { useContext, useEffect } from "react";
import { createStackNavigator } from "@react-navigation/stack";
// contexts
import { shoppingCart } from "../../context/shoppingCart";
//styles
import {
  styles,
  titleStyle,
  headerComponentStyle,
  headerTitleContainer,
} from "../../styles/pages/navigators/headers";
//screens
import { Profile } from "../screen/profile/profile";
import { UserData } from "../screen/profile/userData";
import { Locations } from "../screen/profile/locations";
import { IBAN_Accounts } from "../screen/profile/IBAN_Accounts";
import { TermsAndConditions } from "../screen/profile/termsAndConditions";
import { MapScreen } from "../screen/map/mapScreen";
import { MapBoxScreen } from "../screen/map/mapBoxScreen";
import { DeliveryAddress } from "../screen/payment/deliveryAddress";
import { OnlinePayment } from "../screen/payment/onlinePayment";
import { Plan } from "../screen/orderDefinition/planList/planList";
import { EditPlan } from "../screen/orderDefinition/planList/editPlan";
import { NutritionalPrescription } from "../screen/plans/nutritionalPrescription";
import { NutritionalPlans } from "../screen/profile/nutritionalPlan/nutritionalPlans";
//navigation
import { CreateNewSubscriptionNavigator } from "./newSubscriptionNavigator";
import { SubscriptionNavigator } from "./Subscription-Navigator";
//components
import { GoBack } from "../widgets/goBack";
//hooks
import useIsWeb from "../../hooks/useIsWeb";

export const ProfileNavigator = ({ navigation }) => {
  const Stack = createStackNavigator();
  const bottomTabNavigation = navigation;
  const { isComputer } = useIsWeb();

  return (
    <Stack.Navigator initialRouteName={"profile"}>
      <Stack.Screen
        name={"profile"}
        options={({ navigation }) => ({
          headerTitle: "Mi cuenta",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation }) => (
          <Profile
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"userData"}
        options={({ navigation }) => ({
          headerTitle: "Mis datos",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation }) => (
          <UserData
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"locations"}
        options={({ navigation }) => ({
          headerTitle: "Direcciones de Entrega",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Locations
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"delivery_address"}
        options={({ navigation }) => ({
          headerTitle: "Dirección de Entrega",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <DeliveryAddress
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"mapScreen"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <MapScreen
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"newSubscription"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => null,
          headerShown: false,
        })}
      >
        {({ navigation, route }) => (
          <SubscriptionNavigator
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
            initialRouteName={"subscriptions"}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"IBAN_Accounts"}
        options={({ navigation }) => ({
          headerTitle: "Cuentas IBAN",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <IBAN_Accounts
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"online_payment"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <OnlinePayment
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"nutritionalPlan"}
        options={({ navigation }) => ({
          headerTitle: "Plan Nutricional",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <NutritionalPlans
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"planType"}
        options={({ navigation }) => ({
          headerTitle: "Tipo de Plan",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Plan
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"nutritionalPrescription"}
        options={({ navigation, route }) => ({
          headerTitle: "Prescripción Nutricional",
          headerLeft: () => (
            <GoBack
              navigator={navigation}
              icon={"arrowBack"}
              width={48}
              alternative={null}
            />
          ),
          headerTitleStyle: [
            titleStyle(isComputer ? 25 : 20),
            { marginLeft: "5%" },
          ],
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <NutritionalPrescription
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"editPlan"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} text={"Volver"} icon={"leftArrow"} />
          ),
          headerStyle: styles.headerWithBorder,
        })}
      >
        {({ navigation, route }) => (
          <EditPlan
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"termsAndConditions"}
        options={({ navigation }) => ({
          headerTitle: "Términos y Condiciones",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <TermsAndConditions
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
};
