export const monthsArray = {
  "01": "Enero",
  "02": "Febrero",
  "03": "Marzo",
  "04": "Abril",
  "05": "Mayo",
  "06": "Junio",
  "07": "Julio",
  "08": "Agosto",
  "09": "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
};

export const monthFormat = {
  Enero: "Jan",
  Febrero: "Feb",
  Marzo: "Mar",
  Abril: "Apr",
  Mayo: "May",
  Junio: "Jun",
  Julio: "Jul",
  Agosto: "Aug",
  Septiembre: "Sep",
  Octubre: "Oct",
  Noviembre: "Nov",
  Diciembre: "Dec",
};

export const Month = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const yearSelector = (forBirthDate) => {
  const decrement = forBirthDate ? 80 : 5;
  const array = [];
  const year_ = new Date();

  for (
    let year = year_.getFullYear();
    year > year_.getFullYear() - decrement;
    year--
  ) {
    array.push(year.toString());
  }
  return array;
};

export const yearPicker = () => {
  const array = [];
  const year_ = new Date();

  for (let year = year_.getFullYear(); year < year_.getFullYear() + 4; year++) {
    array.push(year.toString());
  }
  return array;
};

export const daySelector = () => {
  const array = [];

  for (let day = 1; day <= 31; day++) {
    array.push(`${day < 10 ? "0" : ""}${day}`);
  }
  return array;
};

export const getDisabledDates = () => {
  const disabledDates = {};

  // Disable the next 7 days from today
  const currentDate = new Date();
  for (let i = 0; i < 7; i++) {
    const date = new Date();
    date.setDate(currentDate.getDate() + i);
    const dateString = date.toISOString().split("T")[0];
    disabledDates[dateString] = { disabled: true, disableTouchEvent: true };
  }

  // Disable all Tuesdays, Fridays, Saturdays ans Sundays
  const year = currentDate.getFullYear();
  for (let month = 0; month < 12; month++) {
    for (let day = 1; day <= 31; day++) {
      const date = new Date(year, month, day);
      if (date.getFullYear() === year && date.getMonth() === month) {
        const weekday = date.getDay();
        if ([4, 5, 6, 0].includes(weekday)) {
          const dateString = date.toISOString().split("T")[0];
          disabledDates[dateString] = {
            disabled: true,
            disableTouchEvent: true,
          };
        }
      } else {
        break;
      }
    }
  }

  return disabledDates;
};

export const getFirstAvailableDate = (disabledDates) => {
  const currentDate = new Date();
  let day = currentDate.getDate();

  while (
    disabledDates[
      new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
        .toISOString()
        .split("T")[0]
    ]
  ) {
    day++;
  }

  const firstAvailableDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    day
  );

  return firstAvailableDate;
};
