import { View, Text, ScrollView } from "react-native";
//Styles
import {
  styles,
  paragraph,
  sectionContainerWeb,
} from "../../../styles/pages/generalStyles";

export const TermsAndConditions = ({ navigation, bottomTabNavigation }) => {
  return (
    <View style={styles.screen}>
      <ScrollView
        style={[styles.scrollScreen, { paddingTop: 20 }]}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={sectionContainerWeb(800, null, "100%")}
      >
        <Text style={paragraph("100%", 17, 10)}>
          Baraná es la alternativa rápida que lo ayudará a comer de manera
          balanceada con platillos preparados con ingredientes naturales y
          frescos para su bienestar.
        </Text>

        <Text style={paragraph("100%", 17, 10)}>
          Por favor lea detenidamente los términos y condiciones que aplican al
          utilizar nuestra plataforma de pedidos por medio de la aplicación
          móvil
          <Text style={[styles.timeText, { fontSize: 16 }]}>
            “El app para comer bien”
          </Text>
          o el sitio web
          <Text style={[styles.timeText, { fontSize: 16 }]}>
            www.baranacr.com.
          </Text>
          Tome en cuenta que cuando usted (en adelante conocido como el cliente)
          utiliza o realiza compras a través de nuestra plataforma acepta los
          términos y las condiciones descritos a continuación.
        </Text>

        <Text style={{ ...styles.cardText, marginTop: 20 }}>
          Órdenes de Compra
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          1. Baraná se reserva el derecho a rechazar cualquier orden cuando esta
          no cumpla con los elementos solicitados para enviar los paquetes:
          pago, dirección, día de entrega y datos personales para la entrega.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          2. Baraná se empeña por tener en sus bodegas todos los productos
          utilizados en sus recetas, sin embargo, en caso que en el mercado
          nacional no se encuentre disponible algún ingrediente para la
          elaboración de un platillo, la empresa contactará al cliente para
          ofrecer: un cambio en la fecha de entrega, una posible sustitución, u
          otra alternativa viable.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          3. Si el cliente hace un pedido fuera de la fecha y hora de corte de
          pedidos, hacemos la salvedad que alguno de los platos principales,
          harinas o vegetales podría no estar disponible. En caso de que esto
          suceda el platillo sería sustituido por otro según disponibilidad,
          respetando sus indicaciones o preferencias iniciales.
        </Text>

        <Text style={{ ...styles.cardText, marginTop: 20 }}>Compra en línea</Text>
        <Text style={paragraph("100%", 17, 10)}>
          1. Baraná le ofrece una plataforma virtual para realizar sus pedidos.
          Las órdenes de compra ingresadas antes del martes a media noche serán
          distribuidas el fin de semana siguiente, el día de entrega
          seleccionado durante la compra.{" "}
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          2. Las imágenes empleadas para ejemplificar los paquetes o productos
          son con fines estrictamente ilustrativos para exhibir las
          características de los productos. El cliente entiende y acepta que las
          imágenes no constituyen una representación exacta de la realidad.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          3. Para ordenar alguno de los paquetes el cliente puede seleccionar
          los siguientes métodos de pago:
        </Text>
        <Text style={[paragraph("94%", 17), { marginLeft: 20 }]}>
          - Transferencia bancaria.
        </Text>
        {/* <Text style={[paragraph('94%', 17), { marginLeft: 20 }]}>
            - Efectivo o con tarjeta de crédito o débito, contra entrega
            </Text> */}
        <Text style={[paragraph("94%", 17), { marginLeft: 20 }]}>
          - Pago en línea por medio de cuenta IBAN
        </Text>
        {/* <Text style={[paragraph("94%", 17), { marginLeft: 20 }]}>
              - Pago al momento de entrega con datáfono o efectivo en casos
              especiales en los que no se pueda utilizar ninguno de los métodos
              de pago citados anteriormente
            </Text> */}
        <Text style={paragraph("100%", 17, 10)}>
          4. En caso de seleccionar transferencia bancaria el cliente recibirá
          un mensaje con la información necesaria para realizar el pago. Los
          viernes por la tarde Baraná verificará los depósitos bancarios. Los
          pedidos que no hayan sido pagados no serán tramitados.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          5. Los correos de confirmación se envían automáticamente, si la
          información detallada en el mismo es errónea, es obligación del
          cliente comunicárselo a Baraná para poder tramitar correctamente su
          orden.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          6. Baraná se esmera por dar un servicio de calidad a todos sus
          clientes, sin embargo, en caso de ser necesario, se reserva el derecho
          a rechazar pedidos debido a disponibilidad de productos, dirección de
          entrega, entre otras razones.
        </Text>

        <Text style={{ ...styles.cardText, marginTop: 20 }}>Precios</Text>
        <Text style={paragraph("100%", 17, 10)}>
          1. Todos los precios publicados en nuestra plataforma incluyen el
          impuesto de venta.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          2. Los pedidos que indiquen una dirección fuera de las rutas de
          entrega preestablecidas por Baraná serán valorados para su entrega, y
          en caso de ser aceptados se les aplicará un cobro según la zona de
          entrega.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          3. Al confirmar la orden se indicará el precio total de su compra. El
          cliente deberá aceptar el precio directamente para poder tramitar su
          pedido.
        </Text>

        <Text style={{ ...styles.cardText, marginTop: 20 }}>Entrega del producto</Text>
        <Text style={paragraph("100%", 17, 10)}>
          1. Los clientes que hayan indicado como forma de pago efectivo o con
          tarjeta de crédito o débito contra entrega, deberán cancelar el monto
          total de su paquete en el momento de la entrega.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          2. El cliente se compromete a recibir los paquetes el día y en el
          sitio indicados en el formulario de compra. En caso de que deba
          ausentarse, deberá avisar a mas tardar el viernes por la tarde por
          medio de WhatsApp el cambio de horario requerido. Baraná valorará si
          es posible hacer el cambio, sin embargo, de no ser posible el cliente
          deberá retirar el pedido en el local o asumir un costo adicional por
          transporte para realizar el envío por medio de una plataforma de
          entregas a domicilio externa. Esto aplica también en los casos en los
          que el cliente no se encuentre al momento de realizar la entrega. En
          ambos casos no se hará devolución de dinero.{" "}
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          3. El servicio de entrega a domicilio se brindará durante los días
          feriados que coincidan con un día de entrega.
        </Text>

        <Text style={{ ...styles.cardText, marginTop: 20 }}>Devolución y cambios</Text>
        <Text style={paragraph("100%", 17, 10)}>
          1. No se realizará devoluciones de dinero, ni se realizarán cambios en
          el menú asignado a cada uno de los días de la semana.{" "}
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          2. El cliente no podrá cancelar su pedido después del miércoles al
          medio día, de hacerlo, no se le reembolsará el precio pagado.{" "}
        </Text>

        <Text style={{ ...styles.cardText, marginTop: 20 }}>Derechos de propiedad intelectual</Text>
        <Text style={paragraph("100%", 17, 10)}>
          1. La información contenida en www.baranacr.com o nuestra aplicación
          móvil está protegida por los derechos de propiedad intelectual. Todo
          el material publicado en ella le pertenece a Baraná y no podrá ser
          copiado, reproducido, editado, descargado, publicado, difundido o
          puesto a disposición de terceros, sin la autorización previa y por
          escrito de Baraná. Tampoco se conceden derechos para utilizar la marca
          Baraná.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          2. Cualquier violación a las leyes de propiedad intelectual y/o a los
          impedimentos detallados aquí harán que su infractor esté sujeto a las
          sanciones legales establecidas por ley, así como a la indemnización de
          daños y perjuicios ocasionados a la empresa.
        </Text>

        <Text style={{ ...styles.cardText, marginTop: 20 }}>Modificaciones</Text>
        <Text style={paragraph("100%", 17, 10)}>
          1. Baraná se reserva el derecho de modificar los términos y las
          condiciones detallados en esta página web cuando lo estime
          convenientes, sin previo aviso ni el consentimiento previo por parte
          de los clientes. Es responsabilidad de cada cliente revisar las
          modificaciones que se realicen. Para ello, se sugiere que el cliente
          lea atentamente los términos y condiciones cuando ingre al sitio web
          www.baranacr.com  o utilice nuestra app.
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          2. Baraná podrá dejar de ofrecer o rechazar el acceso al servicio o a
          cualquier parte de este, en cualquier momento y por cualquier razón.{" "}
        </Text>
        <Text style={paragraph("100%", 17, 10)}>
          3. Si alguna de las cláusulas de los términos y condiciones detallados
          anteriormente se declara nula, las demás cláusulas permanecerán
          vigentes y se interpretarán que es voluntad expresa de las partes
          someterse a ellas.
        </Text>

        <Text style={paragraph("100%", 17, 10)}>
          Si tiene alguna consulta o comentario acerca de los términos y
          condiciones estipulados aquí comuníquese con nosotros a través del
          correo electrónico:{" "}
          <Text style={[styles.timeText, { fontSize: 16 }]}>
            info@baranacr.com
          </Text>
        </Text>
        <Text style={{ marginBottom: 50 }}></Text>
      </ScrollView>
    </View>
  );
};
