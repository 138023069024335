import { createContext } from 'react'

export const mainContext = createContext({
  darkTheme: false,
  setDarkTheme: () => {},
  userProfile: undefined,
  setUserProfile: () => {},
  userAaddress: [],
  setUserAaddress: () => [],
  IBANaccounts: [],
  setIBANAccount: () => [],
  plansAccordingBarana: [],
  setPlansAccordingBarana: () => [],
  cuttingDay: {},
  setCuttingDay: () => {},
  orders: [],
  setOrders: () => [],
  garrinsonsClient: {},
  setGarrinsonsClient: () => {},
  userData: {
    email: undefined,
    access_token: undefined,
    user_id: undefined,
    referralCode: undefined,
  },
  setUserData: () => {},
  isLoading: false,
  setIsLoading: () => {},
  selectedOption: "combos",
  setSelectedOption: () => {},
  webStarted: false,
  setWebStarted: () => {},
});

