import { Svg, G, Defs, Path, ClipPath, Rect } from 'react-native-svg';

export const Fish = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <G clipPath="url(#clip0_94_4288)">
                <Path fillRule="evenodd" clipRule="evenodd" d="M39.225 25.1101C28.4652 25.1101 15.9709 28.4705 9.19238 37.2374L15.0941 45.6695C15.1651 45.2276 53.0208 45.4693 53.0208 45.4693C56.7088 43.7257 58.7262 40.4016 60.7437 37.077C54.6918 31.0938 47.9669 25.1101 39.225 25.1101Z" fill="#F4CA49" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M10.1177 37.7296C16.898 29.3117 27.749 26.155 38.2719 25.8665C44.6308 25.6924 49.9407 28.6215 54.7081 32.5192C56.2611 33.7882 60.3541 36.2896 59.0746 38.3544C57.408 41.0435 55.4862 43.3235 52.6397 44.8237C51.778 45.277 52.5423 46.5686 53.4026 46.1148C57.1125 44.1609 59.281 40.9317 61.3972 37.4547C61.572 37.1675 61.5175 36.7831 61.2792 36.5485C57.4464 32.7799 53.4853 29.1641 48.6611 26.6729C44.1588 24.3478 39.3637 24.1715 34.4118 24.5842C24.8274 25.3835 15.1599 29.0837 9.04729 36.6722C8.44617 37.4185 9.50941 38.4843 10.1177 37.7296Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M16.1642 44.6115C13.8786 42.3637 11.4885 40.0338 10.3117 37.0022C9.96534 36.1109 8.50255 36.4976 8.8525 37.3994C10.1329 40.6975 12.6031 43.2183 15.0938 45.6693C15.7861 46.3502 16.8565 45.2924 16.1642 44.6115Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M71.6746 29.6608C72.1081 33.6 71.7654 41.1041 72.0331 45.5395C69.7555 44.6951 61.0869 36.5799 61.0869 36.5799C61.0869 36.5799 69.9656 30.5052 71.6746 29.6608Z" fill="#F4CA49" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M70.9182 29.6608C71.4188 34.9353 71.0063 40.2535 71.2767 45.5395C71.6566 45.3243 72.036 45.1091 72.415 44.894C68.3757 43.1194 64.8065 39.026 61.6226 36.051C61.5712 36.4425 61.5202 36.834 61.4688 37.2255C64.9187 34.8673 68.3506 32.2563 72.0561 30.3064C72.9178 29.853 72.1535 28.5619 71.2932 29.0148C67.5868 30.9647 64.1549 33.5757 60.7059 35.9343C60.3176 36.199 60.1763 36.7575 60.5522 37.1088C63.8286 40.1691 67.4809 44.354 71.6521 46.1855C72.1366 46.3985 72.8213 46.1608 72.79 45.5395C72.5191 40.2535 72.9316 34.9353 72.4315 29.6608C72.3408 28.709 70.8271 28.7002 70.9182 29.6608Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M27.2588 26.3782C27.5439 24.6885 36.3176 18.5612 38.297 17.6842C40.8986 16.5313 41.1681 20.2774 41.1681 24.7818C37.8519 24.8595 31.9926 24.525 27.2588 26.3782Z" fill="#F4CA49" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M27.9878 26.5768C28.306 25.5517 29.7089 24.6331 30.4892 23.9734C31.8707 22.8047 33.3482 21.7468 34.8334 20.7124C35.9167 19.9586 37.0246 19.2318 38.1764 18.5831C40.7587 17.1294 40.4052 23.718 40.4106 24.7815C40.6631 24.5323 40.9151 24.2831 41.1672 24.0335C36.3797 24.1117 31.624 24.0118 27.0573 25.6564C26.1478 25.9838 26.5402 27.4301 27.4591 27.0991C31.9011 25.4991 36.5151 25.6052 41.1672 25.5296C41.5784 25.5225 41.9257 25.1947 41.9239 24.7815C41.9064 21.2387 41.8756 14.651 36.7476 17.6755C34.5075 18.9963 32.3994 20.5631 30.3547 22.1587C29.0416 23.1824 27.0501 24.5023 26.5286 26.1787C26.2407 27.1035 27.7022 27.4959 27.9878 26.5768Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M22.2121 29.229C24.5772 29.704 25.2065 32.9707 25.5721 34.937C25.8755 36.5675 26.4597 40.3622 25.0179 41.6396C24.2903 42.2851 25.3642 43.3394 26.0883 42.6974C27.922 41.0718 27.442 37.1675 27.1162 35.0833C26.7019 32.4334 25.7647 28.4195 22.6138 27.7859C21.6614 27.595 21.2556 29.0368 22.2121 29.229Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M16.1527 34.4496C14.8494 36.3655 14.9768 39.5354 17.4206 40.5044C19.0818 41.1637 20.6648 40.4567 21.6454 39.0609C22.4655 37.8935 22.7216 36.1503 21.9256 34.9127C20.7882 33.1448 18.2912 33.5495 16.6049 34.1058C15.6843 34.4094 16.0789 35.8543 17.0067 35.5485C18.0584 35.2016 19.7022 34.6878 20.5947 35.636C21.1243 36.1994 20.8919 37.2444 20.6023 37.8532C20.2353 38.6239 19.5524 39.2791 18.6514 39.2796C16.4458 39.2809 16.5996 36.4676 17.4595 35.2047C18.0052 34.4014 16.6934 33.6538 16.1527 34.4496Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M38.4071 30.3819C42.244 29.5203 45.8704 32.2992 48.4487 34.7935C49.1468 35.4682 50.2172 34.4112 49.5191 33.7356C46.5077 30.8225 42.4299 27.9455 38.0049 28.9392C37.0534 29.1531 37.4574 30.5953 38.4071 30.3819Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M17.6826 56.5179C19.6777 58.9296 23.5155 62.5281 23.5155 62.5281C23.5155 62.5281 47.0189 63.1856 57.663 62.5281C58.1054 61.4318 61.7291 56.4348 61.7291 56.4348" fill="#81A18E" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M17.6583 57.7954C18.8592 59.1855 21.6408 63.2255 23.7347 63.282C26.3568 63.3532 28.9789 63.4053 31.602 63.4513C40.2759 63.6033 48.9973 63.78 57.6627 63.2763C57.9157 63.2617 58.1986 63.1481 58.3161 62.906C59.0732 61.3405 60.8256 59.0189 61.8571 57.6209C62.4332 56.8401 61.1188 56.0947 60.5503 56.8657C59.707 58.0088 58.2348 60.0723 57.479 61.2742C56.7269 62.4703 53.442 61.9626 52.0887 61.9949C47.0634 62.1155 42.0332 62.0837 37.0066 62.0315C33.1273 61.9913 29.2489 61.9198 25.3704 61.8274C24.9508 61.8177 24.5316 61.8071 24.1119 61.7965C23.4849 61.7801 23.5854 61.5976 23.955 61.9175C22.0954 60.3109 20.3322 58.5943 18.7287 56.7381C18.0936 56.0032 17.0281 57.0668 17.6583 57.7954Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M0.789062 45.3593L78.7274 45.8674L72.297 56.6095C63.6887 56.537 10.1209 56.6254 10.1209 56.6254C10.1209 56.6254 2.48202 47.8385 0.789062 45.3593Z" fill="#C5E2CF" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M0.789043 46.1071C26.7685 46.2764 52.7479 46.4456 78.7274 46.6148C78.5093 46.2397 78.2921 45.865 78.074 45.4894C76.3296 48.403 74.5861 51.3167 72.8418 54.2303C72.5066 54.7901 72.1714 55.3504 71.8357 55.9107C71.6029 56.3 72.3752 55.8608 71.29 55.8546C69.9113 55.8466 68.5325 55.844 67.1537 55.8413C57.9131 55.8219 48.6719 55.8329 39.4304 55.8409C30.8735 55.8479 22.3162 55.8586 13.7584 55.8718C12.7747 55.8727 11.7902 55.8745 10.806 55.8762C9.65027 55.8776 10.8664 56.3402 10.1973 55.5669C7.21496 52.1213 4.06681 48.7053 1.44245 44.9813C0.889155 44.1961 -0.423024 44.9428 0.135635 45.7364C2.7658 49.4684 5.90413 52.8897 8.88647 56.3459C9.99485 57.6309 10.7752 57.3719 12.44 57.3693C21.1944 57.356 29.9497 57.345 38.705 57.3375C48.4838 57.3286 58.263 57.3167 68.0413 57.3388C70.5021 57.3445 72.3417 58.0029 73.6623 55.7976C75.568 52.6131 77.4742 49.429 79.3808 46.2445C79.6749 45.7528 79.2972 45.1231 78.7274 45.1191C52.7479 44.9499 26.7685 44.7807 0.789043 44.611C-0.186599 44.6048 -0.186152 46.1005 0.789043 46.1071Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M14.9168 7.3063C13.0236 5.16018 9.92191 8.94516 9.84057 10.8544C9.76101 12.7257 10.2446 14.3531 10.9954 16.0454C11.6319 17.4784 14.1655 22.2434 12.0824 23.4718C11.2453 23.9649 12.0055 25.2587 12.8457 24.7633C14.6495 23.7002 14.4546 21.7605 14.0591 19.955C13.7472 18.5335 13.1206 17.1726 12.5454 15.8404C12.3112 15.2982 12.0613 14.7636 11.8397 14.2166C11.5559 13.4146 11.382 12.5887 11.3163 11.7377C11.2913 9.80944 12.1351 8.68446 13.8464 8.36367C14.4895 9.0923 15.5545 8.02962 14.9168 7.3063Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M25.0697 7.30647C23.1757 5.15991 20.0753 8.94533 19.994 10.8546C19.914 12.7263 20.3971 14.3533 21.1484 16.0456C21.7848 17.4785 24.3185 22.2436 22.2353 23.4719C21.3982 23.9651 22.1585 25.2588 22.9982 24.7635C24.8025 23.7004 24.6072 21.7606 24.2121 19.9552C23.9001 18.5337 23.2735 17.1728 22.6983 15.8406C22.4637 15.2984 22.2143 14.7638 21.9922 14.2167C21.7088 13.4148 21.5345 12.5889 21.4697 11.7379C21.4438 9.80917 22.2876 8.68463 23.9993 8.36384C24.642 9.09247 25.7075 8.02979 25.0697 7.30647Z" fill="#1A1919" />
                <Path fillRule="evenodd" clipRule="evenodd" d="M20.2205 7.30651C18.3264 5.15995 15.2247 8.94494 15.1443 10.8547C15.0647 12.7259 15.5479 14.3533 16.2991 16.0456C16.9351 17.4786 19.4692 22.2441 17.3861 23.472C16.549 23.9651 17.3092 25.2589 18.1494 24.7636C19.9532 23.7004 19.7579 21.7607 19.3619 19.9552C19.0504 18.5338 18.4238 17.1728 17.8486 15.8406C17.6145 15.2985 17.3651 14.7638 17.1429 14.2168C16.8596 13.4148 16.6853 12.589 16.6196 11.7379C16.5941 9.80877 17.4375 8.68424 19.1501 8.36389C19.7928 9.09252 20.8583 8.02984 20.2205 7.30651Z" fill="#1A1919" />
            </G>
            <Defs>
                <ClipPath id="clip0_94_4288">
                    <Rect width="80" height="80" fill="white" />
                </ClipPath>
            </Defs>
        </Svg>

    )
}