import { DefaultTheme } from 'react-native-paper'

export const darkTheme = {
  ...DefaultTheme,
  dark: true,
  roundness: 4,
  colors: {
    ...DefaultTheme.colors,
    // default colors
    background: '#FFFBF4',
    surface: '#FFFFFF',
    onSurface: '#000000',
    error: '#DC6E6E',
    primary: '#d1d1d1',
    primary050: '#FFFBF4',
    primary100: '#E0E0E0',
    secondary: '#FF941A',
  },
}
