import { View, Text } from "react-native";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//styles
import {
  styles,
  title,
  titleMargin,
  paragraph,
  actionText,
  sectionContainerWeb,
  flexContainer,
} from "../../../styles/pages/generalStyles";
import {
  homeStyles,
  sections,
  centerAlignedRow,
} from "../../../styles/pages/home/homePage";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

export const HowItWorks = ({ background }) => {
  const { isComputer } = useIsWeb();

  return (
    <View style={sections(background == "dark", 390, 36, 20)}>
      <View style={sectionContainerWeb(1000, null, "100%")}>
        <Text
          style={[
            title("100%", isComputer ? "center" : "left", null, isComputer),
            titleMargin(isComputer),
          ]}
        >
          ¿Cómo funciona?
        </Text>
        <View
          style={flexContainer("row", "center", null, "wrap", null, null, 20)}
        >
          <View
            style={{
              width: "100%",
              maxWidth: 500,
              justifyContent: "space-between",
              gap: 15,
            }}
          >
            <View style={centerAlignedRow(3.5)}>
              <View style={styles.darkCircle}>
                <Text style={[styles.number]}>1</Text>
              </View>
              <Text style={[homeStyles.helperText, { width: "83%" }]}>
                Baje <Text style={actionText("#DB7500")}>el app</Text> o cree
                una cuenta en línea.
              </Text>
            </View>
            <View style={centerAlignedRow(3.5)}>
              <View style={styles.darkCircle}>
                <Text style={[styles.number]}>2</Text>
              </View>
              <Text style={[homeStyles.helperText, { width: "83%" }]}>
                Escoga su paquete de alimentos o agende su consulta nutricional.
              </Text>
            </View>
            <View style={centerAlignedRow(3.5)}>
              <View style={styles.darkCircle}>
                <Text style={[styles.number]}>3</Text>
              </View>
              <Text style={[homeStyles.helperText, { width: "83%" }]}>
                Reciba su pedido sábado o lunes.
              </Text>
            </View>
            {!isComputer ? (
              <View style={[styles.line, { marginVertical: 18 }]}></View>
            ) : null}
          </View>
          <View style={{ width: "100%", maxWidth: 500 }}>
            <Text style={[paragraph("100%", isComputer && 18)]}>
              El día de cierre de pedidos son los martes y las entregas se
              realizan el sábado o lunes siguiente.
            </Text>
            <Text style={[paragraph("100%", isComputer && 18, 12)]}>
              Si ordena los miércoles recibirá la comida hasta la siguiente
              semana.
            </Text>
            <Text style={[paragraph("100%", isComputer && 18, 12)]}>
              Este sistema de entrega tiene como objetivo evitar el desperdicio
              de comida y utilizar insumos frescos para la preparación de los
              alimentos.
            </Text>
            <Text style={[paragraph("100%", isComputer && 18, 12)]}>
              ¡Suscríbase y despreocúpese de hacer su pedido!
            </Text>
          </View>
        </View>
        <View>
          <Text
            style={[paragraph("100%", isComputer ? 20 : 18, 50, "center", 700)]}
          >
            Para una experiencia óptima le recomendamos descargar nuestra app:
          </Text>
          <View
            style={[
              flexContainer(
                "row",
                "center",
                "center",
                null,
                null,
                isComputer ? 50 : 20
              ),
              { marginTop: 15 },
            ]}
          >
            <BaranaIcons draw={{ width: 155, height: 50 }} name={"GooglePay"} />
            <BaranaIcons draw={{ width: 135, height: 50 }} name={"AppStore"} />
          </View>
        </View>
      </View>
    </View>
  );
};
