import { View, Text, ScrollView, Dimensions, FlatList } from "react-native";
//Styles
import {
  styles,
  titleMargin,
  flexContainer,
} from "../../../../styles/pages/generalStyles";
//components
import { SupermarketItemCard } from "../../../widgets/supermarketItemCard";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";
import useCartStore from "../../shoppingCart/useCartStore";
//utils
import { getItemQuantity, getItemType } from "../../../utils/shoppingCartUtils";

const { width } = Dimensions.get("window");
export const MeatsList = ({
  updatingCart,
  meatsList,
  handleAddToCart,
  handleDeleteToCart,
}) => {
  const { isComputer } = useIsWeb();
  const { getProductById } = useCartStore();

  return (
    <View>
      <Text style={[styles.cardText, titleMargin(isComputer)]}>
        Carnes - Platos Fuertes
      </Text>
      <View
        style={[
          { width: "100%" },
          flexContainer("row", null, "center", "wrap", null, 15),
        ]}
      >
        {meatsList?.map((item, index) => {
          const itemType = getItemType(item);
          item.quantity = getItemQuantity(item?.id, itemType, getProductById);

          return (
            <View
              style={isComputer ? { width: 335 } : undefined}
              key={`${item.id}-meats`}
            >
              <SupermarketItemCard
                item={item}
                updatingCart={updatingCart}
                uniqueKey={`${item.id}-meats`}
                addToCard={() => handleAddToCart(itemType, item)}
                deleteToCard={() => handleDeleteToCart(itemType, item.id)}
                variation={
                  !item?.plates ? item.type_variation : item.cant_people
                }
              />
            </View>
          );
        })}
      </View>
      {/* <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal={true}
        style={{ width: "100%" }}
        contentContainerStyle={{ width: "100%" }}
      >
        <FlatList
          bounces={false}
          style={{ width: isComputer ? "100%" : width - 40 }}
          contentContainerStyle={
            isComputer
              ? flexContainer("row", "space-between", "center", "wrap")
              : undefined
          }
          data={meatsList}
          keyExtractor={(item, index) => index}
          renderItem={({ item, index }) => {
            const itemType = getItemType(item);
            item.quantity = getItemQuantity(item?.id, itemType, getProductById);

            return (
              <View
                style={isComputer ? { width: 335 } : undefined}
                key={`${item.id}-meats`}
              >
                <SupermarketItemCard
                  item={item}
                  updatingCart={updatingCart}
                  uniqueKey={`${item.id}-meats`}
                  addToCard={() => handleAddToCart(itemType, item)}
                  deleteToCard={() => handleDeleteToCart(itemType, item.id)}
                  variation={
                    !item?.plates ? item.type_variation : item.cant_people
                  }
                />
              </View>
            );
          }}
        />
      </ScrollView> */}
    </View>
  );
};
