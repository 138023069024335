import { Svg, Path } from 'react-native-svg';

export const Note = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M19 21.3418L12 16.3418L5 21.3418V5.3418C5 4.81136 5.21071 4.30266 5.58579 3.92758C5.96086 3.55251 6.46957 3.3418 7 3.3418H17C17.5304 3.3418 18.0391 3.55251 18.4142 3.92758C18.7893 4.30266 19 4.81136 19 5.3418V21.3418Z" stroke="#2F322F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>

    )
}