import { useState, useEffect, useContext, useCallback } from "react";
import client from "../controllers/httpClient";

export const useAddressSelectFor = () => {
  const [provinces, setProvinces] = useState({});
  const [locations, setLocations] = useState([]);
  const [zones, setZones] = useState([]);

  //
  useEffect(() => {
    getProvinces();
  }, []);

  const getProvinces = async () => {
    try {
      const resp = await client.get("address/list_address/");
      setProvinces(resp.data.provincia);
      setZones(resp.data.zona);
      setLocations(resp.data.localidad);
    } catch (error) {
      // console.log(error.response.data);
    }
  };

  const filterZones = (province) => {
    let newArray = zones.filter((item) => item.from === province);
    newArray = newArray.map((item) => item.name);
    return Array.from(new Set(newArray));
  };

  const filterLocality = (zone) => {
    let newArray = locations.filter((item) => item.from === zone);
    newArray = newArray.map((item) => item.name);
    return Array.from(new Set(newArray));
    //console.log(newArray)
  };

  return {
    provinces,
    zones,
    locations,
    filterZones,
    filterLocality,
  };
};
