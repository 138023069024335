import React from "react";
import { View, Text, ScrollView, ImageBackground } from "react-native";
//Styles
import {
  styles,
  paragraph,
  sectionContainerWeb,
} from "../../../../styles/pages/generalStyles";

export const Questions = ({ navigation, route }) => {
  return (
    <View style={[styles.screen, { alignItems: "center" }]}>
      <ScrollView
        style={[styles.scrollScreen, { paddingHorizontal: 0, width: "100%" }]}
      >
        <ImageBackground
          source={require("../../../../assets/illustrations/preguntas-banner.png")}
          style={{
            width: "100%",
            height: 300,
          }}
          resizeMode="cover"
        />
        <View style={[sectionContainerWeb(), styles.homeContainer]}>
          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            1. ¿Cómo es la comida preparada de Baraná?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Hemos planeado cuidadosamente cada receta de nuestro menú. Usted
            podrá disfrutar durante varias semanas de platillos ricos,
            saludables y equilibrados que solucionarán sus necesidades
            alimentarias. Por semana 12 opciones de almuerzos y cenas y más de
            30 opciones de porciones individuales para que tenga comida
            preparada en su casa, lista para consumir.
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Nuestro proceso productivo nos permite aprovechar al máximo las
            cualidades nutricionales de los ingredientes y acentuar los sabores
            gracias a la amplia variedad de especies y hierbas con las que
            sazonamos nuestra saludable comida.
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            2. ¿Cuántas porciones tienen los paquetes de almuerzos y cenas?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Para definir el tamaño de las porciones utilizamos las establecidas
            por el Programa de Intervención Nutricional de Enfermedades Crónicas
            (PINEC) elaborado por la CCSS e INCIENSA. Los paquetes de comida
            preparada que ofrecemos son los siguientes:
          </Text>
          <ul>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                <Text
                  style={[paragraph("100%", 17, 10), { fontWeight: "bold" }]}
                >
                  Bienestar:{" "}
                </Text>
                incluye de 90g a 120g de carne (3 a 4 porciones, según
                preparación), 2 porciones de vegetales y 2 porciones de harinas.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                <Text
                  style={[paragraph("100%", 17, 10), { fontWeight: "bold" }]}
                >
                  {" "}
                  Bajo en harinas:{" "}
                </Text>
                incluye de 90g a 120g de carne (3 a 4 porciones, según
                preparación), 2 porciones de vegetales y 1 porción de harina.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                <Text
                  style={[paragraph("100%", 17, 10), { fontWeight: "bold" }]}
                >
                  Sin Harinas:{" "}
                </Text>
                incluye 150g de carne (5 porciones) y 3 porciones de vegetales.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                <Text
                  style={[paragraph("100%", 17, 10), { fontWeight: "bold" }]}
                >
                  Según prescripción de nutricionista:
                </Text>
                sus platos se servirán según el plan que le haya asignado su
                nutricionista o la nutricionista de Baraná.
              </Text>
            </li>
          </ul>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            3. ¿Puedo conocer la cantidad de calorías y otra información
            nutricional de mi almuerzo o cena?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Los platos que tienen una composición de harinas, proteínas y
            vegetales denominada{" "}
            <Text style={[paragraph("100%", 17, 10), { fontWeight: "bold" }]}>
              “Bienestar o regular”
            </Text>{" "}
            cuentan con su información nutricional. En caso de que soliciteun
            paquete{" "}
            <Text style={[paragraph("100%", 17, 10), { fontWeight: "bold" }]}>
              “Bajo en harinas”
            </Text>
            ,{" "}
            <Text style={[paragraph("100%", 17, 10), { fontWeight: "bold" }]}>
              “Sin harinas”
            </Text>
            , o de acuerdo a su plan nutricional, no se incluye etiquetado
            nutricional.
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Bajo la modalidad de porciones individuales, las recetas cuentan con
            etiquetado nutricional.
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            4. ¿Cómo empaca la comida preparada?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            La comida se entrega en empaque al vacío. El empaque al vacío es un
            método que al eliminar el oxígeno, extiende la vida útil de los
            productos de 3 a 5 veces más que lo usual, previniendo así la
            proliferación de microorganismos y limitando la aparición de
            bacterias y hongos. Lo que le permitirá mantener la comida
            refrigerada e irla consumiendo durante la semana.
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            5. ¿Por qué no utilizan empaques biodegradables?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Los empaques biodegradables que existen para el almacenaje de
            alimentos por varios días están hechos con bioplásticos, los cuales
            tienen plástico en su composición, y al separarse de la materia
            orgánica que los componen, generan microplásticos. Lo que en lugar
            de contribuir a disminuir el uso de plátisco, permite la
            proliferación de microplásticos en el ambiente.
            <Text style={paragraph("100%", 17, 10)}>
              Por lo anterior optamos por utilizar empaques plásticos que son
              reciclables y que pueden ser utilizados para producir bolsas de
              basura reciclables. Para reciclarlos, únicamente debe lavarlos,
              secarlos y entregarlos en su centro de apio respectivo.
            </Text>
            <Text style={paragraph("100%", 17, 10)}>
              Muchas municipalidades están recolectando materiales reciclables
              directamente en las casas de la población, por ejemplo los
              nuestros los entregamos a municipalidad de San José cada 15 días.
              Puede consultar en la suya si brindan el servicio.
            </Text>
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            6. ¿Cómo puedo ordenar?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Para ordenar alguno de nuestros paquetes de comida preparada puede
            hacer ir al siguiente enlace:{" "}
            <a href=">https://baranacr.com/ordene-en-linea/">
              https://baranacr.com/ordene-en-linea/
            </a>
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            7. ¿Cómo puedo pagar mis pedidos?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Puede escoger entre transferencia bancaria, pago contra entrega en
            efectivo, tarjeta de crédito o débito, o
            <Text style={[paragraph("100%", 17, 10), { fontWeight: "bold" }]}>
              Sinpe Móvil (8888-2406).
            </Text>
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            En caso de realizar el pago por transferencia o sinpe móvil por
            favor enviar comprobante, foto o pantallazo vía Telegram o WhatsApp
            al 8888-2406.
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            8. ¿Cuándo recibo mi comida?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Las entregas se realizan de manera semanal los sábados de partir de
            1:00 p.m. 8:00 p.m. y lunes de 8:00 a.m. a 1:30 p.m.
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            9. ¿Cuándo es el corte de pedidos?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Sus paquetes de comida preparada se entregarán la siguiente semana a
            la orden. Por ejemplo, si realizó su orden un miércoles, su pedido
            se entregará el sábado de la siguiente semana. Esto con el objetivo
            de preparar la cantidad de alimentos solicitada sin que hayan
            desperdicios y poder solicitar a nuestros proveedores alimentos
            frescos para su preparación.
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            10. ¿Hay un cobro adicional por la entrega a domicilio?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Sí, varía según la zona de entrega. El monto ronda entre los 2500 y
            3000 colones. La entrega se realiza en vehículo, manteniendo la
            cadena de frío, con el objetivo que la comida tenga un sabor fresco
            y dure para toda la semana.
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            11. ¿Cómo debo conservar mi comida?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Nuestra comida saludable está lista para consumir. Al momento de la
            entrega, los almuerzos o cenas se encuentran fríos, por lo tanto,
            debe mantenerlos en refrigeración hasta el momento de su consumo.
            Cuando desee comer, lo único que debe hacer es abrir y calentar en
            el microondas.
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            12. ¿Ofrecen el servicio de consulta nutricional?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Si, usted puede solicitar una consulta con nuestra doctora en
            nutrición por un costo adicional.{" "}
          </Text>

          <Text style={[paragraph("100%", 22, 20), { fontWeight: "bold" }]}>
            13. ¿Qué rutas de entrega tienen?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            La entrega semanal al vacío se puede realizar en diferentes partes
            de San José y Heredia, asì como Alajuela y Cartago centro. Para las
            entregas en Heredia, Alajuela y Cartago, la compra mínima es de 12
            mil colones. Puede llamarnos a consultar por su zona al 8888-2406.
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            En el caso de las zonas alejadas de la gran área metropolitana, se
            pueden preparar pedidos, pero el usuario se debe encargar de
            coordinar la recolección de los alimentos.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};
