import { useContext } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
//styles
import {
  titleStyle,
  headerComponentStyle,
  headerTitleContainer,
} from "../../styles/pages/navigators/headers";
//screens
import { DeliveryAddress } from "../screen/payment/deliveryAddress";
import { MapScreen } from "../screen/map/mapScreen";
import { MethodOfPayment } from "../screen/payment/methodOfPayment";
import { PaymentScreen } from "../screen/payment/paymentScreen";
import { ThanksForYourPurchase } from "../screen/payment/thanksForYourPurchase";
import { OnlinePayment } from "../screen/payment/onlinePayment";
import { SinpeMovil } from "../screen/payment/SinpeMovil";
import { Summary } from "../screen/subscription/summary";
import { IBAN_Accounts } from "../screen/profile/IBAN_Accounts";
import { Locations } from "../screen/profile/locations";
//components
import { StepProgress } from "../widgets/stepProgress";
import { GoBack } from "../widgets/goBack";
//context
import { shoppingCart } from "../../context/shoppingCart";
import { orderContext } from "../../context/orderingContext";
//hooks
import useIsWeb from "../../hooks/useIsWeb";
import useCartStore from "../screen/shoppingCart/useCartStore";

export const PaymentsNavigator = ({
  route,
  navigation,
  bottomTabNavigation,
}) => {
  const Stack = createStackNavigator();
  const { currentStep, totalSteps } = useContext(orderContext);
  const { cart, setCart } = useCartStore();
  const { isComputer } = useIsWeb();

  return (
    <Stack.Navigator initialRouteName={`deliveryAddress`}>
      <Stack.Screen
        name={"deliveryAddress"}
        options={({ navigation }) => ({
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitle: () => (
            <StepProgress step={currentStep} totalSteps={totalSteps} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <DeliveryAddress
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"mapScreen"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <MapScreen
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"deliveryAddressList"}
        options={({ navigation }) => ({
          headerTitle: "Direcciones de Entrega",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Locations navigation={navigation} route={route} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"methodOfPayment"}
        options={({ navigation }) => ({
          headerTitle: () => (
            <StepProgress step={currentStep} totalSteps={totalSteps} />
          ),
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <MethodOfPayment
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"methodOfPaymentEdit"}
        options={({ navigation }) => ({
          headerTitle: () => (
            <StepProgress step={currentStep} totalSteps={totalSteps} />
          ),
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <MethodOfPayment
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"IBANAccounts"}
        options={({ navigation }) => ({
          headerTitle: "Cuentas IBAN",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <IBAN_Accounts
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"paymentScreen"}
        options={({ navigation }) => ({
          headerTitle: cart.payment_method ? cart.payment_method : "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <PaymentScreen navigation={navigation} route={route} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"onlinePayment"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <OnlinePayment navigation={navigation} route={route} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"SinpeMovil"}
        options={({ navigation }) => ({
          headerTitle: "Sinpe Móvil",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <SinpeMovil
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"thanksForYourPurchase"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => null,
          headerTransparent: true,
        })}
      >
        {({ navigation }) => (
          <ThanksForYourPurchase
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"subscriptionSummary"}
        options={() => ({
          headerTitle: "",
          headerLeft: () => null,
          headerTransparent: true,
        })}
      >
        {({ navigation, route }) => (
          <Summary route={route} navigation={navigation} />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
};