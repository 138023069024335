import { Svg, Path } from 'react-native-svg';

export const BlackClock = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M12 22.1758C17.5228 22.1758 22 17.6986 22 12.1758C22 6.65293 17.5228 2.17578 12 2.17578C6.47715 2.17578 2 6.65293 2 12.1758C2 17.6986 6.47715 22.1758 12 22.1758Z" stroke="#2F322F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M12 6.17578V12.1758L16 14.1758" stroke="#2F322F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    )
}