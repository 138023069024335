import { StyleSheet, Platform, Dimensions } from 'react-native'

const { width } = Dimensions.get("window");
export const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: "white",
  },
  justifyScroll: {
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  scrollScreen: {
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 22,
  },
  container: {
    paddingHorizontal: 20,
    paddingBottom: 30,
    marginTop: 20,
  },
  error: {
    fontSize: 12,
    color: "red",
    textAlign: "left",
    marginBottom: 25,
    marginLeft: 20,
  },
  justifyContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputIcon: {
    position: "absolute",
    right: 0,
    top: width >= 1021 ? 13.5 : 17,
    padding: 20,
  },
  inputsContainer: {
    marginVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
  },
  helpText: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 17,
    lineHeight: 22,
    textAlign: "center",
    color: "#2F322F",
  },
  actionContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 40,
  },
  line: {
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 1,
    width: "100%",
  },
  strongLine: {
    borderBottomColor: "#828282",
    borderBottomWidth: 1,
  },
  navHome: {
    height: 60,
    borderBottomColor: "#E0E0E0",
    borderBottomWidth: 0.56,
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  generalText: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 15,
    lineHeight: 20,
    letterSpacing: 0.01,
    color: "#4F534F",
  },
  vignette: {
    width: 8,
    height: 8,
    margin: 8,
    borderRadius: 20,
    backgroundColor: "#4F534F",
  },
  darkCircle: {
    backgroundColor: "#2F322F",
    width: 32,
    height: 32,
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
  number: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: 19,
    lineHeight: 22,
    letterSpacing: 0.01,
    color: "#FFFFFF",
    textAlign: "center",
    width: "100%",
  },
  cardText: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: 19,
    lineHeight: 22,
    letterSpacing: 0.01,
    color: "#2F322F",
    display: "flex",
    alignItems: "center",
    // textTransform: 'capitalize',
  },
  timeText: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 15,
    lineHeight: 20,
    letterSpacing: 0.01,
    color: "#4F534F",
  },
  homeContainer: {
    paddingHorizontal: 20,
    paddingVertical: 36,
  },
  orangeText: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 17,
    lineHeight: 22,
    color: "#DB7500",
  },
  greenText: {
    fontFamily: "Nunito Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 17,
    lineHeight: 22,
    color: "#808080",
  },
  checkIcon: {
    position: "absolute",
    top: 15,
    right: 15,
    // marginTop: 15,
    // marginRight: 15,
  },
  auth_g: {
    width: "92%",
    height: 52,
    backgroundColor: "#4E75FF",
    borderRadius: 10,
    alignItems: "center",
    marginTop: 25,
    marginBottom: 15,
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 18,
    paddingVertical: 14,
  },
  auth_f: {
    width: "92%",
    height: 52,
    backgroundColor: "#1A338E",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 18,
    paddingVertical: 14,
  },
  socialMediaIcon_google: {
    position: "absolute",
    left: 15,
    top: 8,
    width: 37,
    height: 37,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
  },
  socialMediaIcon_facebook: {
    position: "absolute",
    left: 15,
    top: 8,
    width: 37,
    height: 37,
    alignItems: "center",
    justifyContent: "center",
  },
  mapMarker: {
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: "center",
    // left: "50%",
    // marginLeft: -24,
    // marginTop: -80,
    // position: "absolute",
    // top: "50%",
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  sectionContainerWeb: {
    maxWidth: 800,
    minWidth: "50vw",
    alignSelf: "center",
  },
  listContainerWeb: {
    maxWidth: 800,
    minWidth: "50vw",
  },
  bigSectionContainerWeb: {
    maxWidth: 1000,
    minWidth: "70vw",
    alignSelf: "center",
    alignItems: "center",
  },
});

export const navContainer = (maxWidth) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  columnGap: 30,
  maxWidth,
  width: "100%",
  alignSelf: "center",
});

export const sectionContainerWeb = (maxWidth, minWidth, width, alignItems) => ({
  width: width || null,
  maxWidth: maxWidth || 800,
  minWidth: minWidth || null,
  alignItems: alignItems || null,
  alignSelf: "center",
});

export const webContainer = (height_, marginT, center, alignItems) => ({
  height: Platform.OS == "web" ? height_ || "100vh" : "100%",
  justifyContent: center ? "center" : "flex-start",
  alignItems,
});

export const textTransform = (type) => ({
  textTransform: type,
});

export const title = (
  width,
  textAlign,
  marginT,
  isComputer,
  color = "#2F322F"
) => ({
  fontFamily:
    Platform.OS == "web" ? "Playfair Display" : "Playfair Display Bold",
  fontSize: isComputer ? 38 : 24,
  fontWeight: "600",
  fontStyle: "normal",
  lineHeight: 38,
  letterSpacing: 0.01,
  textAlign: textAlign || "center",
  width: width || "100%",
  marginTop: marginT,
  color,
});

export const titleMargin = (isComputer) => ({
  marginVertical: isComputer ? 30 : 5,
});

export const paragraph = (width, fontSize, marginT, alignT, fontWeight) => ({
  width: width || "100%",
  fontFamily: "Nunito Sans",
  fontStyle: "normal",
  fontWeight: fontWeight || "400",
  fontSize: fontSize || 15,
  lineHeight: 20,
  letterSpacing: 0.01,
  color: "#4F534F",
  marginTop: marginT || 0,
  textAlign: alignT || "left",
});

export const paragraph2 = (width, alignT) => ({
  width: width || "100%",
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: 16,
  lineHeight: 26,
  color: "#6E767C",
  textAlign: alignT || "left",
});

export const actionText = (colors) => ({
  fontFamily: "Nunito Sans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: 17,
  lineHeight: 22,
  letterSpacing: 0.01,
  color: colors || "#2F322F",
});

export const navHomeOptions = (selected, marginL) => ({
  paddingTop: 1,
  height: "100%",
  marginHorizontal: marginL || 0,
  borderBottomColor: "#DB7500",
  borderBottomWidth: selected ? 2.5 : 0,
});

export const navHomeText = (selected) => ({
  fontFamily: "Nunito Sans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: 15,
  lineHeight: 20,
  letterSpacing: 0.01,
  color: selected ? "#DB7500" : "#4F534F",
  paddingBottom: 6,
});

export const orangeBox = (height, width) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  paddingVertical: 20,
  paddingHorizontal: 16,
  width: width || "100%",
  height: height || 170,
  backgroundColor: "#FFF8EF",
  borderColor: "#FFE6C8",
  borderWidth: 1.5,
  borderRadius: 10,
  marginVertical: 10,
});

export const orangeCardBody = (width) => ({
  display: "flex",
  width: width || "100%",
  height: "100%",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const whyBaranaItem = (width) => ({
  width: width || "50%",
  height: 99,
  justifyContent: "center",
  alignItems: "center",
});

export const spaceBetween = (marginTop, width) => ({
  justifyContent: "space-between",
  width: width || "100%",
  marginTop: marginTop || 0,
});

export const buttomOption = (
  minHeight,
  marginV,
  padding,
  color,
  width,
  active,
  marginH
) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: padding || 0,
  width: width || "100%",
  minHeight: minHeight || 52,
  backgroundColor: color || "#FFF8EF",
  borderWidth: !active ? 1.5 : 2,
  borderColor: !active ? "#FFE6C8" : "#FF941A",
  borderRadius: 12,
  marginVertical: marginV || 0,
  justifyContent: "space-between",
  marginHorizontal: marginH || 0,
  // marginBottom: width >= 1024 ? 100 : 0
});

export const buttomOptionOnFocus = () => ({
  borderWidth: 2,
  borderColor: "#FF941A",
});

export const textAlignment = (align, marginV, width) => ({
  textAlign: align || "left",
  marginVertical: marginV || 0,
  width: width || "100%",
});

export const smallNumericTnput = () => ({
  borderWidth: 1,
  borderColor: "#e0e0e0",
  textAlign: "center",
  fontFamily: "Nunito Sans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: 17,
  lineHeight: 22,
  color: "#2F322F",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 8,
  width: 52,
  height: 52,
  backgroundColor: "#FFFFFF",
  borderRadius: 5,
});

export const plateImg = (width) => ({
  width: width || 105,
  height: "100%",
  // borderRadius: 10,
  borderTopLeftRadius: 10,
  borderBottomLeftRadius: 10,
});

export const smallButton = (paddingV) => ({
  paddingLeft: 12,
  paddingVertical: paddingV || 7,
  borderRadius: 50,
});

export const lodingStyle = (paddingV) => ({
  zIndex: 1,
  position: "absolute",
  top: "45%",
  left: "45%",
});

export const container = () => ({
  height: 110,
  flexDirection: "column",
  justifyContent: "space-between",
  width: "92%",
});

export const BaranaIconStyle = (width, height) => ({
  width: width || 95,
  height: height || 37,
});

export const flexContainer = (
  flexDirection,
  justifyContent,
  alignItems,
  flexWrap,
  alignSelf,
  columnGap,
  rowGap
) => ({
  flexDirection,
  justifyContent,
  alignItems,
  flexWrap,
  alignSelf,
  columnGap,
  rowGap,
});

export const webTitle = (textAlign, width, alignItems, justifyContent) => ({
  width: width || "100%",
  textAlign: textAlign || "center",
  justifyContent: alignItems || null,
  alignItems: justifyContent || null,
  fontSize: 38,
  fontFamily: "Playfair Display",
  fontWeight: 600,
});