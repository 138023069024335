import { Platform, StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
    buttom: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 7,
    },
    goBackText: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: 18,
        lineHeight: 24.5,
        letterSpacing: 0.01,
        color: '#2F322F',
        marginLeft: 20,
    },
})

export const goBackContainer = (width, paddingTop) => ({
    paddingLeft: 15,
    paddingTop,
    width: width || 108,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
})
