import { useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Platform,
} from "react-native";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  buttomOption,
  buttomOptionOnFocus,
  webContainer,
  paragraph,
  sectionContainerWeb,
  flexContainer,
} from "../../../styles/pages/generalStyles";
//componentes
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
import { LoadingPage } from "../../widgets/loadingPage";
//utils
import { capitalize } from "../../utils/utils";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

const { width } = Dimensions.get("window");
export const AccordingToBarana = ({
  handleCancelButton,
  handleConfirmButton,
  handleSelectPlan,
  handleGetPlans,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const { isComputer } = useIsWeb();

  useEffect(() => {
    const getPlans = async () => {
      setLoading(true);
      setLoadingButton(true);
      try {
        const plans = await handleGetPlans();
        setOptionsList(plans);
      } finally {
        setLoading(false);
        setLoadingButton(false);
      }
    };

    getPlans();
  }, []);

  return (
    <View style={[styles.screen]}>
      <ScrollView horizontal={false}>
        {loading ? (
          <LoadingPage />
        ) : (
          <View
            style={[
              styles.container,
              webContainer(Platform.OS === "web" ? "50vh" : "100%", "5%"),
              isComputer && sectionContainerWeb(600, null, "100%"),
            ]}
          >
            {optionsList?.length === 0 ? (
              <Text
                style={[
                  paragraph("100%", 17, 10),
                  { marginBottom: 10, marginTop: 10 },
                ]}
              >
                No posee planes según Baraná, escribanos para obtener mas
                información; En la pantalla principal tendrá la opción para
                escribirnos al Whatsapp, Telegram o también puede llamar al
                8888-2406.
              </Text>
            ) : (
              <View style={{ width: isComputer ? "100%" : width - 40 }}>
                {optionsList.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={`${item.id}-accordingToBarana`}
                      onPress={() => {
                        setSelected(item);
                        handleSelectPlan(item);
                      }}
                      style={[
                        buttomOption(65, 5, 16),
                        item.id == selected.id && buttomOptionOnFocus(),
                      ]}
                    >
                      <View style={[{ width: "73%" }]}>
                        <Text style={[styles.cardText]}>
                          {capitalize(item.name)}
                        </Text>
                        {item.note ? (
                          <Text style={[styles.generalText, { marginTop: 8 }]}>
                            {capitalize(item.note)}
                          </Text>
                        ) : null}
                      </View>
                      {item.id == selected.id && (
                        <View style={styles.checkIcon}>
                          <BaranaIcons
                            draw={{ width: 22, height: 22 }}
                            name={"check"}
                          />
                        </View>
                      )}
                    </TouchableOpacity>
                  );
                })}
              </View>
            )}
          </View>
        )}
      </ScrollView>
      <LowerOptionsContainer style={{ height: 120 }}>
        <View
          style={[
            flexContainer(
              "column",
              "space-between",
              "center",
              null,
              null,
              null,
              10
            ),
          ]}
        >
          <GeneralButton
            text="Confirmar"
            handleOnPress={() => handleConfirmButton(setLoadingButton)}
            width={isComputer ? 350 : "100%"}
            disabled={loadingButton}
            loading={loadingButton}
          />
          <GeneralButton
            text="Cancelar"
            customColor={"#E0E0E0"}
            handleOnPress={handleCancelButton}
            width={isComputer ? 350 : "100%"}
            disabled={loadingButton}
            loading={loadingButton}
          />
        </View>
      </LowerOptionsContainer>
    </View>
  );
};
