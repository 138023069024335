import { Svg, Path } from 'react-native-svg';

export const Soup = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path fillRule="evenodd" clipRule="evenodd" d="M49.7959 33.0115C57.0786 25.946 69.6856 16.281 77.5735 10.7666C78.2596 11.5279 78.6022 12.0972 79.1166 13.0484C74.1439 16.6604 58.5326 28.6488 54.7681 32.8226C53.2246 33.202 51.5102 33.202 49.7959 33.0115Z" fill="#5E3720" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M50.3366 33.6115C54.916 29.2143 59.7983 25.2245 64.7352 21.3379C67.0457 19.5192 69.3861 17.746 71.7382 15.9934C72.8184 15.1886 73.9063 14.3962 74.996 13.6069C76.563 12.4712 77.2568 11.3486 78.4559 13.4771C78.5472 13.0902 78.639 12.7033 78.7303 12.3164C74.2119 15.6115 69.782 19.0611 65.3906 22.5598C63.1104 24.3765 60.8433 26.2188 58.6272 28.1308C57.6058 29.0123 56.5942 29.9054 55.6053 30.8316C53.8706 32.4564 52.0229 32.3441 49.7956 32.1637C48.8107 32.0835 48.8175 33.7804 49.7956 33.8601C51.2189 33.9759 53.8073 34.4295 55.1538 33.5549C55.8584 33.0978 56.4925 32.2138 57.119 31.6269C59.5637 29.3366 62.168 27.2482 64.752 25.1573C69.5877 21.2447 74.5007 17.4293 79.5027 13.7813C79.8426 13.5332 80.0067 13.028 79.7771 12.6206C79.2695 11.72 78.756 10.9477 78.1138 10.1668C77.89 9.8942 77.4661 9.83807 77.1868 10.034C67.5131 16.8279 57.9212 24.0903 49.2546 32.4117C48.5074 33.1294 49.5944 34.3242 50.3366 33.6115Z" fill="#1A1919" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M39.1646 33.0117C39.1646 33.0117 52.0242 17.0414 58.7112 9.6261C59.3973 10.3869 59.7404 10.9572 60.2547 11.9079C55.7969 17.6117 47.052 28.8294 44.1368 32.8227C42.5933 33.2021 40.8789 33.2021 39.1646 33.0117Z" fill="#5E3720" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M39.7052 33.6115C46.0867 25.6905 52.4957 17.757 59.2524 10.2259C58.8917 10.2259 58.531 10.2259 58.1708 10.2259C59.4933 11.8346 59.3748 11.7419 58.258 13.1718C57.5122 14.127 56.7669 15.0832 56.0216 16.0395C54.3701 18.1589 52.7217 20.2818 51.0765 22.4068C49.5317 24.402 47.9922 26.4021 46.46 28.4093C45.8485 29.2102 45.2411 30.0146 44.6354 30.8204C43.251 32.6627 41.1629 32.3255 39.1647 32.1636C38.1794 32.0834 38.1866 33.7803 39.1647 33.86C41.3287 34.0354 43.8576 34.5246 45.3355 32.5314C46.7805 30.5819 48.2612 28.6644 49.7369 26.7439C53.4006 21.9773 57.0973 17.2413 60.7959 12.5077C61.0182 12.2235 61.1068 11.8191 60.9156 11.4798C60.4081 10.5792 59.8942 9.80691 59.2524 9.02608C58.9721 8.68479 58.4546 8.70985 58.1708 9.02608C51.4137 16.5572 45.0047 24.4907 38.6237 32.4117C37.9683 33.2251 39.0467 34.4294 39.7052 33.6115Z" fill="#1A1919" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M4.35577 36.2446C3.84141 67.4259 23.2177 68.567 23.2177 68.567H52.1958C68.9998 64.0044 72.4295 45.9417 70.1998 37.1958C70.0873 36.7518 4.65995 35.9073 4.35577 36.2446Z" fill="#C5E6EF" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M3.59092 36.2446C3.4933 47.9971 6.1794 60.9453 16.1889 66.9889C20.385 69.5223 24.1939 69.4156 28.8049 69.4156C34.164 69.4156 39.5236 69.4156 44.8836 69.4156C48.4786 69.4156 51.75 69.7453 55.1756 68.4648C65.9539 64.4359 72.8321 51.816 71.3753 39.3694C71.1616 37.5402 71.1358 36.3719 69.4553 36.2607C67.4201 36.1259 65.3762 36.0988 63.3378 36.0457C55.6935 35.8467 48.0465 35.733 40.4009 35.6222C32.1559 35.5024 23.9105 35.4037 15.665 35.3461C12.9554 35.3275 10.2458 35.3125 7.53623 35.321C6.58347 35.324 5.0716 35.0238 4.15273 35.4267C3.24606 35.8242 3.64064 37.4655 4.55906 37.0626C5.53397 36.6356 8.62369 37.0139 10.0488 37.0165C14.4226 37.023 18.7968 37.0636 23.1701 37.1072C34.0135 37.2139 44.8569 37.3638 55.6994 37.5738C59.4946 37.6474 63.2904 37.7236 67.0847 37.8529C67.9706 37.883 68.8574 37.912 69.7419 37.9772C70.3078 38.0193 69.5186 37.6138 69.694 38.4708C70.0049 39.9894 70.0515 41.5621 70.0384 43.1132C69.9787 50.1777 67.0865 57.0026 62.4696 61.7913C58.5595 65.8477 53.835 67.7191 48.6422 67.7191C42.4076 67.7191 36.1731 67.7191 29.9385 67.7191C27.4522 67.7191 24.7905 68.0549 22.3222 67.5898C9.07341 65.0935 5.0142 49.1427 5.12087 36.2446C5.12991 35.1501 3.59951 35.1511 3.59092 36.2446Z" fill="#1A1919" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M11.5574 69.1375C20.3579 69.1375 53.4332 69.3715 61.6266 69.7078C61.9348 70.5142 62.1409 72.9398 62.1409 72.9398L11.2148 71.9886L11.5574 69.1375Z" fill="#519BA8" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M11.5574 69.9854C20.4053 69.9884 29.2532 70.0696 38.1002 70.1573C43.124 70.2069 48.1477 70.2671 53.171 70.3508C54.9897 70.3818 56.808 70.4179 58.6268 70.4625C59.3838 70.4811 60.1409 70.5031 60.898 70.5292C61.3852 70.5452 61.2849 71.952 61.3757 72.9398C61.6311 72.6571 61.886 72.3745 62.1409 72.0918C45.1655 71.7741 28.1902 71.4573 11.2148 71.1401C11.4697 71.4233 11.7246 71.7059 11.9796 71.9886C12.0939 71.0379 12.2083 70.0881 12.3226 69.1374C12.4528 68.0554 10.921 68.0694 10.7927 69.1374C10.6783 70.0881 10.5635 71.0379 10.4496 71.9886C10.3936 72.4532 10.8401 72.83 11.2148 72.837C28.1902 73.1543 45.1655 73.471 62.1409 73.7883C62.526 73.7958 62.9481 73.3959 62.9061 72.9398C62.7981 71.7721 62.6647 70.6109 62.3646 69.4827C62.2652 69.1094 61.9732 68.8728 61.6266 68.8598C44.9585 68.2047 28.2349 68.295 11.5574 68.2889C10.5707 68.2889 10.5707 69.9854 11.5574 69.9854Z" fill="#1A1919" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M1.25793 33.0131C14.1768 33.0131 62.7323 33.2471 74.7603 33.5834C75.2127 34.3898 75.5151 36.8159 75.5151 36.8159L0.754883 35.8647L1.25793 33.0131Z" fill="#519BA8" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M1.2579 33.8611C13.902 33.8636 26.5469 33.9423 39.1906 34.026C46.6043 34.0756 54.0177 34.1342 61.431 34.2164C64.2952 34.248 67.1598 34.2856 70.0245 34.3327C71.1087 34.3507 72.193 34.3713 73.2773 34.3953C74.8371 34.4294 74.556 35.4097 74.777 37.0415C75.0233 36.6837 75.2687 36.3258 75.5151 35.9675C50.5948 35.6508 25.6751 35.3335 0.754849 35.0163C1.00118 35.3741 1.2466 35.7319 1.49293 36.0898C1.66061 35.1396 1.8283 34.1888 1.99598 33.2386C2.184 32.1696 0.709651 31.7141 0.520273 32.7871C0.352589 33.7378 0.184906 34.6885 0.0172221 35.6392C-0.0781451 36.179 0.230103 36.7062 0.754849 36.7127C25.6751 37.0305 50.5948 37.3467 75.5151 37.6645C76.0579 37.671 76.3246 37.1207 76.2527 36.5905C76.09 35.3917 75.855 34.2665 75.4206 33.1554C75.3158 32.8858 75.0061 32.742 74.7603 32.7355C50.2748 32.0714 25.7496 32.1686 1.2579 32.1646C0.271233 32.1646 0.271233 33.8611 1.2579 33.8611Z" fill="#1A1919" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M19.0703 6.86299C16.6215 3.74772 13.7763 9.90208 13.6917 12.1273C13.5756 15.1944 19.1462 24.211 16.0999 26.1911C15.2493 26.7439 16.019 28.2103 16.8719 27.6555C18.9057 26.3339 18.5433 23.7424 18.0452 21.5352C17.6854 19.942 17.0644 18.4154 16.4809 16.9104C16.2472 16.3075 16.0009 15.7121 15.7812 15.1022C15.478 14.1405 15.2917 13.1487 15.2221 12.1273C15.1946 9.77328 16.1166 8.41862 17.9887 8.06279C18.6382 8.88922 19.7157 7.68391 19.0703 6.86299Z" fill="#1A1919" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M29.8896 6.86306C27.4408 3.74729 24.5956 9.90215 24.5116 12.1273C24.3954 15.1945 29.9656 24.211 26.9188 26.1912C26.0686 26.744 26.8383 28.2104 27.6912 27.6556C29.7251 26.334 29.3626 23.7424 28.8646 21.5353C28.5048 19.9421 27.8838 18.4155 27.3003 16.9105C27.0666 16.3076 26.8207 15.7122 26.6006 15.1023C26.2973 14.1405 26.1111 13.1487 26.0415 12.1273C26.0139 9.77335 26.936 8.41869 28.8076 8.06286C29.4571 8.88929 30.5346 7.68397 29.8896 6.86306Z" fill="#1A1919" />
            <Path fillRule="evenodd" clipRule="evenodd" d="M24.7217 6.86306C22.2729 3.74729 19.4277 9.90215 19.3436 12.1273C19.2274 15.1945 24.7976 24.211 21.7508 26.1912C20.9007 26.744 21.6704 28.2104 22.5233 27.6556C24.5572 26.334 24.1947 23.7424 23.6966 21.5353C23.3368 19.9421 22.7158 18.4155 22.1327 16.9105C21.8986 16.3076 21.6527 15.7122 21.4326 15.1023C21.1294 14.1405 20.9431 13.1487 20.8735 12.1273C20.846 9.77335 21.768 8.41869 23.6396 8.06286C24.2891 8.88929 25.3666 7.68397 24.7217 6.86306Z" fill="#1A1919" />
        </Svg>

    )
}