import { useEffect, useRef } from 'react'
import { View, Text, TouchableOpacity, Animated, Easing } from 'react-native';
//icons
import { BaranaIcons } from '../../../assets/icons/BaranaIcons';
//styles
import { homeStyles } from '../../../styles/pages/home/homePage'
import { addRemoveToCart } from '../../../styles/pages/widgets/cart'
import { AddCartButton } from '../../../styles/pages/widgets/animations'


export const AddRemoveToCart = ({
  addToCard,
  updatingCart,
  deleteToCard,
  quantity,
  background,
  animation,
}) => {
  const fadeAnim = useRef(new Animated.Value(0.35)).current;

  useEffect(() => {
    if (animation) {
      Animated.timing(fadeAnim, {
        toValue: 0.1,
        duration: 2000,
        easing: Easing.linear,
        useNativeDriver: true,
      }).start(({ finished }) => {
        Animated.loop(
          Animated.timing(fadeAnim, {
            toValue: 0.63,
            duration: 4000,
            easing: Easing.linear,
            useNativeDriver: true,
          })
        ).start();
      });
    }
  }, [fadeAnim, animation]);

  useEffect(() => {}, [quantity]);

  return (
    <View style={[addRemoveToCart(background)]}>
      <TouchableOpacity disabled={updatingCart} onPress={() => deleteToCard()}>
        <BaranaIcons draw={{ width: 20, height: 20 }} name={"outlinedMinus"} />
      </TouchableOpacity>
      <Text style={[homeStyles.strongText, {fontSize: 15}]}>{quantity}</Text>

      {animation ? (
        <Animated.View style={[AddCartButton(), { opacity: fadeAnim }]}>
          <TouchableOpacity disabled={updatingCart} onPress={() => addToCard()}>
            <BaranaIcons
              draw={{ width: 20, height: 20 }}
              name={"outlinedPlus"}
            />
          </TouchableOpacity>
        </Animated.View>
      ) : (
        <TouchableOpacity disabled={updatingCart} onPress={() => addToCard()}>
          <BaranaIcons draw={{ width: 20, height: 20 }} name={"outlinedPlus"} />
        </TouchableOpacity>
      )}
    </View>
  );
};
