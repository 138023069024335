import { Platform } from 'react-native';
import { SvgXml } from 'react-native-svg';

export const Barana = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    if (Platform.OS == 'web') {
        //width="258" height="71" 
        return <svg width={width} height={height} viewBox="0 0 258 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M243.746 16.4488C240.037 18.5901 235.907 18.3797 234.521 15.9788C233.135 13.5779 235.017 9.89566 238.726 7.75436C242.435 5.61305 253.983 3.16162 254.667 4.34723C255.352 5.53284 247.455 14.3075 243.746 16.4488Z" fill="#65B864" />
            <mask id="path-2-outside-1_548_16140" maskUnits="userSpaceOnUse" x="-0.177246" y="7.26709" width="253" height="64" fill="black">
                <rect fill="white" x="-0.177246" y="7.26709" width="253" height="64" />
                <path d="M2.37334 53.798C3.36008 47.1727 4.11188 40.8059 4.62874 34.6976C5.19259 28.5892 5.47452 23.0447 5.47452 18.064C5.47452 13.0833 5.14561 9.81768 4.48778 8.26709C8.38774 9.58274 10.8311 11.6502 11.8178 14.4694C15.4829 12.449 19.2419 11.4387 23.0948 11.4387C26.9478 11.4387 30.3074 12.496 33.1737 14.6104C36.0869 16.7248 37.52 19.5676 37.473 23.1386C37.473 26.7097 36.3218 29.8109 34.0194 32.4422C31.717 35.0735 28.4514 37.0939 24.2225 38.5036C27.5117 39.2084 30.4954 40.8059 33.1737 43.2963C35.8519 45.7396 37.1911 48.7703 37.1911 52.3884C37.1911 57.9329 35.2411 62.2087 31.3411 65.2159C27.4882 68.1762 22.625 69.6563 16.7515 69.6563C10.9251 69.6563 5.61548 67.8473 0.822754 64.2292C0.869742 63.8533 1.3866 60.3762 2.37334 53.798ZM30.2839 25.3236C30.2839 22.6923 29.4381 20.6013 27.7466 19.0507C26.055 17.4531 23.7527 16.6544 20.8394 16.6544C17.9262 16.6544 15.2009 17.2652 12.6636 18.4869C12.6636 21.4471 12.0293 28.8476 10.7606 40.6885C9.53894 52.4823 8.9281 60.0473 8.9281 63.3834C11.3245 64.2292 13.6738 64.6521 15.9762 64.6521C20.0172 64.6521 23.3533 63.5009 25.9846 61.1985C28.6159 58.8491 29.9315 55.701 29.9315 51.754C29.9315 48.7938 28.5689 46.2095 25.8436 44.0011C23.1653 41.7457 19.4298 40.524 14.6371 40.3361L14.4256 37.7987C19.4063 37.1879 23.2828 35.6138 26.055 33.0765C28.8743 30.4922 30.2839 27.9079 30.2839 25.3236Z" />
                <path d="M58.597 40.4065C57.2344 42.7559 56.1772 45.1758 55.4254 47.6661C54.7205 50.1095 54.3681 52.4588 54.3681 54.7142C54.3681 56.9696 54.8615 58.8961 55.8482 60.4937C56.882 62.0443 58.3386 62.8196 60.2181 62.8196C62.8494 62.8196 65.3162 61.3865 67.6186 58.5202C69.921 55.607 71.683 52.1064 72.9047 48.0185C74.1734 43.8836 74.8077 40.0776 74.8077 36.6006C74.8077 33.0765 74.6667 30.1868 74.3848 27.9314C75.5125 27.8374 76.2173 27.7904 76.4993 27.7904C80.4932 27.7904 82.4902 29.8344 82.4902 33.9223C82.4902 34.815 81.9263 38.4566 80.7986 44.8469C79.6709 51.1902 79.1071 56.2883 79.1071 60.1413C79.1071 63.9473 79.8354 67.3304 81.292 70.2906C78.3318 69.6798 76.1703 68.3876 74.8077 66.4141C73.4921 64.3937 72.8342 61.5039 72.8342 57.7449C72.8342 57.0871 72.9282 55.8184 73.1162 53.9389C71.8475 58.4028 69.6156 61.9973 66.4204 64.7226C63.2253 67.4479 60.0771 68.8105 56.9759 68.8105C53.9218 68.8105 51.4549 67.6593 49.5754 65.3569C47.6959 63.0075 46.7562 59.7889 46.7562 55.701C46.7562 51.5661 47.508 47.5486 49.0116 43.6487C50.5621 39.7017 52.8645 36.2951 55.9187 33.4289C59.0199 30.5627 62.4735 29.1295 66.2795 29.1295C67.924 29.1295 69.7331 29.4585 71.7065 30.1163C68.9343 30.8211 66.3969 32.1133 64.0946 33.9928C61.7922 35.8723 59.9596 38.0102 58.597 40.4065Z" />
                <path d="M103.054 45.0583C104.746 38.9499 106.766 34.4626 109.116 31.5964C111.512 28.6832 113.72 27.2266 115.741 27.2266C117.808 27.2266 119.359 28.0018 120.393 29.5524C121.426 31.056 121.943 32.9825 121.943 35.3319C121.943 37.6813 121.332 39.7252 120.111 41.4638C118.889 43.1553 117.103 44.0011 114.754 44.0011C114.002 44.0011 113.415 43.9541 112.992 43.8601C114.59 41.5577 115.388 39.2084 115.388 36.812C115.388 35.1205 114.707 34.2747 113.344 34.2747C112.264 34.2747 111.113 34.909 109.891 36.1777C108.669 37.4463 107.494 39.2084 106.367 41.4638C105.239 43.7192 104.299 46.6559 103.548 50.2739C102.796 53.892 102.42 57.7919 102.42 61.9738C102.42 62.4437 102.537 64.4407 102.772 67.9647C101.551 68.0587 100.846 68.1057 100.658 68.1057C96.5229 68.1057 94.4555 66.0852 94.4555 62.0443C94.4555 61.1045 94.9488 57.4865 95.9356 51.1902C96.9223 44.8939 97.4157 39.8897 97.4157 36.1777C97.4157 32.4187 97.0163 28.9651 96.2175 25.8169C98.9898 26.8976 100.916 28.4482 101.997 30.4687C103.125 32.4422 103.689 34.768 103.689 37.4463C103.689 40.0776 103.477 42.615 103.054 45.0583Z" />
                <path d="M137.371 40.4065C136.008 42.7559 134.951 45.1758 134.199 47.6661C133.494 50.1095 133.142 52.4588 133.142 54.7142C133.142 56.9696 133.635 58.8961 134.622 60.4937C135.656 62.0443 137.112 62.8196 138.992 62.8196C141.623 62.8196 144.09 61.3865 146.392 58.5202C148.695 55.607 150.457 52.1064 151.679 48.0185C152.947 43.8836 153.582 40.0776 153.582 36.6006C153.582 33.0765 153.441 30.1868 153.159 27.9314C154.286 27.8374 154.991 27.7904 155.273 27.7904C159.267 27.7904 161.264 29.8344 161.264 33.9223C161.264 34.815 160.7 38.4566 159.572 44.8469C158.445 51.1902 157.881 56.2883 157.881 60.1413C157.881 63.9473 158.609 67.3304 160.066 70.2906C157.106 69.6798 154.944 68.3876 153.582 66.4141C152.266 64.3937 151.608 61.5039 151.608 57.7449C151.608 57.0871 151.702 55.8184 151.89 53.9389C150.621 58.4028 148.389 61.9973 145.194 64.7226C141.999 67.4479 138.851 68.8105 135.75 68.8105C132.696 68.8105 130.229 67.6593 128.349 65.3569C126.47 63.0075 125.53 59.7889 125.53 55.701C125.53 51.5661 126.282 47.5486 127.785 43.6487C129.336 39.7017 131.638 36.2951 134.693 33.4289C137.794 30.5627 141.247 29.1295 145.053 29.1295C146.698 29.1295 148.507 29.4585 150.48 30.1163C147.708 30.8211 145.171 32.1133 142.868 33.9928C140.566 35.8723 138.733 38.0102 137.371 40.4065Z" />
                <path d="M205.51 37.3054C205.51 39.4668 205.04 43.2963 204.1 48.7938C203.207 54.2444 202.761 58.2148 202.761 60.7051C202.761 63.1955 203.066 65.0045 203.677 66.1322C204.288 67.2129 205.322 68.1527 206.778 68.9515C206.168 68.9984 205.298 69.0219 204.171 69.0219C200.975 69.0219 198.697 68.4346 197.334 67.2599C196.018 66.0382 195.36 63.8768 195.36 60.7756C195.36 59.0371 195.83 55.5365 196.77 50.2739C197.71 44.9643 198.18 41.0644 198.18 38.574C198.18 35.4729 197.24 33.9223 195.36 33.9223C192.353 33.9223 189.229 36.5771 185.986 41.8867C182.791 47.1962 181.194 53.5865 181.194 61.0576C181.194 64.0178 181.335 66.3202 181.617 67.9647C180.395 68.0587 179.667 68.1057 179.432 68.1057C175.297 68.1057 173.229 66.0852 173.229 62.0443C173.229 61.1045 173.723 57.4865 174.709 51.1902C175.696 44.8939 176.189 39.8897 176.189 36.1777C176.189 32.4187 175.79 28.9651 174.991 25.8169C177.764 26.8976 179.69 28.4482 180.771 30.4687C181.898 32.4422 182.462 34.7211 182.462 37.3054C182.462 39.8427 182.18 42.8029 181.617 46.186C183.167 40.8764 185.587 36.4126 188.876 32.7946C192.212 29.1295 195.313 27.297 198.18 27.297C203.066 27.297 205.51 30.6331 205.51 37.3054Z" />
                <path d="M227.639 40.4065C226.277 42.7559 225.219 45.1758 224.467 47.6661C223.763 50.1095 223.41 52.4588 223.41 54.7142C223.41 56.9696 223.904 58.8961 224.89 60.4937C225.924 62.0443 227.381 62.8196 229.26 62.8196C231.892 62.8196 234.358 61.3865 236.661 58.5202C238.963 55.607 240.725 52.1064 241.947 48.0185C243.216 43.8836 243.85 40.0776 243.85 36.6006C243.85 33.0765 243.709 30.1868 243.427 27.9314C244.555 27.8374 245.259 27.7904 245.541 27.7904C249.535 27.7904 251.532 29.8344 251.532 33.9223C251.532 34.815 250.968 38.4566 249.841 44.8469C248.713 51.1902 248.149 56.2883 248.149 60.1413C248.149 63.9473 248.878 67.3304 250.334 70.2906C247.374 69.6798 245.212 68.3876 243.85 66.4141C242.534 64.3937 241.876 61.5039 241.876 57.7449C241.876 57.0871 241.97 55.8184 242.158 53.9389C240.89 58.4028 238.658 61.9973 235.463 64.7226C232.267 67.4479 229.119 68.8105 226.018 68.8105C222.964 68.8105 220.497 67.6593 218.618 65.3569C216.738 63.0075 215.798 59.7889 215.798 55.701C215.798 51.5661 216.55 47.5486 218.054 43.6487C219.604 39.7017 221.907 36.2951 224.961 33.4289C228.062 30.5627 231.516 29.1295 235.322 29.1295C236.966 29.1295 238.775 29.4585 240.749 30.1163C237.976 30.8211 235.439 32.1133 233.137 33.9928C230.834 35.8723 229.002 38.0102 227.639 40.4065Z" />
            </mask>
            <path d="M2.37334 53.798C3.36008 47.1727 4.11188 40.8059 4.62874 34.6976C5.19259 28.5892 5.47452 23.0447 5.47452 18.064C5.47452 13.0833 5.14561 9.81768 4.48778 8.26709C8.38774 9.58274 10.8311 11.6502 11.8178 14.4694C15.4829 12.449 19.2419 11.4387 23.0948 11.4387C26.9478 11.4387 30.3074 12.496 33.1737 14.6104C36.0869 16.7248 37.52 19.5676 37.473 23.1386C37.473 26.7097 36.3218 29.8109 34.0194 32.4422C31.717 35.0735 28.4514 37.0939 24.2225 38.5036C27.5117 39.2084 30.4954 40.8059 33.1737 43.2963C35.8519 45.7396 37.1911 48.7703 37.1911 52.3884C37.1911 57.9329 35.2411 62.2087 31.3411 65.2159C27.4882 68.1762 22.625 69.6563 16.7515 69.6563C10.9251 69.6563 5.61548 67.8473 0.822754 64.2292C0.869742 63.8533 1.3866 60.3762 2.37334 53.798ZM30.2839 25.3236C30.2839 22.6923 29.4381 20.6013 27.7466 19.0507C26.055 17.4531 23.7527 16.6544 20.8394 16.6544C17.9262 16.6544 15.2009 17.2652 12.6636 18.4869C12.6636 21.4471 12.0293 28.8476 10.7606 40.6885C9.53894 52.4823 8.9281 60.0473 8.9281 63.3834C11.3245 64.2292 13.6738 64.6521 15.9762 64.6521C20.0172 64.6521 23.3533 63.5009 25.9846 61.1985C28.6159 58.8491 29.9315 55.701 29.9315 51.754C29.9315 48.7938 28.5689 46.2095 25.8436 44.0011C23.1653 41.7457 19.4298 40.524 14.6371 40.3361L14.4256 37.7987C19.4063 37.1879 23.2828 35.6138 26.055 33.0765C28.8743 30.4922 30.2839 27.9079 30.2839 25.3236Z" fill="#FF8900" />
            <path d="M58.597 40.4065C57.2344 42.7559 56.1772 45.1758 55.4254 47.6661C54.7205 50.1095 54.3681 52.4588 54.3681 54.7142C54.3681 56.9696 54.8615 58.8961 55.8482 60.4937C56.882 62.0443 58.3386 62.8196 60.2181 62.8196C62.8494 62.8196 65.3162 61.3865 67.6186 58.5202C69.921 55.607 71.683 52.1064 72.9047 48.0185C74.1734 43.8836 74.8077 40.0776 74.8077 36.6006C74.8077 33.0765 74.6667 30.1868 74.3848 27.9314C75.5125 27.8374 76.2173 27.7904 76.4993 27.7904C80.4932 27.7904 82.4902 29.8344 82.4902 33.9223C82.4902 34.815 81.9263 38.4566 80.7986 44.8469C79.6709 51.1902 79.1071 56.2883 79.1071 60.1413C79.1071 63.9473 79.8354 67.3304 81.292 70.2906C78.3318 69.6798 76.1703 68.3876 74.8077 66.4141C73.4921 64.3937 72.8342 61.5039 72.8342 57.7449C72.8342 57.0871 72.9282 55.8184 73.1162 53.9389C71.8475 58.4028 69.6156 61.9973 66.4204 64.7226C63.2253 67.4479 60.0771 68.8105 56.9759 68.8105C53.9218 68.8105 51.4549 67.6593 49.5754 65.3569C47.6959 63.0075 46.7562 59.7889 46.7562 55.701C46.7562 51.5661 47.508 47.5486 49.0116 43.6487C50.5621 39.7017 52.8645 36.2951 55.9187 33.4289C59.0199 30.5627 62.4735 29.1295 66.2795 29.1295C67.924 29.1295 69.7331 29.4585 71.7065 30.1163C68.9343 30.8211 66.3969 32.1133 64.0946 33.9928C61.7922 35.8723 59.9596 38.0102 58.597 40.4065Z" fill="#FF8900" />
            <path d="M103.054 45.0583C104.746 38.9499 106.766 34.4626 109.116 31.5964C111.512 28.6832 113.72 27.2266 115.741 27.2266C117.808 27.2266 119.359 28.0018 120.393 29.5524C121.426 31.056 121.943 32.9825 121.943 35.3319C121.943 37.6813 121.332 39.7252 120.111 41.4638C118.889 43.1553 117.103 44.0011 114.754 44.0011C114.002 44.0011 113.415 43.9541 112.992 43.8601C114.59 41.5577 115.388 39.2084 115.388 36.812C115.388 35.1205 114.707 34.2747 113.344 34.2747C112.264 34.2747 111.113 34.909 109.891 36.1777C108.669 37.4463 107.494 39.2084 106.367 41.4638C105.239 43.7192 104.299 46.6559 103.548 50.2739C102.796 53.892 102.42 57.7919 102.42 61.9738C102.42 62.4437 102.537 64.4407 102.772 67.9647C101.551 68.0587 100.846 68.1057 100.658 68.1057C96.5229 68.1057 94.4555 66.0852 94.4555 62.0443C94.4555 61.1045 94.9488 57.4865 95.9356 51.1902C96.9223 44.8939 97.4157 39.8897 97.4157 36.1777C97.4157 32.4187 97.0163 28.9651 96.2175 25.8169C98.9898 26.8976 100.916 28.4482 101.997 30.4687C103.125 32.4422 103.689 34.768 103.689 37.4463C103.689 40.0776 103.477 42.615 103.054 45.0583Z" fill="#FF8900" />
            <path d="M137.371 40.4065C136.008 42.7559 134.951 45.1758 134.199 47.6661C133.494 50.1095 133.142 52.4588 133.142 54.7142C133.142 56.9696 133.635 58.8961 134.622 60.4937C135.656 62.0443 137.112 62.8196 138.992 62.8196C141.623 62.8196 144.09 61.3865 146.392 58.5202C148.695 55.607 150.457 52.1064 151.679 48.0185C152.947 43.8836 153.582 40.0776 153.582 36.6006C153.582 33.0765 153.441 30.1868 153.159 27.9314C154.286 27.8374 154.991 27.7904 155.273 27.7904C159.267 27.7904 161.264 29.8344 161.264 33.9223C161.264 34.815 160.7 38.4566 159.572 44.8469C158.445 51.1902 157.881 56.2883 157.881 60.1413C157.881 63.9473 158.609 67.3304 160.066 70.2906C157.106 69.6798 154.944 68.3876 153.582 66.4141C152.266 64.3937 151.608 61.5039 151.608 57.7449C151.608 57.0871 151.702 55.8184 151.89 53.9389C150.621 58.4028 148.389 61.9973 145.194 64.7226C141.999 67.4479 138.851 68.8105 135.75 68.8105C132.696 68.8105 130.229 67.6593 128.349 65.3569C126.47 63.0075 125.53 59.7889 125.53 55.701C125.53 51.5661 126.282 47.5486 127.785 43.6487C129.336 39.7017 131.638 36.2951 134.693 33.4289C137.794 30.5627 141.247 29.1295 145.053 29.1295C146.698 29.1295 148.507 29.4585 150.48 30.1163C147.708 30.8211 145.171 32.1133 142.868 33.9928C140.566 35.8723 138.733 38.0102 137.371 40.4065Z" fill="#FF8900" />
            <path d="M205.51 37.3054C205.51 39.4668 205.04 43.2963 204.1 48.7938C203.207 54.2444 202.761 58.2148 202.761 60.7051C202.761 63.1955 203.066 65.0045 203.677 66.1322C204.288 67.2129 205.322 68.1527 206.778 68.9515C206.168 68.9984 205.298 69.0219 204.171 69.0219C200.975 69.0219 198.697 68.4346 197.334 67.2599C196.018 66.0382 195.36 63.8768 195.36 60.7756C195.36 59.0371 195.83 55.5365 196.77 50.2739C197.71 44.9643 198.18 41.0644 198.18 38.574C198.18 35.4729 197.24 33.9223 195.36 33.9223C192.353 33.9223 189.229 36.5771 185.986 41.8867C182.791 47.1962 181.194 53.5865 181.194 61.0576C181.194 64.0178 181.335 66.3202 181.617 67.9647C180.395 68.0587 179.667 68.1057 179.432 68.1057C175.297 68.1057 173.229 66.0852 173.229 62.0443C173.229 61.1045 173.723 57.4865 174.709 51.1902C175.696 44.8939 176.189 39.8897 176.189 36.1777C176.189 32.4187 175.79 28.9651 174.991 25.8169C177.764 26.8976 179.69 28.4482 180.771 30.4687C181.898 32.4422 182.462 34.7211 182.462 37.3054C182.462 39.8427 182.18 42.8029 181.617 46.186C183.167 40.8764 185.587 36.4126 188.876 32.7946C192.212 29.1295 195.313 27.297 198.18 27.297C203.066 27.297 205.51 30.6331 205.51 37.3054Z" fill="#FF8900" />
            <path d="M227.639 40.4065C226.277 42.7559 225.219 45.1758 224.467 47.6661C223.763 50.1095 223.41 52.4588 223.41 54.7142C223.41 56.9696 223.904 58.8961 224.89 60.4937C225.924 62.0443 227.381 62.8196 229.26 62.8196C231.892 62.8196 234.358 61.3865 236.661 58.5202C238.963 55.607 240.725 52.1064 241.947 48.0185C243.216 43.8836 243.85 40.0776 243.85 36.6006C243.85 33.0765 243.709 30.1868 243.427 27.9314C244.555 27.8374 245.259 27.7904 245.541 27.7904C249.535 27.7904 251.532 29.8344 251.532 33.9223C251.532 34.815 250.968 38.4566 249.841 44.8469C248.713 51.1902 248.149 56.2883 248.149 60.1413C248.149 63.9473 248.878 67.3304 250.334 70.2906C247.374 69.6798 245.212 68.3876 243.85 66.4141C242.534 64.3937 241.876 61.5039 241.876 57.7449C241.876 57.0871 241.97 55.8184 242.158 53.9389C240.89 58.4028 238.658 61.9973 235.463 64.7226C232.267 67.4479 229.119 68.8105 226.018 68.8105C222.964 68.8105 220.497 67.6593 218.618 65.3569C216.738 63.0075 215.798 59.7889 215.798 55.701C215.798 51.5661 216.55 47.5486 218.054 43.6487C219.604 39.7017 221.907 36.2951 224.961 33.4289C228.062 30.5627 231.516 29.1295 235.322 29.1295C236.966 29.1295 238.775 29.4585 240.749 30.1163C237.976 30.8211 235.439 32.1133 233.137 33.9928C230.834 35.8723 229.002 38.0102 227.639 40.4065Z" fill="#FF8900" />
            <path d="M2.37334 53.798C3.36008 47.1727 4.11188 40.8059 4.62874 34.6976C5.19259 28.5892 5.47452 23.0447 5.47452 18.064C5.47452 13.0833 5.14561 9.81768 4.48778 8.26709C8.38774 9.58274 10.8311 11.6502 11.8178 14.4694C15.4829 12.449 19.2419 11.4387 23.0948 11.4387C26.9478 11.4387 30.3074 12.496 33.1737 14.6104C36.0869 16.7248 37.52 19.5676 37.473 23.1386C37.473 26.7097 36.3218 29.8109 34.0194 32.4422C31.717 35.0735 28.4514 37.0939 24.2225 38.5036C27.5117 39.2084 30.4954 40.8059 33.1737 43.2963C35.8519 45.7396 37.1911 48.7703 37.1911 52.3884C37.1911 57.9329 35.2411 62.2087 31.3411 65.2159C27.4882 68.1762 22.625 69.6563 16.7515 69.6563C10.9251 69.6563 5.61548 67.8473 0.822754 64.2292C0.869742 63.8533 1.3866 60.3762 2.37334 53.798ZM30.2839 25.3236C30.2839 22.6923 29.4381 20.6013 27.7466 19.0507C26.055 17.4531 23.7527 16.6544 20.8394 16.6544C17.9262 16.6544 15.2009 17.2652 12.6636 18.4869C12.6636 21.4471 12.0293 28.8476 10.7606 40.6885C9.53894 52.4823 8.9281 60.0473 8.9281 63.3834C11.3245 64.2292 13.6738 64.6521 15.9762 64.6521C20.0172 64.6521 23.3533 63.5009 25.9846 61.1985C28.6159 58.8491 29.9315 55.701 29.9315 51.754C29.9315 48.7938 28.5689 46.2095 25.8436 44.0011C23.1653 41.7457 19.4298 40.524 14.6371 40.3361L14.4256 37.7987C19.4063 37.1879 23.2828 35.6138 26.055 33.0765C28.8743 30.4922 30.2839 27.9079 30.2839 25.3236Z" stroke="#FF8900" mask="url(#path-2-outside-1_548_16140)" />
            <path d="M58.597 40.4065C57.2344 42.7559 56.1772 45.1758 55.4254 47.6661C54.7205 50.1095 54.3681 52.4588 54.3681 54.7142C54.3681 56.9696 54.8615 58.8961 55.8482 60.4937C56.882 62.0443 58.3386 62.8196 60.2181 62.8196C62.8494 62.8196 65.3162 61.3865 67.6186 58.5202C69.921 55.607 71.683 52.1064 72.9047 48.0185C74.1734 43.8836 74.8077 40.0776 74.8077 36.6006C74.8077 33.0765 74.6667 30.1868 74.3848 27.9314C75.5125 27.8374 76.2173 27.7904 76.4993 27.7904C80.4932 27.7904 82.4902 29.8344 82.4902 33.9223C82.4902 34.815 81.9263 38.4566 80.7986 44.8469C79.6709 51.1902 79.1071 56.2883 79.1071 60.1413C79.1071 63.9473 79.8354 67.3304 81.292 70.2906C78.3318 69.6798 76.1703 68.3876 74.8077 66.4141C73.4921 64.3937 72.8342 61.5039 72.8342 57.7449C72.8342 57.0871 72.9282 55.8184 73.1162 53.9389C71.8475 58.4028 69.6156 61.9973 66.4204 64.7226C63.2253 67.4479 60.0771 68.8105 56.9759 68.8105C53.9218 68.8105 51.4549 67.6593 49.5754 65.3569C47.6959 63.0075 46.7562 59.7889 46.7562 55.701C46.7562 51.5661 47.508 47.5486 49.0116 43.6487C50.5621 39.7017 52.8645 36.2951 55.9187 33.4289C59.0199 30.5627 62.4735 29.1295 66.2795 29.1295C67.924 29.1295 69.7331 29.4585 71.7065 30.1163C68.9343 30.8211 66.3969 32.1133 64.0946 33.9928C61.7922 35.8723 59.9596 38.0102 58.597 40.4065Z" stroke="#FF8900" mask="url(#path-2-outside-1_548_16140)" />
            <path d="M103.054 45.0583C104.746 38.9499 106.766 34.4626 109.116 31.5964C111.512 28.6832 113.72 27.2266 115.741 27.2266C117.808 27.2266 119.359 28.0018 120.393 29.5524C121.426 31.056 121.943 32.9825 121.943 35.3319C121.943 37.6813 121.332 39.7252 120.111 41.4638C118.889 43.1553 117.103 44.0011 114.754 44.0011C114.002 44.0011 113.415 43.9541 112.992 43.8601C114.59 41.5577 115.388 39.2084 115.388 36.812C115.388 35.1205 114.707 34.2747 113.344 34.2747C112.264 34.2747 111.113 34.909 109.891 36.1777C108.669 37.4463 107.494 39.2084 106.367 41.4638C105.239 43.7192 104.299 46.6559 103.548 50.2739C102.796 53.892 102.42 57.7919 102.42 61.9738C102.42 62.4437 102.537 64.4407 102.772 67.9647C101.551 68.0587 100.846 68.1057 100.658 68.1057C96.5229 68.1057 94.4555 66.0852 94.4555 62.0443C94.4555 61.1045 94.9488 57.4865 95.9356 51.1902C96.9223 44.8939 97.4157 39.8897 97.4157 36.1777C97.4157 32.4187 97.0163 28.9651 96.2175 25.8169C98.9898 26.8976 100.916 28.4482 101.997 30.4687C103.125 32.4422 103.689 34.768 103.689 37.4463C103.689 40.0776 103.477 42.615 103.054 45.0583Z" stroke="#FF8900" mask="url(#path-2-outside-1_548_16140)" />
            <path d="M137.371 40.4065C136.008 42.7559 134.951 45.1758 134.199 47.6661C133.494 50.1095 133.142 52.4588 133.142 54.7142C133.142 56.9696 133.635 58.8961 134.622 60.4937C135.656 62.0443 137.112 62.8196 138.992 62.8196C141.623 62.8196 144.09 61.3865 146.392 58.5202C148.695 55.607 150.457 52.1064 151.679 48.0185C152.947 43.8836 153.582 40.0776 153.582 36.6006C153.582 33.0765 153.441 30.1868 153.159 27.9314C154.286 27.8374 154.991 27.7904 155.273 27.7904C159.267 27.7904 161.264 29.8344 161.264 33.9223C161.264 34.815 160.7 38.4566 159.572 44.8469C158.445 51.1902 157.881 56.2883 157.881 60.1413C157.881 63.9473 158.609 67.3304 160.066 70.2906C157.106 69.6798 154.944 68.3876 153.582 66.4141C152.266 64.3937 151.608 61.5039 151.608 57.7449C151.608 57.0871 151.702 55.8184 151.89 53.9389C150.621 58.4028 148.389 61.9973 145.194 64.7226C141.999 67.4479 138.851 68.8105 135.75 68.8105C132.696 68.8105 130.229 67.6593 128.349 65.3569C126.47 63.0075 125.53 59.7889 125.53 55.701C125.53 51.5661 126.282 47.5486 127.785 43.6487C129.336 39.7017 131.638 36.2951 134.693 33.4289C137.794 30.5627 141.247 29.1295 145.053 29.1295C146.698 29.1295 148.507 29.4585 150.48 30.1163C147.708 30.8211 145.171 32.1133 142.868 33.9928C140.566 35.8723 138.733 38.0102 137.371 40.4065Z" stroke="#FF8900" mask="url(#path-2-outside-1_548_16140)" />
            <path d="M205.51 37.3054C205.51 39.4668 205.04 43.2963 204.1 48.7938C203.207 54.2444 202.761 58.2148 202.761 60.7051C202.761 63.1955 203.066 65.0045 203.677 66.1322C204.288 67.2129 205.322 68.1527 206.778 68.9515C206.168 68.9984 205.298 69.0219 204.171 69.0219C200.975 69.0219 198.697 68.4346 197.334 67.2599C196.018 66.0382 195.36 63.8768 195.36 60.7756C195.36 59.0371 195.83 55.5365 196.77 50.2739C197.71 44.9643 198.18 41.0644 198.18 38.574C198.18 35.4729 197.24 33.9223 195.36 33.9223C192.353 33.9223 189.229 36.5771 185.986 41.8867C182.791 47.1962 181.194 53.5865 181.194 61.0576C181.194 64.0178 181.335 66.3202 181.617 67.9647C180.395 68.0587 179.667 68.1057 179.432 68.1057C175.297 68.1057 173.229 66.0852 173.229 62.0443C173.229 61.1045 173.723 57.4865 174.709 51.1902C175.696 44.8939 176.189 39.8897 176.189 36.1777C176.189 32.4187 175.79 28.9651 174.991 25.8169C177.764 26.8976 179.69 28.4482 180.771 30.4687C181.898 32.4422 182.462 34.7211 182.462 37.3054C182.462 39.8427 182.18 42.8029 181.617 46.186C183.167 40.8764 185.587 36.4126 188.876 32.7946C192.212 29.1295 195.313 27.297 198.18 27.297C203.066 27.297 205.51 30.6331 205.51 37.3054Z" stroke="#FF8900" mask="url(#path-2-outside-1_548_16140)" />
            <path d="M227.639 40.4065C226.277 42.7559 225.219 45.1758 224.467 47.6661C223.763 50.1095 223.41 52.4588 223.41 54.7142C223.41 56.9696 223.904 58.8961 224.89 60.4937C225.924 62.0443 227.381 62.8196 229.26 62.8196C231.892 62.8196 234.358 61.3865 236.661 58.5202C238.963 55.607 240.725 52.1064 241.947 48.0185C243.216 43.8836 243.85 40.0776 243.85 36.6006C243.85 33.0765 243.709 30.1868 243.427 27.9314C244.555 27.8374 245.259 27.7904 245.541 27.7904C249.535 27.7904 251.532 29.8344 251.532 33.9223C251.532 34.815 250.968 38.4566 249.841 44.8469C248.713 51.1902 248.149 56.2883 248.149 60.1413C248.149 63.9473 248.878 67.3304 250.334 70.2906C247.374 69.6798 245.212 68.3876 243.85 66.4141C242.534 64.3937 241.876 61.5039 241.876 57.7449C241.876 57.0871 241.97 55.8184 242.158 53.9389C240.89 58.4028 238.658 61.9973 235.463 64.7226C232.267 67.4479 229.119 68.8105 226.018 68.8105C222.964 68.8105 220.497 67.6593 218.618 65.3569C216.738 63.0075 215.798 59.7889 215.798 55.701C215.798 51.5661 216.55 47.5486 218.054 43.6487C219.604 39.7017 221.907 36.2951 224.961 33.4289C228.062 30.5627 231.516 29.1295 235.322 29.1295C236.966 29.1295 238.775 29.4585 240.749 30.1163C237.976 30.8211 235.439 32.1133 233.137 33.9928C230.834 35.8723 229.002 38.0102 227.639 40.4065Z" stroke="#FF8900" mask="url(#path-2-outside-1_548_16140)" />
        </svg>

    }


    let image =
        `<svg width="69" height="19" viewBox="0 0 69 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-outside-1_412_6764" maskUnits="userSpaceOnUse" x="-0.046875" y="1.1167" width="66" height="18" fill="black">
            <rect fill="white" x="-0.046875" y="1.1167" width="66" height="18" />
            <path d="M1.35021 13.7767C1.60291 12.08 1.79543 10.4495 1.9278 8.88525C2.07219 7.32096 2.14439 5.90107 2.14439 4.62558C2.14439 3.35008 2.06016 2.51379 1.8917 2.1167C2.89044 2.45362 3.51615 2.98307 3.76884 3.70505C4.70742 3.18763 5.67005 2.92893 6.65676 2.92893C7.64346 2.92893 8.50382 3.19967 9.23783 3.74115C9.98388 4.28264 10.3509 5.01063 10.3389 5.92514C10.3389 6.83965 10.044 7.63382 9.45443 8.30767C8.86481 8.98152 8.02852 9.49894 6.94555 9.85992C7.78786 10.0404 8.55195 10.4495 9.23783 11.0873C9.92371 11.713 10.2667 12.4891 10.2667 13.4157C10.2667 14.8356 9.76729 15.9306 8.76855 16.7007C7.78184 17.4588 6.53643 17.8378 5.03231 17.8378C3.54022 17.8378 2.18049 17.3745 0.953125 16.448C0.965158 16.3517 1.09752 15.4613 1.35021 13.7767ZM8.49781 6.48467C8.49781 5.81083 8.28121 5.27536 7.84803 4.87827C7.41484 4.46915 6.82522 4.26459 6.07918 4.26459C5.33313 4.26459 4.63522 4.42102 3.98544 4.73387C3.98544 5.49195 3.82299 7.38715 3.4981 10.4195C3.18524 13.4397 3.02881 15.377 3.02881 16.2314C3.6425 16.448 4.24415 16.5563 4.83376 16.5563C5.8686 16.5563 6.72294 16.2615 7.39679 15.6719C8.07063 15.0702 8.40756 14.264 8.40756 13.2532C8.40756 12.4951 8.0586 11.8333 7.36069 11.2678C6.67481 10.6902 5.71819 10.3773 4.49082 10.3292L4.43667 9.67943C5.71217 9.523 6.70489 9.1199 7.41484 8.47012C8.13682 7.8083 8.49781 7.14649 8.49781 6.48467Z" />
            <path d="M15.7485 10.3473C15.3995 10.9489 15.1288 11.5686 14.9363 12.2064C14.7558 12.8321 14.6655 13.4337 14.6655 14.0113C14.6655 14.5889 14.7919 15.0822 15.0445 15.4914C15.3093 15.8884 15.6823 16.087 16.1636 16.087C16.8375 16.087 17.4692 15.72 18.0588 14.986C18.6484 14.2399 19.0997 13.3435 19.4125 12.2966C19.7374 11.2377 19.8999 10.263 19.8999 9.37259C19.8999 8.47011 19.8638 7.73009 19.7916 7.1525C20.0804 7.12844 20.2608 7.1164 20.333 7.1164C21.3558 7.1164 21.8672 7.63984 21.8672 8.68671C21.8672 8.91534 21.7229 9.84789 21.4341 11.4844C21.1453 13.1088 21.0009 14.4144 21.0009 15.4011C21.0009 16.3758 21.1874 17.2422 21.5604 18.0002C20.8023 17.8438 20.2488 17.5129 19.8999 17.0075C19.5629 16.4901 19.3945 15.7501 19.3945 14.7874C19.3945 14.619 19.4185 14.2941 19.4667 13.8128C19.1418 14.9559 18.5702 15.8764 17.752 16.5743C16.9337 17.2722 16.1275 17.6212 15.3333 17.6212C14.5512 17.6212 13.9195 17.3264 13.4381 16.7368C12.9568 16.1351 12.7162 15.3109 12.7162 14.264C12.7162 13.2051 12.9087 12.1763 13.2937 11.1775C13.6908 10.1668 14.2805 9.29437 15.0626 8.56036C15.8568 7.82635 16.7412 7.45935 17.7159 7.45935C18.137 7.45935 18.6003 7.54358 19.1057 7.71204C18.3957 7.89253 17.746 8.22344 17.1563 8.70476C16.5667 9.18608 16.0974 9.73358 15.7485 10.3473Z" />
            <path d="M27.1335 11.5385C27.5667 9.97424 28.0841 8.82509 28.6857 8.09108C29.2994 7.34503 29.865 6.97201 30.3824 6.97201C30.9118 6.97201 31.3089 7.17055 31.5736 7.56764C31.8384 7.9527 31.9707 8.44605 31.9707 9.0477C31.9707 9.64935 31.8143 10.1728 31.5014 10.618C31.1886 11.0512 30.7313 11.2678 30.1297 11.2678C29.9372 11.2678 29.7867 11.2558 29.6784 11.2317C30.0876 10.6421 30.2921 10.0404 30.2921 9.42674C30.2921 8.99355 30.1176 8.77696 29.7687 8.77696C29.4919 8.77696 29.1971 8.9394 28.8843 9.26429C28.5714 9.58918 28.2706 10.0404 27.9818 10.618C27.693 11.1956 27.4523 11.9476 27.2598 12.8742C27.0673 13.8007 26.971 14.7995 26.971 15.8704C26.971 15.9907 27.0011 16.5021 27.0613 17.4046C26.7484 17.4287 26.5679 17.4407 26.5198 17.4407C25.4609 17.4407 24.9314 16.9233 24.9314 15.8885C24.9314 15.6478 25.0578 14.7213 25.3105 13.1088C25.5632 11.4964 25.6895 10.2149 25.6895 9.26429C25.6895 8.30165 25.5872 7.41723 25.3827 6.61102C26.0926 6.88778 26.586 7.28487 26.8627 7.80228C27.1515 8.30767 27.2959 8.9033 27.2959 9.58918C27.2959 10.263 27.2418 10.9128 27.1335 11.5385Z" />
            <path d="M35.9216 10.3473C35.5726 10.9489 35.3019 11.5686 35.1094 12.2064C34.9289 12.8321 34.8386 13.4337 34.8386 14.0113C34.8386 14.5889 34.965 15.0822 35.2177 15.4914C35.4824 15.8884 35.8554 16.087 36.3367 16.087C37.0106 16.087 37.6423 15.72 38.2319 14.986C38.8215 14.2399 39.2728 13.3435 39.5856 12.2966C39.9105 11.2377 40.073 10.263 40.073 9.37259C40.073 8.47011 40.0369 7.73009 39.9647 7.1525C40.2535 7.12844 40.434 7.1164 40.5062 7.1164C41.529 7.1164 42.0404 7.63984 42.0404 8.68671C42.0404 8.91534 41.896 9.84789 41.6072 11.4844C41.3184 13.1088 41.174 14.4144 41.174 15.4011C41.174 16.3758 41.3605 17.2422 41.7335 18.0002C40.9754 17.8438 40.4219 17.5129 40.073 17.0075C39.736 16.4901 39.5676 15.7501 39.5676 14.7874C39.5676 14.619 39.5916 14.2941 39.6398 13.8128C39.3149 14.9559 38.7433 15.8764 37.9251 16.5743C37.1068 17.2722 36.3006 17.6212 35.5064 17.6212C34.7243 17.6212 34.0926 17.3264 33.6113 16.7368C33.1299 16.1351 32.8893 15.3109 32.8893 14.264C32.8893 13.2051 33.0818 12.1763 33.4669 11.1775C33.8639 10.1668 34.4536 9.29437 35.2357 8.56036C36.0299 7.82635 36.9143 7.45935 37.889 7.45935C38.3101 7.45935 38.7734 7.54358 39.2788 7.71204C38.5688 7.89253 37.9191 8.22344 37.3294 8.70476C36.7398 9.18608 36.2705 9.73358 35.9216 10.3473Z" />
            <path d="M53.3712 9.55308C53.3712 10.1066 53.2509 11.0873 53.0102 12.4951C52.7816 13.891 52.6673 14.9078 52.6673 15.5455C52.6673 16.1833 52.7455 16.6465 52.9019 16.9353C53.0583 17.2121 53.3231 17.4527 53.6961 17.6573C53.5397 17.6693 53.3171 17.6753 53.0283 17.6753C52.21 17.6753 51.6264 17.5249 51.2775 17.2241C50.9405 16.9113 50.7721 16.3577 50.7721 15.5636C50.7721 15.1183 50.8924 14.2219 51.1331 12.8742C51.3737 11.5145 51.4941 10.5157 51.4941 9.87797C51.4941 9.0838 51.2534 8.68671 50.7721 8.68671C50.002 8.68671 49.2018 9.36657 48.3715 10.7263C47.5532 12.086 47.1441 13.7225 47.1441 15.6358C47.1441 16.3938 47.1802 16.9835 47.2524 17.4046C46.9396 17.4287 46.7531 17.4407 46.6929 17.4407C45.634 17.4407 45.1045 16.9233 45.1045 15.8885C45.1045 15.6478 45.2309 14.7213 45.4836 13.1088C45.7363 11.4964 45.8626 10.2149 45.8626 9.26429C45.8626 8.30165 45.7603 7.41723 45.5558 6.61102C46.2657 6.88778 46.7591 7.28487 47.0358 7.80228C47.3246 8.30767 47.469 8.89127 47.469 9.55308C47.469 10.2029 47.3968 10.9609 47.2524 11.8273C47.6495 10.4676 48.2692 9.32446 49.1115 8.39792C49.9659 7.45934 50.76 6.99006 51.4941 6.99006C52.7455 6.99006 53.3712 7.8444 53.3712 9.55308Z" />
            <path d="M59.0383 10.3473C58.6894 10.9489 58.4186 11.5686 58.2261 12.2064C58.0456 12.8321 57.9553 13.4337 57.9553 14.0113C57.9553 14.5889 58.0817 15.0822 58.3344 15.4914C58.5991 15.8884 58.9721 16.087 59.4534 16.087C60.1273 16.087 60.759 15.72 61.3486 14.986C61.9383 14.2399 62.3895 13.3435 62.7024 12.2966C63.0272 11.2377 63.1897 10.263 63.1897 9.37259C63.1897 8.47011 63.1536 7.73009 63.0814 7.1525C63.3702 7.12844 63.5507 7.1164 63.6229 7.1164C64.6457 7.1164 65.1571 7.63984 65.1571 8.68671C65.1571 8.91534 65.0127 9.84789 64.7239 11.4844C64.4351 13.1088 64.2907 14.4144 64.2907 15.4011C64.2907 16.3758 64.4772 17.2422 64.8502 18.0002C64.0922 17.8438 63.5386 17.5129 63.1897 17.0075C62.8528 16.4901 62.6843 15.7501 62.6843 14.7874C62.6843 14.619 62.7084 14.2941 62.7565 13.8128C62.4316 14.9559 61.86 15.8764 61.0418 16.5743C60.2236 17.2722 59.4173 17.6212 58.6232 17.6212C57.841 17.6212 57.2093 17.3264 56.728 16.7368C56.2467 16.1351 56.006 15.3109 56.006 14.264C56.006 13.2051 56.1985 12.1763 56.5836 11.1775C56.9807 10.1668 57.5703 9.29437 58.3524 8.56036C59.1466 7.82635 60.031 7.45935 61.0057 7.45935C61.4269 7.45935 61.8901 7.54358 62.3955 7.71204C61.6856 7.89253 61.0358 8.22344 60.4462 8.70476C59.8566 9.18608 59.3873 9.73358 59.0383 10.3473Z" />
        </mask>
        <path d="M1.35021 13.7767C1.60291 12.08 1.79543 10.4495 1.9278 8.88525C2.07219 7.32096 2.14439 5.90107 2.14439 4.62558C2.14439 3.35008 2.06016 2.51379 1.8917 2.1167C2.89044 2.45362 3.51615 2.98307 3.76884 3.70505C4.70742 3.18763 5.67005 2.92893 6.65676 2.92893C7.64346 2.92893 8.50382 3.19967 9.23783 3.74115C9.98388 4.28264 10.3509 5.01063 10.3389 5.92514C10.3389 6.83965 10.044 7.63382 9.45443 8.30767C8.86481 8.98152 8.02852 9.49894 6.94555 9.85992C7.78786 10.0404 8.55195 10.4495 9.23783 11.0873C9.92371 11.713 10.2667 12.4891 10.2667 13.4157C10.2667 14.8356 9.76729 15.9306 8.76855 16.7007C7.78184 17.4588 6.53643 17.8378 5.03231 17.8378C3.54022 17.8378 2.18049 17.3745 0.953125 16.448C0.965158 16.3517 1.09752 15.4613 1.35021 13.7767ZM8.49781 6.48467C8.49781 5.81083 8.28121 5.27536 7.84803 4.87827C7.41484 4.46915 6.82522 4.26459 6.07918 4.26459C5.33313 4.26459 4.63522 4.42102 3.98544 4.73387C3.98544 5.49195 3.82299 7.38715 3.4981 10.4195C3.18524 13.4397 3.02881 15.377 3.02881 16.2314C3.6425 16.448 4.24415 16.5563 4.83376 16.5563C5.8686 16.5563 6.72294 16.2615 7.39679 15.6719C8.07063 15.0702 8.40756 14.264 8.40756 13.2532C8.40756 12.4951 8.0586 11.8333 7.36069 11.2678C6.67481 10.6902 5.71819 10.3773 4.49082 10.3292L4.43667 9.67943C5.71217 9.523 6.70489 9.1199 7.41484 8.47012C8.13682 7.8083 8.49781 7.14649 8.49781 6.48467Z" fill="#FF8900" />
        <path d="M15.7485 10.3473C15.3995 10.9489 15.1288 11.5686 14.9363 12.2064C14.7558 12.8321 14.6655 13.4337 14.6655 14.0113C14.6655 14.5889 14.7919 15.0822 15.0445 15.4914C15.3093 15.8884 15.6823 16.087 16.1636 16.087C16.8375 16.087 17.4692 15.72 18.0588 14.986C18.6484 14.2399 19.0997 13.3435 19.4125 12.2966C19.7374 11.2377 19.8999 10.263 19.8999 9.37259C19.8999 8.47011 19.8638 7.73009 19.7916 7.1525C20.0804 7.12844 20.2608 7.1164 20.333 7.1164C21.3558 7.1164 21.8672 7.63984 21.8672 8.68671C21.8672 8.91534 21.7229 9.84789 21.4341 11.4844C21.1453 13.1088 21.0009 14.4144 21.0009 15.4011C21.0009 16.3758 21.1874 17.2422 21.5604 18.0002C20.8023 17.8438 20.2488 17.5129 19.8999 17.0075C19.5629 16.4901 19.3945 15.7501 19.3945 14.7874C19.3945 14.619 19.4185 14.2941 19.4667 13.8128C19.1418 14.9559 18.5702 15.8764 17.752 16.5743C16.9337 17.2722 16.1275 17.6212 15.3333 17.6212C14.5512 17.6212 13.9195 17.3264 13.4381 16.7368C12.9568 16.1351 12.7162 15.3109 12.7162 14.264C12.7162 13.2051 12.9087 12.1763 13.2937 11.1775C13.6908 10.1668 14.2805 9.29437 15.0626 8.56036C15.8568 7.82635 16.7412 7.45935 17.7159 7.45935C18.137 7.45935 18.6003 7.54358 19.1057 7.71204C18.3957 7.89253 17.746 8.22344 17.1563 8.70476C16.5667 9.18608 16.0974 9.73358 15.7485 10.3473Z" fill="#FF8900" />
        <path d="M27.1335 11.5385C27.5667 9.97424 28.0841 8.82509 28.6857 8.09108C29.2994 7.34503 29.865 6.97201 30.3824 6.97201C30.9118 6.97201 31.3089 7.17055 31.5736 7.56764C31.8384 7.9527 31.9707 8.44605 31.9707 9.0477C31.9707 9.64935 31.8143 10.1728 31.5014 10.618C31.1886 11.0512 30.7313 11.2678 30.1297 11.2678C29.9372 11.2678 29.7867 11.2558 29.6784 11.2317C30.0876 10.6421 30.2921 10.0404 30.2921 9.42674C30.2921 8.99355 30.1176 8.77696 29.7687 8.77696C29.4919 8.77696 29.1971 8.9394 28.8843 9.26429C28.5714 9.58918 28.2706 10.0404 27.9818 10.618C27.693 11.1956 27.4523 11.9476 27.2598 12.8742C27.0673 13.8007 26.971 14.7995 26.971 15.8704C26.971 15.9907 27.0011 16.5021 27.0613 17.4046C26.7484 17.4287 26.5679 17.4407 26.5198 17.4407C25.4609 17.4407 24.9314 16.9233 24.9314 15.8885C24.9314 15.6478 25.0578 14.7213 25.3105 13.1088C25.5632 11.4964 25.6895 10.2149 25.6895 9.26429C25.6895 8.30165 25.5872 7.41723 25.3827 6.61102C26.0926 6.88778 26.586 7.28487 26.8627 7.80228C27.1515 8.30767 27.2959 8.9033 27.2959 9.58918C27.2959 10.263 27.2418 10.9128 27.1335 11.5385Z" fill="#FF8900" />
        <path d="M35.9216 10.3473C35.5726 10.9489 35.3019 11.5686 35.1094 12.2064C34.9289 12.8321 34.8386 13.4337 34.8386 14.0113C34.8386 14.5889 34.965 15.0822 35.2177 15.4914C35.4824 15.8884 35.8554 16.087 36.3367 16.087C37.0106 16.087 37.6423 15.72 38.2319 14.986C38.8215 14.2399 39.2728 13.3435 39.5856 12.2966C39.9105 11.2377 40.073 10.263 40.073 9.37259C40.073 8.47011 40.0369 7.73009 39.9647 7.1525C40.2535 7.12844 40.434 7.1164 40.5062 7.1164C41.529 7.1164 42.0404 7.63984 42.0404 8.68671C42.0404 8.91534 41.896 9.84789 41.6072 11.4844C41.3184 13.1088 41.174 14.4144 41.174 15.4011C41.174 16.3758 41.3605 17.2422 41.7335 18.0002C40.9754 17.8438 40.4219 17.5129 40.073 17.0075C39.736 16.4901 39.5676 15.7501 39.5676 14.7874C39.5676 14.619 39.5916 14.2941 39.6398 13.8128C39.3149 14.9559 38.7433 15.8764 37.9251 16.5743C37.1068 17.2722 36.3006 17.6212 35.5064 17.6212C34.7243 17.6212 34.0926 17.3264 33.6113 16.7368C33.1299 16.1351 32.8893 15.3109 32.8893 14.264C32.8893 13.2051 33.0818 12.1763 33.4669 11.1775C33.8639 10.1668 34.4536 9.29437 35.2357 8.56036C36.0299 7.82635 36.9143 7.45935 37.889 7.45935C38.3101 7.45935 38.7734 7.54358 39.2788 7.71204C38.5688 7.89253 37.9191 8.22344 37.3294 8.70476C36.7398 9.18608 36.2705 9.73358 35.9216 10.3473Z" fill="#FF8900" />
        <path d="M53.3712 9.55308C53.3712 10.1066 53.2509 11.0873 53.0102 12.4951C52.7816 13.891 52.6673 14.9078 52.6673 15.5455C52.6673 16.1833 52.7455 16.6465 52.9019 16.9353C53.0583 17.2121 53.3231 17.4527 53.6961 17.6573C53.5397 17.6693 53.3171 17.6753 53.0283 17.6753C52.21 17.6753 51.6264 17.5249 51.2775 17.2241C50.9405 16.9113 50.7721 16.3577 50.7721 15.5636C50.7721 15.1183 50.8924 14.2219 51.1331 12.8742C51.3737 11.5145 51.4941 10.5157 51.4941 9.87797C51.4941 9.0838 51.2534 8.68671 50.7721 8.68671C50.002 8.68671 49.2018 9.36657 48.3715 10.7263C47.5532 12.086 47.1441 13.7225 47.1441 15.6358C47.1441 16.3938 47.1802 16.9835 47.2524 17.4046C46.9396 17.4287 46.7531 17.4407 46.6929 17.4407C45.634 17.4407 45.1045 16.9233 45.1045 15.8885C45.1045 15.6478 45.2309 14.7213 45.4836 13.1088C45.7363 11.4964 45.8626 10.2149 45.8626 9.26429C45.8626 8.30165 45.7603 7.41723 45.5558 6.61102C46.2657 6.88778 46.7591 7.28487 47.0358 7.80228C47.3246 8.30767 47.469 8.89127 47.469 9.55308C47.469 10.2029 47.3968 10.9609 47.2524 11.8273C47.6495 10.4676 48.2692 9.32446 49.1115 8.39792C49.9659 7.45934 50.76 6.99006 51.4941 6.99006C52.7455 6.99006 53.3712 7.8444 53.3712 9.55308Z" fill="#FF8900" />
        <path d="M59.0383 10.3473C58.6894 10.9489 58.4186 11.5686 58.2261 12.2064C58.0456 12.8321 57.9553 13.4337 57.9553 14.0113C57.9553 14.5889 58.0817 15.0822 58.3344 15.4914C58.5991 15.8884 58.9721 16.087 59.4534 16.087C60.1273 16.087 60.759 15.72 61.3486 14.986C61.9383 14.2399 62.3895 13.3435 62.7024 12.2966C63.0272 11.2377 63.1897 10.263 63.1897 9.37259C63.1897 8.47011 63.1536 7.73009 63.0814 7.1525C63.3702 7.12844 63.5507 7.1164 63.6229 7.1164C64.6457 7.1164 65.1571 7.63984 65.1571 8.68671C65.1571 8.91534 65.0127 9.84789 64.7239 11.4844C64.4351 13.1088 64.2907 14.4144 64.2907 15.4011C64.2907 16.3758 64.4772 17.2422 64.8502 18.0002C64.0922 17.8438 63.5386 17.5129 63.1897 17.0075C62.8528 16.4901 62.6843 15.7501 62.6843 14.7874C62.6843 14.619 62.7084 14.2941 62.7565 13.8128C62.4316 14.9559 61.86 15.8764 61.0418 16.5743C60.2236 17.2722 59.4173 17.6212 58.6232 17.6212C57.841 17.6212 57.2093 17.3264 56.728 16.7368C56.2467 16.1351 56.006 15.3109 56.006 14.264C56.006 13.2051 56.1985 12.1763 56.5836 11.1775C56.9807 10.1668 57.5703 9.29437 58.3524 8.56036C59.1466 7.82635 60.031 7.45935 61.0057 7.45935C61.4269 7.45935 61.8901 7.54358 62.3955 7.71204C61.6856 7.89253 61.0358 8.22344 60.4462 8.70476C59.8566 9.18608 59.3873 9.73358 59.0383 10.3473Z" fill="#FF8900" />
        <path d="M1.35021 13.7767C1.60291 12.08 1.79543 10.4495 1.9278 8.88525C2.07219 7.32096 2.14439 5.90107 2.14439 4.62558C2.14439 3.35008 2.06016 2.51379 1.8917 2.1167C2.89044 2.45362 3.51615 2.98307 3.76884 3.70505C4.70742 3.18763 5.67005 2.92893 6.65676 2.92893C7.64346 2.92893 8.50382 3.19967 9.23783 3.74115C9.98388 4.28264 10.3509 5.01063 10.3389 5.92514C10.3389 6.83965 10.044 7.63382 9.45443 8.30767C8.86481 8.98152 8.02852 9.49894 6.94555 9.85992C7.78786 10.0404 8.55195 10.4495 9.23783 11.0873C9.92371 11.713 10.2667 12.4891 10.2667 13.4157C10.2667 14.8356 9.76729 15.9306 8.76855 16.7007C7.78184 17.4588 6.53643 17.8378 5.03231 17.8378C3.54022 17.8378 2.18049 17.3745 0.953125 16.448C0.965158 16.3517 1.09752 15.4613 1.35021 13.7767ZM8.49781 6.48467C8.49781 5.81083 8.28121 5.27536 7.84803 4.87827C7.41484 4.46915 6.82522 4.26459 6.07918 4.26459C5.33313 4.26459 4.63522 4.42102 3.98544 4.73387C3.98544 5.49195 3.82299 7.38715 3.4981 10.4195C3.18524 13.4397 3.02881 15.377 3.02881 16.2314C3.6425 16.448 4.24415 16.5563 4.83376 16.5563C5.8686 16.5563 6.72294 16.2615 7.39679 15.6719C8.07063 15.0702 8.40756 14.264 8.40756 13.2532C8.40756 12.4951 8.0586 11.8333 7.36069 11.2678C6.67481 10.6902 5.71819 10.3773 4.49082 10.3292L4.43667 9.67943C5.71217 9.523 6.70489 9.1199 7.41484 8.47012C8.13682 7.8083 8.49781 7.14649 8.49781 6.48467Z" stroke="#FF8900" mask="url(#path-1-outside-1_412_6764)" />
        <path d="M15.7485 10.3473C15.3995 10.9489 15.1288 11.5686 14.9363 12.2064C14.7558 12.8321 14.6655 13.4337 14.6655 14.0113C14.6655 14.5889 14.7919 15.0822 15.0445 15.4914C15.3093 15.8884 15.6823 16.087 16.1636 16.087C16.8375 16.087 17.4692 15.72 18.0588 14.986C18.6484 14.2399 19.0997 13.3435 19.4125 12.2966C19.7374 11.2377 19.8999 10.263 19.8999 9.37259C19.8999 8.47011 19.8638 7.73009 19.7916 7.1525C20.0804 7.12844 20.2608 7.1164 20.333 7.1164C21.3558 7.1164 21.8672 7.63984 21.8672 8.68671C21.8672 8.91534 21.7229 9.84789 21.4341 11.4844C21.1453 13.1088 21.0009 14.4144 21.0009 15.4011C21.0009 16.3758 21.1874 17.2422 21.5604 18.0002C20.8023 17.8438 20.2488 17.5129 19.8999 17.0075C19.5629 16.4901 19.3945 15.7501 19.3945 14.7874C19.3945 14.619 19.4185 14.2941 19.4667 13.8128C19.1418 14.9559 18.5702 15.8764 17.752 16.5743C16.9337 17.2722 16.1275 17.6212 15.3333 17.6212C14.5512 17.6212 13.9195 17.3264 13.4381 16.7368C12.9568 16.1351 12.7162 15.3109 12.7162 14.264C12.7162 13.2051 12.9087 12.1763 13.2937 11.1775C13.6908 10.1668 14.2805 9.29437 15.0626 8.56036C15.8568 7.82635 16.7412 7.45935 17.7159 7.45935C18.137 7.45935 18.6003 7.54358 19.1057 7.71204C18.3957 7.89253 17.746 8.22344 17.1563 8.70476C16.5667 9.18608 16.0974 9.73358 15.7485 10.3473Z" stroke="#FF8900" mask="url(#path-1-outside-1_412_6764)" />
        <path d="M27.1335 11.5385C27.5667 9.97424 28.0841 8.82509 28.6857 8.09108C29.2994 7.34503 29.865 6.97201 30.3824 6.97201C30.9118 6.97201 31.3089 7.17055 31.5736 7.56764C31.8384 7.9527 31.9707 8.44605 31.9707 9.0477C31.9707 9.64935 31.8143 10.1728 31.5014 10.618C31.1886 11.0512 30.7313 11.2678 30.1297 11.2678C29.9372 11.2678 29.7867 11.2558 29.6784 11.2317C30.0876 10.6421 30.2921 10.0404 30.2921 9.42674C30.2921 8.99355 30.1176 8.77696 29.7687 8.77696C29.4919 8.77696 29.1971 8.9394 28.8843 9.26429C28.5714 9.58918 28.2706 10.0404 27.9818 10.618C27.693 11.1956 27.4523 11.9476 27.2598 12.8742C27.0673 13.8007 26.971 14.7995 26.971 15.8704C26.971 15.9907 27.0011 16.5021 27.0613 17.4046C26.7484 17.4287 26.5679 17.4407 26.5198 17.4407C25.4609 17.4407 24.9314 16.9233 24.9314 15.8885C24.9314 15.6478 25.0578 14.7213 25.3105 13.1088C25.5632 11.4964 25.6895 10.2149 25.6895 9.26429C25.6895 8.30165 25.5872 7.41723 25.3827 6.61102C26.0926 6.88778 26.586 7.28487 26.8627 7.80228C27.1515 8.30767 27.2959 8.9033 27.2959 9.58918C27.2959 10.263 27.2418 10.9128 27.1335 11.5385Z" stroke="#FF8900" mask="url(#path-1-outside-1_412_6764)" />
        <path d="M35.9216 10.3473C35.5726 10.9489 35.3019 11.5686 35.1094 12.2064C34.9289 12.8321 34.8386 13.4337 34.8386 14.0113C34.8386 14.5889 34.965 15.0822 35.2177 15.4914C35.4824 15.8884 35.8554 16.087 36.3367 16.087C37.0106 16.087 37.6423 15.72 38.2319 14.986C38.8215 14.2399 39.2728 13.3435 39.5856 12.2966C39.9105 11.2377 40.073 10.263 40.073 9.37259C40.073 8.47011 40.0369 7.73009 39.9647 7.1525C40.2535 7.12844 40.434 7.1164 40.5062 7.1164C41.529 7.1164 42.0404 7.63984 42.0404 8.68671C42.0404 8.91534 41.896 9.84789 41.6072 11.4844C41.3184 13.1088 41.174 14.4144 41.174 15.4011C41.174 16.3758 41.3605 17.2422 41.7335 18.0002C40.9754 17.8438 40.4219 17.5129 40.073 17.0075C39.736 16.4901 39.5676 15.7501 39.5676 14.7874C39.5676 14.619 39.5916 14.2941 39.6398 13.8128C39.3149 14.9559 38.7433 15.8764 37.9251 16.5743C37.1068 17.2722 36.3006 17.6212 35.5064 17.6212C34.7243 17.6212 34.0926 17.3264 33.6113 16.7368C33.1299 16.1351 32.8893 15.3109 32.8893 14.264C32.8893 13.2051 33.0818 12.1763 33.4669 11.1775C33.8639 10.1668 34.4536 9.29437 35.2357 8.56036C36.0299 7.82635 36.9143 7.45935 37.889 7.45935C38.3101 7.45935 38.7734 7.54358 39.2788 7.71204C38.5688 7.89253 37.9191 8.22344 37.3294 8.70476C36.7398 9.18608 36.2705 9.73358 35.9216 10.3473Z" stroke="#FF8900" mask="url(#path-1-outside-1_412_6764)" />
        <path d="M53.3712 9.55308C53.3712 10.1066 53.2509 11.0873 53.0102 12.4951C52.7816 13.891 52.6673 14.9078 52.6673 15.5455C52.6673 16.1833 52.7455 16.6465 52.9019 16.9353C53.0583 17.2121 53.3231 17.4527 53.6961 17.6573C53.5397 17.6693 53.3171 17.6753 53.0283 17.6753C52.21 17.6753 51.6264 17.5249 51.2775 17.2241C50.9405 16.9113 50.7721 16.3577 50.7721 15.5636C50.7721 15.1183 50.8924 14.2219 51.1331 12.8742C51.3737 11.5145 51.4941 10.5157 51.4941 9.87797C51.4941 9.0838 51.2534 8.68671 50.7721 8.68671C50.002 8.68671 49.2018 9.36657 48.3715 10.7263C47.5532 12.086 47.1441 13.7225 47.1441 15.6358C47.1441 16.3938 47.1802 16.9835 47.2524 17.4046C46.9396 17.4287 46.7531 17.4407 46.6929 17.4407C45.634 17.4407 45.1045 16.9233 45.1045 15.8885C45.1045 15.6478 45.2309 14.7213 45.4836 13.1088C45.7363 11.4964 45.8626 10.2149 45.8626 9.26429C45.8626 8.30165 45.7603 7.41723 45.5558 6.61102C46.2657 6.88778 46.7591 7.28487 47.0358 7.80228C47.3246 8.30767 47.469 8.89127 47.469 9.55308C47.469 10.2029 47.3968 10.9609 47.2524 11.8273C47.6495 10.4676 48.2692 9.32446 49.1115 8.39792C49.9659 7.45934 50.76 6.99006 51.4941 6.99006C52.7455 6.99006 53.3712 7.8444 53.3712 9.55308Z" stroke="#FF8900" mask="url(#path-1-outside-1_412_6764)" />
        <path d="M59.0383 10.3473C58.6894 10.9489 58.4186 11.5686 58.2261 12.2064C58.0456 12.8321 57.9553 13.4337 57.9553 14.0113C57.9553 14.5889 58.0817 15.0822 58.3344 15.4914C58.5991 15.8884 58.9721 16.087 59.4534 16.087C60.1273 16.087 60.759 15.72 61.3486 14.986C61.9383 14.2399 62.3895 13.3435 62.7024 12.2966C63.0272 11.2377 63.1897 10.263 63.1897 9.37259C63.1897 8.47011 63.1536 7.73009 63.0814 7.1525C63.3702 7.12844 63.5507 7.1164 63.6229 7.1164C64.6457 7.1164 65.1571 7.63984 65.1571 8.68671C65.1571 8.91534 65.0127 9.84789 64.7239 11.4844C64.4351 13.1088 64.2907 14.4144 64.2907 15.4011C64.2907 16.3758 64.4772 17.2422 64.8502 18.0002C64.0922 17.8438 63.5386 17.5129 63.1897 17.0075C62.8528 16.4901 62.6843 15.7501 62.6843 14.7874C62.6843 14.619 62.7084 14.2941 62.7565 13.8128C62.4316 14.9559 61.86 15.8764 61.0418 16.5743C60.2236 17.2722 59.4173 17.6212 58.6232 17.6212C57.841 17.6212 57.2093 17.3264 56.728 16.7368C56.2467 16.1351 56.006 15.3109 56.006 14.264C56.006 13.2051 56.1985 12.1763 56.5836 11.1775C56.9807 10.1668 57.5703 9.29437 58.3524 8.56036C59.1466 7.82635 60.031 7.45935 61.0057 7.45935C61.4269 7.45935 61.8901 7.54358 62.3955 7.71204C61.6856 7.89253 61.0358 8.22344 60.4462 8.70476C59.8566 9.18608 59.3873 9.73358 59.0383 10.3473Z" stroke="#FF8900" mask="url(#path-1-outside-1_412_6764)" />
        <path d="M64.6077 4.21236C63.6579 4.76073 62.6002 4.70683 62.2452 4.09199C61.8902 3.47714 62.3724 2.53417 63.3222 1.98581C64.272 1.43744 67.2292 0.809657 67.4045 1.11328C67.5798 1.4169 65.5575 3.664 64.6077 4.21236Z" fill="#65B864" />
    </svg>`
    return <SvgXml fill={color} xml={image} height={height} width={width} />
}