import { useState } from "react";
import { Text, ScrollView, Platform, View } from "react-native";
import { ScrollTo } from "@nandorojo/anchor";
//styles
import {
  styles,
  navHomeOptions,
  navHomeText,
  navContainer,
} from "../../../styles/pages/generalStyles";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

export const NavHome = ({ onPressanchors }) => {
  const [selectedOption, setSelectedOption] = useState("intro");
  const { isComputer } = useIsWeb();

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      style={styles.navHome}
      contentContainerStyle={[
        {
          justifyContent: "center",
          alignItems: "center",
          width: isComputer && "100%",
        },
      ]}
    >
      <View style={navContainer(1000)}>
        <ScrollTo
          onPress={() => {
            setSelectedOption("intro");
            onPressanchors("homePage/intro");
          }}
          style={[navHomeOptions(selectedOption == "intro")]}
        >
          <Text style={[navHomeText(selectedOption == "intro")]}>Intro</Text>
        </ScrollTo>
        {Platform.OS == "web" ? (
          <ScrollTo
            onPress={() => {
              setSelectedOption("howItWorks");
              onPressanchors("homePage/howItWorks");
            }}
            style={navHomeOptions(selectedOption == "howItWorks")}
          >
            <Text style={[navHomeText(selectedOption == "howItWorks")]}>
              ¿Cómo Funciona?
            </Text>
          </ScrollTo>
        ) : null}
        <ScrollTo
          onPress={() => {
            setSelectedOption("packages");
            onPressanchors("homePage/packages");
          }}
          style={navHomeOptions(selectedOption == "packages")}
        >
          <Text style={[navHomeText(selectedOption == "packages")]}>
            Paquetes
          </Text>
        </ScrollTo>
        <ScrollTo
          onPress={() => {
            setSelectedOption("supermarket");
            onPressanchors("homePage/supermarket");
          }}
          style={[navHomeOptions(selectedOption == "supermarket")]}
        >
          <Text style={[navHomeText(selectedOption == "supermarket")]}>
            Supermercado
          </Text>
        </ScrollTo>

        <ScrollTo
          onPress={() => {
            setSelectedOption("whyBarana");
            onPressanchors("homePage/whyBarana");
          }}
          style={[navHomeOptions(selectedOption == "whyBarana")]}
        >
          <Text style={[navHomeText(selectedOption == "whyBarana")]}>
            Más Ventajas
          </Text>
        </ScrollTo>
        <ScrollTo
          onPress={() => {
            setSelectedOption("packagingTechnology");
            onPressanchors("homePage/packagingTechnology");
          }}
          style={[navHomeOptions(selectedOption == "packagingTechnology")]}
        >
          <Text style={[navHomeText(selectedOption == "packagingTechnology")]}>
            Tecnología de empaque
          </Text>
        </ScrollTo>
        <ScrollTo
          onPress={() => {
            setSelectedOption("frequentlyQuestions");
            onPressanchors("homePage/frequentlyQuestions");
          }}
          style={[navHomeOptions(selectedOption == "frequentlyQuestions")]}
        >
          <Text style={[navHomeText(selectedOption == "frequentlyQuestions")]}>
            Preguntas frecuentes
          </Text>
        </ScrollTo>
        <ScrollTo
          onPress={() => {
            setSelectedOption("order");
            onPressanchors("homePage/order");
          }}
          style={[navHomeOptions(selectedOption == "order")]}
        >
          <Text style={[navHomeText(selectedOption == "order")]}>Ordenar</Text>
        </ScrollTo>
      </View>
    </ScrollView>
  );
};
