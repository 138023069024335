import { useContext, useEffect } from "react";
import { Text, View } from "react-native";
import { ScrollTo } from "@nandorojo/anchor";
//styles
import {
  styles,
  navHomeOptions,
  navHomeText,
  navContainer,
} from "../../../../styles/pages/generalStyles";
import { mainContext } from "../../../../context/mainContext";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";

export const NavSupermarket = ({ onPressanchors }) => {
  const { selectedOption, setSelectedOption } = useContext(mainContext);
  const { isComputer } = useIsWeb();

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (selectedOption == "meat") {
  //       onPressanchors("supermarket/meat");
  //     } else if (selectedOption == "vegetable") {
  //       onPressanchors("supermarket/vegetable");
  //     } else if (selectedOption == "flour") {
  //       onPressanchors("supermarket/flour");
  //     } else if (selectedOption == "Snack") {
  //       onPressanchors("supermarket/Snack");
  //     } else {
  //       onPressanchors("supermarket/combos");
  //     }
  //   }, 80);
  // }, [selectedOption]);

  return (
    <View style={styles.navHome}>
      <View style={[navContainer(1060)]}>
        <ScrollTo
          onPress={() => {
            setSelectedOption("combos");
            onPressanchors("supermarket/combos");
          }}
          style={[navHomeOptions(selectedOption == "combos")]}
        >
          <Text style={[navHomeText(selectedOption == "combos")]}>Combos</Text>
        </ScrollTo>
        <ScrollTo
          onPress={() => {
            setSelectedOption("meat");
            onPressanchors("supermarket/meat");
          }}
          style={[navHomeOptions(selectedOption == "meat")]}
        >
          <Text style={[navHomeText(selectedOption == "meat")]}>Carne</Text>
        </ScrollTo>
        <ScrollTo
          onPress={() => {
            setSelectedOption("vegetable");
            onPressanchors("supermarket/vegetable");
          }}
          style={[navHomeOptions(selectedOption == "vegetable")]}
        >
          <Text style={[navHomeText(selectedOption == "vegetable")]}>
            Vegetal
          </Text>
        </ScrollTo>
        <ScrollTo
          onPress={() => {
            setSelectedOption("flour");
            onPressanchors("supermarket/flour");
          }}
          style={[navHomeOptions(selectedOption == "flour")]}
        >
          <Text style={[navHomeText(selectedOption == "flour")]}>Harina</Text>
        </ScrollTo>
        <ScrollTo
          onPress={() => {
            setSelectedOption("Snack");
            onPressanchors("supermarket/Snack");
          }}
          style={[navHomeOptions(selectedOption == "Snack")]}
        >
          <Text style={[navHomeText(selectedOption == "Snack")]}>Merienda</Text>
        </ScrollTo>
      </View>
    </View>
  );
};
