import { Platform } from 'react-native';
import { SvgXml, Path, Svg } from 'react-native-svg';

export const ArrowBack = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    if (Platform.OS == 'web') {
        return (
            <Svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <Path d="M19 12H5" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <Path d="M12 19L5 12L12 5" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </Svg>
        )
    }

    let image =
        `<svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 12H5" stroke="#DB7500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 19L5 12L12 5" stroke="#DB7500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`
    return <SvgXml fill={color} xml={image} height={height} width={width} />
}