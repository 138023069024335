import { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  Linking,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  title,
  titleMargin,
  paragraph,
  webContainer,
  flexContainer,
  sectionContainerWeb,
} from "../../../styles/pages/generalStyles";
import {
  homeStyles,
  centerAlignedRow,
} from "../../../styles/pages/home/homePage";
//components
import { GeneralButton } from "../../widgets/generalButton";
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";

import { SupermarketListItem } from "../shoppingCart/supermarketProducts/supermarketListItem";
import { ScheduledAppointmentList } from "../shoppingCart/scheduledAppointment/listItems";
import { PackageProductsList } from "../shoppingCart/packageProducts/packageProducts";
// contexts
import { orderContext } from "../../../context/orderingContext";
import { shoppingCart } from "../../../context/shoppingCart";
//utils
import { orderDefinitionInitialValues } from "../../utils/shoppingCart/initialValues";
import { capitalize } from "../../utils/utils";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useModal from "../../../hooks/useModal";

const { width } = Dimensions.get("window");
export const ThanksForYourPurchase = ({ navigation, bottomTabNavigation }) => {
  const { setCurrentStep, setOrderDefinition } = useContext(orderContext);
  const { combos, setCombos, plates, setPlates } = useContext(shoppingCart);
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hideBottomMenu, setHideBottomMenu] = useState(false);
  const { isComputer } = useIsWeb();
  const { openMessageModal } = useModal();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  useEffect(() => {
    getCart();
    setTimeout(() => setHideBottomMenu(true), 100);
  }, []);

  const getCart = async () => {
    const cartAux = await AsyncStorage.getItem("shoppingCart_copy");
    setCart(JSON.parse(cartAux));
  };
  const initializeSupermarket = async () => {
    if (cart?.combos?.length > 0) {
      setCombos(combos.map((item) => ({ ...item, quantity: 0 })));
    }
    if (cart?.plates_supermaker?.length > 0) {
      setPlates(plates.map((item) => ({ ...item, quantity: 0 })));
    }
  };
  return (
    <View style={[styles.screen]}>
      <ScrollView>
        <View
          style={[
            styles.container,
            webContainer("calc(100vh - 160px)"),
            sectionContainerWeb(800, null, "100%"),
          ]}
        >
          <View style={styles.justifyContent}>
            <BaranaIcons draw={{ width: 80, height: 62 }} name="soup" />
          </View>
          <Text
            style={[
              title("100%", "center"),
              titleMargin(isComputer),
              { marginBottom: 24, marginTop: 15 },
            ]}
          >
            Gracias por su Compra
          </Text>

          <View style={[centerAlignedRow()]}>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="cube" />
            <Text style={[homeStyles.strongText, { marginLeft: 10 }]}>
              Entrega: {cart?.day_of_delivery || ""}
            </Text>
          </View>

          <View style={[styles.line, { marginVertical: 25 }]}></View>
          <View style={[centerAlignedRow()]}>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="location" />
            <Text style={[paragraph("75%"), { marginLeft: 10 }]}>
              {cart?.address?.province ? `${cart.address.province}, ` : ""}
              {cart?.address?.zone ? `${cart.address.zone}, ` : ""}
              {cart?.address?.location ? `${cart.address.location}` : ""}
              {cart?.address?.text ? `,  ${cart.address?.text}. ` : ""}
            </Text>
          </View>

          <View style={[styles.line, { marginVertical: 25 }]}></View>
          <View style={[centerAlignedRow()]}>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="colones" />
            <Text style={[paragraph("75%"), { marginLeft: 10 }]}>
              Método de pago: {capitalize(cart?.type_method_pay) || ""}
            </Text>
          </View>
          {cart?.type_method_pay === "transferencia" ? (
            <TouchableOpacity
              onPress={
                async () =>
                  await Linking.openURL(
                    "https://storage.3.basecamp.com/5461918/blobs/19f8850c-6d13-11ee-9aba-36ef172d8ce0/download/Detalles%20Bancarios%20Baran%C3%A1.pdf"
                  )
                // openMessageModal(
                //   "Banco Nacional: \nCuenta IBAN: CR61016100012345678900"
                // )
              }
            >
              <Text
                style={{ ...styles.orangeText, fontSize: 15, marginLeft: 34 }}
              >
                Ver números de cuenta
              </Text>
            </TouchableOpacity>
          ) : null}

          <View style={[styles.line, { marginVertical: 25 }]}></View>
          <View style={[centerAlignedRow()]}>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="note" />
            <Text style={[paragraph("75%"), { marginLeft: 10 }]}>
              Factura electrónica: {cart?.facture ? "Si" : "No"}
            </Text>
          </View>

          {/* <--------------------- cart.combos ------------------> */}
          {cart?.combos?.length > 0 || cart?.plates_supermaker?.length > 0 ? (
            <View style={[styles.line, { marginTop: 25 }]}></View>
          ) : null}
          {cart?.combos?.length > 0 && (
            <View style={[centerAlignedRow()]}>
              <SupermarketListItem
                list={cart.combos}
                comboList={true}
                editable={false}
                margin={25}
              />
            </View>
          )}

          {/* <--------------------- plates supermaker ------------------> */}
          {cart?.plates_supermaker?.length > 0 && (
            <View style={[centerAlignedRow()]}>
              <SupermarketListItem
                list={cart.plates_supermaker}
                comboList={false}
                editable={false}
                margin={25}
              />
            </View>
          )}

          {/* <--------------------- armed packages ------------------> */}
          {cart?.armed_packages?.length > 0 && (
            <View style={{ width: !isComputer ? width - 44 : "100%" }}>
              {cart.armed_packages.map((item, index) => {
                return (
                  <PackageProductsList
                    armedPackages={item}
                    key={item.sub_packages.sub_package_id}
                  />
                );
              })}
            </View>
          )}

          {/* <--------------------- nutritional consultation ------------------> */}
          {cart?.nutritional_consultation?.length > 0 && (
            <ScheduledAppointmentList
              nutritionalConsultes={cart.nutritional_consultation}
            />
          )}

          <View style={[styles.line]}></View>
          <View style={[centerAlignedRow(25, "space-between")]}>
            <Text style={homeStyles.strongText}>Transporte</Text>
            <Text style={homeStyles.strongText}>₡ {cart?.transporte}</Text>
          </View>

          <View style={[styles.line]}></View>
          {/* <View style={[styles.line, { marginVertical: 25 }]}></View> */}
          <View
            style={[
              centerAlignedRow(25, "space-between"),
              { paddingBottom: 30 },
            ]}
          >
            <Text style={homeStyles.strongText}>Total</Text>
            <Text style={homeStyles.strongText}>
              ₡ {(cart?.total_price + cart?.transporte).toFixed(2)}
            </Text>
          </View>
        </View>
      </ScrollView>
      <LowerOptionsContainer>
        <GeneralButton
          text="Continuar"
          loading={loading}
          handleOnPress={async () => {
            await initializeSupermarket();
            setOrderDefinition({ ...orderDefinitionInitialValues });
            setCurrentStep(1);

            if (cart.type_method_pay === "sinpe-movil" && !isComputer) {
              navigation.navigate("SinpeMovil");
            } else {
              await AsyncStorage.removeItem("cartArmedPackages");
              navigation.reset({ index: 0, routes: [{ name: "Home" }] });
            }
          }}
          width={isComputer ? 350 : "100%"}
        />
      </LowerOptionsContainer>
    </View>
  );
};
