import { useContext } from "react";
import { View, Text, TouchableOpacity } from "react-native";
//context
import { mainContext } from "../../../context/mainContext";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//styles
import {
  styles,
  title,
  titleMargin,
  paragraph,
  smallButton,
  flexContainer,
  sectionContainerWeb,
} from "../../../styles/pages/generalStyles";
import {
  sections,
  centerAlignedRow,
  container,
} from "../../../styles/pages/home/homePage";
//components
import { GeneralButton } from "../../widgets/generalButton";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

export const Order = ({ background, navigation, setOpenPopup }) => {
  const { cuttingDay } = useContext(mainContext);
  const { isComputer } = useIsWeb();

  return (
    <View style={sections(background == "dark", 350, null)}>
      <View style={[container()]}>
        <View
          style={[
            sectionContainerWeb(1000, null, "100%"),
            flexContainer("row", "center", "center", "wrap", "center", 10, 40),
          ]}
        >
          <View style={sectionContainerWeb(500, null, "100%")}>
            <Text
              style={[
                title("100%", "left", null, isComputer),
                titleMargin(isComputer),
              ]}
            >
              Saludable de verdad, simple de verdad.
            </Text>
            <Text style={paragraph("98%")}>
              Ordene en 3 minutos y reciba su comida preparada y fresca, sin
              preocupaciones.
            </Text>
            <View style={centerAlignedRow(25, "space-between")}>
              <GeneralButton
                text="Ordenar"
                handleOnPress={() => {
                  navigation.navigate("Signup");
                }}
                width={isComputer ? 260 : 110}
              />
              <Text style={[paragraph("50%"), { marginHorizontal: 18 }]}>
                {cuttingDay?.day_delivery_general || ""}
              </Text>
              <TouchableOpacity
                style={smallButton()}
                onPress={() => setOpenPopup(true)}
              >
                <BaranaIcons
                  draw={{ width: 22, height: 22 }}
                  name={"helpIcon"}
                />
              </TouchableOpacity>
            </View>
            <View style={[styles.line]}></View>
            <View
              style={[
                centerAlignedRow(null, "space-between"),
                { marginTop: 16 },
              ]}
            >
              <Text style={styles.generalText}>Corte de pedidos</Text>
              <View style={centerAlignedRow()}>
                <BaranaIcons draw={{ width: 20, height: 20 }} name={"clock"} />
                <Text style={styles.timeText}>
                  {" "}
                  {cuttingDay?.dia_corte || ""}
                </Text>
              </View>
            </View>
          </View>

          {isComputer ? (
            <View
              style={flexContainer(
                "column",
                "center",
                "center",
                null,
                null,
                null,
                15
              )}
            >
              <Text
                style={[
                  paragraph("70%", 24),
                  { textAlign: "center", lineHeight: 30, fontWeight: 800 },
                ]}
              >
                El app de comida saludable ¡Descárguela ya!
              </Text>
              <BaranaIcons
                draw={{ width: 174, height: 55 }}
                name={"AppStore"}
              />
              <BaranaIcons
                draw={{ width: 174, height: 63 }}
                name={"GooglePay"}
              />
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};
