import { Svg, Path, ClipPath, G, Defs, Rect } from 'react-native-svg';

export const ColorLocation = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <G clip-path="url(#clip0_1432_23646)">
                <Path d="M21 10.625C21 17.625 12 23.625 12 23.625C12 23.625 3 17.625 3 10.625C3 8.23805 3.94821 5.94887 5.63604 4.26104C7.32387 2.57321 9.61305 1.625 12 1.625C14.3869 1.625 16.6761 2.57321 18.364 4.26104C20.0518 5.94887 21 8.23805 21 10.625Z" stroke="#DB7500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <Path d="M12 13.625C13.6569 13.625 15 12.2819 15 10.625C15 8.96815 13.6569 7.625 12 7.625C10.3431 7.625 9 8.96815 9 10.625C9 12.2819 10.3431 13.625 12 13.625Z" stroke="#DB7500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </G>
            <Defs>
                <ClipPath id="clip0_1432_23646">
                    <Rect width="24" height="24" fill="white" transform="translate(0 0.625)" />
                </ClipPath>
            </Defs>
        </Svg>
    )
}