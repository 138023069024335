import { Svg, Path } from 'react-native-svg';

export const Information = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M22.5 44.5C34.3741 44.5 44 34.8741 44 23C44 11.1259 34.3741 1.5 22.5 1.5C10.6259 1.5 1 11.1259 1 23C1 34.8741 10.6259 44.5 22.5 44.5Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M22.5 44.5C34.3741 44.5 44 34.8741 44 23C44 11.1259 34.3741 1.5 22.5 1.5C10.6259 1.5 1 11.1259 1 23C1 34.8741 10.6259 44.5 22.5 44.5Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M22.4954 32.1612C24.2866 32.1612 26.0377 31.6239 27.5271 30.6172C29.0165 29.6105 30.1773 28.1797 30.8628 26.5056C31.5483 24.8315 31.7277 22.9894 31.3782 21.2122C31.0287 19.435 30.1661 17.8026 28.8995 16.5213C27.6329 15.24 26.0191 14.3674 24.2623 14.0139C22.5054 13.6604 20.6843 13.8419 19.0294 14.5353C17.3745 15.2287 15.96 16.403 14.9648 17.9096C13.9697 19.4162 13.4385 21.1876 13.4385 22.9996C13.4385 25.4294 14.3927 27.7597 16.0912 29.4778C17.7897 31.196 20.0933 32.1612 22.4954 32.1612V32.1612Z" fill="#DB7500" />
            <Path d="M22.4854 21.2344V27.626" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
            <Path d="M23.4556 19.3394C23.4556 18.8057 23.0279 18.373 22.5002 18.373C21.9726 18.373 21.5449 18.8057 21.5449 19.3394V19.5978C21.5449 20.1315 21.9726 20.5642 22.5002 20.5642C23.0279 20.5642 23.4556 20.1315 23.4556 19.5978V19.3394Z" fill="white" />
        </Svg>
    )
}