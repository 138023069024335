import { useState, useEffect, useContext } from "react";
import { View, Text, ScrollView, Platform } from "react-native";
import { Formik } from "formik";
import * as yup from "yup";
//icons
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
//color
import { useTheme } from "react-native-paper";
//Styles
import {
  styles,
  title,
  titleMargin,
  paragraph,
  paragraph2,
  webContainer,
} from "../../../styles/pages/generalStyles";
//components
import { InputOverall } from "../../widgets/inputOverall";
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
// contexts
import { forgotContext } from "../../../context/forgotContext";
//controllers
import { changePassController } from "../../controllers/authentication";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useModal from "../../../hooks/useModal";

const loginValidationSchema = yup.object({
  password: yup
    .string()
    .min(
      8,
      ({ min }) => `La contraseña debe contener al menos ${min} caracteres.`
    )
    .matches(/[A-Z]/, "La contraseña debe contener al menos una mayúscula.")
    .matches(/[a-z]/, "La contraseña debe contener al menos una minúscula.")
    .matches(
      /\W/g,
      "La contraseña debe contener al menos un caracter especial."
    )
    .required("La contraseña es requerida."),
  confirm_password: yup
    .string()
    .equals([yup.ref("password"), null], "La contraseña no coincide.")
    .required("La contraseña es requerida."),
});

export const ChangePassword = ({ navigation }) => {
  const { colors } = useTheme();
  const { code, email } = useContext(forgotContext);
  const [eye, setEye] = useState("eye-off-outline");
  const [eyeConfirmPass, setEyeConfirmPass] = useState("eye-off-outline");
  const passVisible = eye === "eye-off-outline" ? true : false;
  const passVisibleConfirmPass =
    eyeConfirmPass === "eye-off-outline" ? true : false;
  const [typeInput, setTypeInput] = useState("outlined");
  const [loding, setLoading] = useState(false);
  const fromWeb = Platform.OS == "web";
  const { isComputer } = useIsWeb();
  const { openMessageModal } = useModal();

  useEffect(() => {
    navigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  const handleChangePassword = async (values) => {
    setLoading(true);
    try {
      const res = await changePassController(
        email,
        code,
        values.password,
        values.confirm_password
      );

      if (res.updated) {
        openMessageModal("Contraseña actualizada exitosamente.");
        navigation.navigate("login");
      }
    } catch (error) {
      openMessageModal(error.message);
    } finally {
      setLoading(false);
    }
  };

  const pressEye = () => {
    if (eye === "eye-outline") {
      setEye("eye-off-outline");
    } else {
      setEye("eye-outline");
    }
  };

  const pressEyeConfirmPass = () => {
    if (eyeConfirmPass === "eye-outline") {
      setEyeConfirmPass("eye-off-outline");
    } else {
      setEyeConfirmPass("eye-outline");
    }
  };

  return (
    <Formik
      initialValues={{ password: "", confirm_password: "" }}
      validationSchema={loginValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleChangePassword}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
      }) => (
        <View style={styles.screen}>
          <ScrollView>
            <View
              style={[
                styles.container,
                webContainer(!isComputer && "65vh", null, isComputer),
                isComputer && { alignItems: "center", marginTop: "10%" },
              ]}
            >
              <Text
                style={[
                  title("80%", isComputer ? "center" : "left"),
                  titleMargin(isComputer),
                ]}
              >
                Cree una nueva contraseña
              </Text>
              <Text
                style={[paragraph("98.9%", null, null, isComputer && "center")]}
              >
                {`Ingrese una nueva contraseña, esta debe ser diferente a las contraseñas usadas \nanteriormente.`}
              </Text>

              <View style={styles.inputsContainer}>
                <InputOverall
                  label="Contraseña"
                  value={values.password}
                  placeholder="Contraseña"
                  secureTextEntry={passVisible}
                  onChangeText={handleChange("password")}
                  autoCapitalize={"none"}
                  type={typeInput}
                  errors={
                    touched.password && errors.password && errors.password
                  }
                  onBlur={() => handleBlur("password")}
                  icon={
                    <MaterialCommunityIcons
                      name={eye}
                      color={colors.secondary}
                      size={25}
                      style={styles.inputIcon}
                      onPress={pressEye}
                    />
                  }
                />
                <InputOverall
                  label="Confirmar Contraseña"
                  value={values.confirm_password}
                  placeholder="Confirmar Contraseña"
                  secureTextEntry={passVisibleConfirmPass}
                  onChangeText={handleChange("confirm_password")}
                  autoCapitalize={"none"}
                  type={typeInput}
                  errors={
                    touched.confirm_password &&
                    errors.confirm_password &&
                    errors.confirm_password
                  }
                  onBlur={() => handleBlur("confirm_password")}
                  icon={
                    <MaterialCommunityIcons
                      name={eyeConfirmPass}
                      color={colors.secondary}
                      size={25}
                      style={styles.inputIcon}
                      onPress={pressEyeConfirmPass}
                    />
                  }
                />
              </View>

              <Text
                style={[
                  paragraph2("99%", isComputer && "center"),
                  fromWeb && { marginTop: 30 },
                ]}
              >
                La contraseña debe contener al menos 8 caracteres, debe incluir
                al menos una letra mayúscula, una letra minúscula y un caracter
                especial.
              </Text>

              {isComputer ? (
                <View style={{ marginTop: 45, marginBottom: "15%" }}>
                  <GeneralButton
                    text="Ingresar"
                    handleOnPress={handleSubmit}
                    disabled={
                      loding ||
                      values.password === "" ||
                      values.confirm_password === ""
                        ? true
                        : false
                    }
                    loading={loding}
                    width={350}
                  />
                </View>
              ) : null}
            </View>
          </ScrollView>

          {!isComputer ? (
            <LowerOptionsContainer>
              <GeneralButton
                text="Ingresar"
                handleOnPress={handleSubmit}
                disabled={
                  loding ||
                  values.password === "" ||
                  values.confirm_password === ""
                    ? true
                    : false
                }
                loading={loding}
                width="100%"
              />
            </LowerOptionsContainer>
          ) : null}
        </View>
      )}
    </Formik>
  );
};
