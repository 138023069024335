import { useContext, useEffect } from "react";
import { View } from "react-native";
// contexts
import { orderContext } from "../../context/orderingContext";
//styles
import { centerAlignedRow } from "../../styles/pages/home/homePage";
import { spaceBetween } from "../../styles/pages/generalStyles";
import * as headerStyle from "../../styles/pages/navigators/headers";
import useIsWeb from "../../hooks/useIsWeb";

export const StepProgress = ({ step, totalSteps }) => {
  const { setCurrentStep } = useContext(orderContext);
  const columns = [];
  const { isComputer } = useIsWeb();

  useEffect(() => {
    setCurrentStep(step);
  }, []);

  for (let i = 0; i < totalSteps; i++) {
    columns.push(
      <View
        key={`${i}`}
        style={[
          headerStyle.steps(100 / totalSteps - 2),
          step > i && headerStyle.styles.stepDone,
        ]}
      ></View>
    );
  }
  return (
    <View
      style={[
        centerAlignedRow(),
        spaceBetween(null, isComputer ? "100%" : "92%"),
        { marginTop: 10, marginLeft: "5%" },
        isComputer && { maxWidth: 500 },
      ]}
    >
      {columns}
    </View>
  );
};
