import {
  View,
  Text,
  Modal,
  Pressable,
  FlatList,
  Touchable,
  TouchableOpacity,
} from "react-native";
//styles
import { paragraph, styles } from "../../styles/pages/generalStyles";
import { modalStyles, picker } from "../../styles/pages/widgets/modalMenu";

export const SelectorModal = ({
  openModal,
  valueList,
  setValue,
  width,
  height,
  test,
}) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={true}
      onRequestClose={() => openModal(false)}
    >
      <TouchableOpacity
        style={modalStyles.container_options}
        onPress={() => openModal(false)}
      >
        <View style={picker(height, width)}>
          {valueList.length ? (
            <FlatList
              bounces={false}
              showsVerticalScrollIndicator={false}
              style={{ width: "100%" }}
              data={valueList}
              renderItem={({ item, index }) => {
                return (
                  <Pressable
                    style={{
                      width: "100%",
                      alignItems: "center",
                      height: 42.5,
                    }}
                    onPress={() => {
                      setValue(item);
                      openModal(false);
                    }}
                  >
                    <Text style={[styles.cardText]}>{item}</Text>
                  </Pressable>
                );
              }}
            />
          ) : (
            <Text style={[paragraph("89%"), { textAlign: "center" }]}>
              No hay Opciones disponibles
            </Text>
          )}
        </View>
      </TouchableOpacity>
    </Modal>
  );
};
