import { Text, View, TextInput } from 'react-native';
//style
import { styles, shadedInput } from '../../styles/pages/widgets/inputs'
import { InputShadow } from '../../styles/pages/widgets/shadows';

export const ShadedInput = (props) => {
    const { errors, icon, width, input, inputMinHeight, multiline, platform } = props;

    return (
        <View style={{ width }}>
            <InputShadow platform={platform}>
                <TextInput
                    style={[shadedInput(input || '97%', inputMinHeight || 59), multiline && { textAlignVertical: 'top' }]}
                    {...props}
                />
            </InputShadow>
            {icon && icon}
            {errors ? <Text style={styles.error}>{errors}</Text> : null}
        </View>
    )
}