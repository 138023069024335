import { StyleSheet } from 'react-native'

export const bottomBar = (color) => ({
  display: "flex",
  height: 60,
  paddingBottom: 10,
  paddingTop: 10,
  paddingHorizontal: 35,
  backgroundColor: color,
});
