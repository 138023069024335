export const shoppingCartInitialValues = {
    shopping_cart: null,
    combos: [],
    plates_supermaker: [],
    facture: false,
    dni: undefined,
    armed_packages: [],
    nutritional_consultation: [],
    type_method_pay: undefined,
    method_payment: undefined,
    note: undefined,
    address: undefined,
    total: 0,
    total_price: 0,
}

export const orderDefinitionInitialValues = {
    reorder: false,
    address: undefined,
    packageTypeId: '',
    packageType: null,
    pre_armed_package_id: null,
    sub_package_id: null,
    barama_time_food: [],
    armed_package_id: null,
    garrisons: [],
    //for predefined packages
    breakfasts: {
        selected: false,
        id: null,
        price: undefined,
        plates: [],
        plate_default: [],
        plan: null,
        freeSelection: false,
    },
    lunches: {
        selected: false,
        id: null,
        lunchAndDinner: false,
        price: undefined,
        plates: [],
        plate_default: [],
        plan: null,
        freeSelection: false,
    },
    dinners: {
        selected: false,
        id: null,
        lunchAndDinner: false,
        price: undefined,
        plates: [],
        plate_default: [],
        plan: null,
        freeSelection: false,
    },
    adult_or_family: {
        selected: false,
        price: undefined,
        plates: [],
        plate_default: [],
        plan: null,
        freeSelection: false,
    },
    nutritional_consultation: [],
    combos: [],
    plates_supermaker: [],
    orderMade: false
}

export const suscriptionInitialValues = {
    day_delivery: undefined,
    packageType: undefined,
    delivery_day: undefined,
    hours_range: undefined,
    note: undefined,
    address: undefined,
    detailedDirections: undefined,
    payment_method: undefined,
    price: undefined,
    suscription_id: undefined,
    subscription_id: undefined,
    armed_packages_id: undefined,
    armed_packages: {
        sub_packages: {

        }
    }
}