import client from "./httpClient";
import { addTrailingSlashOnIos } from "../utils/utils";

//--------------------------------------------------
//--------------COUPONS REQUESTS-----------------------
//--------------------------------------------------

export const getCouponList = async (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  try {
    const response = await client.get(
      addTrailingSlashOnIos(`/reference/list_barana`)
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const getCodeReference = async (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  try {
    const response = await client.get(
      addTrailingSlashOnIos(`/reference/get_code_reference`)
    );
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const useCoupon = async (token, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  try {
    const response = await client.post("/reference/use_code/", data);
    return response;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const addCoupon = async (token, coupon) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  try {
    const response = await client.post("/reference/add_coupon/", { coupon });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
