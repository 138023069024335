import { useState, useContext } from "react";
import {
    View,
    Text,
    Modal,
    Pressable,
    FlatList,
    TouchableOpacity,
} from "react-native";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//styles
import { spaceBetween } from "../../../styles/pages/generalStyles";
import {
    modalStyles,
    modal,
    title,
} from "../../../styles/pages/widgets/modalMenu";
import {
    homeStyles,
    centerAlignedRow,
} from "../../../styles/pages/home/homePage";
// contexts
import { orderContext } from "../../../context/orderingContext";
//hooks
import useUserData from "../../../hooks/useUserData";

const Menu1 = [
    {
        option: "Mi Cuenta",
        title: true,
    },
    {
        option: "Ingresar",
        nav: "Signup",
        id: 1,
    },
    {
        option: "Pedidos",
        nav: "Orders",
        id: 2,
    },
    {
        option: "Cupones",
        nav: "couponList",
        id: 3,
    },
];

const Menu2 = [
  {
    option: "Servicio de alimentación",
    title: true,
  },
  {
    option: "Características",
    nav: "nuestroconcepto",
    id: 1,
  },
  {
    option: "Política ambiental",
    nav: "politica-ambiental",
    id: 2,
  },
  {
    option: "Preguntas frecuentes",
    nav: "preguntas-frecuentes",
    id: 3,
  },
  {
    option: "Opiniones",
    nav: "opiniones-del-servicio",
    id: 4,
  },
];

const Menu3 = [
  {
    option: "Sobre Baraná",
    title: true,
  },
  {
    option: "Nosotros",
    nav: "aboutUs",
    id: 1,
  },
  {
    option: "Atención nutricional",
    nav: "ScheduleAppointment",
    id: 2,
  },
  {
    option: "Intervenciones educativas",
    nav: "ScheduleAppointment",
    id: 3,
  },
];

export const ModalMenu = ({ show, setShow, navigation }) => {
  const {isLoggedIn} = useUserData()
  const { setTotalSteps } = useContext(orderContext);
  const [menu, setMenu] = useState({
    submenu1: true,
    submenu2: true,
    submenu3: true,
  });

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={show}
      onRequestClose={() => setShow(false)}
    >
      <Pressable style={modalStyles.container} onPress={() => setShow(false)}>
        <View style={modal()}>
          <TouchableOpacity
            onPress={() => setShow(false)}
            style={[spaceBetween(), { flexDirection: "row" }]}
          >
            <Text></Text>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="equis" />
          </TouchableOpacity>
          <View style={centerAlignedRow()}>
            <Text style={[homeStyles.strongText, title("15%")]}>Mi Cuenta</Text>
            <TouchableOpacity
              style={{ marginHorizontal: 10 }}
              onPress={() => setMenu({ ...menu, submenu1: !menu.submenu1 })}
            >
              <BaranaIcons
                draw={{ width: 18, height: 18 }}
                name={!menu.submenu1 ? "plus" : "minus"}
              />
            </TouchableOpacity>
          </View>
          <View style={!menu.submenu1 && modalStyles.show}>
            <FlatList
              bounces={false}
              style={{ width: 160 }}
              data={Menu1}
              renderItem={({ item, index }) => {
                return (
                  <TouchableOpacity
                    key={`${index}-menu1`}
                    style={{
                      display:
                      isLoggedIn && item.option == "Ingresar"
                          ? "none"
                          : "flex",
                    }}
                    onPress={() => {
                      if (!isLoggedIn) {
                        navigation.navigate("Signup");
                      } else if (item.nav) {
                        navigation.navigate(item.nav);
                      }
                      setShow(false);
                    }}
                  >
                    {!item.title ? (
                      <Text style={title("5%")}>{item.option}</Text>
                    ) : null}
                  </TouchableOpacity>
                );
              }}
            />
          </View>
          <View style={centerAlignedRow()}>
            <Text style={[homeStyles.strongText, title("15%")]}>
              Servicio de alimentación
            </Text>
            <TouchableOpacity
              style={{ marginHorizontal: 10 }}
              onPress={() => setMenu({ ...menu, submenu2: !menu.submenu2 })}
            >
              <BaranaIcons
                draw={{ width: 18, height: 18 }}
                name={!menu.submenu2 ? "plus" : "minus"}
              />
            </TouchableOpacity>
          </View>
          <View style={!menu.submenu2 && modalStyles.show}>
            <FlatList
              bounces={false}
              style={{ width: 160 }}
              data={Menu2}
              renderItem={({ item, index }) => {
                return (
                  <TouchableOpacity
                    key={`${index}-menu2`}
                    onPress={() => {
                      !isLoggedIn
                        ? navigation.navigate("Signup")
                        : item.nav
                        ? navigation.navigate(item.nav)
                        : null;
                      setShow(false);
                    }}
                  >
                    {!item.title ? (
                      <Text style={title("5%")}>{item.option}</Text>
                    ) : null}
                  </TouchableOpacity>
                );
              }}
            />
          </View>
          <View style={centerAlignedRow()}>
            <Text style={[homeStyles.strongText, title("15%")]}>
              Sobre Baraná
            </Text>
            <TouchableOpacity
              style={{ marginHorizontal: 10 }}
              onPress={() => setMenu({ ...menu, submenu3: !menu.submenu3 })}
            >
              <BaranaIcons
                draw={{ width: 18, height: 18 }}
                name={!menu.submenu3 ? "plus" : "minus"}
              />
            </TouchableOpacity>
          </View>
          <View style={!menu.submenu3 && modalStyles.show}>
            <FlatList
              bounces={false}
              style={{ width: 160 }}
              data={Menu3}
              renderItem={({ item, index }) => {
                return (
                  <TouchableOpacity
                    key={`${index}-menu3`}
                    onPress={() => {
                      if (item?.nav == "ScheduleAppointment") setTotalSteps(3);

                      !isLoggedIn
                        ? navigation.navigate("Signup")
                        : item.nav
                        ? navigation.navigate(item.nav)
                        : null;
                      setShow(false);
                    }}
                  >
                    {!item.title ? (
                      <Text style={title("5%")}>{item.option}</Text>
                    ) : null}
                  </TouchableOpacity>
                );
              }}
            />
          </View>
        </View>
      </Pressable>
    </Modal>
  );
};
