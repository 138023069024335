import { Svg, Path } from 'react-native-svg';

export const Blue_faceBook = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return <Svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path fillRule="evenodd" clipRule="evenodd" d="M0 17.0949C0 23.1891 3.19147 28.5431 7.98167 31.5902C10.9209 33.4598 14.1667 30.8036 14.1667 27.3202V23.8467C14.1667 22.6731 13.2153 21.7218 12.0417 21.7218C10.8681 21.7218 9.91667 20.7704 9.91667 19.5968V19.125C9.91667 17.9514 10.8681 17 12.0417 17C13.2153 17 14.1667 16.0486 14.1667 14.875V13.2218C14.1667 8.97175 16.9051 6.61158 20.7782 6.61158C21.3597 6.61158 21.9627 6.6539 22.5669 6.71848C23.7147 6.84117 24.5551 7.84067 24.5551 8.99502V9.16158C24.5551 10.361 23.5828 11.3333 22.3833 11.3333C20.3051 11.3333 19.8333 12.3717 19.8333 13.6949V14.9134C19.8333 16.0658 20.7675 17 21.9199 17C23.2037 17 24.183 18.1483 23.9803 19.4161L23.8862 20.0045C23.728 20.9939 22.8745 21.7218 21.8726 21.7218C20.7463 21.7218 19.8333 22.6347 19.8333 23.761V27.3202C19.8333 30.8036 23.0792 33.4598 26.0184 31.5903C30.8086 28.5436 34 23.1901 34 17.0949C34 7.6925 26.35 0 17 0C7.65 0 0 7.6925 0 17.0949Z" fill="white" />
    </Svg>
}