import { useEffect, useState, useContext, useRef } from "react";
import {
  View,
  Text,
  Modal,
  FlatList,
  ActivityIndicator,
  Animated,
  Easing,
  ScrollView,
} from "react-native";
import { useTheme } from "react-native-paper";
//styles
import {
  spaceBetween,
  textAlignment,
  textTransform,
} from "../../../../styles/pages/generalStyles";
import {
  modalStyles,
  editGarrisons,
} from "../../../../styles/pages/widgets/modalMenu";
import { garrinsonPrice } from "../../../../styles/pages/widgets/animations";
import {
  homeStyles,
  centerAlignedRow,
} from "../../../../styles/pages/home/homePage";
//components
import { GeneralButton } from "../../../widgets/generalButton";
import { CardItem } from "./cardItem";
import { useFormik } from "formik";
//context
import { orderContext } from "../../../../context/orderingContext";
//controllers
import { useGarrinsons } from "../../../hooks/useGarrisons";

export const EditGarrinsons = ({
  setShowModal,
  selectItem,
  timeFoodId,
  editAccompanimentInMenu,
}) => {
  const { colors } = useTheme();
  const [optionsListTwo, setOptionsListTwo] = useState([]);
  const [optionsListOne, setOptionsListOne] = useState([]);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [additionalAmount, setAdditionalAmount] = useState();
  const { garrinsons } = useGarrinsons();
  const { selectedGarrison, setSelectedGarrisons } = useContext(orderContext);

  useEffect(() => {
    return () => {
      setOptionsListTwo([]);
      setOptionsListOne([]);
    };
  }, [selectItem, timeFoodId]);

  useEffect(() => {
    const getData = async () => {
      await getGarrinsons();
      if (selectedGarrison.length > 0) getGarrinsonsBeingSelected();
    };
    getData();
  }, [garrinsons]);

  const formik = useFormik({
    initialValues: {
      checkbox: selectItem?.garrisons,
    },
    validateOnChange: false,
    onSubmit: async (formValue) => {
      let newGarrisonsArray = [];
      newGarrisonsArray.push(checked);
      newGarrisonsArray.push(checked2);

      selectedGarrison.push({
        plate: selectItem?.id,
        garrison: newGarrisonsArray,
        time_food: timeFoodId,
      });
      setSelectedGarrisons(selectedGarrison);
      setTimeout(() => {
        editAccompanimentInMenu(garrinsons, selectItem?.id, newGarrisonsArray);
        setShowModal(false);
      }, 50);
    },
  });

  const getGarrinsons = async () => {
    if (garrinsons["Acompañamiento 1"]?.length > 0) {
      selectItem?.garrisons.map((garrison) => {
        garrinsons["Acompañamiento 1"]?.map((g, i) => {
          if (garrison?.id == g.id || garrison == g.id) {
            setChecked(g.id);
            garrinsons["Acompañamiento 1"][i].default = true;
          }
        });
      });
      setOptionsListOne([...garrinsons["Acompañamiento 1"]]);
    }
    if (garrinsons["Acompañamiento 2"]?.length > 0) {
      selectItem?.garrisons.map((garrison) => {
        garrinsons["Acompañamiento 2"]?.map((g, i) => {
          if (garrison?.id == g.id || garrison == g.id) {
            setChecked2(g.id);
            garrinsons["Acompañamiento 2"][i].default = true;
          }
        });
      });
      setOptionsListTwo([...garrinsons["Acompañamiento 2"]]);
    }
  };

  const getGarrinsonsBeingSelected = () => {
    if (garrinsons["Acompañamiento 1"]?.length > 0) {
      selectedGarrison.map((plateGarrison, i) => {
        const plate = plateGarrison.plate;
        const time_food = plateGarrison.time_food;

        plateGarrison.garrison.map((item) => {
          garrinsons["Acompañamiento 1"]?.map((g) => {
            if (
              item == g.id &&
              plate == selectItem?.id &&
              time_food == timeFoodId
            ) {
              setChecked(g.id);
              setSelectedGarrisons([
                ...selectedGarrison.filter((GRR) => GRR.plate == plate),
              ]);
            }
          });
        });
      });
    }
    if (garrinsons["Acompañamiento 2"]?.length > 0) {
      selectedGarrison.map((plateGarrison) => {
        const plate_ = plateGarrison.plate;
        const time_food_ = plateGarrison.time_food;

        plateGarrison.garrison.map((item) => {
          garrinsons["Acompañamiento 2"]?.map((g, i) => {
            if (
              item == g.id &&
              plate_ == selectItem?.id &&
              time_food_ == timeFoodId
            ) {
              setChecked2(g.id);
              setSelectedGarrisons([
                ...selectedGarrison.filter((GRR) => GRR.plate == plate_),
              ]);
            }
          });
        });
      });
    }
  };

  const handleRadioButton = (G_, listNumber) => {
    setAdditionalAmount(G_?.additional_amount);
    // if (listNumber == "listOne") {
    //   optionsListOne.map((item) => {
    //     if (item.id == G_.id && !item.default && item.additional_amount > 0) {
    //cobrar adicional
    // setAnimation(true);
    // setTimeout(async () => {
    //   setAnimation(false);
    //   fadeAnim.setValue(-50);
    // op.setValue(1);
    // }, 2100);
    //     }
    //   });
    // } else {
    //   optionsListTwo.map((item) => {
    //     if (item.id == G_.id && !item.default && item.additional_amount > 0) {
    //combrar adicional
    // setAnimation(true);
    // setTimeout(async () => {
    //   setAnimation(false);
    //   fadeAnim.setValue(-50);
    //   op.setValue(1);
    // }, 2100);
    //     }
    //   });
    // }
  };

  return (optionsListTwo && optionsListTwo.length == 0) ||
    (optionsListOne && optionsListOne.length == 0) ? (
    <View style={{ position: "absolute", top: "45%", left: "45%" }}>
      <ActivityIndicator size="large" color={"black"} />
    </View>
  ) : (
    <Modal
      animationType="fade"
      transparent={true}
      visible={true}
      onRequestClose={() => setShowModal(false)}
    >
      <View style={modalStyles.container_options}>
        <ScrollView style={editGarrisons()}>
          <View
            style={[spaceBetween(), { flexDirection: "row", marginBottom: 15 }]}
          >
            <Text style={[homeStyles.strongText, textTransform("capitalize")]}>
              {selectItem?.recipe}{" "}
            </Text>
          </View>
          <Text style={[homeStyles.strongText, textAlignment("left", 6)]}>
            Acompañamiento 1
          </Text>

          {optionsListOne.length > 0 ? (
            <View style={{ width: "100%" }}>
              {optionsListOne?.map((item, index) => {
                return (
                  <View key={`${item?.id}-editMenu`}>
                    <CardItem
                      item={item}
                      index={index}
                      length={item?.length}
                      uniqueKey={`${index}-editOptionItems`}
                      handleRadioButton={handleRadioButton}
                      checked={checked}
                      setChecked={setChecked}
                    />
                  </View>
                );
              })}
            </View>
          ) : null}
          <Text style={[homeStyles.strongText, textAlignment("left", 6)]}>
            Acompañamiento 2
          </Text>

          {optionsListTwo.length > 0 ? (
            <View style={{ width: "100%" }}>
              {optionsListTwo?.map((item, index) => {
                return (
                  <View key={`${index}-editMenu2`}>
                    <CardItem
                      item={item}
                      index={index}
                      length={item?.length}
                      uniqueKey={`${index}-editOptionItems2`}
                      handleRadioButton={handleRadioButton}
                      checked={checked2}
                      setChecked={setChecked2}
                    />
                  </View>
                );
              })}
            </View>
          ) : null}
          <View
            style={[centerAlignedRow(null, "space-around"), { marginTop: 10 }]}
          >
            <GeneralButton
              text="Cancelar"
              handleOnPress={() => setShowModal(false)}
              width={"45%"}
              customColor={colors.primary100}
            />
            <GeneralButton
              text="Confirmar"
              handleOnPress={formik.handleSubmit}
              loading={formik.isSubmitting}
              width={"45%"}
            />
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
};
