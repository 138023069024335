import { useState, useEffect } from 'react'
import { Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
//screens
import { Login } from "../screen/login/login";
import { ForgotPassword } from "../screen/login/forgotPassword";
import { CheckYourEmailMessage } from "../screen/login/checkYourEmailMessage";
import { Verification } from "../screen/login/verification";
import { ChangePassword } from "../screen/login/changePassword";
//components
import { GoBack } from "../widgets/goBack";
//styles
import {
  styles,
  headerComponentStyle,
} from "../../styles/pages/navigators/headers";
//context
import { forgotContext } from "../../context/forgotContext";
//hooks
import useIsWeb from "../../hooks/useIsWeb";

export const AuthNavigator = ({ bottomTabNavigation }) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const Stack = createStackNavigator();
  const fromWeb = Platform.OS == "web";
  const { isComputer } = useIsWeb();

  useEffect(() => {
    if (bottomTabNavigation) {
      bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
    }
  });
  return (
    <forgotContext.Provider value={{ email, setEmail, code, setCode }}>
      <Stack.Navigator initialRouteName={"login"}>
        <Stack.Screen
          name={"login"}
          options={({ navigation }) => ({
            headerTitle: "",
            headerLeft: () => (
              <GoBack
                navigator={navigation}
                text={"Volver"}
                icon={"leftArrow"}
                paddingTop={ Platform.OS === 'ios' ? 40 : 0}
              />
            ),
            headerStyle: styles.headerWithBorder,
          })}
        >
          {({ route, navigation }) => (
            <Login route={route} navigation={navigation} />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={"forgotPassword"}
          options={({ navigation }) => ({
            headerTitle: "",
            headerLeft: () => (
              <GoBack navigator={navigation} icon={"arrowBack"} width={48} paddingTop={ Platform.OS === 'ios' ? 40 : 0}/>
            ),
            headerStyle: [
              headerComponentStyle.containerHeight,
              fromWeb && headerComponentStyle.headerGeneral,
            ],
            // headerShown: !isComputer,
          })}
        >
          {({ navigation, route }) => (
            <ForgotPassword route={route} navigation={navigation} />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={"checkYourEmailMessage"}
          options={({ navigation }) => ({
            headerTitle: "",
            headerLeft: () => (
              <GoBack navigator={navigation} icon={"arrowBack"} width={48} paddingTop={ Platform.OS === 'ios' ? 40 : 0}/>
            ),
            headerStyle: [
              headerComponentStyle.containerHeight,
              fromWeb && headerComponentStyle.headerGeneral,
            ],
            // headerShown: !fromWeb,
          })}
        >
          {({ navigation, route }) => (
            <CheckYourEmailMessage route={route} navigation={navigation} />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={"verification"}
          options={({ navigation }) => ({
            headerTitle: "",
            headerLeft: () => (
              <GoBack navigator={navigation} icon={"arrowBack"} width={48} paddingTop={ Platform.OS === 'ios' ? 40 : 0}/>
            ),
            headerStyle: [
              headerComponentStyle.containerHeight,
              fromWeb && headerComponentStyle.headerGeneral,
            ],
            // headerShown: !fromWeb,
          })}
        >
          {({ navigation, route }) => (
            <Verification route={route} navigation={navigation} />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={"changePassword"}
          options={({ navigation }) => ({
            headerTitle: "",
            headerLeft: () => (
              <GoBack navigator={navigation} icon={"arrowBack"} width={48} paddingTop={ Platform.OS === 'ios' ? 40 : 0}/>
            ),
            headerStyle: [
              headerComponentStyle.containerHeight,
              fromWeb && headerComponentStyle.headerGeneral,
            ],
            // headerShown: !fromWeb,
          })}
        >
          {({ navigation, route }) => (
            <ChangePassword route={route} navigation={navigation} />
          )}
        </Stack.Screen>
      </Stack.Navigator>
    </forgotContext.Provider>
  );
};