import React, { useState, useEffect, useRef, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import "./mapbox.css";
import "./styles.css";
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
import useIsWeb from "../../../hooks/useIsWeb";
import useDeliveryAdressState from "../payment/useDeliveryAdressState";

export const MapScreen = ({ bottomTabNavigation, navigation }) => {
  const map = useRef(null);
  const marker = useRef(null);
  const mapContainerRef = useRef(null);
  const [initialUserLocation, setUserInitialLocation] = useState({
    lng: null,
    lat: null,
  });
  const { isComputer } = useIsWeb();
  const { cords, setCords, zoom, setZoom } = useDeliveryAdressState();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  mapboxgl.accessToken =
    "pk.eyJ1IjoiYmFyYW5hZHJhZ29zIiwiYSI6ImNsbzh2bzFidzAwcXUyanFvODV5N2J1bWwifQ.UCmdmlBbH0OBQBqkHbLAXQ";

  function onDragEnd() {
    const { lng, lat } = marker.current.getLngLat();

    map.current.flyTo({
      center: [lng, lat],
    });
  }

  useEffect(() => {
    if (cords.lat === null && cords.lng === null) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserInitialLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          setUserInitialLocation({
            lat: 9,
            lng: -84,
          });
        }
      );
    } else {
      setUserInitialLocation({
        lat: cords.lat,
        lng: cords.lng,
      });
    }
  }, []);

  useEffect(() => {
    if (
      initialUserLocation.lat === null ||
      initialUserLocation.lng === null ||
      map.current
    )
      return;

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      zoom: zoom,
      center: [initialUserLocation.lng, initialUserLocation.lat],
    });

    marker.current = new mapboxgl.Marker({
      draggable:
        initialUserLocation.lng === -84.0753535 &&
        initialUserLocation.lat === 9.9226069
          ? false
          : true,
    })
      .setLngLat([initialUserLocation.lng, initialUserLocation.lat])
      .addTo(map.current);

    marker.current.on("dragend", onDragEnd);

    return () => map.current.remove();
  }, [initialUserLocation]);

  return (
    <div>
      <div
        ref={mapContainerRef}
        className={
          initialUserLocation.lng === -84.0753535 &&
          initialUserLocation.lat === 9.9226069
            ? "map-container-local"
            : "map-container"
        }
      />
      {initialUserLocation.lng === -84.0753535 &&
      initialUserLocation.lat === 9.9226069 ? null : (
        <LowerOptionsContainer>
          <GeneralButton
            text="Guardar ubicación"
            handleOnPress={() => {
              const { lng, lat } = marker.current.getLngLat();

              const zoomToSave = map.current.getZoom();

              setCords({
                lng,
                lat,
              });
              setZoom(zoomToSave);
              navigation.goBack();
            }}
            width={isComputer ? 350 : "100%"}
          />
        </LowerOptionsContainer>
      )}
    </div>
  );
};
