import client from './httpClient'
import { addTrailingSlashOnIos } from "../utils/utils";

export const fetchHomeFeed = async (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client.get(addTrailingSlashOnIos(`/home_feed`));
  return response.data;
};

export const dayCorte = async () => {
  try {
    const response = await client.get(addTrailingSlashOnIos("/day_corte"));
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const homePrices = async () => {
  try {
    const response = await client.get(addTrailingSlashOnIos(`/home_prices`));
    return response.data;
  } catch (error) {
    return error.response;
  }
};