import { Svg, Path } from 'react-native-svg';

export const Delete = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M3 6.17578H5H21" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M8 6.17578V4.17578C8 3.64535 8.21071 3.13664 8.58579 2.76157C8.96086 2.38649 9.46957 2.17578 10 2.17578H14C14.5304 2.17578 15.0391 2.38649 15.4142 2.76157C15.7893 3.13664 16 3.64535 16 4.17578V6.17578M19 6.17578V20.1758C19 20.7062 18.7893 21.2149 18.4142 21.59C18.0391 21.9651 17.5304 22.1758 17 22.1758H7C6.46957 22.1758 5.96086 21.9651 5.58579 21.59C5.21071 21.2149 5 20.7062 5 20.1758V6.17578H19Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M10 11.1758V17.1758" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M14 11.1758V17.1758" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    )
}