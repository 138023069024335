import { Fragment, useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  FlatList,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { useLinkTo } from "@react-navigation/native";
//controllers
import { getPackageArmed, getTimeFoods } from "../../controllers/package";
// contexts
import { orderContext } from "../../../context/orderingContext";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//styles
import {
  styles,
  title,
  titleMargin,
  buttomOption,
  paragraph,
  sectionContainerWeb,
  lodingStyle,
  flexContainer,
} from "../../../styles/pages/generalStyles";
import { sections, homeStyles } from "../../../styles/pages/home/homePage";
//components
import { OrangeCard } from "../../widgets/orangeCrad";
import { OutlinedButton } from "../../widgets/outlinedButton";
import { PopUp } from "../../widgets/popUp";
//utils
import { sortMealTimesArray, capitalize } from "../../utils/utils";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useUserData from "../../../hooks/useUserData";
import useOrderStore from "../../../hooks/useOrderStore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useModal from "../../../hooks/useModal";

const packageWithoutMealTime = {
  "porciones familiares": "familiar",
  "adulto mayor": "ninguno",
  ninguno: "ninguno",
};

const icons = {
  1: "lunches",
  2: "olderAdults",
  3: "pamilyPortion",
};

const packages = [
  {
    name: "almuerzos, cenas, desayunos completos",
    description: "Arme su paquete de 3 a 6 días por semana.",
    priceOrDescription: "",
    icon: "lunches",
  },
  {
    name: "adulto mayor",
    description:
      "Almuerzos o cenas para atender las necesidades nutricionales de las personas de la tercera edad.",
    priceOrDescription: "",
    icon: "olderAdults",
  },
  {
    name: "porciones familiares",
    description:
      "Paquetes de platos principales o vegetales, para 4 o 6 personas.",
    priceOrDescription: "",
    icon: "pamilyPortion",
  },
];

const { width } = Dimensions.get("window");
export const Packages = ({ background, navigation, homePricesState }) => {
  const { isLoggedIn, access_token } = useUserData();
  const {
    setTotalSteps,
    setCurrentStep,
    baranaPackageList,
    orderDefinition,
    setOrderDefinition,
    mealTimesList,
    setPackageArmedList,
    baranaPlanList,
    setMealTimesList,
    setCreateNewSubscription,
    setCreateSubscriptionFromHome,
    setEditSubscription,
  } = useContext(orderContext);
  const [value, setValue] = useState([]);
  const [loding, setLoading] = useState(false);
  const [packagesNotAvailable, setPackagesNotAvailable] = useState(false);
  const { isComputer } = useIsWeb();
  const linkTo = useLinkTo();
  const {
    setPackageType,
    setTimeFood,
    setTimesFoodId,
    setPackages,
    setTimesFood,
  } = useOrderStore();
  const { openMessageModal } = useModal();

  useEffect(() => {
    if (isLoggedIn) {
      const packagesList = baranaPackageList?.map((item) => {
        return {
          id: item.id,
          name: item.name,
          is_time_food: item.is_time_food,
          is_package: true,
          description: item.description,
          linkTo: "/mealTimes",
          icon: icons[item.id],
        };
      });
      if (isComputer) {
        packagesList.push(
          {
            id: null,
            name: "Suscripción",
            is_time_food: false,
            is_package: false,
            description: "",
            linkTo: "newSubscription",
            parentLink: "Profile",
            icon: "subscription",
          },
          {
            id: null,
            name: "Consulta Nutricional",
            is_time_food: false,
            is_package: false,
            description: "",
            linkTo: "ScheduleAppointment",
            icon: "doctor",
          }
        );
      }
      setValue([...packagesList]);
    } else {
      setValue([...packages]);
    }

    mealTimesList?.length == 0 && access_token && handleGetTimeFoods();
  }, [baranaPackageList, isComputer]);

  const handleGetPackageArmed = async (selected) => {
    setTimesFood({
      desayunos: false,
      almuerzos: false,
      cenas: false,
      ninguno: false,
      familiar: false,
    });

    const timeFood = mealTimesList.find(
      (item) =>
        item.name.toLowerCase() ===
        (packageWithoutMealTime[selected.name.toLowerCase()] || "ninguno")
    );

    setLoading(true);

    try {
      const packageResponse = await getPackageArmed(
        {
          barana_package: selected?.id,
          barana_time_food: [timeFood?.id],
          type_assembled: "barana",
        },
        access_token
      );

      if (packageResponse?.status && packageResponse?.status === 400) {
        setPackagesNotAvailable(true);
      } else {
        setPackageType({
          type: selected.name,
          id: selected.id,
        });
        setTimeFood(timeFood.name.toLowerCase(), true);
        setTimesFoodId([timeFood?.id]);
        setTotalSteps(4);
        setPackages(packageResponse);

        navigation.navigate("order_definition", {
          screen: "numberOfMeals",
        });
      }
    } catch (error) {
      openMessageModal(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGetTimeFoods = async () => {
    setLoading(true);
    // await AsyncStorage.getItem("food_times")
    await getTimeFoods(access_token)
      .then(async (res) => {
        if (res.length > 0) {
          setMealTimesList([...sortMealTimesArray(res)]);
          await AsyncStorage.setItem("food_times", JSON.stringify(res));
        }
        setLoading(false);
      })
      .catch((err) => {
        // console.log('err ', err)
        setLoading(false);
      });
  };

  return (
    <>
      <View style={[sections(background == "dark", 400, 36, 20)]}>
        <View
          style={
            isComputer && sectionContainerWeb(1000, null, "100%", "center")
          }
        >
          {isLoggedIn && !isComputer ? (
            <View
              style={[
                titleMargin(isComputer),
                { alignItems: "center", maxWidth: 400, width: "100%" },
              ]}
            >
              <View
                style={{
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <BaranaIcons draw={{ width: 67, height: 67 }} name={"doctor"} />
                <View style={{ width: "75%" }}>
                  <Text style={styles.cardText}>
                    Atención Clínica Nutricional
                  </Text>
                  <Text style={paragraph("100%")}>
                    Ayudamos a la prevención, diagnóstico y tratamiento de
                    cambios nutricionales y metabólicos del cuerpo.
                  </Text>
                </View>
              </View>
              <OutlinedButton
                text="Más información"
                handleOnPress={() => {
                  setTotalSteps(3);
                  navigation.navigate("ScheduleAppointment");
                }}
                width={"100%"}
              />
            </View>
          ) : null}

          <Text
            style={[
              !isLoggedIn
                ? title(
                    "100%",
                    isComputer ? "center" : "left",
                    null,
                    isComputer
                  )
                : styles.cardText,
              titleMargin(isComputer),
            ]}
          >
            Paquetes
          </Text>
          {value.length > 0 ? (
            <View
              style={flexContainer("row", "center", null, "wrap", null, 50)}
            >
              {value.map((item, index) => {
                return (
                  <Fragment key={index}>
                    {!isLoggedIn ? (
                      <OrangeCard
                        title={item.name}
                        text={
                          item.name.toLowerCase().includes("almuerzos")
                            ? homePricesState?.info_package_almuerzo_cena_desayuno
                            : item.name.toLowerCase().includes("adulto")
                            ? homePricesState?.info_package_adulto_mayor
                            : homePricesState?.info_package_porciones_familiares
                        }
                        // priceOrDescription={
                        //   item.name.toLowerCase().includes("almuerzos")
                        //     ? homePricesState?.price_package_almuerzo_cena_desayuno
                        //     : item.name.toLowerCase().includes("adulto")
                        //     ? homePricesState?.price_package_adulto_mayor
                        //     : homePricesState?.price_package_porciones_familiares
                        // }
                        typeButton="general"
                        textButton="Ordenar"
                        widthButton="100%"
                        height={!isComputer ? 220 : 200}
                        width={!isComputer ? "100%" : 300}
                      />
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setCurrentStep(1);
                          setCreateNewSubscription(false);
                          setCreateSubscriptionFromHome(false);
                          setEditSubscription(false);

                          if (item.is_package) {
                            if (item.is_time_food) {
                              setPackageType({
                                type: item.name,
                                id: item.id,
                              });
                              /////////
                              setOrderDefinition({
                                ...orderDefinition,
                                packageTypeId: item.id,
                                packageType: item.name,
                              });
                              setTotalSteps(6);
                              linkTo(item.linkTo);
                            } else {
                              handleGetPackageArmed(item);
                            }
                          } else {
                            if (item.parentLink) {
                              navigation.navigate(item.parentLink, {
                                screen: item.linkTo,
                              });
                            } else {
                              navigation.navigate(item.linkTo);
                            }
                          }
                        }}
                        style={[
                          buttomOption(
                            99,
                            11,
                            16,
                            null,
                            isComputer && 300,
                            null
                          ),
                        ]}
                      >
                        <BaranaIcons
                          draw={{
                            width: isComputer ? 50 : 67,
                            height: isComputer ? 50 : 67,
                          }}
                          name={item.icon || icons[item.id]}
                        />
                        <Text
                          style={[
                            styles.cardText,
                            { width: "75%", marginLeft: 20 },
                          ]}
                        >
                          {capitalize(item.name)}
                        </Text>
                      </TouchableOpacity>
                    )}
                  </Fragment>
                );
              })}
            </View>
          ) : (
            <View style={homeStyles.loader}>
              <ActivityIndicator size="small" color={"black"} />
            </View>
          )}
        </View>
      </View>

      {!isLoggedIn && <View style={[styles.line, { marginTop: 20 }]}></View>}
      {packagesNotAvailable && (
        <PopUp
          showModal={packagesNotAvailable}
          openModal={setPackagesNotAvailable}
          title="Error"
          text="Paquete armado no disponible"
          buttonText={"ok"}
          button={true}
        />
      )}
    </>
  );
};
