import { Svg, Path } from 'react-native-svg';

export const Camera = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M23 19.9062C23 20.4367 22.7893 20.9454 22.4142 21.3205C22.0391 21.6955 21.5304 21.9062 21 21.9062H3C2.46957 21.9062 1.96086 21.6955 1.58579 21.3205C1.21071 20.9454 1 20.4367 1 19.9062V8.90625C1 8.37582 1.21071 7.86711 1.58579 7.49204C1.96086 7.11696 2.46957 6.90625 3 6.90625H7L9 3.90625H15L17 6.90625H21C21.5304 6.90625 22.0391 7.11696 22.4142 7.49204C22.7893 7.86711 23 8.37582 23 8.90625V19.9062Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M12 17.9062C14.2091 17.9062 16 16.1154 16 13.9062C16 11.6971 14.2091 9.90625 12 9.90625C9.79086 9.90625 8 11.6971 8 13.9062C8 16.1154 9.79086 17.9062 12 17.9062Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>
    )
}