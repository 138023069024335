import { useEffect, useContext } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { Platform } from "react-native";
//styles
import { headerComponentStyle, styles } from "../../styles/pages/navigators/headers";
//screens
import { Supermarket } from "../screen/supermarket/";
import { DetailsPlate } from "../screen/supermarket/menu/details";
//components
import { GoBack } from "../widgets/goBack";
import { NavBar } from "../widgets/navBar";
//hooks
import useIsWeb from "../../hooks/useIsWeb";
import useUserData from "../../hooks/useUserData";

export const SupermarketNavigator = ({ bottomTabNavigation }) => {
  const Stack = createStackNavigator();
  const { isLoggedIn } = useUserData();
  const { isComputer } = useIsWeb();

  // useEffect(() => {
  //   bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  // });
  return (
    <Stack.Navigator initialRouteName={"supermarket"}>
      <Stack.Screen
        name={"supermarket"}
        options={({ navigation, route }) => ({
          headerLeft: () => (
            <GoBack  paddingTop={Platform.OS === "ios" ? 40 : 0} navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitle: () => (
            <NavBar
            
              navigation={navigation}
              route={route}
              marginL={!isLoggedIn && 45}
              headerTitle="Supermercado"
            />
          ),
          headerStyle: [headerComponentStyle.containerHeight],
          headerTitleContainerStyle: isComputer
            ? { width: "100%", paddingHorizontal: 110 }
            : Platform.OS == "web"
            ? { width: "100%" }
            : { alignItems: "center", paddingTop: 20, marginLeft: -3 },
        })}
      >
        {({ navigation }) => <Supermarket navigation={navigation} />}
      </Stack.Screen>
      <Stack.Screen
        name={"detailsPlate"}
        options={({ navigation, route }) => ({
          headerTitle: () => (
            <NavBar
              navigation={navigation}
              route={route}
              marginL={!isLoggedIn && 45}
            />
          ),
          headerLeft: () => (
            <GoBack paddingTop={ Platform.OS === 'ios' ? 40 : 0} navigator={navigation} text={"Volver"} icon={"leftArrow"} />
          ),
          headerStyle: styles.headerWithBorder,
          headerTitleContainerStyle: isComputer
            ? { width: "100%", paddingHorizontal: 110 }
            : Platform.OS == "web"
            ? { width: "100%" }
            : { alignItems: "center", paddingTop: 40, marginLeft: -3 },
        })}
      >
        {({ navigation, route }) => (
          <DetailsPlate
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
};