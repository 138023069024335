import { useContext, useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  FlatList,
  Platform,
} from "react-native";
//icons
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//controllers
import { getPlansUser } from "../../../controllers/plans";
//Styles
import {
  styles,
  buttomOption,
  buttomOptionOnFocus,
  webContainer,
  paragraph,
  sectionContainerWeb,
} from "../../../../styles/pages/generalStyles";
//componentes
import { LowerOptionsContainer } from "../../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../../widgets/generalButton";
import { LoadingPage } from "../../../widgets/loadingPage";
// contexts
import { orderContext } from "../../../../context/orderingContext";
import { mainContext } from "../../../../context/mainContext";
//utils
import { capitalize } from "../../../utils/utils";
//hooks
import useUserData from "../../../../hooks/useUserData";
import useIsWeb from "../../../../hooks/useIsWeb";
import useOrderStore from "../../../../hooks/useOrderStore";

const { width } = Dimensions.get("window");
export const AccordingToBarana = ({
  navigation,
  route,
  bottomTabNavigation,
}) => {
  const { access_token } = useUserData();
  const { orderDefinition, setOrderDefinition } = useContext(orderContext);
  const { plansAccordingBarana, setPlansAccordingBarana } =
    useContext(mainContext);
  const [loding, setLoading] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [selected, setSelected] = useState([]);
  const to = route.params?.to.toLowerCase();
  const { isComputer } = useIsWeb();
  const { setSubpackage, setPlan, subpackage } = useOrderStore();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
    handleGetPlans();
  }, []);

  const setPlanforThePackage = (plan) => {
    setPlan(plan);
    if (to.includes("desayunos")) {
      setSubpackage({
        ...subpackage,
        desayunos: { ...subpackage?.desayunos, plan },
      });
    } else if (to.includes("almuerzos")) {
      setSubpackage({
        ...subpackage,
        almuerzos: { ...subpackage?.almuerzos, plan },
      });
    } else if (to.includes("cenas")) {
      setSubpackage({
        ...subpackage,
        cenas: { ...subpackage?.cenas, plan },
      });
    } else if (to.includes("familiar")) {
      setSubpackage({
        ...subpackage,
        generic: { ...subpackage?.generic, plan },
      });
    }
  };
  const handleGetPlans = async () => {
    setLoading(true);
    await getPlansUser(access_token, "según mi plan barana")
      .then(async (planes) => {
        setOptionsList(planes);
        setPlansAccordingBarana(planes);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <View style={[styles.screen]}>
      <ScrollView horizontal={false}>{
            loding ? <LoadingPage /> :         <View
            style={[
              styles.container,
              webContainer(Platform.OS === "web" ? "50vh" : "100%", "5%"),
              isComputer && sectionContainerWeb(600, null, "100%"),
            ]}
          >
            {optionsList?.length === 0 ? (
              <Text
                style={[
                  paragraph("100%", 17, 10),
                  { marginBottom: 10, marginTop: 10 },
                ]}
              >
                No posee planes según Baraná, escribanos para obtener mas
                información; En la pantalla principal tendrá la opción para
                escribirnos al Whatsapp, Telegram o también puede llamar al
                8888-2406.
              </Text>
            ) : (
              <View style={{ width: isComputer ? "100%" : width - 40 }}>
                {optionsList.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={`${item.id}-accordingToBarana`}
                      onPress={() => {
                        setSelected(item);
                        setPlanforThePackage(item);
                      }}
                      style={[
                        buttomOption(65, 5, 16),
                        item.id == selected.id && buttomOptionOnFocus(),
                      ]}
                    >
                      <View style={[{ width: "73%" }]}>
                        <Text style={[styles.cardText]}>
                          {capitalize(item.name)}
                        </Text>
                        {item.note ? (
                          <Text style={[styles.generalText, { marginTop: 8 }]}>
                            {capitalize(item.note)}
                          </Text>
                        ) : null}
                      </View>
                      {item.id == selected.id && (
                        <View style={styles.checkIcon}>
                          <BaranaIcons
                            draw={{ width: 22, height: 22 }}
                            name={"check"}
                          />
                        </View>
                      )}
                    </TouchableOpacity>
                  );
                })}
              </View>
            )}
          </View>
          }

      </ScrollView>
      <LowerOptionsContainer>
        <GeneralButton
          text="Confirmar"
          handleOnPress={() => {
            navigation.goBack();
          }}
          loading={loding}
          disabled={loding}
          // disabled={selected.length < 5}
          width={isComputer ? 350 : "100%"}
        />
      </LowerOptionsContainer>
    </View>
  );
};
