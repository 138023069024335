import { Svg, Path } from 'react-native-svg';


export const FishPreparations = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 56 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M10.5317 14.3652C11.2337 14.3879 11.9016 14.6754 12.4023 15.1706C12.8804 15.6837 13.1639 16.3493 13.2034 17.0512C13.2017 17.4032 13.1299 17.7513 12.9923 18.075C12.8548 18.3987 12.6542 18.6915 12.4023 18.9361C12.1608 19.1903 11.8705 19.3927 11.549 19.531C11.2275 19.6693 10.8814 19.7406 10.5317 19.7406C10.182 19.7406 9.83591 19.6693 9.51439 19.531C9.19287 19.3927 8.90256 19.1903 8.66104 18.9361C8.39243 18.7049 8.18044 18.4146 8.04151 18.0877C7.90258 17.7608 7.84044 17.4061 7.85995 17.0512C7.85831 16.6993 7.92857 16.3508 8.06637 16.0273C8.20417 15.7038 8.40657 15.4122 8.66104 15.1706C9.16132 14.6747 9.82945 14.387 10.5317 14.3652V14.3652Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
            <Path d="M11.082 16.5158C10.9206 16.4155 10.7383 16.3539 10.5494 16.3359C10.354 16.324 10.1617 16.3884 10.0125 16.5158C9.85792 16.6525 9.763 16.8448 9.74828 17.0513C9.7352 17.1573 9.75326 17.2649 9.80019 17.3608C9.84713 17.4567 9.92093 17.5367 10.0125 17.591C10.0732 17.6768 10.1538 17.7462 10.2475 17.7933C10.3411 17.8403 10.4447 17.8636 10.5494 17.8609C10.6517 17.8544 10.7517 17.8272 10.8432 17.7808C10.9348 17.7344 11.016 17.6699 11.082 17.591C11.1748 17.5377 11.2498 17.458 11.2975 17.362C11.3453 17.2659 11.3637 17.1578 11.3505 17.0513C11.3433 16.9486 11.3159 16.8483 11.2698 16.7564C11.2237 16.6644 11.1599 16.5827 11.082 16.5158Z" fill="#DB7500" />
            <Path d="M2.00974 18.8204L12.6114 7.55832C12.775 7.38544 12.9972 7.28067 13.234 7.2648C13.4708 7.24893 13.7049 7.32312 13.8898 7.47265C13.975 7.644 14.0602 7.72967 14.1454 7.90103C15.2387 11.6433 15.7913 15.5241 15.786 19.4245C15.7549 23.3221 15.2031 27.1979 14.1454 30.9479C14.0728 31.1797 13.9195 31.3774 13.7137 31.5048C13.5079 31.6323 13.2633 31.6809 13.0248 31.6419C12.8341 31.5573 12.6596 31.4398 12.5092 31.2949L2.00974 20.1227C1.84304 19.9474 1.75 19.7142 1.75 19.4716C1.75 19.229 1.84304 18.9958 2.00974 18.8204V18.8204Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
            <Path d="M45.9715 21.7635C44.6927 23.8068 43.2215 25.7219 41.5783 27.4824C38.6467 30.7723 35.0247 33.7196 31.2323 35.2789C27.2694 36.9282 23.1361 37.0995 19.0795 34.5892C18.2021 34.0876 17.3917 33.4758 16.6677 32.7686C16.544 32.6469 16.4547 32.4942 16.409 32.3264C16.3634 32.1585 16.3629 31.9814 16.4078 31.8133C18.7344 23.3228 18.9091 14.7423 16.4078 6.16617C16.3427 6.00007 16.3329 5.81726 16.3797 5.64507C16.4266 5.47288 16.5276 5.32056 16.6677 5.21088C17.465 4.49097 18.3314 3.85241 19.2542 3.30459C23.2171 0.965631 27.3504 1.0513 31.2323 2.69628C35.1142 4.16991 38.6467 7.11717 41.5783 10.4071C43.2035 12.2135 44.6732 14.1553 45.9715 16.2117L53.2155 8.50084C53.389 8.33823 53.6174 8.24782 53.8546 8.24782C54.0919 8.24782 54.3203 8.33823 54.4938 8.50084C54.6658 8.75265 54.7552 9.0522 54.7495 9.3576L53.4243 14.8966C52.8719 17.6475 52.856 20.4801 53.3774 23.2371L54.579 28.5105C54.6286 28.7508 54.5847 29.0011 54.4563 29.2099C54.3279 29.4187 54.1248 29.57 53.8887 29.6329C53.7366 29.6564 53.5812 29.6455 53.4338 29.6011C53.2863 29.5566 53.1506 29.4797 53.0365 29.3758L45.9715 21.7635Z" stroke="#DB7500" strokeWidth="2" strokeMiterlimit="10" />
        </Svg>
    )
}