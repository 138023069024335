import { StyleSheet, Dimensions } from 'react-native'


const { width } = Dimensions.get("window");
const isComputer = width >= 1024;
export const modalStyles = StyleSheet.create({
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        width: '100%',
        height: '100%',
    },
    container_options: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        zIndex: 0
    },
    show: {
        display: 'none',
    }
})

export const modal = () => ({
    backgroundColor: '#FFFFFF',
    width: 204,
    maxHeight: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 20,
    zIndex: 1,
})
export const editGarrisons = () => ({
    padding: 20,
    // position: 'relative',
    width: !isComputer ? '83%' : '45%',
    maxHeight: 550,
    // maxHeight: 200,
    backgroundColor: 'white',
    borderRadius: 12,
    zIndex: 100
})

export const options = () => ({
    padding: 20,
    position: 'relative',
    width: !isComputer ? '83%' : '45%',
    minHeight: 175,
    // maxHeight: 200,
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    zIndex: 100
})

export const helpModal = () => ({
    padding: 20,
    position: 'relative',
    width: !isComputer ? '83%' : '45%',
    minHeight: 200,
    backgroundColor: '#FFFFFF',
    borderRadius: 12,
    zIndex: 100
})

export const picker = (height, width) => ({
    paddingHorizontal: 15,
    paddingVertical: 26,
    position: 'relative',
    width: width || '70%',
    maxHeight: height || 190,
    backgroundColor: 'white',
    borderRadius: 8,
    zIndex: 100
})

export const title = (marginV) => ({
    textAlign: 'left',
    marginVertical: marginV || 0,
})

