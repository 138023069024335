 
import { useContext } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
//context
import { orderContext } from '../../context/orderingContext'
//icons
import { BaranaIcons } from '../../assets/icons/BaranaIcons'
//styles
import { styles, goBackContainer } from '../../styles/pages/widgets/goBack'


export const GoBack = ({ navigator, text, icon, width, alternative, paddingTop }) => {
  const { currentStep, setCurrentStep } = useContext(orderContext)

  const handlerOnPress = async () => {
    if (currentStep > 1) setCurrentStep(value => parseInt(value) - 1)
    if (alternative) {
      navigator.navigate(alternative)
    } else { navigator.goBack() }
  }

  return (
    <View style={[goBackContainer(width, paddingTop)]}>
      <TouchableOpacity style={styles.buttom} onPress={() => handlerOnPress()}>
        <BaranaIcons draw={{ width: 24, height: 24 }} name={icon} />
        {text &&
          <Text style={styles.goBackText}>{text}</Text>
        }
      </TouchableOpacity>
    </View>
  )
}