import { useContext, useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  FlatList,
  Dimensions,
  TouchableOpacity,
} from "react-native";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  title,
  buttomOption,
  buttomOptionOnFocus,
  spaceBetween,
  sectionContainerWeb,
  webContainer,
} from "../../../styles/pages/generalStyles";
import { centerAlignedRow } from "../../../styles/pages/home/homePage";
//components
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
//controllers
import { getNutritionalBarana } from "../../controllers/nutritionalConsulte";
// contexts
import { orderContext } from "../../../context/orderingContext";
import { nutritionalConsulte } from "../../../context/nutritionalConsulte";
//utils
import { capitalize } from "../../utils/utils";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useUserData from "../../../hooks/useUserData";

const { width } = Dimensions.get("window");
export const Options = ({ route, navigation, bottomTabNavigation }) => {
  const { access_token } = useUserData();
  const { setCurrentStep } = useContext(orderContext);
  const { setTypeConsultation } = useContext(nutritionalConsulte);
  const [loding, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selected, setSelected] = useState(0);
  const [optionsList, setOptionsList] = useState([]);
  const { isComputer } = useIsWeb();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  }, []);

  useEffect(() => {
    getListOfConsulaOptions();
    setSelected(1);
    setTypeConsultation(1);
    return () => {
      setOptionsList([]);
    };
  }, []);

  const getListOfConsulaOptions = async () => {
    setLoading(true);
    try {
      const res = await getNutritionalBarana(access_token);
      if (res?.length > 0) {
        setOptionsList([...res]);
      }
    } catch (error) {
      setError("Algo inesperado ha sucedido por favor vuelva a intentarlo.");
    } finally {
      setLoading(false);
    }
  };

  const handleOnPress = () => {
    const consultSelected = optionsList.find((item) => item.id === selected);
    setCurrentStep((value) => parseInt(value) + 1);

    if (consultSelected.type_consultation === "individual") {
      navigation.navigate("makeAnAppointment");
    } else if (consultSelected.type_consultation === "grupal") {
      navigation.navigate("groupSession");
    } else if (consultSelected.type_consultation === "evaluación") {
      navigation.navigate("evaluation");
    }
  };

  return (
    <View style={styles.screen}>
      <ScrollView>
        <View
          style={[
            styles.container,
            webContainer("calc(100vh - 160px)"),
            isComputer && {
              ...sectionContainerWeb(700, null, "100%", "center"),
              paddingBottom: 50,
            },
          ]}
        >
          <Text style={[title("100%", "center")]}>
            Atención Nutricional a Domicilio
          </Text>
          <Text style={[styles.generalText, { marginVertical: 25 }]}>
            Nuestra nutricionista Laura Guzmán Pérez será la encargada de
            brindarle el acompañamiento nutricional. Seleccione la opción que se
            ajuste a sus necesidades.
          </Text>
          <View
            style={{
              width: isComputer ? "100%" : width - 40,
            }}
          >
            {optionsList?.map((item, index) => {
              return (
                <TouchableOpacity
                  key={`${index}-consulteOptions`}
                  onPress={() => {
                    setTypeConsultation(item.id);
                    setSelected(item.id);
                  }}
                  style={[
                    buttomOption(80, 5, 16),
                    item.id == selected && buttomOptionOnFocus(),
                  ]}
                >
                  <View
                    style={[
                      centerAlignedRow(),
                      spaceBetween(null, item.id == selected ? "88%" : null),
                    ]}
                  >
                    <Text style={[styles.cardText, { maxWidth: 125 }]}>
                      {capitalize(item.name)}
                    </Text>
                    <Text style={[styles.cardText]}>
                      {item.price ? `₡ ${item.price}` : "Cotizar"}
                    </Text>
                  </View>
                  {item.id == selected && (
                    <View style={styles.checkIcon}>
                      <BaranaIcons
                        draw={{ width: 22, height: 22 }}
                        name={"check"}
                      />
                    </View>
                  )}
                </TouchableOpacity>
              );
            })}
          </View>
        </View>
      </ScrollView>
      <LowerOptionsContainer>
        <GeneralButton
          text="Continuar"
          handleOnPress={handleOnPress}
          disabled={loding}
          loading={loding}
          width={isComputer ? 350 : "100%"}
        />
      </LowerOptionsContainer>
    </View>
  );
};
