import { useEffect, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  FlatList,
  Dimensions,
  SafeAreaView,
} from "react-native";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  title,
  titleMargin,
  paragraph,
  webContainer,
} from "../../../styles/pages/generalStyles";
import {
  homeStyles,
  centerAlignedRow,
} from "../../../styles/pages/home/homePage";
//components
import { GeneralButton } from "../../widgets/generalButton";
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { SupermarketListItem } from "../shoppingCart/supermarketProducts/supermarketListItem";
import { PackageProductsList } from "../shoppingCart/packageProducts/packageProducts";
import { ScheduledAppointmentList } from "../shoppingCart/scheduledAppointment/listItems";
//utils
import { capitalize } from "../../utils/utils";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

const { width } = Dimensions.get("window");
export const ShowOrderDetails = ({ navigation, route }) => {
  const [order, setOrder] = useState(route.params?.order);
  const { shopping_cart } = order;
  const { isComputer } = useIsWeb();

  return (
    <SafeAreaView style={[styles.screen]}>
      <ScrollView>
        <View style={[styles.container, webContainer("calc(100vh - 160px)")]}>
          <Text
            style={[
              title("100%", "center"),
              titleMargin(isComputer),
              { marginTop: 60 },
            ]}
          >
            Detalles de la Compra
          </Text>

          {/* <--------------------- address ------------------> */}
          {shopping_cart?.address?.id ? (
            <>
              <View style={[centerAlignedRow()]}>
                <BaranaIcons draw={{ width: 24, height: 24 }} name="cube" />
                <Text style={[homeStyles.strongText, { marginLeft: 10 }]}>
                  Entrega: {capitalize(shopping_cart.day_of_delivery)}
                </Text>
              </View>

              <View style={[styles.line, { marginVertical: 25 }]}></View>
              <View style={[centerAlignedRow(null, "space-between")]}>
                <BaranaIcons draw={{ width: 24, height: 24 }} name="location" />
                <Text style={[paragraph("75%", 17)]}>
                  {shopping_cart.address.province
                    ? `${shopping_cart.address.province}, `
                    : ""}
                  {shopping_cart.address.zone !== "No aplica"
                    ? `${shopping_cart.address.zone}, `
                    : ""}
                  {shopping_cart.address.location !== "No aplica"
                    ? `${shopping_cart.address.location}`
                    : ""}
                  {shopping_cart.address?.text !== "No aplica"
                    ? `, ${shopping_cart.address?.text}. `
                    : ""}
                </Text>
              </View>
            </>
          ) : (
            <View style={[centerAlignedRow()]}>
              <BaranaIcons draw={{ width: 24, height: 24 }} name="cube" />
              <Text style={[homeStyles.strongText, { marginLeft: 10 }]}>
                Entrega: {capitalize(order.delivery_day) || ""}
              </Text>
            </View>
          )}

          {/* <--------------------- payment type ------------------> */}
          {shopping_cart?.type_method_pay ? (
            <>
              <View style={[styles.line, { marginVertical: 25 }]}></View>
              <View style={[centerAlignedRow()]}>
                <BaranaIcons draw={{ width: 24, height: 24 }} name="colones" />
                <Text style={[paragraph("75%"), { marginLeft: 10 }]}>
                  Método de pago:
                  {capitalize(shopping_cart.type_method_pay)}
                </Text>
              </View>
            </>
          ) : null}

          {/* <--------------------- factura ------------------> */}
          <View style={[styles.line, { marginVertical: 25 }]}></View>
          <View style={[centerAlignedRow()]}>
            <BaranaIcons draw={{ width: 24, height: 24 }} name="note" />
            <Text style={[paragraph("75%"), { marginLeft: 10 }]}>
              Factura electrónica: {shopping_cart?.facture ? "Si" : "No"}
            </Text>
          </View>

          {/* <--------------------- combos ------------------> */}
          {shopping_cart?.combos?.length > 0 ||
          shopping_cart?.plates?.length > 0 ? (
            <View style={[styles.line, { marginTop: 25 }]}></View>
          ) : null}
          {shopping_cart?.combos?.length > 0 ? (
            <SupermarketListItem
              list={shopping_cart.combos}
              comboList={true}
              margin={shopping_cart?.plates?.length == 0 && 15}
            />
          ) : null}

          {/* <--------------------- plates supermaker ------------------> */}
          {shopping_cart?.plates?.length > 0 ? (
            <SupermarketListItem
              list={shopping_cart.plates}
              comboList={false}
              margin={15}
            />
          ) : null}

          {/* <--------------------- armed packages ------------------> */}
          {shopping_cart?.armed_packages?.length > 0 ? (
            <View style={{ width: isComputer ? "100%" : width - 44 }}>
              {shopping_cart.armed_packages.map((item, index) => {
                return (
                  <PackageProductsList
                    armedPackages={item}
                    key={item.armed_package}
                  />
                );
              })}
            </View>
          ) : null}

          {/* <--------------------- nutritional consultation ------------------> */}
          {shopping_cart?.nutritional_consultation?.length > 0 && (
            <ScheduledAppointmentList
              nutritionalConsultes={shopping_cart.nutritional_consultation}
            />
          )}

          {/* <--------------------- note ------------------> */}
          {shopping_cart?.note && (
            <>
              <View style={[styles.line, { marginVertical: 25 }]}></View>
              <View style={[centerAlignedRow()]}>
                <BaranaIcons draw={{ width: 27, height: 27 }} name={"note"} />
                <Text style={[paragraph("73%"), { marginLeft: 10 }]}>
                  <Text style={styles.timeText}>Nota:</Text>{" "}
                  {shopping_cart.note || ""}
                </Text>
              </View>
            </>
          )}

          {/* <--------------------- transport ------------------> */}
          {shopping_cart?.transporte ? (
            <>
              <View style={[styles.line, { marginVertical: 25 }]}></View>
              <View style={[centerAlignedRow(null, "space-between")]}>
                <Text style={[styles.helpText]}>Transporte</Text>
                <Text style={[styles.helpText]}>
                  ₡ {order.shopping_cart.transporte}
                </Text>
              </View>
            </>
          ) : null}

          {/* <--------------------- total ------------------> */}
          {order?.total_price ? (
            <View style={{ paddingBottom: 30 }}>
              <View style={[styles.line, { marginVertical: 25 }]}></View>
              <View style={[centerAlignedRow(null, "space-between")]}>
                <Text style={[styles.helpText]}>Total</Text>
                <Text style={[styles.helpText]}>₡{order.total_price}</Text>
              </View>
            </View>
          ) : null}
        </View>
      </ScrollView>
      <LowerOptionsContainer>
        <GeneralButton
          text={"Listo"}
          handleOnPress={() => navigation.goBack()}
          width={isComputer ? 350 : "100%"}
        />
      </LowerOptionsContainer>
    </SafeAreaView>
  );
};
