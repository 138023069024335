import { createContext } from 'react'

export const nutritionalPrescription = createContext({
    nutritionalPlan: {
        name: '',
        equivalence_system: 'PINEC',
        type_plan: '',
        note: '',
        photo: null
    },
    setNutritionalPlan: () => { },
    planPortions: [],
    setPlanPortions: () => [],
})