import { useContext, useEffect, useState } from "react";
import { View, Text, Image, Linking, Share } from "react-native";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//styles
import {
  styles,
  paragraph,
  spaceBetween,
  sectionContainerWeb,
  flexContainer,
} from "../../../styles/pages/generalStyles";
import {
  sections,
  centerAlignedRow,
} from "../../../styles/pages/home/homePage";
//widgets
import { OutlinedButton } from "../../widgets/outlinedButton";
//controller
import { getCodeReference } from "../../controllers/coupons";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useUserData from "../../../hooks/useUserData";

export const Discount = ({ background, navigation }) => {
  const { access_token } = useUserData();
  const [loding, setLoading] = useState(false);
  const { isComputer } = useIsWeb();
  const [referenceCode, setFerenceCode] = useState();

  useEffect(() => {
    handleGetReferenceCode();
  }, []);

  const handleGetReferenceCode = async () => {
    setLoading(true);
    await getCodeReference(access_token)
      .then((res) => {
        setFerenceCode(res.my_coupon);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleFacebookPress = async () => {
    // await Linking.openURL(
    //     "https://www.facebook.com/Coma.Barana/reviews"
    // );
    await Linking.openURL("https://www.facebook.com/Coma.Barana");
  };

  const handleGooglePress = async () => {
    await Linking.openURL("https://g.page/r/CXG_4_jVtMUPEBM/review");
  };

  const openShare = async () => {
    try {
      const result = await Share.share({
        message: `Con el siguiente código obtendrá un descuento por ₡3000 en su próxima compra en Baraná, el app para comer bien. Usted y su amigo recibirán el descuento cuando la persona referida ingrese el código al momento de la compra \n\nCódigo: ${referenceCode}`,
      });

      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <View>
      <View style={[sections(background == "dark", 465, 20, 20)]}>
        <BaranaIcons
          draw={{ width: "100%", height: 206 }}
          name={"packagingTechnology"}
        />
        {isComputer ? (
          <View
            style={[
              flexContainer("row", "center", "center", "wrap", null, 157),
              sectionContainerWeb(1000, null, "100%"),
            ]}
          >
            <BaranaIcons draw={{ width: 193, height: 193 }} name={"fish"} />
            <View
              style={[
                flexContainer(
                  "row",
                  "space-evenly",
                  "center",
                  "wrap",
                  null,
                  50
                ),
              ]}
            >
              <View style={{ width: 300 }}>
                <Text
                  style={[
                    paragraph("89%", 26),
                    { fontWeight: 800, lineHeight: 36 },
                  ]}
                >
                  Recomiende y gane
                </Text>
                <Text style={[paragraph("89%")]}>
                  Recomiende a un amigo y reciba un descuento por ₡3000.
                </Text>
                <OutlinedButton
                  text="Recomendar"
                  handleOnPress={() => openShare()}
                  width={isComputer ? "80%" : "100%"}
                />
              </View>
              <View style={{ width: 300 }}>
                <Text
                  style={[
                    paragraph("89%", 26),
                    { fontWeight: 800, lineHeight: 36 },
                  ]}
                >
                  Califíquenos
                </Text>
                <Text style={[paragraph("89%")]}>
                  ¡Califíquenos en Google o Facebook y reciba una regalía
                  sorpresa!
                </Text>
                <OutlinedButton
                  text="Google"
                  handleOnPress={() => handleGooglePress()}
                  width={isComputer ? "80%" : "100%"}
                />
                <OutlinedButton
                  text="Facebook"
                  handleOnPress={() => handleFacebookPress()}
                  width={isComputer ? "80%" : "100%"}
                />
              </View>
            </View>
          </View>
        ) : (
          <View
            style={[styles.homeContainer, isComputer && sectionContainerWeb()]}
          >
            <Text style={[styles.cardText, { textTransform: "none" }]}>
              ¡Gane con Baraná!
            </Text>
            <View style={[centerAlignedRow(), spaceBetween(30)]}>
              <BaranaIcons
                draw={{ width: "23%", height: isComputer ? 45 : 60 }}
                name={"users"}
              />
              <View style={{ width: "66%" }}>
                <Text style={[paragraph("89%")]}>
                  Recomiende a un amigo y reciba un descuento por ₡3000.
                </Text>
                <OutlinedButton
                  text="Recomendar"
                  handleOnPress={() => openShare()}
                  width={isComputer ? "80%" : "100%"}
                />
              </View>
            </View>
            <View style={[centerAlignedRow(), spaceBetween(30)]}>
              <BaranaIcons
                draw={{ width: "24%", height: isComputer ? 45 : 60 }}
                name={"prize"}
              />
              <View style={{ width: "66%" }}>
                <Text style={paragraph("89%")}>
                  ¡Califíquenos en Google o Facebook y reciba una regalía
                  sorpresa!
                </Text>
                <OutlinedButton
                  text="Google"
                  handleOnPress={() => handleGooglePress()}
                  width={isComputer ? "80%" : "100%"}
                />
                <OutlinedButton
                  text="Facebook"
                  handleOnPress={() => handleFacebookPress()}
                  width={isComputer ? "80%" : "100%"}
                />
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};