import { View, Text, ScrollView, Dimensions, FlatList } from "react-native";
//Styles
import {
  styles,
  titleMargin,
  flexContainer,
} from "../../../../styles/pages/generalStyles";
//components
import { DishCard } from "../../../widgets/dishCard";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";
import useCartStore from "../../shoppingCart/useCartStore";
//utils
import { getItemType, getItemQuantity } from "../../../utils/shoppingCartUtils";

const { width } = Dimensions.get("window");
export const CombosList = ({
  updatingCart,
  combosList,
  handleAddToCart,
  handleDeleteToCart,
  navigation,
}) => {
  const { isComputer } = useIsWeb();
  const { getProductById } = useCartStore();

  return (
    <View>
      <Text style={[styles.cardText, titleMargin(isComputer)]}>Combos</Text>
      <View
        style={[
          { width: "100%" },
          flexContainer(
            "row",
            isComputer ? null : "space-between",
            "center",
            "wrap",
            null,
            15
          ),
        ]}
      >
        {combosList?.map((item, index) => {
          const itemType = getItemType(item);
          const itemQuantity = getItemQuantity(
            item?.id,
            itemType,
            getProductById
          );

          return (
            <DishCard
              uniqueKey={`${item.id}-combos`}
              updatingCart={updatingCart}
              uri={true}
              id={item.id}
              image={item?.plates[0]?.image || ""}
              recipe={item.name}
              price={item.price}
              icon={"plusCircle"}
              details={true}
              width={isComputer ? 160 : 140}
              combo={item.plates ? true : false}
              quantity={itemQuantity}
              onPress={() => handleAddToCart(itemType, item)}
              onDelete={() => handleDeleteToCart(itemType, item.id)}
              variation={item.cant_people}
              goTo={(params) => navigation.navigate("detailsPlate", params)}
            />
          );
        })}
      </View>
      {/* <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal={true}
        style={{ width: "100%" }}
        contentContainerStyle={{ width: "100%" }}
      >
        <FlatList
          bounces={false}
          style={{ width: isComputer ? "100%" : width - 40 }}
          contentContainerStyle={flexContainer(
            "row",
            isComputer ? null : "space-between",
            "center",
            "wrap",
            null,
            isComputer && 10
          )}
          data={combosList}
          keyExtractor={(item, index) => index}
          renderItem={({ item, index }) => {
            const itemType = getItemType(item);
            const itemQuantity = getItemQuantity(
              item?.id,
              itemType,
              getProductById
            );

            return (
              <DishCard
                uniqueKey={`${item.id}-combos`}
                updatingCart={updatingCart}
                uri={true}
                id={item.id}
                image={item?.plates[0]?.image || ""}
                recipe={item.name}
                price={item.price}
                icon={"plusCircle"}
                details={true}
                width={isComputer ? 180 : 140}
                combo={item.plates ? true : false}
                quantity={itemQuantity}
                onPress={() => handleAddToCart(itemType, item)}
                onDelete={() => handleDeleteToCart(itemType, item.id)}
                variation={item.cant_people}
                goTo={(params) => navigation.navigate("detailsPlate", params)}
              />
            );
          }}
        />
      </ScrollView> */}
    </View>
  );
};
