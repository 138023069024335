import { createStackNavigator } from '@react-navigation/stack'
//color
import { useTheme } from 'react-native-paper'
//screens
import { Orders } from '../screen/orders/orders'
import { ShowOrderDetails } from '../screen/orders/showOrderDetails'


export const OrdersNavigator = ({ navigation }) => {
    const Stack = createStackNavigator()
    const { colors } = useTheme()
    return (
        <Stack.Navigator initialRouteName={'orders'}>
            <Stack.Screen
                name={'orders'}
                options={({ navigation }) => ({
                    headerTitle: '',
                    headerLeft: () => null,
                    headerTransparent: true,
                })}
            >
                {({ route, navigation }) => (
                    <Orders navigation={navigation} />
                )}
            </Stack.Screen>
            <Stack.Screen
                name={'orderDetails'}
                options={({ navigation }) => ({
                    headerTitle: '',
                    headerLeft: () => null,
                    headerTransparent: true,
                })}
            >
                {({ route, navigation }) => (
                    <ShowOrderDetails navigation={navigation} route={route} />
                )}
            </Stack.Screen>
        </Stack.Navigator>
    )
}