import { useEffect, useContext } from "react";
import { createStackNavigator } from "@react-navigation/stack";
//context
import { orderContext } from "../../context/orderingContext";
//screens
import { Introduction } from "../screen/subscription/subscription";
import { Subscriptions } from "../screen/profile/subscriptions/subscriptions";
import { Details } from "../screen/subscription/details";
import { Summary } from "../screen/subscription/summary";
import { MapScreen } from "../screen/map/mapScreen";
import { DeliveryAddress } from "../screen/payment/deliveryAddress";
import { Locations } from "../screen/profile/locations";
import { IBAN_Accounts } from "../screen/profile/IBAN_Accounts";
import { OnlinePayment } from "../screen/payment/onlinePayment";
import { FoodMenu } from "../screen/orderDefinition/foodMenu";
import { NumberOfMeals } from "../screen/orderDefinition/numberOfMeals";
import { DetailsMenu } from "../screen/orderDefinition/menu/details";
import { Plan } from "../screen/orderDefinition/planList/planList";
import { EditPlan } from "../screen/orderDefinition/planList/editPlan";
import { NutritionalPlans } from "../screen/subscription/plans/nutritionalPlans";
import { NutritionalPrescription } from "../screen/plans/nutritionalPrescription";
import { AccordingBaranaPlans } from "../screen/subscription/plans/accordingBaranaPlans";
//navigators
import { OrderDefinitionNavigator } from "./orderDefinitionNavigator";
//styles
import {
  styles,
  headerComponentStyle,
  titleStyle,
  headerTitleContainer,
} from "../../styles/pages/navigators/headers";
//components
import { GoBack } from "../widgets/goBack";
import { StepProgress } from "../widgets/stepProgress";
//hooks
import useIsWeb from "../../hooks/useIsWeb";

export const SubscriptionNavigator = ({
  bottomTabNavigation,
  initialRouteName,
  createSubscriptionFromHome = false,
}) => {
  const { currentStep, totalSteps } = useContext(orderContext);
  const Stack = createStackNavigator();
  const { isComputer } = useIsWeb();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });
  return (
    <Stack.Navigator initialRouteName={initialRouteName}>
      <Stack.Screen
        name={"introduction"}
        options={({ navigation }) => ({
          headerTitle: () => (
            <StepProgress step={currentStep} totalSteps={totalSteps} />
          ),
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ route, navigation }) => (
          <Introduction route={route} navigation={navigation} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"subscriptions"}
        options={({ navigation }) => ({
          headerTitle: "Suscripciones",
          headerLeft: () => (
            <GoBack
              navigator={navigation}
              icon={"arrowBack"}
              width={48}
              alternative={"profile"}
            />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation }) => (
          <Subscriptions
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"suscriptionDetails"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Details
            navigation={navigation}
            route={route}
            createSubscriptionFromHome={createSubscriptionFromHome}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"createSubscriptionAddress"}
        options={({ navigation }) => ({
          headerTitle: "Dirección de Entrega",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <DeliveryAddress
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"mapScreen"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <MapScreen
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"editSubscriptionAddress"}
        options={({ navigation }) => ({
          headerTitle: "Direcciones de Entrega",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Locations
            navigation={navigation}
            route={route}
            createSubscriptionFromHome={createSubscriptionFromHome}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"orderDefinition"}
        options={{
          headerTitle: "",
          headerLeft: () => null,
          headerTransparent: true,
        }}
      >
        {({ route }) => (
          <OrderDefinitionNavigator
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"editMenu"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <FoodMenu
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"detailsMenu"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"leftArrow"} text={"Volver"} />
          ),
          headerStyle: styles.headerWithBorder,
        })}
      >
        {({ navigation, route }) => (
          <DetailsMenu
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"editPackagePlan"}
        options={({ navigation }) => ({
          headerTitle: "Tipo de Plan",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <Plan
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"nutritionalPlan"}
        options={({ navigation }) => ({
          headerTitle: "Plan Nutricional",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <NutritionalPlans
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"accordingToBarana"}
        options={({ navigation }) => ({
          headerTitle: "Mis Planes Según Barana",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <AccordingBaranaPlans
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"nutritionalPrescription"}
        options={({ navigation, route }) => ({
          headerTitle: "Prescripción Nutricional",
          headerLeft: () => (
            <GoBack
              navigator={navigation}
              icon={"arrowBack"}
              width={48}
              alternative={null}
            />
          ),
          headerTitleStyle: [titleStyle(), { marginLeft: "5%" }],
          headerTitleContainerStyle: headerComponentStyle.titleContainer,
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <NutritionalPrescription
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"editPlan"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"leftArrow"} text={"Volver"} />
          ),
          headerStyle: styles.headerWithBorder,
        })}
      >
        {({ navigation, route }) => (
          <EditPlan
            navigation={navigation}
            route={route}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"IBAN"}
        options={({ navigation }) => ({
          headerTitle: "Débito Automático",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerTitleStyle: titleStyle(isComputer ? 25 : 20),
          headerTitleContainerStyle: headerTitleContainer(isComputer ? 60 : 40),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <IBAN_Accounts
            route={route}
            navigation={navigation}
            bottomTabNavigation={bottomTabNavigation}
          />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"createOnlinePayment"}
        options={({ navigation }) => ({
          headerTitle: "",
          headerLeft: () => (
            <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
          ),
          headerStyle: headerComponentStyle.containerHeight,
        })}
      >
        {({ navigation, route }) => (
          <OnlinePayment navigation={navigation} route={route} />
        )}
      </Stack.Screen>
      <Stack.Screen
        name={"subscriptionSummary"}
        options={() => ({
          headerTitle: "",
          headerLeft: () => null,
          headerTransparent: true,
        })}
      >
        {({ navigation, route }) => (
          <Summary route={route} navigation={navigation} />
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
};
