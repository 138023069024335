import { useState, useEffect, useContext } from 'react'
import {
    View,
    Text,
    ScrollView,
    TouchableOpacity,
} from 'react-native'
import { Formik } from 'formik'
import * as yup from 'yup'
//icons
import { BaranaIcons } from '../../../assets/icons/BaranaIcons'
//color
import { RadioButton, Checkbox } from 'react-native-paper'
//components
import { ShadedInput } from '../../widgets/shadedInput'
import { LowerOptionsContainer } from '../../widgets/lowerOptionsContainer'
import { GeneralButton } from '../../widgets/generalButton'
// contexts
import { shoppingCart } from '../../../context/shoppingCart'
//Styles
import { styles, title, webContainer } from '../../../styles/pages/generalStyles'
import { centerAlignedRow } from '../../../styles/pages/home/homePage'
import * as inputStyles from '../../../styles/pages/widgets/inputs'
import useCartStore from "../shoppingCart/useCartStore";

const validationSchema = yup.object({
  cardNumber: yup.number().required(""),
  ownerName: yup.string().required(""),
  cvs: yup.number().required(""),
  year: yup.string().required(""),

  month: yup.string().required(""),
  ID_Number: yup.string().required(""),
});

// Tranferencias
export const PaymentScreen = ({ navigation, route }) => {
  const { cart, setCart } = useCartStore();
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [title_, setTitle_] = useState("");
  const [loding, setLoading] = useState(false);
  const { methodOfPayment } = route.params;

  useEffect(() => {
    setTitle_(methodOfPayment);
    navigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  const submit = async () => {};

  return (
    <Formik
      initialValues={{
        cardNumber: "",
        ownerName: "",
        cvs: "",
        year: "",
        month: "",
        ID_Number: "",
      }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={submit}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
      }) => (
        <View style={styles.screen}>
          <ScrollView>
            <View style={[styles.container, webContainer(null, 5)]}>
              {!cart.payment_method ? (
                <Text style={[title("100%", "center"), { marginBottom: 18 }]}>
                  {title_}
                </Text>
              ) : null}
              <View style={[styles.inputsContainer]}>
                <ShadedInput
                  value={values.cardNumber}
                  placeholder="Número de tarjeta"
                  onChangeText={handleChange("cardNumber")}
                  autoCapitalize={"none"}
                  errors={
                    errors.cardNumber && touched.cardNumber && errors.cardNumber
                  }
                  onBlur={() => handleBlur("cardNumber")}
                  width="100%"
                />
                <ShadedInput
                  value={values.ownerName}
                  placeholder="Nombre del títular"
                  onChangeText={handleChange("ownerName")}
                  autoCapitalize={"none"}
                  errors={
                    touched.ownerName && errors.ownerName && errors.ownerName
                  }
                  onBlur={() => handleBlur("ownerName")}
                  width="100%"
                />
                <View style={[centerAlignedRow(null, "space-between")]}>
                  <ShadedInput
                    value={values.month}
                    placeholder="Mes"
                    onChangeText={handleChange("month")}
                    autoCapitalize={"none"}
                    errors={touched.month && errors.month && errors.month}
                    onBlur={() => handleBlur("month")}
                    icon={
                      <TouchableOpacity style={inputStyles.styles.rightIcon}>
                        <BaranaIcons
                          draw={{ width: 24, height: 24 }}
                          name={"rollDown"}
                        />
                      </TouchableOpacity>
                    }
                    width="33.3%"
                    input="90%"
                  />
                  <ShadedInput
                    value={values.year}
                    placeholder="Año"
                    onChangeText={handleChange("year")}
                    autoCapitalize={"none"}
                    errors={touched.year && errors.year && errors.year}
                    onBlur={() => handleBlur("year")}
                    icon={
                      <TouchableOpacity style={inputStyles.styles.rightIcon}>
                        <BaranaIcons
                          draw={{ width: 24, height: 24 }}
                          name={"rollDown"}
                        />
                      </TouchableOpacity>
                    }
                    width="33.3%"
                    input="90%"
                  />
                  <ShadedInput
                    value={values.cvs}
                    placeholder="CVS"
                    onChangeText={handleChange("cvs")}
                    autoCapitalize={"none"}
                    errors={touched.cvs && errors.cvs && errors.cvs}
                    onBlur={() => handleBlur("cvs")}
                    icon={
                      <TouchableOpacity style={inputStyles.styles.rightIcon}>
                        <BaranaIcons
                          draw={{ width: 24, height: 24 }}
                          name={"exclamation"}
                        />
                      </TouchableOpacity>
                    }
                    width="33.3%"
                    input="90%"
                  />
                </View>
                <ShadedInput
                  value={values.ID_Number}
                  placeholder="Número de cédula"
                  onChangeText={handleChange("ID_Number")}
                  autoCapitalize={"none"}
                  errors={
                    touched.ID_Number && errors.ID_Number && errors.ID_Number
                  }
                  onBlur={() => handleBlur("ID_Number")}
                  width="100%"
                />
                <View
                  style={[centerAlignedRow(), { marginTop: 22, width: "100%" }]}
                >
                  <RadioButton
                    color={"#DB7500"}
                    value={checked}
                    status={checked ? "checked" : "unchecked"}
                    onPress={() => setChecked(!checked)}
                  />
                  <Text>Guardar método de pago.</Text>
                </View>
                <View style={[centerAlignedRow(), { width: "100%" }]}>
                  <Checkbox
                    color={"#E0E0E0"}
                    value={invoice}
                    status={invoice ? "checked" : "unchecked"}
                    onPress={() => setInvoice(!invoice)}
                  />
                  <Text>Solicitar factura</Text>
                </View>
              </View>

              {error && <Text style={styles.error}>{error}</Text>}
            </View>
          </ScrollView>
          <LowerOptionsContainer>
            <GeneralButton
              text="Continuar"
              handleOnPress={() => navigation.navigate("thanksForYourPurchase")}
              width="100%"
            />
          </LowerOptionsContainer>
        </View>
      )}
    </Formik>
  );
};