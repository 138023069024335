import axios from 'axios'
import AsyncStorage from "@react-native-async-storage/async-storage";
import useUserData from "../../hooks/useUserData";
import useCartStore from "../screen/shoppingCart/useCartStore";
import * as navigation from "../../setup/navigationUtils";

export const url = "https://api.barana.app";

const client = axios.create({
  baseURL: `${url}`,
  timeout: 100000,
  headers: {
    "content-type": "application/json",
  },
  withCredentials: false,
});

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response } = error;
    const access_token = await AsyncStorage.getItem("access_token");
    if (access_token && response?.status === 401) {
      useUserData.getState().removeUserData();
      useCartStore.getState().clearCart();
      client.defaults.headers.common["Authorization"] = undefined;
      navigation.reset({
        index: 0,
        routes: [{ name: "Home" }],
      });
    }
    return Promise.reject(error);
  }
);

export default client