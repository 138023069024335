import { useContext, useState, useEffect } from "react";
import { View, Text, TouchableOpacity, FlatList } from "react-native";
import { useLinkTo, useNavigation } from "@react-navigation/native";
//icons
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//Styles
import { styles, smallButton } from "../../../../styles/pages/generalStyles";
import {
  homeStyles,
  centerAlignedRow,
} from "../../../../styles/pages/home/homePage";
//context
import { orderContext } from "../../../../context/orderingContext";
//utils
import { sortPackageToEdit } from "../../../utils/shoppingCart/package";
import { capitalize } from "../../../utils/utils";

export const ListPackageComponent = ({
  subPackages,
  title,
  armedPackage,
  armedPackageId,
  edit,
  timeFood,
  goTo,
}) => {
  const {
    mealTimesList,
    setOrderDefinition,
    orderDefinition,
    createSubscriptionFromHome,
  } = useContext(orderContext);
  const [mealTimes, setMealTimes] = useState([]);
  const linkTo = useLinkTo();
  const navigation = useNavigation();

  useEffect(() => {
    setMealTimes([]);

    setTimeout(() => {
      mealTimesList.map((item) => {
        if (title.toLowerCase().includes(item.name)) {
          mealTimes.push(item.id);
          setMealTimes(mealTimes);
        }
      });
    }, 30);
  }, [subPackages]);

  return (
    <View style={{ width: "100%" }}>
      <View style={[styles.line, { marginVertical: 22 }]}></View>
      <View style={[centerAlignedRow(8, "space-between")]}>
        <Text
          style={[homeStyles.strongText, { width: "60%", textAlign: "left" }]}
        >
          {`${capitalize(title)} - ${subPackages.cant_plates} días`}
        </Text>
        {subPackages?.price ? (
          <Text style={homeStyles.strongText}>₡ {subPackages?.price}</Text>
        ) : null}

        {/* )} */}
      </View>

      {subPackages?.plan?.name ? (
        <View style={[centerAlignedRow(8, "space-between")]}>
          <Text style={[styles.helpText]}>
            Tipo de plan: {capitalize(subPackages?.plan?.name)}
          </Text>

          {edit ? (
            <TouchableOpacity
              style={smallButton(2)}
              onPress={async () => {
                const packageToEdit = await sortPackageToEdit(
                  armedPackage,
                  armedPackageId,
                  mealTimes
                );
                setOrderDefinition({ ...orderDefinition, ...packageToEdit });
                setTimeout(() => {
                  goTo && goTo();
                }, 50);
              }}
            >
              <BaranaIcons draw={{ width: 24, height: 24 }} name="pencil" />
            </TouchableOpacity>
          ) : null}
        </View>
      ) : null}

      <View style={[styles.line, { marginVertical: 22 }]}></View>

      {/* plates */}
      {subPackages?.plates ? (
        <>
          <View
            style={[centerAlignedRow(5, "space-between"), { width: "100%" }]}
          >
            <View style={[centerAlignedRow()]}>
              <BaranaIcons
                draw={{ width: 24, height: 24, color: "black" }}
                name="littleHeart"
              />
              <Text style={[styles.helpText, { marginLeft: 10 }]}>Menú</Text>
            </View>
            <TouchableOpacity
              onPress={() => {
                // setCurrentStep(3);
                // setTotalSteps(5);
                navigation.navigate("editMenu", {
                  to: "edit",
                  timeFood,
                });
              }}
              style={smallButton()}
            >
              <BaranaIcons draw={{ width: 24, height: 24 }} name="pencil" />
            </TouchableOpacity>
          </View>
          <View style={[centerAlignedRow(8, "center", "column", "flex-start")]}>
            {subPackages.plates.map((plate) => (
              <Text style={[styles.helpText]} key={plate.id}>
                {capitalize(plate.recipe)}
              </Text>
            ))}
          </View>
        </>
      ) : null}
    </View>
  );
};
