import { useEffect, useState, useContext } from "react";
import { View, Text, TouchableOpacity } from "react-native";
//components
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  paragraph,
  spaceBetween,
} from "../../../../styles/pages/generalStyles";
import {
  homeStyles,
  centerAlignedRow,
} from "../../../../styles/pages/home/homePage";
//utils
import { capitalize } from "../../../utils/utils";
//context
import { mainContext } from "../../../../context/mainContext";
//hooks
import useCartStore from "../useCartStore";
import useDebounce from "../../../../hooks/useDebounce";
import useUserData from "../../../../hooks/useUserData";

export const ScheduledAppointmentItem = ({ item, index }) => {
  const { access_token } = useUserData();
  const [loading, setLoading] = useState(false);
  const [groupConsultationOrEvaluation, setOption] = useState(false);
  const { removeNutricionalConsultationToCart, createOrUpdateCart } =
    useCartStore();
  const { debounceFunction: createOrUpdateCartDebounce } = useDebounce(
    (...args) => {
      setLoading(true);
      createOrUpdateCart(...args).finally(() => setLoading(false));
    }
  );

  useEffect(() => {
    if (
      item?.type_consultation?.includes("evaluación") ||
      item?.type_consultation?.includes("grupal")
    ) {
      setOption(true);
    } else {
      setOption(false);
    }
  }, [item.id]);

  const handleDeleteToCart = () => {
    removeNutricionalConsultationToCart(item.id);
    createOrUpdateCartDebounce(access_token);
  };

  return (
    <View key={`${index}_appointmentItem`}>
      <View style={[styles.line, { marginTop: 25 }]}></View>
      <View style={[centerAlignedRow(22), spaceBetween(22)]}>
        <Text
          style={[
            homeStyles.strongText,
            { textAlign: "left", maxWidth: "75%" },
          ]}
        >
          {capitalize(item.type_consultation)}
        </Text>
        <Text style={[homeStyles.strongText]}>
          {item.price ? `₡ ${item.price.toFixed(2)}` : ""}
        </Text>
        <TouchableOpacity disabled={loading} onPress={handleDeleteToCart}>
          <BaranaIcons
            draw={{ width: 24, height: 24 }}
            name={"outlinedMinus"}
          />
        </TouchableOpacity>
      </View>
      {!groupConsultationOrEvaluation ? (
        <>
          <Text style={[paragraph("97%", 17, 10)]}>
            Fecha: {item.schedule_date}
          </Text>
          <Text style={[paragraph("97%", 17, 10)]}>
            Hora: {item.delivery_hour}
          </Text>
        </>
      ) : (
        <Text style={[paragraph("97%", 17, 10)]}>Cotizacion solicitada</Text>
      )}
    </View>
  );
};
