import { Svg, G, Defs, Path, ClipPath, Rect } from "react-native-svg";

export const Subscription = ({
  color,
  height,
  width,
  active = false,
  focused = false,
}) => {
  return (
    <Svg
      height={height}
      viewBox="0 -16 511.99999 511"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="m427.214844 8h-203.144532c-22.058593 0-39.941406 17.882812-39.941406 39.941406v394.585938h283.027344v-394.585938c0-22.058594-17.882812-39.941406-39.941406-39.941406zm0 0"
        fill="#dc8b72"
      />
      <Path
        d="m224.203125 48.074219h202.878906v394.453125h-202.878906zm0 0"
        fill="#b3684b"
      />
      <Path
        d="m401.589844 245.300781c0 10.957031-8.886719 19.839844-19.84375 19.839844-10.957032 0-19.839844-8.882813-19.839844-19.839844s8.882812-19.839843 19.839844-19.839843c10.957031 0 19.84375 8.882812 19.84375 19.839843zm0 0"
        fill="#fecc2e"
      />
      <Path
        d="m146.777344 412.625h357.726562v59.804688h-357.726562zm0 0"
        fill="#4f648f"
      />
      <Path
        d="m146.777344 412.625v59.804688h165.046875c1.902343-5.054688 2.949219-10.523438 2.949219-16.234376v-43.570312zm0 0"
        fill="#41476b"
      />
      <Path
        d="m222.125 314.289062-85.5-28.335937-53.136719 93.238281 53.136719 93.238282h131.914062c8.964844 0 16.234376-7.269532 16.234376-16.234376v-170.242187zm0 0"
        fill="#fedd60"
      />
      <Path
        d="m72.058594 314.289062-41.710938-28.335937v170.242187c0 8.964844 7.269532 16.234376 16.234375 16.234376h90.042969v-186.476563zm0 0"
        fill="#fecc2e"
      />
      <Path
        d="m307.625 342.628906-22.851562-56.675781h-148.148438l22.851562 56.675781zm0 0"
        fill="#fecc2e"
      />
      <Path
        d="m7.496094 342.628906 22.851562-56.675781h106.277344l-22.851562 56.675781zm0 0"
        fill="#fedd60"
      />
      <Path d="m169.695312 390.5625c4.140626 0 7.5-3.359375 7.5-7.5v-8.015625c0-4.140625-3.359374-7.5-7.5-7.5-4.140624 0-7.5 3.359375-7.5 7.5v8.015625c0 4.140625 3.355469 7.5 7.5 7.5zm0 0" />
      <Path d="m258.253906 390.5625c4.140625 0 7.5-3.359375 7.5-7.5v-8.015625c0-4.140625-3.359375-7.5-7.5-7.5-4.144531 0-7.5 3.359375-7.5 7.5v8.015625c0 4.140625 3.355469 7.5 7.5 7.5zm0 0" />
      <Path d="m213.972656 394.839844c6.425782 0 12.464844-2.695313 16.578125-7.398438 2.726563-3.121094 2.40625-7.855468-.710937-10.582031-3.117188-2.730469-7.859375-2.410156-10.582032.707031-1.265624 1.445313-3.1875 2.273438-5.285156 2.273438-2.09375 0-4.019531-.828125-5.28125-2.273438-2.726562-3.117187-7.464844-3.4375-10.582031-.707031-3.121094 2.726563-3.4375 7.460937-.710937 10.582031 4.109374 4.703125 10.152343 7.398438 16.574218 7.398438zm0 0" />
      <Path d="m409.082031 245.300781c0-15.074219-12.265625-27.339843-27.339843-27.339843-15.078126 0-27.34375 12.265624-27.34375 27.339843 0 15.078125 12.265624 27.34375 27.34375 27.34375 15.074218 0 27.339843-12.265625 27.339843-27.34375zm-27.339843 12.339844c-6.804688 0-12.34375-5.535156-12.34375-12.339844 0-6.804687 5.539062-12.34375 12.34375-12.34375 6.804687 0 12.339843 5.539063 12.339843 12.34375 0 6.804688-5.535156 12.339844-12.339843 12.339844zm0 0" />
      <Path d="m474.648438 47.941406c0-26.160156-21.28125-47.441406-47.441407-47.441406h-130.800781c-4.140625 0-7.5 3.359375-7.5 7.5s3.359375 7.5 7.5 7.5h130.800781c17.890625 0 32.441407 14.554688 32.441407 32.441406v357.183594h-25.074219s0-356.421875 0-357.050781c0-4.097657-3.402344-7.5-7.5-7.5h-202.878907c-4.140624 0-7.5 3.359375-7.5 7.5v230.378906h-25.070312v-230.511719c0-17.890625 14.550781-32.441406 32.4375-32.441406h42.34375c4.140625 0 7.5-3.359375 7.5-7.5s-3.359375-7.5-7.5-7.5h-42.34375c-26.15625 0-47.4375 21.28125-47.4375 47.441406v230.511719h-146.285156c-3.058594 0-5.8125 1.855469-6.957032 4.695313 0 0-22.828124 56.617187-22.851562 56.671874-1.957031 4.855469 1.738281 10.308594 6.957031 10.308594h15.351563v106.066406c0 13.085938 10.648437 23.734376 23.734375 23.734376h457.925781c4.140625 0 7.5-3.359376 7.5-7.5v-59.804688c0-4.140625-3.359375-7.5-7.5-7.5h-29.851562zm-456.050782 287.183594 16.804688-41.671875h90.105468l-16.804687 41.671875zm110.519532 129.804688h-82.542969c-4.8125 0-8.730469-3.917969-8.730469-8.734376v-106.066406h75.921875c3.058594 0 5.8125-1.859375 6.957031-4.695312l8.394532-20.824219zm148.148437-52.304688v43.570312c0 4.671876-4.074219 8.734376-8.734375 8.734376h-124.414062v-140.320313l8.398437 20.820313c1.117187 2.773437 3.976563 4.695312 6.957031 4.695312h117.792969zm-112.730469-77.5-16.804687-41.671875h131.972656l16.804687 41.671875zm332.464844 85v44.804688h-206.398438c.019532-.046876.03125-.101563.050782-.148438 1.0625-2.726562 1.613281-5.65625 1.613281-8.585938v-36.066406h73.476563c4.140624 0 7.5-3.359375 7.5-7.5 0-4.144531-3.359376-7.5-7.5-7.5h-73.476563v-55h15.355469c5.15625 0 8.882812-5.53125 6.957031-10.304687 0 0-22.546875-55.914063-22.609375-56.066407-1.226562-3.046874-3.804688-5.304687-7.199219-5.304687h-53.070312v-222.878906h187.878906v349.550781h-23.832031c-4.144532 0-7.503906 3.359375-7.503906 7.5 0 4.144531 3.359374 7.5 7.503906 7.5zm0 0" />
    </Svg>
  );
};
