export const getPlanName = async (planList, planId) => {
    let plan = {};

    plan = planList.find(item => item.id === planId)
    return plan?.name || '';
}

export const filterPlans = (planList, filter, value) => {
    let plan = {};
    // console.log('planList ', planList)
    plan = planList.find(item => item[filter].toLowerCase() === value)
    return plan;
}

export const getPlanById = (planList, planId) => {
    let plan = {};

    plan = planList.find(item => item.id === planId)
    return plan;
}