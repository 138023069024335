import { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
//controllers
import {
  getPaymentMethods,
  payIban,
  deletePaymentMethods,
} from "../../controllers/paymentMethods";
// contexts
import { mainContext } from "../../../context/mainContext";
// import { subscription } from "../../../context/subscription";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  buttomOption,
  buttomOptionOnFocus,
  webContainer,
  textTransform,
  smallButton,
  sectionContainerWeb,
  paragraph,
  flexContainer,
} from "../../../styles/pages/generalStyles";
import { centerAlignedRow } from "../../../styles/pages/home/homePage";
import { signupStyles } from "../../../styles/pages/signup/signup";
//components
import { GeneralButton } from "../../widgets/generalButton";
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { LoadingPage } from "../../widgets/loadingPage";
import { PopUp } from "../../widgets/popUp";
//utils
import { capitalize } from "../../utils/utils";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useCartStore from "../shoppingCart/useCartStore";
import useUserData from "../../../hooks/useUserData";
import useOrderStore from "../../../hooks/useOrderStore";
import useModal from "../../../hooks/useModal";

const { width } = Dimensions.get("window");
export const IBAN_Accounts = ({ navigation, route, bottomTabNavigation }) => {
  const { access_token } = useUserData();
  const { IBANaccounts, setIBANAccount } = useContext(mainContext);
  const [selection, setSelection] = useState();
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { isComputer } = useIsWeb();
  const { cart, clearCart } = useCartStore();
  const { subscription, setInitialValues } = useOrderStore();
  const { openMessageModal } = useModal();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  useEffect(() => {
    handleGetPaymentMethods();
    return () => {};
  }, []);

  const handleGetPaymentMethods = async () => {
    setLoading(true);
    try {
      const methodsResponse = await getPaymentMethods(access_token);
      if (methodsResponse.length > 0) {
        setValues([...methodsResponse]);
        setIBANAccount([...methodsResponse]);
      }
    } catch (error) {
      openMessageModal(err?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePayIban = async () => {
    setButtonLoading(true);
    await payIban(access_token, {
      shopping_cart: cart.shopping_cart,
      iban_account: selection.iban_account,
      dni: selection.dni,
      phone_number: selection.phone_number,
      name: selection.name,
      last_name: selection.last_name,
      type_coint: selection.type_coint,
      terms: true,
      is_save: true,
      address: cart.address.id,
    })
      .then(async (res) => {
        await AsyncStorage.setItem("shoppingCart_copy", JSON.stringify(cart));
        await AsyncStorage.removeItem("nutritionalConsultations");
        await AsyncStorage.removeItem("shoppingCartId");
        await clearCart();

        navigation.reset({
          index: 0,
          routes: [{ name: "thanksForYourPurchase" }],
        });
      })
      .catch((err) => {
        openMessageModal(err?.data?.message);
      })
      .finally(() => setButtonLoading(false));
  };

  const handlePaySubscriptions = async () => {
    setButtonLoading(true);

    await payIban(access_token, {
      subscription: subscription.subscription_id,
      iban_account: selection.iban_account,
      dni: selection.dni,
      phone_number: selection.phone_number,
      name: selection.name,
      last_name: selection.last_name,
      type_coint: selection.type_coint,
      terms: true,
      is_save: true,
      address: subscription.address.id,
    })
      .then(async (res) => {
        setInitialValues();
        route.name === "IBAN_"
          ? navigation.navigate("subscriptionSummary", {
              id: subscription.subscription_id,
            })
          : navigation.navigate("subscription_summary", {
              id: subscription.subscription_id,
            });
      })
      .catch((err) => {
        openMessageModal(err?.data?.message);
      })
      .finally(() => setButtonLoading(false));
  };

  const handleDeletePaymentMethods = async (id) => {
    setButtonLoading(true);

    await deletePaymentMethods(access_token, id)
      .then(async (res) => {
        setButtonLoading(false);
        if (res.status == 200 || res.status == 204) {
          const newArray = IBANaccounts.filter((item) => item.id !== id);
          setValues(newArray);
          setIBANAccount(newArray);
        }
      })
      .catch((err) => {
        openMessageModal(err?.data?.message);
        setButtonLoading(false);
      });
  };

  return (
    <View style={styles.screen}>
      <ScrollView horizontal={false}>
        <View
          style={[
            styles.container,
            webContainer("calc(100vh - 220px)", 30),
            isComputer && sectionContainerWeb(800, null, "100%", "center"),
          ]}
        >
          {loading ? (
            <View
              style={[
                flexContainer(null, "center", "center"),
                { height: "100%" },
              ]}
            >
              <LoadingPage />
            </View>
          ) : (
            <>
              {route.name !== "IBAN_Accounts" ? (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate(
                      route.name == "IBAN_"
                        ? "create_online_payment"
                        : route?.name == "IBANAccounts"
                        ? "onlinePayment"
                        : route?.name == "IBAN"
                        ? "createOnlinePayment"
                        : "online_payment"
                    );
                  }}
                  style={[
                    buttomOption(
                      isComputer ? 30 : 52,
                      null,
                      isComputer ? 10 : 16,
                      null,
                      isComputer ? "50%" : "100%"
                    ),
                    { marginBottom: 20 },
                  ]}
                >
                  <View style={styles.rowContainer}>
                    <View style={{ flex: 1 }}>
                      <Text style={signupStyles.optionButtonText}>
                        Añadir cuenta
                      </Text>
                    </View>
                    <View>
                      <BaranaIcons
                        draw={{ width: 24, height: 24 }}
                        name={"plus"}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              ) : null}
              <View>
                {values.length === 0 ? (
                  <Text style={[paragraph("100%", 17, 5, "center")]}>
                    No tiene cuentas IBAN registradas
                  </Text>
                ) : null}
                {route.name === "IBAN_Accounts" ? (
                  <Text style={[paragraph("100%", 17, 5, "center")]}>
                    Las cuentas se agregan o editan desde el carrito de compras
                    o al momento del pago.
                  </Text>
                ) : null}
              </View>
              <View style={[styles.inputsContainer]}>
                {values.length > 0 ? (
                  <ScrollView
                    showsHorizontalScrollIndicator={false}
                    horizontal={true}
                    style={{ width: "100%" }}
                    contentContainerStyle={{ width: "100%" }}
                  >
                    <FlatList
                      bounces={false}
                      style={{ width: isComputer ? "100%" : width - 40 }}
                      data={values}
                      renderItem={({ item, index }) => {
                        return (
                          <TouchableOpacity
                            key={`${index}-iban`}
                            // disabled={
                            //   route?.name != "IBANAccounts" &&
                            //   route?.name != "IBAN" &&
                            //   route.name !== "IBAN_"
                            // }
                            onPress={() => {
                              setSelection(item);
                            }}
                            style={[
                              buttomOption(100, 10, 16),
                              selection?.id == item.id && buttomOptionOnFocus(),
                            ]}
                          >
                            <View
                              style={{
                                height: 85,
                                flexDirection: "column",
                                justifyContent: "space-between",
                                maxWidth: "88%",
                              }}
                            >
                              <Text style={styles.cardText}>
                                {capitalize(item.name)} {item.last_name}
                              </Text>
                              <Text
                                style={[
                                  styles.generalText,
                                  { fontSize: 18, fontWeight: "600" },
                                ]}
                              >
                                Cuenta:
                              </Text>
                              <Text
                                style={[
                                  styles.cardText,
                                  textTransform("uppercase"),
                                ]}
                              >
                                {item.iban_account}
                              </Text>
                            </View>
                            {route.name === "IBAN_Accounts" ? (
                              <View>
                                {/* <TouchableOpacity
                              style={[
                                smallButton(),
                                { paddingVertical: 2, paddingLeft: 20 },
                              ]}
                              onPress={() => {
                                navigation.navigate("online_payment", {
                                  edit: true,
                                  method: item,
                                  methodOfPayment: "Pago en Línea",
                                });
                              }}
                            >
                              <BaranaIcons
                                draw={{ width: 24, height: 24 }}
                                name={"pencil"}
                              />
                            </TouchableOpacity> */}
                                <TouchableOpacity
                                  style={[
                                    smallButton(),
                                    { paddingVertical: 8, paddingLeft: 20 },
                                  ]}
                                  onPress={() => {
                                    handleDeletePaymentMethods(item.id);
                                  }}
                                >
                                  <BaranaIcons
                                    draw={{ width: 24, height: 24 }}
                                    name={"delete"}
                                  />
                                </TouchableOpacity>
                              </View>
                            ) : null}
                          </TouchableOpacity>
                        );
                      }}
                    />
                  </ScrollView>
                ) : null}
              </View>
            </>
          )}
        </View>
      </ScrollView>
      <LowerOptionsContainer>
        <GeneralButton
          text="Confirmar"
          handleOnPress={() => {
            if (route?.name == "IBANAccounts") {
              handlePayIban();
            } else if (route?.name == "IBAN" || route?.name == "IBAN_") {
              handlePaySubscriptions();
            } else {
              navigation.goBack();
            }
          }}
          width={isComputer ? 350 : "100%"}
          loading={buttonLoading}
          disabled={
            buttonLoading ||
            ((route?.name == "IBANAccounts" || route?.name == "IBAN") &&
              !selection?.id)
          }
        />
      </LowerOptionsContainer>
    </View>
  );
};
