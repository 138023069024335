import { Svg, Path } from 'react-native-svg';

export const Addresses = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (
        <Svg width={width} height={height} viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M13.1689 20.1048L21.7481 13.3496L30.3368 20.1048V30.7209C30.3368 31.2284 30.1375 31.7152 29.7827 32.074C29.4279 32.4329 28.9467 32.6345 28.445 32.6345H15.0749C14.8245 32.6354 14.5764 32.5859 14.345 32.489C14.1136 32.3921 13.9036 32.2497 13.727 32.07C13.3709 31.7075 13.1704 31.2176 13.1689 30.7065V20.1048Z" fill="#DB7500" />
            <Path d="M18.707 32.6496V23H24.4344V32.6496" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
            <Path d="M22.5 44.5C34.3741 44.5 44 34.8741 44 23C44 11.1259 34.3741 1.5 22.5 1.5C10.6259 1.5 1 11.1259 1 23C1 34.8741 10.6259 44.5 22.5 44.5Z" stroke="#DB7500" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>

    )
}