import { Platform } from "react-native";
export const getMenuType = (type, listMenu) => {
  let filter = [];
  filter = listMenu.filter((item) => item.type_plate == type);
  return filter;
};

export const handledListMenuPerDay = async (list, perDay) => {
  let menu = [];
  if (!perDay) {
    menu = await list.filter((item, index) => {
      if (item.day_week == "ninguno") {
        return item;
      }
    });
  } else {
    menu = await list.filter((item, index) => item.day_week !== "ninguno");
  }
  return menu.sort((a, b) => days[a.day_week] > days[b.day_week]);
};

export const swap = (array, index1, index2) => {
  let aux = array[index1];
  array[index1] = array[index2];
  array[index2] = aux;

  return array;
};

// breakfasts lunches dinners
export const sortMealTimesArray = (array) => {
  let sortedArray = [...array];
  array.map((item, i) => {
    if (item?.name?.toLowerCase().includes("desayunos") && i != 0) {
      sortedArray = swap(array, 0, i);
    }
    if (item?.name?.toLowerCase().includes("almuerzos") && i != 1) {
      sortedArray = swap(array, 1, i);
    }
    if (item?.name?.toLowerCase().includes("cenas") && i != 2) {
      sortedArray = swap(array, 2, i);
    }
  });

  return sortedArray;
};

export const capitalize = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
};

export const lowerCaseFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const orderByDayOfWeek = (array) => {
  const diasSemana = [
    "lunes",
    "martes",
    "miercoles",
    "jueves",
    "viernes",
    "sabado",
    "domingo",
  ];

  // Función auxiliar para obtener el índice del día de la semana en el arreglo
  function obtenerIndiceDiaSemana(dia) {
    return diasSemana.indexOf(dia);
  }

  // Función de comparación para ordenar los objetos del arreglo
  function compararPorDiaSemana(a, b) {
    const diaA = a[0].toLowerCase();
    const diaB = b[0].toLowerCase();
    const indiceA = obtenerIndiceDiaSemana(diaA);
    const indiceB = obtenerIndiceDiaSemana(diaB);

    return indiceA - indiceB;
  }

  // Ordenar el array utilizando la función de comparación
  const resultado = array.sort(compararPorDiaSemana);

  return resultado;
};

export const groupBy = (array, key) => {
  let result = {};
  for (let item of array) {
    // If the key is a function then we compute its value, otherwise we use it directly
    let keyValue = typeof key === "function" ? key(item) : item[key];
    // If an array already present for key, push it to the array. Else create an array and push the object
    if (!result[keyValue]) {
      result[keyValue] = [];
    }
    result[keyValue].push(item);
  }
  // Return the current resultant object
  return result;
};

export const getNonEmptyKeys = (obj = {}) => {
  let nonEmptyKeys = [];

  for (let [key, value] of Object.entries(obj)) {
    if (typeof value === "object" && Object.keys(value).length > 0) {
      nonEmptyKeys.push(key);
    }
  }

  return nonEmptyKeys;
};

export const addTrailingSlashOnIos = (path) => {
  return Platform.OS === "ios" ? `${path}/` : path;
};
