import { View, Text, TouchableOpacity } from "react-native";
//color
import { useTheme } from "react-native-paper";
//icons
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//styles
import {
  styles,
  textTransform,
  flexContainer,
} from "../../../styles/pages/generalStyles";
import {
  homeStyles,
  centerAlignedRow,
} from "../../../styles/pages/home/homePage";

export const TimeFoodComponent = ({
  item,
  navigation,
  custom,
  addTimeFood,
  deleteTimeFood,
  uniqueKey,
  planId,
}) => {
  const { colors } = useTheme();
  return (
    <View key={uniqueKey}>
      <View style={[styles.line, { marginVertical: 22 }]}></View>
      <View style={[centerAlignedRow(null, "space-between")]}>
        <View style={[centerAlignedRow()]}>
          <BaranaIcons
            draw={{ width: 24, height: 24, color: !custom && "black" }}
            name={"clock"}
          />
          <Text
            style={[
              homeStyles.strongText,
              textTransform("capitalize"),
              { marginHorizontal: 25 },
            ]}
          >
            {item?.time_food}
          </Text>
        </View>
        <View
          style={[
            flexContainer("row", "space-between", "center", null, null, 15),
            { width: 60 },
          ]}
        >
          {custom ? (
            <>
              {item.selected ? (
                <TouchableOpacity onPress={() => deleteTimeFood(item)}>
                  <BaranaIcons
                    draw={{ width: 27, height: 27 }}
                    name={"delete"}
                  />
                </TouchableOpacity>
              ) : null}

              <TouchableOpacity
                onPress={() => addTimeFood(item, item.selected)}
              >
                <BaranaIcons
                  draw={{ width: 24, height: 24 }}
                  name={item.selected ? "pencil" : "plus"}
                />
              </TouchableOpacity>
            </>
          ) : (
            <MaterialCommunityIcons
              name={"eye-outline"}
              color={colors.secondary}
              size={25}
              onPress={() =>
                navigation.navigate("editPlan", {
                  to: item.time_food,
                  id: item?.id,
                  planId,
                })
              }
            />
          )}
        </View>
      </View>
    </View>
  );
};
