import { Svg, Path } from 'react-native-svg';

export const AutomaticPayment = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return (


        <Svg width={width} height={height} viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Path d="M22.5 44.5C34.3741 44.5 44 34.8741 44 23C44 11.1259 34.3741 1.5 22.5 1.5C10.6259 1.5 1 11.1259 1 23C1 34.8741 10.6259 44.5 22.5 44.5Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M22.7647 13.2109L19.6055 32.7016" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M28.3167 18.1152H20.7497C20.1949 18.1262 19.6493 18.2605 19.1516 18.5085C18.6538 18.7565 18.2161 19.1122 17.8694 19.5505C17.0976 20.538 16.6777 21.7601 16.6777 23.019C16.6777 24.2779 17.0976 25.5 17.8694 26.4875C18.2161 26.9257 18.6538 27.2815 19.1516 27.5295C19.6493 27.7775 20.1949 27.9118 20.7497 27.9228H28.3167" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <Path d="M26.085 13.3066L22.9258 32.8021" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </Svg>

    )
}