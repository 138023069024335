import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useOrderStore = create(
  devtools((set, get) => ({
    packages: [],
    subpackage: {},
    packageType: null,
    timeFood: {
      desayunos: false,
      almuerzos: false,
      cenas: false,
      ninguno: false,
      familiar: false,
    },
    subscription: null,
    timesFoodId: [],
    plan: null,
    userSubscriptions: [],
    setPackages: (newPackages) => {
      set({ packages: newPackages });
    },
    removePackages: () => {
      set({ packages: [] });
    },
    setSubpackage: (selectedPackage) => {
      set({ subpackage: selectedPackage });
    },
    removeSubpackage: () => {
      set({ subpackage: {} });
    },
    setPackageType: (type) => {
      set({ packageType: type });
    },
    removePackageType: () => {
      set({ packageType: null });
    },
    setTimeFood: (time, value) => {
      set((state) => ({
        timeFood: {
          ...state.timeFood,
          [time]: value,
        },
      }));
    },
    setTimesFood: (times) => {
      set((state) => ({
        timeFood: {
          ...state.timeFood,
          ...times,
        },
      }));
    },
    setTimesFoodId: (timesId) => {
      set((state) => ({
        timesFoodId: timesId,
      }));
    },
    setSubscription: (newSubscription) => {
      set({ subscription: newSubscription });
    },
    setPlan: (newPlan) => {
      set({ plan: newPlan });
    },
    setUserSubscriptions: (subscriptions) => {
      set({ userSubscriptions: subscriptions });
    },
    addUserSubscription: (subscription) => {
      set((state) => ({
        userSubscriptions: [...state.userSubscriptions, subscription],
      }));
    },
    removeUserSubscription: (subscriptionId) => {
      const subscriptions = get().userSubscriptions.filter(
        (subscription) => subscription.subscription_id !== subscriptionId
      );
      set({ userSubscriptions: subscriptions });
    },
    updateUserSubscriptions: (subscriptionUpdated) => {
      const userSubscriptionsAux = get().userSubscriptions;
      const subscriptionId = userSubscriptionsAux.findIndex(
        (subs) => subs.subscription_id === subscriptionUpdated.subscription_id
      );

      if (subscriptionId !== -1) {
        userSubscriptionsAux[subscriptionId] = subscriptionUpdated;
      }

      set({ userSubscriptions: userSubscriptionsAux });
    },
    setInitialValues: () => {
      set({
        packages: [],
        subpackage: {},
        packageType: null,
        timeFood: {
          desayunos: false,
          almuerzos: false,
          cenas: false,
          ninguno: false,
          familiar: false,
        },
        subscription: null,
        plan: null,
      });
    },
  }))
);

export default useOrderStore;
