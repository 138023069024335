import { useContext, useState, useEffect } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  title,
  paragraph,
  webContainer,
  smallButton,
  buttomOption,
  flexContainer,
} from "../../../styles/pages/generalStyles";
import {
  homeStyles,
  centerAlignedRow,
} from "../../../styles/pages/home/homePage";
//components
import { LowerOptionsContainer } from "../../widgets/lowerOptionsContainer";
import { GeneralButton } from "../../widgets/generalButton";
import { InputOverall } from "../../widgets/inputOverall";
import { ListPackageComponent } from "./components/listPackageComponent";
import { LoadingPage } from "../../widgets/loadingPage";
import { PopUp } from "../../widgets/popUp";
//context
import { mainContext } from "../../../context/mainContext";
import { orderContext } from "../../../context/orderingContext";
//controllers
import {
  updateSubscriptions,
  getSubscriptions,
  deleteSuscription,
} from "../../controllers/subscriptions";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useUserData from "../../../hooks/useUserData";
import useOrderStore from "../../../hooks/useOrderStore";
import { getPackageArmed } from "../../controllers/package";
import useModal from "../../../hooks/useModal";

const packageWithoutMealTime = {
  "porciones familiares": "familiar",
  "adulto mayor": "ninguno",
  ninguno: "ninguno",
};

export const Details = ({ route, navigation, createSubscriptionFromHome }) => {
  const subscriptionId = route.params?.subscription_id;
  const fromPackageType = route.params?.fromPackageType;

  const { access_token } = useUserData();
  const { userAaddress } = useContext(mainContext);
  const {
    setEditSubscription,
    setCreateNewSubscription,
    setCreateSubscriptionFromHome,
  } = useContext(orderContext);
  const [editNote, setEditNote] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [packagesNotAvailable, setPackagesNotAvailable] = useState(false);
  const { isComputer } = useIsWeb();
  const {
    subscription: subscriptionStore,
    setSubscription,
    setSubpackage,
    setTimesFood,
    timesFoodId,
    subpackage,
    userSubscriptions,
    removeUserSubscription,
    packageType,
    setPackages,
    setTimeFood,
    setTimesFoodId,
  } = useOrderStore();
  const { openMessageModal } = useModal();

  useEffect(() => {
    if (subscriptionId) {
      setEditSubscription(true);
      setCreateNewSubscription(false);
      setCreateSubscriptionFromHome(false);
      handleGetSubscriptions(subscriptionId);
    }
  }, []);

  const handleGetSubscriptions = async (id) => {
    setLoading(true);
    try {
      const subscriptionResponse = await getSubscriptions(access_token, id);

      if (subscriptionResponse?.subscription_id) {
        const subscriptionArmedPackage =
          subscriptionResponse?.armed_packages ?? {};
        const subscriptionSubpackage =
          subscriptionArmedPackage?.sub_packages[0] ?? {};

        setSubscription(subscriptionResponse);
        setSubpackage({
          ...subscriptionSubpackage,
          package_id: subscriptionSubpackage?.sub_package_id,
          armed_package: subscriptionArmedPackage?.armed_package,
        });

        setTimesFood({
          desayunos: subscriptionSubpackage?.food_time?.some(
            (time) => time.toLowerCase() === "desayunos"
          ),
          almuerzos: subscriptionSubpackage?.food_time?.some(
            (time) => time.toLowerCase() === "almuerzos"
          ),
          cenas: subscriptionSubpackage?.food_time?.some(
            (time) => time.toLowerCase() === "cenas"
          ),
          familiar: subscriptionSubpackage?.food_time?.some(
            (time) => time.toLowerCase() === "familiar"
          ),
          ninguno: subscriptionSubpackage?.food_time?.some(
            (time) => time.toLowerCase() === "ninguno"
          ),
        });
      }
    } catch (error) {
      openMessageModal(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSubscriptions = async () => {
    setLoadingButton(true);

    try {
      const subscriptionUpdated = await updateSubscriptions(
        access_token,
        subscriptionStore.subscription_id,
        {
          delivery_day: subscriptionStore.address?.day_delivery,
          hours_range: subscriptionStore.address?.hours_range,
          note: subscriptionStore.note,
          address: subscriptionStore.address?.id,
        }
      );
      if (subscriptionUpdated.updated) {
        setEditSubscription(false);

        if (subscriptionUpdated?.subscription?.is_payment) {
          navigation.goBack();
        } else {
          if (!subscriptionStore.address?.id) {
            if (route?.name === "suscription_details") {
              navigation.navigate("create_subscriptionAddress");
            } else {
              navigation.navigate("createSubscriptionAddress");
            }
          } else if (!subscriptionStore?.payment_method?.id) {
            if (route?.name === "suscription_details") {
              navigation.navigate("IBAN_");
            } else {
              navigation.navigate("IBAN");
            }
          } else {
            navigation.goBack();
          }
        }
      }
    } catch (error) {
      openMessageModal(error.message);
    } finally {
      setLoadingButton(false);
    }
  };

  const handleDeleteSubscriptions = async (id, showModal = true) => {
    setLoading(true);
    setLoadingButton(true);
    if (id) {
      try {
        const res = await deleteSuscription(access_token, id);
        if (
          res?.message == "Suscripcion Eliminada" ||
          res?.message == "Suscripcion Cancelada"
        ) {
          removeUserSubscription(id);

          setOpenModal(false);
          setOpenConfirmModal(showModal);
        }
      } catch (error) {
        openMessageModal(error.message);
      } finally {
        setLoadingButton(false);
        setLoading(false);
      }
    }
  };

  const handleGetPackageArmed = async () => {
    const foodTimesList = JSON.parse(await AsyncStorage.getItem("food_times"));

    const timeFood = foodTimesList.find(
      (item) =>
        item.name.toLowerCase() ===
        (packageWithoutMealTime[packageType?.name?.toLowerCase()] || "ninguno")
    );

    setLoading(true);
    setLoadingButton(true);
    try {
      const packageResponse = await getPackageArmed(
        {
          barana_package: packageType?.id,
          barana_time_food: [timeFood?.id],
          type_assembled: "barana",
        },
        access_token
      );

      if (packageResponse?.status && packageResponse?.status === 400) {
        setPackagesNotAvailable(true);
      } else {
        if (packageResponse.map((p) => p?.packages || []).flat().length > 0) {
          setPackages(packageResponse);
          setTimeFood(timeFood.name.toLowerCase(), true);
          setTimesFoodId([timeFood?.id]);

          navigation.navigate("numberOfMeals");
        } else {
          setPackagesNotAvailable(true);
        }
      }
    } catch (error) {
      openMessageModal(error.message);
    } finally {
      setLoading(false);
      setLoadingButton(false);
    }
  };
  return (
    <View style={styles.screen}>
      {loading ? (
        <LoadingPage />
      ) : (
        <ScrollView>
          <View
            style={[
              styles.container,
              webContainer("calc(100vh - 160px)"),
              isComputer && styles.sectionContainerWeb,
            ]}
          >
            <Text style={[title("100%", "center")]}>
              Detalles de la Suscripción
            </Text>
            {subscriptionStore?.address?.id ? (
              <>
                <View style={[centerAlignedRow(), { marginTop: 25 }]}>
                  <BaranaIcons draw={{ width: 24, height: 24 }} name="cube" />
                  <Text style={[homeStyles.strongText, { marginLeft: 10 }]}>
                    Entrega: {subscriptionStore?.day_and_hours_range}
                  </Text>
                </View>
                <Text style={[paragraph("95%", 17, 10)]}>
                  {
                    "( Si desea modificar el día de entrega por favor edítelo en la sección de ¨dirección¨. )"
                  }
                </Text>

                <View style={[styles.line, { marginVertical: 22 }]}></View>

                <View style={[centerAlignedRow(null, "space-between")]}>
                  <View style={[centerAlignedRow(), { flex: 1 }]}>
                    <BaranaIcons
                      draw={{ width: 24, height: 24 }}
                      name="location"
                    />
                    <Text style={[paragraph("90%", 17), { marginLeft: 10 }]}>
                      {subscriptionStore?.address.province
                        ? `${subscriptionStore?.address.province}, `
                        : ""}
                      {subscriptionStore?.address.zone
                        ? `${subscriptionStore?.address.zone}, `
                        : ""}
                      {subscriptionStore?.address.location
                        ? `${subscriptionStore?.address.location}`
                        : ""}
                      {subscriptionStore?.address.text
                        ? `,  ${subscriptionStore?.address.text}. `
                        : ""}
                    </Text>
                  </View>

                  <TouchableOpacity
                    style={smallButton()}
                    onPress={() => {
                      navigation.navigate("editSubscriptionAddress", {
                        address: subscriptionStore?.address.id,
                      });
                    }}
                  >
                    <BaranaIcons
                      draw={{ width: 24, height: 24 }}
                      name={"pencil"}
                    />
                  </TouchableOpacity>
                </View>
              </>
            ) : userAaddress[0]?.province ? (
              <>
                <View style={[centerAlignedRow(), { marginTop: 25 }]}>
                  <BaranaIcons draw={{ width: 24, height: 24 }} name="cube" />
                  <Text style={[homeStyles.strongText, { marginLeft: 10 }]}>
                    Entrega: {userAaddress[0].delivery_day}
                  </Text>
                </View>
                <Text style={[paragraph("95%", 17, 10)]}>
                  {`( Si desea modificar el día de entrega por favor edítelo en la sección de ¨dirección¨. )`}
                </Text>

                <View style={[styles.line, { marginVertical: 22 }]}></View>
                <View style={[centerAlignedRow(null, "space-between")]}>
                  <BaranaIcons
                    draw={{ width: 24, height: 24 }}
                    name="location"
                  />
                  <Text style={[paragraph("75%", 17)]}>
                    {userAaddress[0].province
                      ? `${userAaddress[0].province}, `
                      : ""}
                    {userAaddress[0].zone ? `${userAaddress[0].zone}, ` : ""}
                    {userAaddress[0].location
                      ? `${userAaddress[0].location} `
                      : ""}
                    {userAaddress[0].text ? `,  ${userAaddress[0].text}. ` : ""}
                  </Text>
                  <TouchableOpacity
                    style={smallButton()}
                    onPress={() =>
                      navigation.navigate("editSubscriptionAddress", {
                        address: userAaddress[0],
                      })
                    }
                  >
                    <BaranaIcons
                      draw={{ width: 24, height: 24 }}
                      name={"pencil"}
                    />
                  </TouchableOpacity>
                </View>
              </>
            ) : null}

            <View style={[styles.line, { marginVertical: 22 }]}></View>
            <View style={[centerAlignedRow(null, "space-between")]}>
              <View style={[centerAlignedRow()]}>
                <BaranaIcons draw={{ width: 24, height: 24 }} name="cube" />
                <Text
                  style={[
                    homeStyles.strongText,
                    { marginLeft: 10, textAlign: "left" },
                  ]}
                >
                  Paquete:{" "}
                  <Text style={[styles.helpText]}>
                    {subscriptionStore?.armed_packages?.type_package}.
                  </Text>
                </Text>
              </View>
              {/* Editar paquete             */}
              {/* <TouchableOpacity
                onPress={async () => navigation.navigate("orderDefinition")}
                style={{}}
              >
                <BaranaIcons draw={{ width: 24, height: 24 }} name={"pencil"} />
              </TouchableOpacity> */}
            </View>

            {/* <------------------------- packages --------------------> */}
            {subpackage?.desayunos?.plates?.length > 0 ? (
              <ListPackageComponent
                title="Desayunos"
                subPackages={subpackage.desayunos}
                armedPackage={subpackage}
                armedPackageId={subpackage.armed_package}
                edit={true}
                goTo={() =>
                  navigation.navigate("editPackagePlan", {
                    to: "desayunos",
                    planId: subpackage.desayunos.plan?.id,
                  })
                }
                timeFood="desayunos"
              />
            ) : null}
            {subpackage?.almuerzos?.plates?.length > 0 ? (
              <ListPackageComponent
                title="Almuerzos"
                subPackages={subpackage.almuerzos}
                armedPackage={subpackage}
                armedPackageId={subpackage.armed_package}
                edit={true}
                goTo={() =>
                  navigation.navigate("editPackagePlan", {
                    to: "almuerzos",
                    planId: subpackage.almuerzos.plan?.id,
                  })
                }
                timeFood="almuerzos"
              />
            ) : null}
            {subpackage?.cenas?.plates?.length > 0 ? (
              <ListPackageComponent
                title="Cenas"
                subPackages={subpackage.cenas}
                armedPackage={subpackage}
                armedPackageId={subpackage.armed_package}
                edit={true}
                goTo={() =>
                  navigation.navigate("editPackagePlan", {
                    to: "cenas",
                    planId: subpackage.cenas.plan?.id,
                  })
                }
                timeFood="cenas"
              />
            ) : null}
            {subpackage?.generic?.plates?.length > 0 ? (
              <ListPackageComponent
                title="Menú"
                armedPackage={subpackage}
                armedPackageId={subpackage.armed_package}
                subPackages={subpackage.generic}
                edit={true}
                timeFood="generic"
              />
            ) : null}

            <View style={[styles.line, { marginVertical: 22 }]}></View>
            <View style={[centerAlignedRow(0, "space-between")]}>
              {!editNote ? (
                <View style={[centerAlignedRow()]}>
                  <BaranaIcons draw={{ width: 27, height: 27 }} name={"note"} />
                  <Text style={[paragraph("78%"), { marginLeft: 10 }]}>
                    <Text style={[homeStyles.strongText]}>Notas:</Text>{" "}
                    {subscriptionStore?.note}
                  </Text>
                </View>
              ) : (
                <InputOverall
                  label="Nota"
                  value={subscriptionStore?.note}
                  placeholder="Nota"
                  marginT={"0%"}
                  onChangeText={(value) => {
                    setSubscription({
                      ...subscriptionStore,
                      note: value,
                    });
                  }}
                  type={"outlined"}
                  onBlur={() => setEditNote(false)}
                />
              )}
              {!editNote && (
                <TouchableOpacity
                  style={smallButton()}
                  onPress={() => setEditNote(true)}
                >
                  <BaranaIcons
                    draw={{ width: 24, height: 24 }}
                    name={"pencil"}
                  />
                </TouchableOpacity>
              )}
            </View>
            <View style={[styles.line, { marginVertical: 20 }]}></View>
            <View style={[centerAlignedRow(10, "space-between")]}>
              <Text style={[homeStyles.strongText]}>Total Semanal</Text>
              <Text style={[homeStyles.strongText]}>
                ₡ {subscriptionStore?.price || "0"}
              </Text>
            </View>

            <View
              style={[
                flexContainer(
                  "column",
                  "center",
                  "center",
                  null,
                  null,
                  null,
                  15
                ),
                { padding: 30 },
              ]}
            >
              {fromPackageType ? (
                <>
                  <GeneralButton
                    text={`Suscribirse por: ₡ ${
                      subscriptionStore?.price || 0
                    } - Semanales`}
                    handleOnPress={() => {
                      if (subscriptionStore?.subscription_id)
                        handleUpdateSubscriptions();
                    }}
                    disabled={loadingButton}
                    loading={loadingButton}
                    width={isComputer ? 350 : "100%"}
                  />
                  <GeneralButton
                    text="Crear nueva suscripción"
                    handleOnPress={async () => {
                      await handleDeleteSubscriptions(
                        subscriptionStore?.subscription_id,
                        false
                      ).then(() => {
                        setEditSubscription(false);
                        setCreateNewSubscription(true);
                        setSubscription(null);
                        if (packageType?.is_time_food) {
                          navigation.navigate("mealTimes");
                        } else {
                          handleGetPackageArmed();
                        }
                      });
                    }}
                    disabled={loadingButton}
                    loading={loadingButton}
                    width={isComputer ? 350 : "100%"}
                  />
                </>
              ) : null}
              <TouchableOpacity
                onPress={() => setOpenModal(true)}
                style={[buttomOption(60, 0, 16, "#FB3A2E", isComputer && 300)]}
              >
                <Text style={[styles.number]}>
                  {fromPackageType
                    ? "No deseo suscribirme"
                    : "Cancelar suscripción"}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          {openModal ? (
            <PopUp
              openModal={setOpenModal}
              title={"Cancelar suscripción"}
              text={"¿Está seguro que desea cancelar su suscripción?"}
              button={true}
              buttonText="Sí, estoy seguro"
              cancelButton={true}
              handleOnPress={() =>
                handleDeleteSubscriptions(subscriptionStore?.subscription_id)
              }
              handleCancelOnPress={() => setOpenModal(false)}
            />
          ) : null}
          {openConfirmModal ? (
            <PopUp
              openModal={setOpenConfirmModal}
              text={"Su subscripcion ha sido cancelada."}
              button={true}
              buttonText="Continuar"
              handleOnPress={() => {
                if (createSubscriptionFromHome) {
                  navigation.reset({
                    index: 0,
                    routes: [{ name: "homePage" }],
                  });
                } else {
                  navigation.navigate("subscriptions");
                }
              }}
            />
          ) : null}
          {packagesNotAvailable ? (
            <PopUp
              showModal={packagesNotAvailable}
              openModal={setPackagesNotAvailable}
              title="Error"
              text="Paquete armado no disponible"
              buttonText={"Ok"}
              button={true}
              handleOnPress={() => {
                if (createSubscriptionFromHome) {
                  navigation.reset({
                    index: 0,
                    routes: [{ name: "homePage" }],
                  });
                } else {
                  navigation.navigate("subscriptions");
                }
              }}
            />
          ) : null}
        </ScrollView>
      )}
      {!fromPackageType ? (
        <LowerOptionsContainer>
          <GeneralButton
            text={
              subscriptionStore?.is_payment
                ? "Continuar"
                : `Total Semanal: ₡ ${
                    subscriptionStore?.price || 0
                  } - Continuar`
            }
            handleOnPress={() => {
              if (subscriptionStore?.subscription_id)
                handleUpdateSubscriptions();
            }}
            disabled={loadingButton}
            loading={loadingButton}
            width={isComputer ? 350 : "100%"}
          />
        </LowerOptionsContainer>
      ) : null}
    </View>
  );
};
