import { View, Text, Modal, TouchableOpacity } from "react-native";
//styles
import { spaceBetween, textAlignment } from "../../styles/pages/generalStyles";
import { modalStyles, options } from "../../styles/pages/widgets/modalMenu";
import { homeStyles, centerAlignedRow } from "../../styles/pages/home/homePage";
//components
import { GeneralButton } from "./generalButton";
//utils
import useModal from "../../hooks/useModal";

export const ModalMessage = () => {
  const { isOpen, message, closeModal } = useModal();
  return isOpen ? (
    <Modal
      animationType="fade"
      transparent={true}
      visible={true}
      onRequestClose={closeModal}
    >
      <TouchableOpacity
        style={modalStyles.container_options}
        onPress={closeModal}
      >
        <View style={options()}>
          <View>
            <Text style={[homeStyles.helperText, textAlignment("left", 6)]}>
              {message}
            </Text>
          </View>
          <View style={[centerAlignedRow(null, "flex-end"), { marginTop: 12 }]}>
            <GeneralButton
              text={"Entendido"}
              handleOnPress={closeModal}
              width={"45%"}
            />
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  ) : null;
};
