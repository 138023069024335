import { create } from "zustand";
import { devtools } from "zustand/middleware";

const useDeliveryAdressState = create(
  devtools((set, get) => ({
    cords: {
      lat: null,
      lng: null,
    },
    zoom: 10,
    setZoom: (zoom) => {
      set({
        zoom
      })
    },
    setCords: (cords) => {
      set({
        cords,
      });
    },
  }))
);

export default useDeliveryAdressState;
