import React from "react";
import { View, Text, ScrollView, Image } from "react-native";
//Styles
import {
  styles,
  paragraph,
  title,
  sectionContainerWeb,
} from "../../../../styles/pages/generalStyles";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";

export const AboutUs = ({ navigation, route }) => {
  const { isComputer } = useIsWeb();

  return (
    <View style={[styles.screen, { alignItems: "center" }]}>
      <ScrollView
        style={[styles.scrollScreen, { paddingHorizontal: 0, width: "100%" }]}
      >
        <View style={[sectionContainerWeb(), styles.homeContainer]}>
          <View
            style={{
              width: isComputer ? "80%" : "100%",
              height: isComputer ? 400 : 280,
              borderRadius: 18,
              overflow: "hidden",
              alignSelf: "center",
              marginBottom: 40,
            }}
          >
            <Image
              source={require("../../../../assets/illustrations/Barana-Costa-Rica.jpg")}
              style={{ width: "100%", height: "100%" }}
            />
          </View>
          <Text style={paragraph("100%", 17, 10)}>
            ¡Hola! Somos Laura y Paola, dos hermanas que trabajamos juntas en
            este emprendimiento que llamamos Baraná.
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            De niñas tuvimos la oportunidad de aprender buenos hábitos de
            alimentación gracias a la insistencia y trucos de nuestros padres.
            Como resultado, una de nosotras se volvió nutricionista y hoy
            disfrutamos de la comida sin preocupaciones de peso o de salud.
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            La salud de nuestro cuerpo depende de qué tan buenas o malas sean
            las decisiones alimentarias que tomamos todos los días. Por eso
            queremos que más personas tengan una buena relación con la comida y
            transmitir el poder y placer de mantener una alimentación
            balanceada.
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Queremos contribuir a enfrentar los nuevos retos asociados a la
            automatización de actividades, el sedentarismo, las enfermedades
            crónicas no transmisibles y el aumento del número de personas
            mayores en el país.
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Queremos personas satisfechas con las decisiones que impactan su
            salud; para ello buscamos transmitir el poder y placer de mantener
            estilos de vida saludables por medio de:
          </Text>

          <ul>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                <Text style={{ fontWeight: "bold" }}>
                  Sesiones educativas:{" "}
                </Text>
                el conocimiento adquirido y los hábitos diarios son las bases
                para un estilo de vida saludable.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                <Text style={{ fontWeight: "bold" }}>
                  Asesorías en nutrición:
                </Text>{" "}
                le brindamos acompañamiento para que tome decisiones informadas
                sobre su alimentación.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                <Text style={{ fontWeight: "bold" }}>
                  Servicio de comida preparada, saludable de verdad:
                </Text>{" "}
                Somos un emprendimiento que le permitirá comer bien sin la
                necesidad de invertir tiempo en la cocina. Trabajamos bajo la
                guía de una nutricionista que cuida cada detalle con el
                propósito de brindarle platillos ricos, pero sanos.
              </Text>
            </li>
          </ul>

          <Text style={[title()]}>Valores</Text>
          <ul>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Calidad: nuestros platillos se preparan cuidadosamente con
                ingredientes frescos, nutritivos y de alta calidad.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Responsabilidad: todo lo que hacemos tiene el propósito de
                mejorar su alimentación.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Pasión: estamos comprometidos a elaborar platillos sanos y
                deliciosos.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Mejora continua para satisfacer las necesidades y exceder las
                expectativas de nuestros clientes.
              </Text>
            </li>
          </ul>
        </View>
      </ScrollView>
    </View>
  );
};
