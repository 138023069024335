import { useEffect } from "react";
import { NutritionalPlanList } from "../../plans/nutritionalPlanList";
//controllers
import { getPlansUser } from "../../../controllers/plans";
//hooks
import useUserData from "../../../../hooks/useUserData";
import useNutritionalPrescription from "../../../../hooks/useNutritionalPrescription";
import useModal from "../../../../hooks/useModal";

export const NutritionalPlans = ({
  navigation,
  bottomTabNavigation,
  route,
}) => {
  const { access_token } = useUserData();
  const {
    setNutritionalPlan,
    setIsCustom,
    setNutritionalPrescriptionInitialValue,
  } = useNutritionalPrescription();
  const { openMessageModal } = useModal();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  const handleGetPlans = async () => {
    try {
      const plans = await getPlansUser(access_token);
      return plans;
    } catch (error) {
      openMessageModal(error.message);
    }
  };

  return (
    <NutritionalPlanList
      handleGetNutritionalPlans={handleGetPlans}
      handleAddNutritionalPlan={() => {
        setNutritionalPrescriptionInitialValue();
        setIsCustom(true);
        navigation.navigate("nutritionalPrescription");
      }}
      handleConfirmButton={() => navigation.goBack()}
      handleEditNutritionalPlan={(plan) => {
        setNutritionalPlan(plan);
        setIsCustom(plan.type_plan === "personalizado");
        navigation.navigate("nutritionalPrescription", { id: plan.id });
      }}
      cancelButton={false}
    />
  );
};
