import { useEffect, useContext, useState } from "react";
import {
  View,
  Text,
  FlatList,
  Dimensions,
  ActivityIndicator,
  Image,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
//utils
import { getMenuType } from "../../utils/utils";
import { getPlatesFromCurrentOrder } from "../../utils/shoppingCart";
import { getItemType, getItemQuantity } from "../../utils/shoppingCartUtils";
//controllers
import { getListMenu, getCombos } from "../../controllers/menus";
// contexts
import { mainContext } from "../../../context/mainContext";
import { shoppingCart } from "../../../context/shoppingCart";
import { orderContext } from "../../../context/orderingContext";
//styles
import {
  styles,
  paragraph,
  sectionContainerWeb,
  flexContainer,
  webTitle,
} from "../../../styles/pages/generalStyles";
import { sections } from "../../../styles/pages/home/homePage";
//components
import { GeneralButton } from "../../widgets/generalButton";
import { SmallOrangeCards } from "../../widgets/smallOrangeCards";
import { DishCard } from "../../widgets/dishCard";
import { OrangeCard } from "../../widgets/orangeCrad";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useCartStore from "../shoppingCart/useCartStore";
import useDebounce from "../../../hooks/useDebounce";
import useUserData from "../../../hooks/useUserData";

const { width } = Dimensions.get("window");
export const MiniSupermarket = ({ background, navigation }) => {
  const { selectedOption, setSelectedOption } = useContext(mainContext);
  const { setTotal, setSelections, combos, setCombos, plates, setPlates } =
    useContext(shoppingCart);
  const [loding, setLoading] = useState(false);
  const [updatingCart, setUpdatingCart] = useState(false);
  const [ref, setRef] = useState(null);
  const [listMenu, setLsistMenu] = useState([]);
  const [meatsList, setMeatsList] = useState([]);
  const [vegetablesList, setVegetablesList] = useState([]);
  const [getDate, setGetData] = useState(false);
  const [getCombos_, setGetCombos] = useState(false);
  const { isLoggedIn, access_token } = useUserData();
  const { isComputer } = useIsWeb();
  const {
    addToCart,
    removeFromCart,
    getProductById,
    createOrUpdateCart,
    cart,
  } = useCartStore();
  const { debounceFunction: createOrUpdateCartDebounce } = useDebounce(
    (...args) => {
      setUpdatingCart(true);
      createOrUpdateCart(...args).finally(() => setUpdatingCart(false));
    }
  );

  useEffect(() => {
    let abortController = new AbortController();
    if (combos.length == 0) handleGetCombos();
    if (plates.length == 0) handleGetMenus();
    else {
      handleGetPlatesFromCurrentOrder(plates);
      handleGetCombosFromCurrentOrder(combos);
      filterMenu(plates);

      const array = combos.concat(plates, []);
      setLsistMenu([...array]);
    }

    return () => {
      setLoading(false);
      setLsistMenu([]);
      setMeatsList([]);
      setVegetablesList([]);
      setRef(null);
      setSelectedOption("Ver Todo");
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const array = combos.concat(plates, []);
    setLsistMenu([...array]);
  }, [plates, combos, cart.plates_supermaker, cart.combos]);

  useEffect(() => {
    filterMenu(plates);
  }, [plates]);

  const handleGetMenus = () => {
    setLoading(true);
    getListMenu()
      .then(async (res) => {
        if (res.length > 0) {
          setPlates([...res]);
          filterMenu(res);
          await AsyncStorage.setItem("plates_supermaker", JSON.stringify(res));
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const filterMenu = (list) => {
    setMeatsList([...getMenuType("carne", list)]);
    setVegetablesList([...getMenuType("vegetal", list)]);
  };

  const handleGetCombos = () => {
    setLoading(true);
    getCombos()
      .then(async (res) => {
        if (res.length > 0) {
          setCombos(res);
          await AsyncStorage.setItem("combos", JSON.stringify(res));
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleAddToCart = (type, item) => {
    addToCart(type, item);
    createOrUpdateCartDebounce(access_token, cart);
  };

  const handleDeleteToCart = (type, itemId) => {
    removeFromCart(type, itemId);
    createOrUpdateCartDebounce(access_token, cart);
  };

  const handleGetPlatesFromCurrentOrder = (platesSupermaker) => {
    if (cart.plates_supermaker?.length > 0) {
      setPlates([
        ...getPlatesFromCurrentOrder(
          cart.plates_supermaker,
          platesSupermaker,
          false,
          setSelections,
          setTotal,
          true
        ),
      ]);
      setGetData(true);
    }
  };

  const handleGetCombosFromCurrentOrder = (combos_) => {
    if (cart.combos?.length > 0) {
      setCombos([
        ...getPlatesFromCurrentOrder(
          cart.combos,
          combos_,
          true,
          setSelections,
          setTotal,
          true
        ),
      ]);
      setGetCombos(true);
    }
  };

  const handleGoSuperMeat = () => {
    setSelectedOption("meat");
    navigation.navigate("Supermarket");
  };

  const handleGoSuperCombos = () => {
    setSelectedOption("combos");
    navigation.navigate("Supermarket");
  };

  const handleGoSuperVegetables = () => {
    setSelectedOption("vegetable");
    navigation.navigate("Supermarket");
  };

  return (
    <View
      style={sections(background == "dark", !isLoggedIn ? 530 : 470, 25, 20)}
    >
      <View style={isComputer && sectionContainerWeb(1000, null, "100%")}>
        <Text
          style={[
            styles.cardText,
            isComputer && { fontSize: 26, lineHeight: 40 },
          ]}
        >
          Supermercado
        </Text>
        <Text
          style={[paragraph("100%", isComputer && 18), { marginBottom: 22 }]}
        >
          Porciones individuales de todos nuestros platillos.
        </Text>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <SmallOrangeCards
            icon="soup"
            text="Combos"
            selectedOption={selectedOption}
            onPress={handleGoSuperCombos}
            // onPress={setSelectedOption}
            // list={combos}
          />
          <SmallOrangeCards
            icon="steak"
            text="Carnes"
            // selectedOption={selectedOption}
            onPress={handleGoSuperMeat}
            //onPress={setSelectedOption}
            list={meatsList}
          />
          <SmallOrangeCards
            icon="vegetables"
            text="Vegetales"
            selectedOption={selectedOption}
            onPress={handleGoSuperVegetables}
            //onPress={setSelectedOption}
            //list={vegetablesList}
          />
          <SmallOrangeCards
            icon="spaghetti"
            text="Ver Todo"
            //selectedOption={selectedOption}
            onPress={handleGoSuperCombos}
            //onPress={setSelectedOption}
            //list={listMenu}
          />
        </View>
        <View>
          {!loding ? (
            <FlatList
              showsHorizontalScrollIndicator={false}
              horizontal
              bounces={false}
              keyExtractor={(item, index) => index}
              style={{
                width: !isComputer ? width - 40 : "100%",
                marginVertical: 12,
              }}
              contentContainerStyle={
                isComputer
                  ? flexContainer("row", "space-evenly", null, "wrap")
                  : undefined
              }
              ref={(ref) => {
                setRef(ref);
              }}
              data={listMenu}
              renderItem={({ item, index }) => {
                const itemType = getItemType(item);
                const itemQuantity = getItemQuantity(
                  item?.id,
                  itemType,
                  getProductById
                );

                return (
                  <DishCard
                    uniqueKey={`miniSupermarket-${index}`}
                    id={item.id}
                    uri={true}
                    image={!item?.plates ? item?.image : item.plates[0].image}
                    recipe={item.recipe || item.name}
                    price={item.price}
                    icon={"plusCircle"}
                    combo={item?.plates ? true : false}
                    quantity={itemQuantity}
                    updatingCart={updatingCart}
                    onPress={() => handleAddToCart(itemType, item)}
                    onDelete={() => handleDeleteToCart(itemType, item.id)}
                    variation={
                      !item?.plates ? item.type_variation : item.cant_people
                    }
                    goTo={(params) => {
                      navigation.navigate("Supermarket", {
                        screen: "detailsPlate",
                        params,
                      });
                    }}
                  />
                );
              }}
            />
          ) : (
            <View
              style={[styles.justifyContent, { width: "100%", height: 199 }]}
            >
              <ActivityIndicator size="small" color={"black"} />
            </View>
          )}
        </View>
        <View
          style={{
            marginBottom: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GeneralButton
            text="Ir al Supermercado"
            handleOnPress={() => navigation.navigate("Supermarket")}
            width={isComputer ? 356 : "100%"}
            // loading={lodingButton}
            // disabled={lodingButton || loding}
          />
        </View>
      </View>
      {!isLoggedIn && !isComputer ? (
        <OrangeCard
          title="Consulta Nutricional"
          text="Si lo desea, agende una cita antes de iniciar."
          priceOrDescription=""
          typeButton="general"
          textButton="Agendar Cita"
          onPress={() => navigation.navigate("Signup")}
          widthButton="100%"
          height={170}
        />
      ) : null}
      {!isLoggedIn && isComputer ? (
        <View
          style={[
            sectionContainerWeb(1000, null, "100%"),
            flexContainer("row", "center", "center", "wrap", "center", 100, 30),
            { marginTop: 30 },
          ]}
        >
          <View
            style={{
              width: 448,
              height: 349,
              borderRadius: 16,
              overflow: "hidden",
            }}
          >
            <Image
              source={require("../../../assets/illustrations/Consulta-Nutricional-Barana.jpg")}
              style={{ width: "100%", height: "100%" }}
            />
          </View>
          <View
            style={[
              flexContainer(
                "column",
                "space-between",
                "center",
                null,
                null,
                20,
                20
              ),
              { width: 448 },
            ]}
          >
            <Text style={webTitle("left")}>Consulta Nutricional</Text>
            <Text style={paragraph("100%", 18)}>
              Ayudamos a la prevención, diagnóstico y tratamiento de cambios
              nutricionales y metabólicos del cuerpo. Si lo desea, agende una
              cita antes de iniciar con su plan de alimentación.
            </Text>
            <GeneralButton
              text="Agendar Cita"
              handleOnPress={() => navigation.navigate("Signup")}
              width={356}
            />
          </View>
        </View>
      ) : null}
    </View>
  );
};
