import { useEffect, useState } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//Styles
import {
  styles,
  title,
  titleMargin,
  spaceBetween,
  buttomOption,
  webContainer,
} from "../../../styles/pages/generalStyles";
import { signupStyles } from "../../../styles/pages/signup/signup";
//controllers
import { payNotIban } from "../../controllers/paymentMethods";
import { PopUp } from "../../widgets/popUp";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";
import useCartStore from "../shoppingCart/useCartStore";
import useUserData from "../../../hooks/useUserData";

export const MethodOfPayment = ({ navigation, route, bottomTabNavigation }) => {
  const { access_token } = useUserData();
  const { cart, clearCart, setCart } = useCartStore();
  const [showModal, setShowModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { isComputer } = useIsWeb();
  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  const edit = route?.params?.to === "edit";

  const handlePayNotIban = async (type) => {
    setPaymentModal(true);

    await payNotIban(access_token, {
      shopping_cart: cart.shopping_cart,
      type_pay: type,
    })
      .then(async (res) => {
        if (res) {
          setShowModal(true);
        }
      })
      .catch((err) => {
        setErrorModal(true);
        setErrorMessage(err.message);
      })
      .finally(() => {
        setPaymentModal(false);
      });
  };

  return (
    <View style={styles.screen}>
      <ScrollView>
        <View
          style={[
            styles.container,
            webContainer(null, 10),
            isComputer && styles.sectionContainerWeb,
          ]}
        >
          <Text
            style={[
              title("100%", "center"),
              titleMargin(isComputer),
              { marginBottom: 30 },
            ]}
          >
            Método de Pago
          </Text>
          <TouchableOpacity
            onPress={() => {
              setCart({ ...cart, type_method_pay: "transferencia" });
              if (edit) {
                navigation.goBack();
              } else {
                handlePayNotIban("transferencia");
              }
            }}
            style={[buttomOption(54, 15, 16), spaceBetween()]}
          >
            <Text style={signupStyles.optionButtonText}>
              Transferencia Bancaria
            </Text>
            <View style={signupStyles.socialMediaIcon}>
              <BaranaIcons
                draw={{ width: 24, height: 24 }}
                name={"arrowsInCircle"}
              />
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setCart({ ...cart, type_method_pay: "sinpe-movil" });

              if (edit) {
                navigation.goBack();
              } else {
                setShowModal(true);
              }
            }}
            style={[buttomOption(54, 15, 16), spaceBetween()]}
          >
            <Text style={signupStyles.optionButtonText}>Sinpe Móvil</Text>
            <View style={signupStyles.socialMediaIcon}>
              <BaranaIcons draw={{ width: 24, height: 24 }} name={"phone"} />
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setCart({ ...cart, type_method_pay: "pago en linea" });
              if (edit) {
                navigation.goBack();
              } else {
                navigation.navigate("onlinePayment");
              }
            }}
            style={[buttomOption(54, 15, 16), spaceBetween()]}
          >
            <Text style={signupStyles.optionButtonText}>Pago en Línea</Text>
            <View style={signupStyles.socialMediaIcon}>
              <BaranaIcons
                draw={{ width: 28, height: 28 }}
                name={"onlinePayment"}
              />
            </View>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              setCart({ ...cart, type_method_pay: "otro" });
              if (edit) {
                navigation.goBack();
              } else {
                handlePayNotIban("otro");
              }
            }}
            style={[buttomOption(54, 15, 16), spaceBetween(), {}]}
          >
            <Text style={[signupStyles.optionButtonText, { width: "91%" }]}>
              {`Requiero otro método\n`}
              <Text style={styles.helpText}>
                {"(Será contactado para coordinar)"}
              </Text>
            </Text>
            <View style={{ alignContent: "center", marginRight: 2 }}>
              <BaranaIcons
                draw={{ width: 24, height: 24 }}
                name={"creditCard"}
              />
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>
      {showModal && (
        <PopUp
          showModal={showModal}
          openModal={setShowModal}
          title="Alerta"
          text="Por favor envíe el comprobante de pago por WhatsApp al 8888-2406. Si seleccionó la opción de Transferencia el número de cuenta será enviado a su correo electrónico con su orden de compra."
          buttonText={"Continuar"}
          handleOnPress={async () => {
            await AsyncStorage.setItem(
              "shoppingCart_copy",
              JSON.stringify(cart)
            );
            await AsyncStorage.removeItem("nutritionalConsultations");
            await clearCart();

            navigation.reset({
              index: 0,
              routes: [{ name: "thanksForYourPurchase" }],
            });
          }}
          button={true}
        />
      )}
      {paymentModal && (
        <PopUp
          showModal={paymentModal}
          openModal={setPaymentModal}
          title="Alerta"
          text="Procesando pago"
        />
      )}
      {errorModal && (
        <PopUp
          showModal={errorModal}
          openModal={setErrorModal}
          title="Alerta"
          text={errorMessage}
          buttonText={"Continuar"}
          button={true}
        />
      )}
    </View>
  );
};
