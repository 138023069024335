import { StyleSheet, Platform, Dimensions } from 'react-native'

const { width } = Dimensions.get("window");
export const styles = StyleSheet.create({
    error: {
        color: 'red',
        textAlign: 'left',
        width: '100%',
        marginLeft: '5%',
        fontFamily: 'Nunito Sans',
        fontWeight: '400',
        fontSize: 11,
    },
    rightIcon: {
        position: 'absolute',
        right: 16,
        top: 38
    }
})

export const input = (width_, marginT, height) => ({
    width: width_ || '100%',
    height: height ? height : Platform.OS == 'web' ? 45 : 54,
    borderRadius: 6,
    backgroundColor: 'white',
    fontFamily: 'Nunito Sans',
    fontWeight: '400',
    color: '#4F534F',
    fontSize: 17,
    lineHeight: 22,
    marginTop: marginT || 15,
    marginBottom: Platform.OS == 'web' ? 0 : 10,
})

export const maps = (width_, displayList) => ({
    textInputContainer: {
        width: width_ || '100%',
        minHeight: 54,
    },
    container: {
        width: '100%',
    },
    listView: {
        borderColor: '#949494',
        borderWidth: 1,
        width: width_ || '100%',
        display: displayList ? 'flex' : 'none'
    },
    textInput: {
        height: '100%',
        color: '#5d5d5d',
        fontSize: 16,
        borderColor: '#949494',
        borderWidth: 0.8,
        width: '100%',
    },
    predefinedPlacesDescription: {
        color: '#FF941A',
    },
    predefinedPlacesDescription: {
        color: 'black'
    }
})

export const shadedInput = (width_, minHeight) => ({
    width: width_ || '90%',
    minHeight: minHeight || 59,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#E7E7E7',
    backgroundColor: 'white',
    fontFamily: 'Nunito Sans',
    fontWeight: '400',
    fontSize: 17,
    lineHeight: 22,
    marginTop: width >= 1024 ? 0 : 5,
    // marginBottom: '3%',
    paddingHorizontal: 16,
    paddingVertical: 14
})

export const pickerContainer = (width_, height, top, marginL, marginR) => ({
    width: width_ || '100%',
    height: height || 154,
    borderWidth: 2,
    borderColor: '#E7E7E7',
    backgroundColor: 'white',
    position: 'absolute',
    top: top || 76,
    zIndex: 1,
    marginLeft: marginL || 0,
    marginRight: marginR || 0,
    paddingTop: 15
})

export const Left = (left) => ({
    left: left || 0
})

export const Right = (right) => ({
    right: right || 0
})

export const picker = (height) => ({
    backgroundColor: 'white',
    width: '100%',
    height: height || 150,
})

export const dropDownListIcon = (
    error
) => ({
    width: '95%',
    height: '60%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: error ? '22%' : '30%',
    paddingRight: 15,
})

export const helpIconInput = (
    error
) => ({
    height: '60%',
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: error ? '22%' : '30%',
    paddingRight: 15,
})
