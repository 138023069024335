import { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  FlatList,
  Image,
} from "react-native";
import { RadioButton } from "react-native-paper";
//icons
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//styles
import { styles, textAlignment } from "../../../../styles/pages/generalStyles";
import { centerAlignedRow } from "../../../../styles/pages/home/homePage";

export const CardItem = ({
  item,
  index,
  length,
  uniqueKey,
  handleRadioButton,
  checked,
  setChecked,
}) => {
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {}, [item]);

  const handleOnPress = (grr) => {
    setChecked(grr.id);
    handleRadioButton(grr, uniqueKey.includes("2") ? "listTwo" : "listOne");
  };

  return (
    <View key={uniqueKey}>
      <TouchableOpacity
        onPress={() => {
          if (item.alergenos?.length > 0 || item.description !== "")
            setShowDescription(!showDescription);
        }}
      >
        <View style={[centerAlignedRow(6, "space-between")]}>
          <View style={[centerAlignedRow(), { gap: 10, width: "80%" }]}>
            <RadioButton
              color={"#DB7500"}
              value={checked == item.id ? true : false}
              status={checked == item.id ? "checked" : "unchecked"}
              onPress={() => handleOnPress(item)}
              labelStyle={{ padding: 0, margin: 0 }}
            />
            <Text
              style={[
                styles.helpText,
                textAlignment("left", null, "fit-content"),
              ]}
            >
              {item.recipe}
            </Text>
            {item.additional_amount ? (
              <Text style={styles.orangeText}>+₡{item.additional_amount}</Text>
            ) : null}
          </View>
          {item.alergenos?.length > 0 ? (
            <BaranaIcons draw={{ width: 24, height: 24 }} name={"rollDown"} />
          ) : null}
        </View>
      </TouchableOpacity>
      {showDescription ? (
        <View style={{ marginVertical: 10, marginHorizontal: 20 }}>
          {item?.description ? (
            <Text style={[styles.helpText, textAlignment("left", 5, "88%")]}>
              {item.description}
            </Text>
          ) : null}
          <FlatList
            bounces={false}
            style={{ width: 150 }}
            data={item.alergenos}
            numColumns={3}
            renderItem={({ item, index }) => {
              return (
                <View key={`${index}-alergenos`} style={{ marginRight: 20 }}>
                  <Image
                    source={{ uri: item.image }}
                    style={{
                      width: 60,
                      height: 50,
                      objectFit: "cover",
                    }}
                  />
                </View>
              );
            }}
          />
        </View>
      ) : null}
      <View style={[styles.line, { marginVertical: 6 }]}></View>
    </View>
  );
};
