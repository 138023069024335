import { View, ScrollView, FlatList, Dimensions } from "react-native";
//Styles
import { styles } from "../../../../styles/pages/generalStyles";
//components
import { SubPackageListItems } from "./subPackageListItems";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";

const { width } = Dimensions.get("window");
export const PackageProductsList = ({
  armedPackages,
  handleDuplicateCartPackages,
}) => {
  const { isComputer } = useIsWeb();

  return (
    <View style={[styles.screen]}>
      <ScrollView
        showsHorizontalScrollIndicator={false}
        horizontal={true}
        contentContainerStyle={{ width: "100%" }}
      >
        <FlatList
          bounces={false}
          style={{ width: !isComputer ? width - 44 : "100%" }}
          data={armedPackages.sub_packages || armedPackages}
          keyExtractor={(item, index) => index}
          showsVerticalScrollIndicator={false}
          renderItem={({ item, index }) => {
            return item?.copy?.cant > 0 ? (
              <SubPackageListItems
                subPackage={item}
                handleDuplicateCartPackages={handleDuplicateCartPackages}
                armedPackageId={armedPackages.armed_package}
              />
            ) : null;
          }}
        />
      </ScrollView>
    </View>
  );
};
