import client from './httpClient'
import { addTrailingSlashOnIos } from "../utils/utils";

//--------------------------------------------------
//--------------ADDRESS REQUESTS-----------------------
//--------------------------------------------------

export const getListAddress = async (token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(addTrailingSlashOnIos(`/address`))
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const getAddress = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .get(addTrailingSlashOnIos(`/address/${id}`))
    .then((value) => {
      return value.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });
  return response;
};

export const createAddress = async (data, token) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .post(`/address/`, data)
    .then((value) => {
      return { ...value.data, created: true };
    })
    .catch((error) => {
      throw new Error(error.response.data?.message);
    });
  return response;
};

export const updateAddress = async (token, id, data) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = await client
    .patch(`/address/${id ? id : ""}${id ? "/" : ""}`, data)
    .then((value) => {
      return { ...value.data, updated: true };
    })
    .catch((error) => {
      throw new Error(error.response.data?.message);
    });
  return response;
};

export const deleteAddress = async (token, id) => {
  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  try {
    const response = await client.delete(`/address/${id}/`);
    return true;
  } catch (error) {
    return false;
  }
};