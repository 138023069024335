import { useContext, useState } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useTheme } from "react-native-paper";
import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
//icons
import { BaranaIcons } from "../../assets/icons/BaranaIcons";
// contexts
import { mainContext } from "../../context/mainContext";
import { subscription } from "../../context/subscription";
import { nutritionalPrescription } from "../../context/nutritionalPrescription";
//screens
import { HomeNavigator } from "./homeNavigator";
import { OrdersNavigator } from "./ordersNavigator";
import { ShoppingCartNavigator } from "./shoppingCartNavigator";
import { ProfileNavigator } from "./profileNavigator";
//styles
import { bottomBar } from "../../styles/pages/buttomNavigator/bottomNavigatorsStyle";
//hooks
import useIsWeb from "../../hooks/useIsWeb";
import useUserData from "../../hooks/useUserData";
import { Platform } from "react-native";

const getTabBarVisibility = (route, userData) => {
  const routeName = getFocusedRouteNameFromRoute(route) ?? "";
  switch (routeName) {
    case "homePage":
      return userData;
    case "orderType":
      return false;
    case "mealTimes":
      return false;
    case "numberOfMeals_breakfasts":
      return false;
    case "numberOfMeals_lunches":
      return false;
    case "numberOfMeals_dinners":
      return false;
    case "foodMenu_breakfasts":
      return false;
    case "foodMenu_lunches":
      return false;
    case "foodMenu_dinners":
      return false;
    case "plans":
      return false;
    case "editPlan":
      return false;
    case "detailsMenu":
      return false;
    case "":
      return false;
    default:
      return false;
  }
};

const Tab = createBottomTabNavigator();
export function ToolBar() {
  const { isComputer } = useIsWeb();
  const { colors } = useTheme();
  const { isLoggedIn } = useUserData();
  const [createSubscription, setCreateSubscription] = useState({});
  //nutritionalPrescription
  const [nutritionalPlan, setNutritionalPlan] = useState({});
  const [planPortions, setPlanPortions] = useState([]);
  return (
    <nutritionalPrescription.Provider
      value={{
        nutritionalPlan,
        setNutritionalPlan,
        planPortions,
        setPlanPortions,
      }}
    >
      <subscription.Provider
        value={{ createSubscription, setCreateSubscription }}
      >
        <Tab.Navigator
          initialRouteName={"Home"}
          screenOptions={({ route }) => ({
            tabBarIcon: ({ focused, color, size }) => {
              let iconName;
              if (route.name === "Home") {
                iconName = "home";
              } else if (route.name === "ShoppingCart") {
                iconName = "shoppingCart";
              } else if (route.name === "Orders") {
                iconName = "orders";
              } else if (route.name === "Profile") {
                iconName = "profile";
              }
              return (
                <BaranaIcons
                  draw={
                    isComputer
                      ? {
                          width: size + 5,
                          height: size + 5,
                          focused,
                          color: colors.primary050,
                          focusedColor: colors.primary050,
                        }
                      : {
                          width: size + 3,
                          height: size + 3,
                          focused,
                        }
                  }
                  name={iconName}
                />
              );
            },
            tabBarActiveBackgroundColor:
              isComputer && "rgba(255, 255, 255, 0.3)",
            tabBarItemStyle: { borderRadius: 20 },
            tabBarActiveTintColor: isComputer ? colors.primary050 : "#828282",
            tabBarLabelStyle: {
              fontFamily: Platform.OS === 'web'  ? "sans-serif" : undefined,
              fontSize: isComputer ? 15 : 12,
              letterSpacing: 0,
              paddingBottom: 0,
              color: isComputer ? colors.primary050 : "#828282",
            },
            tabBarStyle: [
              bottomBar(isComputer ? "#DB7500" : colors.primary050),
              null,
            ],
          })}
        >
          <Tab.Screen
            name="Home"
            options={({ navigation, route }) => ({
              headerTitle: () => null,
              title: "Home",
              headerShown: false,
              tabBarVisible: getTabBarVisibility(route, isLoggedIn),
            })}
          >
            {({ navigation, route }) => (
              <HomeNavigator navigation={navigation} route={route} />
            )}
          </Tab.Screen>

          <Tab.Screen
            name="ShoppingCart"
            options={({ navigation }) => ({
              headerTitle: () => null,
              title: "Carrito",
              headerShown: false,
            })}
          >
            {({ navigation, route }) => (
              <ShoppingCartNavigator navigation={navigation} route={route} />
            )}
          </Tab.Screen>

          <Tab.Screen
            name="Orders"
            options={({ route }) => ({
              headerTitle: () => null,
              title: "Pedidos",
              headerShown: false,
            })}
          >
            {({ navigation }) => <OrdersNavigator navigation={navigation} />}
          </Tab.Screen>

          <Tab.Screen
            name="Profile"
            options={({ route }) => ({
              headerTitle: () => null,
              title: `Perfil`,
              headerShown: false,
            })}
          >
            {({ navigation }) => <ProfileNavigator navigation={navigation} />}
          </Tab.Screen>
        </Tab.Navigator>
      </subscription.Provider>
    </nutritionalPrescription.Provider>
  );
}
