import { Svg, Path } from 'react-native-svg';

export const CreditCard = ({
    color,
    height,
    width,
    active = false,
    focused = false
}) => {
    return <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <Path d="M1 10H23" stroke="#DB7500" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
}