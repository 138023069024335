import React from "react";
import { View, Text, ScrollView, ImageBackground } from "react-native";
//Styles
import {
  styles,
  paragraph,
  title,
  sectionContainerWeb,
} from "../../../../styles/pages/generalStyles";

export const ServiceFeatures = ({ navigation, route }) => {
  return (
    <View style={[styles.screen, { alignItems: "center" }]}>
      <ScrollView
        style={[styles.scrollScreen, { paddingHorizontal: 0, width: "100%" }]}
      >
        <ImageBackground
          source={require("../../../../assets/illustrations/caracteristicas-banner.png")}
          style={{
            width: "100%",
            height: 300,
          }}
          resizeMode="cover"
        />
        <View style={[sectionContainerWeb(), styles.homeContainer]}>
          <Text style={paragraph("100%", 17, 10)}>
            En Baraná queremos facilitarle su vida, preparándole platillos
            sabrosos, llenos de vitaminas y minerales que contribuyan a mejorar
            su estado de salud. Le ofrecemos almuerzos y cenas balanceadas,
            preparados con ingredientes frescos y bien sazonados.
          </Text>

          <Text style={title("100%", "left")}>¿Qué le ofrecemos?</Text>
          <ul>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Le entregamos su comida preparada en la puerta de su hogar u
                oficina.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Nuestra nutricionista adaptará las preparaciones a sus
                necesidades y preferencias alimenticias.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                ¡Podrá disfrutar de un almuerzo hecho como en casa! Nuestros
                platillos están elaborados con ingredientes cuidadosamente
                seleccionados.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>Le ayudamos a:</Text>
              <ul>
                <li>
                  <Text style={paragraph("100%", 17, 10)}>
                    Controlar su peso
                  </Text>
                </li>
                <li>
                  <Text style={paragraph("100%", 17, 10)}>
                    Regular los niveles de azúcar en la sangre.
                  </Text>
                </li>
                <li>
                  <Text style={paragraph("100%", 17, 10)}>
                    Disminuir la presión arterial
                  </Text>
                </li>
                <li>
                  <Text style={paragraph("100%", 17, 10)}>
                    Bajar el colesterol y los triglicéridos
                  </Text>
                </li>
                <li>
                  <Text style={paragraph("100%", 17, 10)}>
                    Mejorar sus niveles de ácido úrico
                  </Text>
                </li>
                <li>
                  <Text style={paragraph("100%", 17, 10)}>
                    Reducir su riesgo cardiovascular
                  </Text>
                </li>
              </ul>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Ponemos a su disposición un servicio de consulta nutricional.
              </Text>
            </li>
          </ul>

          <Text style={title("100%", "left")}>
            Nuestra labor se basa en 6 pilares
          </Text>

          <Text style={[paragraph("100%", 24, 10), { fontWeight: "bold" }]}>
            1. Adecuado balance nutricional
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Todos nuestra comida preparada es nutricionalmente balanceada.
            Nuestros almuerzos y cenas incluyen un plato principal elaborado con
            una proteína animal (pollo, pescado, carne de res o cerdo), una
            guarnición harinosa y un acompañamiento vegetal. La elección y la
            combinación cuidadosa de los componentes de nuestros platillos
            permite establecer un adecuado balance entre los diferentes grupos
            de alimentos y sus propiedades nutricionales.
          </Text>

          <Text style={[paragraph("100%", 24, 10), { fontWeight: "bold" }]}>
            2. La calidad nuestra prioridad
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            La selección y elaboración de los alimentos está supervisada por
            Laura, nuestra nutricionista. Por lo que las recetas se preparan con
            ingredientes frescos, de alta calidad y llenos de nutrientes,
            vitaminas y minerales esenciales para el buen funcionamiento de su
            cuerpo.
          </Text>
          <ul>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                No añadimos conservantes ni químicos a nuestra comida.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Nuestros platillos son libres de grasa trans.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Utilizamos aceite de oliva apto para cocinar
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Controlamos la cantidad de sal y grasa de cada preparación.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Mono-ración: Raciones individuales.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Fácil preparación: Tus platos listos en menos de 3 minutos al
                microondas
              </Text>
            </li>
          </ul>

          <Text style={[paragraph("100%", 24, 10), { fontWeight: "bold" }]}>
            3. En la variedad está el gusto
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Utilizamos una gran variedad de ingredientes, especias y hierbas con
            cualidades nutricionales distintas que combinamos para crear un menú
            variado, rico y sano.
          </Text>

          <Text style={[paragraph("100%", 24, 10), { fontWeight: "bold" }]}>
            4. Proceso de elaboración
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Nuestro proceso productivo garantiza el máximo aprovechamiento de
            las vitaminas y los minerales que se encuentran en los alimentos.
            Las técnicas de cocción y el equipo especializado capaz de regular
            la temperatura y humedad a la que se cocinan los alimentos permite
            reducir de forma significativa la pérdida de nutrientes y obtener
            una cocción uniforme. Seguimos un leve proceso de pasteurización
            seguido de un enfriamiento rápido que garantizan las condiciones
            higiénico sanitarias de nuestra comida.
          </Text>

          <Text style={[paragraph("100%", 24, 10), { fontWeight: "bold" }]}>
            5. Etiquetado nutricional
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Nuestros paquetes Bienestar incluyen información detallada sobre su
            contenido nutricional por si desea llevar un control de los
            alimentos saludables que consume durante el día.
          </Text>

          <Text style={[paragraph("100%", 24, 10), { fontWeight: "bold" }]}>
            6. Supervisión nutricional
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Trabajamos bajo la guía de una nutricionista que cuida cada detalle
            durante la selección de materias primas y la elaboración de nuestros
            platillos, velando por su balance nutricional y el cumpliento de las
            buenas prácticas de manipulación de alimentos. Por lo que le
            aseguramos que la comida preparada que consumirá es saludable y
            balanceada de verdad.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};
