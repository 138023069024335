import { useEffect } from 'react'
import { Text, View } from "react-native";
//color
import { TextInput } from "react-native-paper";
//style
import { styles, input } from "../../styles/pages/widgets/inputs";
//hooks
import useIsWeb from "../../hooks/useIsWeb";

// noWeb prop  =>  indicates whether to make use of the default input style for the web.

export const InputOverall = (props) => {
  const { isComputer } = useIsWeb();
  const { errors, icon, label, type, marginT, width_, multiline, noWeb } =
    props;

  useEffect(() => {}, [multiline]);

  return (
    <View style={{ width: !noWeb && isComputer ? 330 : width_ || "100%" }}>
      <TextInput
        label={type == "flat" ? "" : label}
        style={[
          input("100%", marginT, multiline && 127),
          styles[type],
          multiline && { textAlignVertical: "top" },
        ]}
        mode={type}
        {...props}
        underlineColor="#e8e8e8"
        outlineColor="#d1d1d1"
        theme={{ colors: { text: "#4F534F" } }}
      />
      {icon && icon}
      {errors ? <Text style={styles.error}>{errors}</Text> : null}
    </View>
  );
};