import { useContext, useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
//icons
import { BaranaIcons } from "../../../../assets/icons/BaranaIcons";
//Styles
import {
  paragraph,
  spaceBetween,
} from "../../../../styles/pages/generalStyles";
import {
  homeStyles,
  centerAlignedRow,
} from "../../../../styles/pages/home/homePage";
//utils
import { getItemType } from "../../../utils/shoppingCartUtils";
//hooks
import useCartStore from "../useCartStore";
import useDebounce from "../../../../hooks/useDebounce";
import useUserData from "../../../../hooks/useUserData";

export const SupermarketItem = ({ item, uniqueKey, editable }) => {
  const { access_token } = useUserData();
  const [loading, setLoading] = useState(false);
  const { addToCart, removeFromCart, createOrUpdateCart, cart } =
    useCartStore();
  const { debounceFunction: createOrUpdateCartDebounce } = useDebounce(
    (...args) => {
      setLoading(true);
      createOrUpdateCart(...args).finally(() => {
        setLoading(false);
      });
    }
  );
  const itemType = getItemType(item);

  const handleAddToCart = () => {
    addToCart(itemType, item);
    createOrUpdateCartDebounce(access_token, cart);
  };

  const handleDeleteToCart = () => {
    removeFromCart(itemType, item.plate_id || item.combo_id);
    createOrUpdateCartDebounce(access_token, cart);
  };

  return (
    <View
      key={uniqueKey}
      style={[
        centerAlignedRow(),
        spaceBetween(),
        { marginTop: 5, marginBottom: 5 },
      ]}
    >
      <Text style={[paragraph("45%")]} ellipsizeMode={"tail"} numberOfLines={2}>
        {item.recipe || item.name}
      </Text>
      <View style={[centerAlignedRow(null, "center"), spaceBetween(null, 70)]}>
        {editable ? (
          <>
            <TouchableOpacity
              disabled={loading}
              onPress={() => {
                handleDeleteToCart();
              }}
            >
              <BaranaIcons
                draw={{ width: 20, height: 20 }}
                name={editable && "outlinedMinus"}
              />
            </TouchableOpacity>
            <Text style={[homeStyles.strongText, { marginHorizontal: 5 }]}>
              {item.quantity}
            </Text>
            <TouchableOpacity
              disabled={loading}
              onPress={() => {
                handleAddToCart();
              }}
            >
              <BaranaIcons
                draw={{ width: 20, height: 20 }}
                name={editable && "outlinedPlus"}
              />
            </TouchableOpacity>
          </>
        ) : (
          <Text style={[homeStyles.strongText, { marginHorizontal: 5 }]}>
            {item.quantity}
          </Text>
        )}
      </View>
      {/* <Text style={[homeStyles.strongText, { marginRight: 19 }]}>
        {!editable && "x"}
      </Text> */}
      {/* <Text style={[homeStyles.strongText, { minWidth: 75 }]}> */}
      <Text style={[homeStyles.strongText, { width: 80, textAlign: "left" }]}>
        ₡
        {(
          item.unit_price * item.quantity ||
          item.price * item.count ||
          0
        ).toFixed(1)}
      </Text>
    </View>
  );
};
