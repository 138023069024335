import { useEffect } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/core";
//icons
import { BaranaIcons } from "../../assets/icons/BaranaIcons";
//styles
import { styles } from "../../styles/pages/generalStyles";
import * as widgetsStyles from "../../styles/pages/widgets/general";
import { centerAlignedRow } from "../../styles/pages/home/homePage";
//components
import { AddRemoveToCart } from "./cart/addRemoveToCart";

export const DishCard = ({
  uniqueKey,
  id,
  image,
  updatingCart,
  recipe,
  price,
  icon,
  uri,
  details,
  width,
  combo,
  onPress,
  quantity,
  onDelete,
  variation,
  goTo,
}) => {
  const goToDetails = () => {
    goTo({
      plate: { id, image, recipe, price, combo, quantity, variation },
    });
  };

  return (
    <View key={uniqueKey} style={[widgetsStyles.dishCardContainer(width)]}>
      {quantity && quantity > 0 ? (
        <View style={widgetsStyles.styles.dish_plus}>
          <AddRemoveToCart
            updatingCart={updatingCart}
            quantity={quantity}
            addToCard={() => onPress()}
            deleteToCard={() => onDelete()}
          />
        </View>
      ) : (
        <TouchableOpacity
          onPress={() => onPress()}
          style={widgetsStyles.styles.dish_plus}
        >
          <BaranaIcons draw={{ width: 32, height: 32 }} name={icon} />
        </TouchableOpacity>
      )}
      <View
        style={[
          widgetsStyles.styles.dishCard,
          centerAlignedRow(0, "space-between", "column", "flex-start"),
          { width: "100%", height: 165 },
        ]}
      >
        {uri ? (
          <TouchableOpacity
            onPress={() => goToDetails()}
            style={{ width: "100%" }}
          >
            <Image
              source={{
                uri: `${
                  image || require("../../assets/illustrations/toast.jpg")
                }`,
              }}
              style={widgetsStyles.styles.img}
            />
          </TouchableOpacity>
        ) : null}
        <Text
          onPress={() => goToDetails()}
          ellipsizeMode="tail"
          numberOfLines={2}
          style={[styles.generalText]}
        >
          {recipe}
        </Text>
        <View
          style={[centerAlignedRow(null, "space-between"), { width: "100%" }]}
        >
          <Text onPress={() => goToDetails()} style={[styles.generalText]}>
            ₡{price.toFixed(2)}{" "}
          </Text>
          {details ? (
            <TouchableOpacity onPress={() => goToDetails()}>
              <Text style={[styles.orangeText, { marginRight: 15 }]}>
                Detalles
              </Text>
            </TouchableOpacity>
          ) : null}
        </View>
      </View>
    </View>
  );
};
