import { useState, useEffect, useContext } from "react";
import { Platform } from "react-native";
import { createStackNavigator } from "@react-navigation/stack";
//screens
import { Signup } from "../screen/signup/signup";
import { RegistrationOptions } from "../screen/signup/registrationOptions";
import { AuthNavigator } from "./AuthNavigator";
//styles
import {
  styles,
  headerComponentStyle,
} from "../../styles/pages/navigators/headers";
//context
import { forgotContext } from "../../context/forgotContext";
//components
import { GoBack } from "../widgets/goBack";
//context
import useIsWeb from "../../hooks/useIsWeb";

export const SignupNavigator = ({ navigation, bottomTabNavigation }) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const Stack = createStackNavigator();
  const fromWeb = Platform.OS == "web";
  const { isComputer } = useIsWeb();

  useEffect(() => {
    if (bottomTabNavigation) {
      bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
    }
  });

  return (
    <forgotContext.Provider value={{ email, setEmail, code, setCode }}>
      <Stack.Navigator initialRouteName={"registrationOptions"}>
        <Stack.Screen
          name={"registrationOptions"}
          options={({ navigation }) => ({
            headerTitle: "",
            headerLeft: () =>
              fromWeb && (
                <GoBack navigator={navigation} icon={"arrowBack"} width={48} />
              ),
            headerStyle: [
              headerComponentStyle.containerHeight,
              fromWeb && headerComponentStyle.headerGeneral,
            ],
            headerShown: !isComputer,
          })}
        >
          {({ navigation, route }) => (
            <RegistrationOptions route={route} navigation={navigation} />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={"signup"}
          options={({ navigation }) => ({
            headerTitle: "",
            headerLeft: () => (
              <GoBack
                paddingTop={Platform.OS === "ios" ? 40 : 0}
                navigator={navigation}
                text={"Volver"}
                icon={"leftArrow"}
              />
            ),
            headerStyle: styles.headerWithBorder,
            // headerShown: !isComputer,
          })}
        >
          {({ navigation, route }) => (
            <Signup route={route} navigation={navigation} />
          )}
        </Stack.Screen>
        <Stack.Screen
          name={"auth"}
          options={{
            headerTitle: "",
            headerLeft: () => null,
            headerTransparent: true,
          }}
        >
          {() => <AuthNavigator bottomTabNavigation={bottomTabNavigation} />}
        </Stack.Screen>
      </Stack.Navigator>
    </forgotContext.Provider>
  );
};
