import React, { useContext, useEffect, useState } from "react";
import { ScrollView, FlatList, View, Dimensions, Text } from "react-native";
//components
import { PlateCard } from "./plateCard";
//styles
import { styles, flexContainer } from "../../../../styles/pages/generalStyles";
//hooks
import useIsWeb from "../../../../hooks/useIsWeb";
import useOrderStore from "../../../../hooks/useOrderStore";
import { groupBy, orderByDayOfWeek, capitalize } from "../../../utils/utils";

export const MealTimeMenu = ({
  navigation,
  plates,
  handleOnPressMenu,
  openModalGarrinsons,
  editPlates,
}) => {
  const { windowWidth } = useIsWeb();
  const [platesData, setPlatesData] = useState([]);
  const { packageType, timeFood } = useOrderStore();

  useEffect(() => {
    const auxData = orderByDayOfWeek(
      Object.entries(groupBy(plates, "day_week"))
    );
    setPlatesData(auxData);
  }, [plates]);

  return (
    <ScrollView
      showsHorizontalScrollIndicator={false}
      style={{ width: "100%", paddingBottom: 30 }}
      contentContainerStyle={{ width: "100%" }}
    >
      {platesData.map((plateInfo, i) => {
        return (
          <View
            key={plateInfo[0]}
            style={[
              flexContainer(
                "column",
                "center",
                windowWidth < 940 ? "center" : null,
                "wrap",
                null,
                20
              ),
            ]}
          >
            {timeFood.almuerzos ^ timeFood.cenas ? (
              <Text
                style={[
                  styles.cardText,
                  {
                    marginVertical: 22,
                  },
                ]}
              >
                {capitalize(plateInfo[0])}
              </Text>
            ) : null}

            <View
              style={flexContainer(
                "row",
                windowWidth < 940 ? "space-around" : "space-between",
                "center",
                "wrap",
                null,
                20
              )}
            >
              {plateInfo[1].map((plate, index) => {
                return (
                  <PlateCard
                    item={plate}
                    navigation={navigation}
                    openModalGarrinsons={openModalGarrinsons}
                    handleOnPressMenu={handleOnPressMenu}
                    index={index}
                    editPlate={editPlates}
                  />
                );
              })}
            </View>
          </View>
        );
      })}
    </ScrollView>
  );
};
