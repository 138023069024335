import React from "react";
import { View, Text, ScrollView, ImageBackground } from "react-native";
//Styles
import {
  styles,
  paragraph,
  title,
  sectionContainerWeb,
} from "../../../../styles/pages/generalStyles";

export const EnvironmentalPolicy = ({ navigation, route }) => {
  return (
    <View style={[styles.screen, { alignItems: "center" }]}>
      <ScrollView
        style={[styles.scrollScreen, { paddingHorizontal: 0, width: "100%" }]}
      >
        <ImageBackground
          source={require("../../../../assets/illustrations/politica-ambiental-banner.png")}
          style={{
            width: "100%",
            height: 300,
          }}
          resizeMode="cover"
        />
        <View style={[sectionContainerWeb(), styles.homeContainer]}>
          <Text style={paragraph("100%", 17, 10)}>
            Para nosotros es muy importante el adecuado manejo de los residuos
            que generamos y disminuir los efectos adversos al medio ambiente de
            nuestro servicio de alimentación.
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Por lo anterior tomamos las siguientes acciones:
          </Text>
          <ul>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Repartimos nuestros alimentos 1 vez por semana. Disminuyendo el
                consumo de hidrocarburos que podría representar la entrega
                varias veces por semana de nuestros productos.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Separamos los residuos que generamos durante la producción de
                nuestros alimentos para que puedan ser reciclados, por medio del
                servicio que brinda la Municipalidad de San José.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Todos los materiales que utilizamos en nuestros empaques pueden
                ser reciclados. Únicamente deben ser lavados y secados antes de
                ser entregados a los centros de reciclaje.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Compramos equipo de refrigeración con refrigerante natural que
                además de proteger la capa de ozono, no contribuye a generar
                gases de efecto invernadero.
              </Text>
            </li>
          </ul>

          <Text style={[title()]}>
            Procedimiento para el manejo de empaques retornables
          </Text>
          <Text style={paragraph("100%", 17, 15)}>
            A continuación, se describe cómo funciona el procedimiento para
            hacer un mejor uso de las bandejas de empaque que utiliza Baraná, y
            así lograr su reutilización.
          </Text>
          <Text style={[paragraph("100%", 17, 10), { fontWeight: "bold" }]}>
            Responsabilidades del cliente
          </Text>
          <ul>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Poner los alimentos en refrigeración a menos de 5°C al recibir
                los alimentos.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Retirar el plástico que cubre el plato antes de calentar en el
                microondas. Ese plástico se puede lavar y reciclar.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Lavar el recipiente con jabón y una esponja suave para eliminar
                la grasa; procurando no romper o rayar el material que recubre
                el recipiente para que pueda ser reutilizado. Si este se rompe,
                el recipiente solo podráá ser reciclado.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Dejar secar el recipiente.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Entregar los recipientes a nuestro repartidor al momento de la
                entrega semanal de alimentos (No se recibirán recipientes
                sucios).
              </Text>
            </li>
          </ul>
          <Text style={[paragraph("100%", 17, 15), { fontWeight: "bold" }]}>
            Responsabilidades Baraná
          </Text>
          <ul>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Seleccionar los recipientes en buen estado.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Lavar los recipientes seleccionados y desinfectarlos.
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                Reciclar los recipientes en mal estado.
              </Text>
            </li>
          </ul>

          <Text style={[title()]}>
            ¿Por qué no utilizamos empaques biodegradables?
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Hay dos tipos de materiales biodegradables que podríamos utilizar,
            en primer lugar, se encuentran los que se producen a base de cartón
            o plantas naturales. Estos tienen la desventaja de que no permiten
            almacenar la comida por varios días, pues se deshacen. Así mismo
            dentro de la oferta de empaques nacionales lo que se encuentra en su
            mayoría son cajas de cartón no aptas para las preparaciones que
            realizamos pues se derramarían durante su transporte.
          </Text>
          <Text style={paragraph("100%", 17, 10)}>
            Por otro lado, están los empaques realizados con bioplásticos, que
            ahora están tomando fuerza en el mercado y se venden como
            biodegradables. En un principio estábamos buscando proveedores en
            estos materiales, pero nos dimos cuenta que lamentablemente generan
            otro problema, los microplásticos. Esto porque en su composición
            combinan materiales biodegradables con plástico para hacerlos más
            resistentes. Lo que significa que, al biodegradarse, liberan en el
            ambiente un montón de plástico que puede diluirse en nuestros mares.
            Pueden leer más sobre este tema en este estos enlaces:
          </Text>
          <ul>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                <a href="https://sustainablepackaging.org/biodegradability-additives-cannot-alleviate-ocean-plastic-pollution/">
                  Biodegradability Additives Cannot Alleviate Ocean Plastic
                  Pollution
                </a>
              </Text>
            </li>
            <li>
              <Text style={paragraph("100%", 17, 10)}>
                <a href="https://www.ecoenclose.com/bioplastic-is-not-plastic-free/">
                  Bioplastic is not plastic-free
                </a>
              </Text>
            </li>
          </ul>
          <Text style={paragraph("100%", 17, 10)}>
            Por lo anterior, consideramos que la alternativa ambientalmente
            responsable y que al mismo tiempo permite recibir la comida en buen
            estado es la de la{" "}
            <a href="https://news.un.org/es/interview/2018/12/1447801">
              economía circular
            </a>
            que busca la reutilización de los recursos, en lugar de utilizar
            materiales vírgenes para producir.
          </Text>
        </View>
      </ScrollView>
    </View>
  );
};
