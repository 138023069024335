import { createNavigationContainerRef } from "@react-navigation/core";

export const navigationRef = createNavigationContainerRef();

export const navigate = (name, params) => {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
};

export const reset = (...args) => {
  if (navigationRef.isReady()) {
    navigationRef.reset(...args);
  }
};
