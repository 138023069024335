import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAmp_3BTXOAQqVhfxSAy2w9Y3Ed_mQXY6Y",
  authDomain: "barana-app-697d4.firebaseapp.com",
  projectId: "barana-app-697d4",
  storageBucket: "barana-app-697d4.appspot.com",
  messagingSenderId: "722280454914",
  appId: "1:722280454914:web:5d447752e1ab8bcb43801b",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

//IOS: 722280454914-nph5gfoh7vjumv4g0scjm064fmgl5ksa.apps.googleusercontent.com
//ANDROID: 722280454914-fp9htcf0a20o7n54odvummkrm3275bkk.apps.googleusercontent.com
