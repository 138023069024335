
import { View } from 'react-native';
//styles
import { styles } from '../../styles/pages/widgets/general'

export const LowerOptionsContainer = ({ children, style }) => {
  return <View style={[styles.LowerOptionsContainer, style]}>{children}</View>;
};

export const LowerOptionsContainerH = ({ children, showFixedCard }) => {
    return (
        <View style={[styles.LowerOptionsContainer, { display: !showFixedCard ? 'none' : 'flex' }]}>
            {children}
        </View>
    )
}