import { View, Text, ScrollView, FlatList, Dimensions } from "react-native";
//icons
import { BaranaIcons } from "../../../assets/icons/BaranaIcons";
//styles
import {
  sectionContainerWeb,
  title,
  titleMargin,
  whyBaranaItem,
  flexContainer,
} from "../../../styles/pages/generalStyles";
import { homeStyles, sections } from "../../../styles/pages/home/homePage";
//utils
import { capitalize } from "../../utils/utils";
//hooks
import useIsWeb from "../../../hooks/useIsWeb";

const reasonsToChooseBarana = [
  {
    text: "Buena relación precio-calidad",
    icon: "scale",
  },
  {
    text: "Acompañamiento nutricional",
    icon: "fresh",
  },
  {
    text: "Control de grasa y sal",
    icon: "loupe",
  },
  {
    text: "Carnes magras",
    icon: "leanMeats",
  },
  {
    text: "Reducción de enlatados",
    icon: "canningReduction",
  },
  {
    text: "Uso de aceite de oliva",
    icon: "oliveOil",
  },
  {
    text: "Sin aditivos, consomes, etc",
    icon: "additive-free",
  },
  {
    text: "Preparaciones con pescado",
    icon: "fishPreparations",
  },
];

const { width } = Dimensions.get("window");
export const WhyBarana = ({ background }) => {
  const { isComputer } = useIsWeb();

  return (
    <View style={sections(background == "dark", 400, 36, 20)}>
      <View style={sectionContainerWeb(1000, null, "100%", "center")}>
        <Text
          style={[
            title("100%", isComputer ? "center" : "left", null, isComputer),
            titleMargin(isComputer),
          ]}
        >
          ¿Por qué Baraná?
        </Text>
        <View style={flexContainer("row", "center", null, "wrap", null, 15)}>
          {reasonsToChooseBarana?.map((item, index) => {
            return (
              <View
                key={`${index}-whyBarana`}
                style={whyBaranaItem(isComputer ? 238 : 160)}
              >
                <BaranaIcons
                  draw={{ width: 35, height: 35 }}
                  name={item.icon}
                />
                <Text
                  style={[
                    homeStyles.strongText,
                    { marginTop: 10, textTransform: "none", fontSize: 16 },
                  ]}
                >
                  {capitalize(item.text)}
                </Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};
