import { useEffect, useState } from "react";
import { NutritionalPlanList } from "../../plans/nutritionalPlanList";
//controllers
import { getPlansUser } from "../../../controllers/plans";
//utils
import { getNonEmptyKeys } from "../../../utils/utils";
//hooks
import useUserData from "../../../../hooks/useUserData";
import useNutritionalPrescription from "../../../../hooks/useNutritionalPrescription";
import useOrderStore from "../../../../hooks/useOrderStore";
//components
import { PopUp } from "../../../widgets/popUp";
import { capitalize } from "lodash";
import useModal from "../../../../hooks/useModal";

export const NutritionalPlans = ({
  navigation,
  bottomTabNavigation,
  route,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [planSelected, setPlanSelected] = useState();
  const { access_token } = useUserData();
  const {
    setNutritionalPlan,
    setIsCustom,
    setNutritionalPrescriptionInitialValue,
  } = useNutritionalPrescription();
  const { subpackage, setSubpackage, setPlan } = useOrderStore();
  const to = route.params?.to;
  const { openMessageModal } = useModal();

  useEffect(() => {
    bottomTabNavigation.setOptions({ tabBarStyle: { display: "none" } });
  });

  const handleGetPlans = async () => {
    try {
      const plans = await getPlansUser(access_token, "personalizado");
      return plans;
    } catch (error) {
      openMessageModal(error.message);
    }
  };

  const setPlanforThePackage = (plan) => {
    setPlanSelected(plan);
    const foodTimes = getNonEmptyKeys(plan.portions);

    if (!foodTimes.includes(to)) {
      setOpenModal(true);
    } else {
      setPlan(plan);
      if (to.includes("desayunos")) {
        setSubpackage({
          ...subpackage,
          desayunos: { ...subpackage?.desayunos, plan },
        });
      } else if (to.includes("almuerzos")) {
        setSubpackage({
          ...subpackage,
          almuerzos: { ...subpackage?.almuerzos, plan },
        });
      } else if (to.includes("cenas")) {
        setSubpackage({
          ...subpackage,
          cenas: { ...subpackage?.cenas, plan },
        });
      } else if (to.includes("familiar")) {
        setSubpackage({
          ...subpackage,
          generic: { ...subpackage?.generic, plan },
        });
      }
    }
  };

  return (
    <>
      <NutritionalPlanList
        handleGetNutritionalPlans={handleGetPlans}
        handleAddNutritionalPlan={() => {
          setNutritionalPrescriptionInitialValue();
          setIsCustom(true);
          navigation.navigate("nutritionalPrescription");
        }}
        handleConfirmButton={() => navigation.navigate("foodMenu")}
        handleCancelButton={() => navigation.goBack()}
        handleEditNutritionalPlan={(plan) => {
          setNutritionalPlan(plan);
          setIsCustom(true);
          navigation.navigate("nutritionalPrescription", { id: plan.id });
        }}
        handleSelectPlan={setPlanforThePackage}
      />
      {openModal && (
        <PopUp
          showModal={openModal}
          openModal={setOpenModal}
          title="Complete la cantidad de porciones"
          text={`Por favor complete la cantidad de porciones que desea para sus: ${capitalize(
            to
          )}`}
          button={true}
          buttonText={"Completar"}
          cancelButton={true}
          handleCancelOnPress={() => setOpenModal(false)}
          handleOnPress={() => {
            setPlan(planSelected);

            navigation.navigate("editPlan", {
              to,
              planId: planSelected.id,
            });
          }}
        />
      )}
    </>
  );
};
